/**
 * 车线管理常量文件
 
 
 */
import { orgFetchApiFormate } from './tools';

export const orgFetchApi = {
  url: 'Basic/Sug/companySug',
  formateFunc: orgFetchApiFormate,
};
// 下拉的url
export const sugUrl = {
  company_id: '/Basic/Org/orgSug', // 所属组织
  contract: '/Basic/CustomerContract/contractSug', // 客户合同名称
  customer_proj: '/Basic/CustomerProj/sfhSug', // 客户名称
  carrier: '/Basic/CustomerContract/contractSug', // 承运商合同名称
  carrier_proj: '/Basic/Carrier/dispatchCarrierSug', // 承运商名称
  project: '/Basic/CustomerProject/projectSug', // 项目名称
};
export const baseDataSettingKey = {
  // [车线管理的key]: 系统设置中设置项的key
  project: 'proj_data_source',
};
// 默认货物数据
export const defGoods = {
  g_name: '', // 货物名称
  g_type: '', // 货物类型
  g_pkg: '', // 货物包装
  g_num: '', // 默认件数
  g_weight: '', // 默认重量
  g_volume: '', // 默认体积
};
// export const historyLineTypeMap = {
//   1: '干线线路',
//   3: '提货线路',
//   4: '送货线路',
// }
// 车线类型 => 系统设置的key
export const lineType2SetKey = {
  1: 'tr_line_node_types',
  3: 'pickup_line_node_types',
  4: 'delivery_line_node_types',
};

// header ⬇
export const orgHeader = {
  id: '组织id',
  short_name: '组织简称',
  head_name: '联系人',
  query_phone: '联系方式',
  address_show_val: '组织地址',
  address_poi: '地址坐标',
  address_remark: '地址备注',
};
// 合同名称 下拉头部
export const contractHeader = [
  { key: 'name', title: '合同名称' },
  { key: 'project_name', title: '项目名称' },
  { key: 'customer_proj_name', title: '客户名称' },
  { key: 'company_name', title: '所属组织' },
  { key: 'use_corp_type', title: '使用组织' },
];
// 客户名称 下拉头部
export const customerProjHeader = [
  { key: 'proj_name', title: '客户名称' },
  { key: 'proj_type', title: '客户类型' },
  { key: 'company_name', title: '所属组织' },
  { key: 'use_corp_type', title: '使用组织' },
];
// 项目名称 下拉头部
export const projectHeader = [
  { key: 'name', title: '项目名称' },
  { key: 'customer_proj_name', title: '客户名称' },
  { key: 'company_name', title: '所属组织' },
  { key: 'use_corp_type', title: '使用组织' },
];
export const carrierHeader = [
  { key: 'name', title: '合同名称' },
  { key: 'project_name', title: '项目名称' },
  { key: 'carrier_name', title: '承运商名称' },
  { key: 'company_name', title: '所属组织' },
];
// 承运商名称 下拉头部
export const carrierProjHeader = [
  { key: 'carrier_name', title: '承运商名称' },
  { key: 'trans_type_show', title: '承运商类型' },
  { key: 'company_name', title: '所属组织' },
];
// header ⬆
// tips ⬇
export const effect_radius_tip = '线路调度时，发货地址或收货地址在调度半径范围内的运单可调度到该线路上。';
// tips ⬆

// 帮助中心的经验路线导航菜单ID
export const HELP_CENTER_MENU_ID = '149853';

// 收发货人sug 接口
export const ceeAndCorSug = {
  url: 'Basic/Nsug/ceeAndCorSug',
  para: {
    company_id: window.company_id,
    group_id: window.group_id,
    user_id: window.user_id,
  },
  key: 'search',
};

// 收发货人显示header
export const ceeAndCorHeader = [
  { key: 'name', title: '姓名' },
  { key: 'telephone', title: '手机号' },
  { key: 'species_name', title: '客户类型' },
  { key: 'address_show', title: '地址' },
  { key: 'address_remark', title: '地址备注' },
  { key: 'customer_cname', title: '单位' },
];

// 编辑字段配置
export const TAG_ENUM = {
  address: {
    editedKey: 'addressEdited',
    validateKey: 'poi',
  },
  addrRemark: {
    editedKey: 'addrRemarkEdited',
  },
};
