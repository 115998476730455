/**
 
 
 * Describe: 客户管理 - 客户tab 侧拉
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import {
  CardBox,
  SlideDrager,
  Button,
  Icon,
  Label,
  PureInput as Input,
  Select,
  AddrSug,
  TextArea,
  UploadFile,
  PopUp,
  Tabs,
  TabPane,
  PureTextarea,
  DateTimePicker,
} from 'components';
import { ERROR, CHECK, MOBILE, PERCENT, NUMBER_NO_ZERO } from 'constants';
import { bem, showInfo, fetchApi as fetch, validateFieldsOf, hasPermission } from 'utils';
import SiderDrager from 'pages/CompanyController/companyGroup/components/SiderDrager';
import Contract from 'pages/ContractController/Entity';
import List from './List';
import { getCardHeaderClass, getCardBodyClass, getItemClass, handleFieldSet } from './tools';
import { prefixCls } from './index.scss';

const { FormItem } = CardBox;
const cls = bem(prefixCls);

export default class CustomerOp extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    opType: PropTypes.oneOf(['add', 'update', 'view']).isRequired,
    title: PropTypes.string,
    resData: PropTypes.object,
    close: PropTypes.func,
    onConfirm: PropTypes.func,
    togglePopWidth: PropTypes.func,
    popContainer: PropTypes.object,
  };

  static defaultProps = {
    title: '新增客户',
  };

  constructor(props) {
    super(props);
    const resData = props.resData || {};
    console.log(resData);
    const { info = {}, enums, contractList, business_license } = resData;
    const enumData = enums || {};
    const { salesmen, salesmen_name, salesmen_phone } = info;
    if (salesmen && salesmen_name) {
      enumData.salesmen = [{ key: salesmen, name: salesmen_name, telephone: salesmen_phone }];
    }
    enumData.gps_type = Object.keys(enums.gps_type).map(key => ({ key, name: enums.gps_type[key].display }));
    const data = { ...info };
    this.state = {
      card: {},
      data,
      enums: enumData,
      contractList,
      // eslint-disable-next-line react/no-unused-state
      business_license,
      organisation: [],
    };
    this.fieldSet = handleFieldSet(); // 获取系统设置的显示必填数据
    this.isView = props.opType === 'view';
  }

  setCard = (key, value) => this.setState({ card: { ...this.state.card, [key]: value } });
  onChange = field => e => {
    const value = e.target ? e.target.value : e;
    this.setState({ data: { ...this.state.data, [field]: value } });
  };
  businessLicenseChange = field => e => {
    const value = e.target ? e.target.value : e;
    this.setState({ data: { ...this.state.data, [field]: value } });
  };
  isDisabled = field => this.props.opType === 'view';
  setEnum = obj => this.setState({ enums: { ...this.state.enums, ...obj } });

  handleHide = () => this.customerRef.handleHide();
  handleSubmit = async () => {
    if (!(await validateFieldsOf(this.wrap))) return false;
    const { id, opType } = this.props;
    const { required } = this.fieldSet;
    const { data } = this.state;
    const { salesmen_name, imgs, remark } = data;
    const { imgsRequired, remarkRequired } = required;

    if (imgsRequired && imgs === undefined) {
      showInfo(ERROR, '附件信息必填');
      return;
    }
    if (remarkRequired && !remark) {
      showInfo(ERROR, '备注信息必填');
      return;
    }
    const url = '/Basic/CustomerProj/opProj';
    const req = {
      id,
      op_type: opType,
      proj_info: data,
    };
    const conf = { method: 'POST', body: { req } };
    const res = await fetch(url, conf);
    if (+res.errno !== 0) {
      res.errmsg && showInfo(ERROR, res.errmsg);
      return;
    }
    res.errmsg && showInfo(CHECK, res.errmsg);
    if (salesmen_name) {
      res.salesmen_name = salesmen_name;
    }
    this.props.onConfirm && this.props.onConfirm(res);
    this.handleHide();
  };

  handleSalesmenChange = value => {
    const { show } = this.fieldSet;
    const { salesmenPhoneShow } = show;
    const obj = { salesmen: value.id, salesmen_name: value.name };
    if (salesmenPhoneShow) obj.salesmen_phone = value.telephone;
    this.setState({
      data: {
        ...this.state.data,
        ...obj,
      },
    });
  };
  UNSAFE_componentWillMount() {
    this.getOrganisation();
  }
  // 获取所属组织列表
  async getOrganisation() {
    const params = {};
    const conf = { method: 'POST', body: { req: params } };
    const url = '/Table/Suggest/orgSug';
    const res = await fetch(url, conf);
    console.log(res, 'ppp', res.error === 0);
    if (res.errno === 0) {
      this.setState({
        organisation: res.res,
      });
    }
  }
  // 业务员sug
  salesmenSug = async keyword => {
    const req = { slice_num: 50, type: 'sub', user_type: [1, 2], search: keyword };
    const conf = { method: 'POST', body: { req } };
    const url = '/Basic/Org/userSug';
    const res = await fetch(url, conf);

    const enums = (res.res || []).map(item => ({ ...item, key: item.id }));

    this.setEnum({ salesmen: enums });
  };
  // 业务员加号
  onSalesmenClick = () => {
    new PopUp(SiderDrager, {
      popName: 'addSalesman',
      type: 'User',
      opType: 'add',
      inforeq: { User_id: '0', type: 'User', readonly: false, name, src: 'customer_proj', show_type: '1' },
      opreq: { User_id: '', type: 'User' },
      title: '添加业务员',
      currId: window.company_id,
      ref: r => (this.addSalesmanSlider = r),
      onConfirm: (res, req) => {
        console.log(res, req);
        const salesmen_id = res.res[1];
        const info = req.org_info || {};
        const { name, telephone } = info;
        const salesmen_enum = this.state.enums.salesmen || [];
        this.setState({
          data: {
            ...this.state.data,
            salesmen_phone: telephone,
            salesmen: salesmen_id,
          },
          enums: {
            ...this.state.enums,
            salesmen: [...salesmen_enum, { key: salesmen_id, name }],
          },
        });
        this.addSalesmanSlider && this.addSalesmanSlider.handleHide();
      },
    }).show();
  };

  renderItem = ({
    field,
    show = true,
    required,
    label,
    Component,
    className,
    valueKey = 'value',
    eventKey = 'onChange',
    ...props
  }) => {
    const { data, enums } = this.state;

    if (label) {
      return (
        show && (
          <FormItem key={field} required={required} label={label} field={field} className={className}>
            {React.createElement(Component, {
              [valueKey]: data[field],
              required,
              [eventKey]: this.onChange(field),
              disabled: this.isDisabled(field),
              data: enums[field],
              ...props,
            })}
          </FormItem>
        )
      );
    }
    return (
      <div>
        <p>营业执照照片</p>
        {React.createElement(Component, {
          [valueKey]: data[field],
          required,
          [eventKey]: this.onChange(field),
          disabled: this.isDisabled(field),
          data: enums[field],
          ...props,
        })}
      </div>
    );
  };

  handleContent = () => {
    const { basicCard, imgsCard } = this.state.card;
    const { organisation } = this.state;
    const {
      customer_no,
      proj_name,
      proj_type,
      industry,
      company_id,
      use_corp_type,
      name,
      contractor_job,
      contractor_telephone,
      telephone,
      customer_address,
      gps_type,
      salesmen_department,
      salesmen,
    } = this.state.data;
    const { salesmen_phone, remark, imgs, business_license, receivable_fee_type, reconciliation_period } =
      this.state.data;
    // 枚举值处理 ⬇
    const {
      company_id: companyIdEnum,
      use_corp_type: useCorpTypeEnum,
      gps_type: gpsTypeEnum,
      salesmen: salesmenEnum,
      receivable_fee_type: receivable_fee_type_enum,
    } = this.state.enums;
    const customer_proj_type_enum = _.get(window.company_setting, 'customer_proj_type.selc_value', []); // 客户类型
    const industry_enum = _.get(window.company_setting, 'industry.selc_value', []); // 所属行业
    const salesmen_department_enum = _.get(window.company_setting, 'salesmen_department.selc_value', []); // 业务员部门
    console.log('industry_enum,receivableFeeTypeEnum', this.state.data);
    const receivableFeeTypeEnum = [];
    receivable_fee_type_enum.forEach(e => {
      receivableFeeTypeEnum.push({
        key: e.key,
        value: e.name,
      });
    });

    // 枚举值处理 ⬆

    // 显示 && 必填处理
    const { show, required, showField } = this.fieldSet;
    const {
      customerNoShow,
      projNameShow,
      projTypeShow,
      industryShow,
      companyIdShow,
      useCorpTypeShow,
      nameShow,
      contractorJobShow,
      contractorTelephoneShow,
      telephoneShow,
      customerAddressShow,
      gpsTypeShow,
      salesmenDepartmentShow,
      salesmenShow,
      salesmenPhoneShow,
      remarkShow,
      imgsShow,
      receivableFeeTypeShow,
      reconciliationPeriodShow,
      invoiceTitleShow,
      invoiceTaxShow,
      invoiceAddressShow,
      invoiceTelephoneShow,
      invoiceBankShow,
      invoiceCardNumShow,
      invoiceTaxRateShow,
      invoiceContentShow,
      invoiceRemarkShow,
    } = show;
    const {
      customerNoRequired,
      projNameRequired,
      projTypeRequired,
      industryRequired,
      companyIdRequired,
      useCorpTypeRequired,
      nameRequired,
      contractorJobRequired,
      contractorTelephoneRequired,
      telephoneRequired,
      customerAddressRequired,
      gpsTypeRequired,
      salesmenDepartmentRequired,
      salesmenRequired,
      salesmenPhoneRequired,
      remarkRequired,
      imgsRequired,
      receivableFeeTypeRequired,
      reconciliationPeriodRequired,
      invoiceTitleRequired,
      invoiceTaxRequired,
      invoiceAddressRequired,
      invoiceTelephoneRequired,
      invoiceBankRequired,
      invoiceCardNumRequired,
      invoiceTaxRateRequired,
      invoiceContentRequired,
      invoiceRemarkRequired,
    } = required;

    const invoiceShow =
      invoiceTitleShow ||
      invoiceTaxShow ||
      invoiceAddressShow ||
      invoiceTelephoneShow ||
      invoiceBankShow ||
      invoiceCardNumShow ||
      invoiceTaxRateShow ||
      invoiceContentShow ||
      invoiceRemarkShow;

    // 卡片是否显示的处理
    const attachmentField = ['imgs'];

    const isAttachmentCardShow = attachmentField.filter(item => showField.includes(item)).length > 0;
    return (
      <div className={prefixCls} ref={r => (this.wrap = r)}>
        <div className="set-card">
          <div className={getCardHeaderClass(basicCard)}>
            <Icon iconType="icon-carry-out" onClick={() => this.setCard('basicCard', !basicCard)} />
            <div className="card-title">
              <span className="normal"> 基本信息 </span>
            </div>
          </div>
          <div className={getCardBodyClass(basicCard, 'basic')}>
            {customerNoShow && (
              <div className={getItemClass('customer-no')}>
                <Label isRequired={customerNoRequired} className="fn-label">
                  客户编号
                </Label>
                <Input
                  required={customerNoRequired}
                  value={customer_no}
                  disabled={this.isView}
                  onChange={this.onChange('customer_no')}
                  maxLength={50}
                />
              </div>
            )}
            {projNameShow && (
              <div className={getItemClass('proj-name')}>
                <Label isRequired={projNameRequired} className="fn-label">
                  客户名称
                </Label>
                <Input
                  required={projNameRequired}
                  value={proj_name}
                  disabled={this.isView}
                  onChange={this.onChange('proj_name')}
                  // customValidity={(val) => this.trNumCheckFunc({ val, trNumCheck, tab })}
                />
              </div>
            )}
            {projTypeShow && (
              <div className={getItemClass('proj-type')}>
                <Label isRequired={projTypeRequired} className="fn-label">
                  客户类型
                </Label>
                <Select
                  required={projTypeRequired}
                  format="value"
                  filter="value"
                  header={['value']}
                  data={customer_proj_type_enum}
                  value={proj_type}
                  onChange={this.onChange('proj_type')}
                  disabled={this.isView}
                />
              </div>
            )}
            {industryShow && (
              <div className={getItemClass('industry')}>
                <Label isRequired={industryRequired} className="fn-label">
                  所属行业
                </Label>
                <Select
                  required={industryRequired}
                  format="value"
                  filter="value"
                  header={['value']}
                  data={industry_enum}
                  value={industry}
                  onChange={this.onChange('industry')}
                  disabled={this.isView}
                />
              </div>
            )}
            {companyIdShow && (
              <div className={getItemClass('company-id')}>
                <Label isRequired={companyIdRequired} className="fn-label">
                  所属组织
                </Label>
                <Select
                  required={companyIdRequired}
                  data={organisation}
                  value={company_id}
                  onChange={this.onChange('company_id')}
                  disabled={this.isView}
                />
              </div>
            )}
            {useCorpTypeShow && (
              <div className={getItemClass('use-corp-type')}>
                <Label isRequired={useCorpTypeRequired} className="fn-label">
                  使用组织
                </Label>
                <Select
                  required={useCorpTypeRequired}
                  data={useCorpTypeEnum}
                  value={use_corp_type}
                  onChange={this.onChange('use_corp_type')}
                  disabled={this.isView}
                />
              </div>
            )}
            {nameShow && (
              <div className={getItemClass('name')}>
                <Label isRequired={nameRequired} className="fn-label">
                  联系人
                </Label>
                <Input required={nameRequired} value={name} disabled={this.isView} onChange={this.onChange('name')} />
              </div>
            )}
            {contractorJobShow && (
              <div className={getItemClass('contractor-job')}>
                <Label isRequired={contractorJobRequired} className="fn-label">
                  联系人职务
                </Label>
                <Input
                  required={contractorJobRequired}
                  value={contractor_job}
                  disabled={this.isView}
                  onChange={this.onChange('contractor_job')}
                />
              </div>
            )}
            {contractorTelephoneShow && (
              <div className={getItemClass('contractor-telephone')}>
                <Label isRequired={contractorTelephoneRequired} className="fn-label">
                  联系人手机
                </Label>
                <Input
                  required={contractorTelephoneRequired}
                  value={contractor_telephone}
                  disabled={this.isView}
                  onChange={this.onChange('contractor_telephone')}
                />
              </div>
            )}
            {telephoneShow && (
              <div className={getItemClass('telephone')}>
                <Label isRequired={telephoneRequired} className="fn-label">
                  联系人电话
                </Label>
                <Input
                  required={telephoneRequired}
                  value={telephone}
                  disabled={this.isView}
                  onChange={this.onChange('telephone')}
                />
              </div>
            )}
            {receivableFeeTypeShow && (
              <div className={getItemClass('receivable_fee_type')}>
                <Label isRequired={receivableFeeTypeRequired} className="fn-label">
                  应收付款方式
                </Label>
                <Select
                  required={receivableFeeTypeRequired}
                  format="value"
                  filter="value"
                  header={['value']}
                  data={receivableFeeTypeEnum}
                  value={receivable_fee_type}
                  onChange={this.onChange('receivable_fee_type')}
                  disabled={this.isView}
                />
              </div>
            )}
            {reconciliationPeriodShow && (
              <div className={getItemClass('reconciliation_period')}>
                <Label isRequired={reconciliationPeriodRequired} className="fn-label">
                  对账账期
                </Label>
                <Input
                  required={reconciliationPeriodRequired}
                  value={reconciliation_period}
                  disabled={this.isView}
                  pattern={NUMBER_NO_ZERO}
                  onChange={this.onChange('reconciliation_period')}
                />
              </div>
            )}
            {customerAddressShow && (
              <div className={getItemClass('customer-address')}>
                <Label isRequired={customerAddressRequired} className="fn-label">
                  客户地址
                </Label>
                <AddrSug
                  required={customerAddressRequired}
                  mapIcon={false} // 地图弹框响应图标 不要图标需手动置false
                  mode="address" // 查询模式  district , address
                  defaultValue={customer_address}
                  disabled={this.isView}
                  handleSelected={this.onChange('customer_address')}
                  onChange={this.onChange('customer_address')}
                />
              </div>
            )}
            {gpsTypeShow && (
              <div className={getItemClass('gps-type')}>
                <Label isRequired={gpsTypeRequired} className="fn-label">
                  GPS服务商
                </Label>
                <Select
                  required={gpsTypeRequired}
                  value={gps_type}
                  disabled={this.isView}
                  data={gpsTypeEnum}
                  onChange={this.onChange('gps_type')}
                />
              </div>
            )}
            {salesmenDepartmentShow && (
              <div className={getItemClass('salesmen-department')}>
                <Label isRequired={salesmenDepartmentRequired} className="fn-label">
                  业务员部门
                </Label>
                <Select
                  required={salesmenDepartmentRequired}
                  format="value"
                  filter="value"
                  header={['value']}
                  value={salesmen_department}
                  disabled={this.isView}
                  data={salesmen_department_enum}
                  onChange={this.onChange('salesmen_department')}
                />
              </div>
            )}
            {salesmenShow && (
              <div className={getItemClass('salesmen')}>
                <Label isRequired={salesmenRequired} className="fn-label">
                  业务员
                </Label>
                <Select
                  required={salesmenRequired}
                  value={salesmen}
                  showIcon={this.isView}
                  map={false}
                  disabled={this.isView}
                  data={salesmenEnum}
                  filter={keyword => this.salesmenSug(keyword)}
                  onChange={this.handleSalesmenChange}
                />
                {!this.isView && <Icon iconType="icon-add-rad" onClick={() => this.onSalesmenClick()} />}
              </div>
            )}
            {salesmenPhoneShow && (
              <div className={getItemClass('salesmen-phone')}>
                <Label isRequired={salesmenPhoneRequired} className="fn-label">
                  业务员电话
                </Label>
                <Input
                  required={salesmenPhoneRequired}
                  value={salesmen_phone}
                  disabled={this.isView}
                  onChange={this.onChange('salesmen_phone')}
                />
              </div>
            )}
            {remarkShow && (
              <div className={getItemClass('remark')}>
                <Label isRequired={remarkRequired} className="fn-label">
                  备注信息
                </Label>
                <TextArea
                  placeholder=""
                  required={remarkRequired}
                  classname="customer-basic-remark"
                  maxLength={500}
                  defaultValue={remark}
                  onChange={this.onChange('remark')}
                  disabled={this.isView}
                />
                <label className="textarea-num">{(remark && remark.length) || 0}/500</label>
              </div>
            )}
          </div>
        </div>
        {/* --------- 企业信息 --------- */}
        <CardBox title="企业信息" className={cls('business')}>
          <this.renderItem field="taxer_identify" label="纳税人识别码" Component={Input} />
          <this.renderItem field="taxer_telphone" label="电话号码" Component={Input} pattern={MOBILE} />
          <this.renderItem field="enterprise_type" label="企业类型" Component={Select} />
          <this.renderItem field="legal_person_name" label="法定代表人" Component={Input} />
          <this.renderItem field="registered_capital" label="注册资本" Component={Input} />
          <this.renderItem
            field="established_date"
            label="成立时间"
            Component={DateTimePicker}
            valueKey="defaultValue"
            forceFormat
          />
          <this.renderItem
            field="registered_address"
            label="注册所在地"
            Component={AddrSug}
            valueKey="defaultValue"
            eventKey="handleSelected"
            mode="address"
            mapIcon={false}
          />
          <this.renderItem field="registered_address_val" label="注册地址" Component={Input} />
          <this.renderItem field="operation_address" label="生产经营地址" Component={Input} />
          <this.renderItem field="tax_authority" label="主选税务机关" Component={Input} />
          {/* <this.renderItem field="declare_by" label="申报人" Component={Input} />
        <this.renderItem field="declare_time" label="申报时间" Component={DateTimePicker} valueKey="defaultValue" forceFormat /> */}
          <this.renderItem field="business_scope" label="经营范围" className="x2" Component={PureTextarea} />
        </CardBox>
        {/* --------- 发票信息 --------- */}
        {invoiceShow && (
          <CardBox title="发票信息" className={cls('invoice')}>
            <this.renderItem
              show={invoiceTitleShow}
              required={invoiceTitleRequired}
              field="invoice_title"
              label="发票抬头"
              Component={Input}
            />
            <this.renderItem
              show={invoiceTaxShow}
              required={invoiceTaxRequired}
              field="invoice_tax"
              label="税号"
              Component={Input}
            />
            <this.renderItem
              show={invoiceAddressShow}
              required={invoiceAddressRequired}
              field="invoice_address"
              label="单位地址"
              Component={Input}
            />
            <this.renderItem
              show={invoiceTelephoneShow}
              required={invoiceTelephoneRequired}
              field="invoice_telephone"
              label="电话"
              Component={Input}
            />
            <this.renderItem
              show={invoiceBankShow}
              required={invoiceBankRequired}
              field="invoice_bank"
              label="开户银行"
              Component={Input}
            />
            <this.renderItem
              show={invoiceCardNumShow}
              required={invoiceCardNumRequired}
              field="invoice_card_num"
              label="账户"
              Component={Input}
            />
            <this.renderItem
              show={invoiceTaxRateShow}
              required={invoiceTaxRateRequired}
              field="invoice_tax_rate"
              label="税率"
              Component={Input}
              pattern={PERCENT}
            />
            <this.renderItem
              show={invoiceContentShow}
              required={invoiceContentRequired}
              field="invoice_content"
              label="开票内容"
              maxLength={500}
              className="invoice_content x-100"
              Component={PureTextarea}
            />
            <this.renderItem
              show={invoiceRemarkShow}
              required={invoiceRemarkRequired}
              field="invoice_remark"
              label="开票备注"
              maxLength={200}
              className="invoice_remark x-100"
              Component={PureTextarea}
            />
          </CardBox>
        )}
        {/* --------- 照片信息 --------- */}
        <CardBox title="照片信息" className={cls('images')}>
          <this.renderItem
            labelText="营业执照照片"
            Component={UploadFile}
            className="customer-imgs"
            // isRequired={imgsRequired}
            type="customer_proj"
            fileList={business_license}
            key="business_license"
            // disabled={this.isView}
            onChange={this.businessLicenseChange('business_license')}
            accept="image"
            showCamera={false}
          />
        </CardBox>

        {isAttachmentCardShow && window.permissionObj.balance_upload_file && (
          <div className="set-card">
            <div className={getCardHeaderClass(imgsCard)}>
              <Icon iconType="icon-carry-out" onClick={() => this.setCard('imgsCard', !imgsCard)} />
              <div className="card-title">
                <span className="normal"> 附件信息 </span>
                <span className="text yellow">支持的文件类型：gif、jpg、jpeg、png、doc、docx、pdf、xls、xlsx</span>
              </div>
            </div>
            <div className={getCardBodyClass(imgsCard, 'imgs')}>
              {imgsShow && (
                <UploadFile
                  className="customer-imgs"
                  isRequired={imgsRequired}
                  type="customer_proj"
                  fileList={imgs}
                  disabled={this.isView}
                  onChange={this.onChange('imgs')}
                  isOss
                  sizeLimit={50}
                  // onChange={this.handleImgsChange}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  };
  renderContracts = () => {
    const { contractList } = this.state;
    if (!contractList || !contractList.length) {
      return (
        <div className="empty-contract">
          <div className="empty-img" />
          <div className="empty-text">没有对应数据</div>
        </div>
      );
    }
    return (
      <Tabs className="project-contract-tabs" defaultActiveKey="1">
        {contractList.map((item, index) => (
          <TabPane tab={item.name} key={index + 1}>
            <Contract showType="component" id={item.id} opType="view" />
          </TabPane>
        ))}
      </Tabs>
    );
  };
  renderContent = () => {
    const { id } = this.props;
    const isShowIntentionProject = hasPermission('viewIntentionProject');
    const isShowCooperativeProject = hasPermission('viewCooperativeProject');
    const isShowContract = hasPermission('viewCustromerProjContract');
    if (!this.isView || (!isShowIntentionProject && !isShowCooperativeProject && !isShowContract)) {
      return this.handleContent();
    }
    return (
      <Tabs defaultActiveKey="info" className={`${prefixCls}-tabs`}>
        <TabPane tab="客户信息" key="info">
          {this.handleContent()}
        </TabPane>
        {isShowIntentionProject && (
          <TabPane tab="意向项目" key="意向项目">
            <List category="CustomerProject" tab="customer_detail_intention_project" query={{ customer_proj_id: id }} />
          </TabPane>
        )}
        {isShowCooperativeProject && (
          <TabPane tab="合作项目" key="合作项目">
            <List
              category="CustomerProject"
              tab="customer_detail_cooperative_project"
              query={{ customer_proj_id: id }}
            />
          </TabPane>
        )}
        {isShowContract && (
          <TabPane tab="合同信息" key="合同信息" className="contract-tabs">
            {this.renderContracts()}
          </TabPane>
        )}
      </Tabs>
    );
  };
  handleFooter = () => {
    const { loading } = this.state;
    if (this.isView) {
      return (
        <div className="customer-pop-footer">
          <Button type="primary" onClick={() => this.handleHide()}>
            关闭
          </Button>
        </div>
      );
    }
    return (
      <div className="customer-pop-footer">
        <Button loading={loading} type="primary" onClick={() => this.handleSubmit()}>
          确定
        </Button>
        <Button onClick={() => this.handleHide()}>取消</Button>
      </div>
    );
  };

  render() {
    const { title } = this.props;
    const { popContainer, togglePopWidth, close } = this.props;
    return (
      <SlideDrager
        isShow
        dragerContainer={popContainer}
        togglePopWidth={togglePopWidth}
        ref={r => (this.customerRef = r)}
        slideWidth={754}
        HeaderTitle={title}
        close={close}
        contentDiv={this.renderContent()}
        footerDiv={this.handleFooter()}
      />
    );
  }
}
