// round
// multiples
// division
// *zero2null
// datetime
// *trim_zero
// *thousands_sep
// join
// formatOpList 未对传入参数做校验 默认后端给出的配置正确
import { typeIs, isEmptyObj, thousandSep } from 'utils';
import moment from 'moment';

const formatOpList = {
  // 固定位数
  round: (val, f) => {
    if (f && (typeIs(val, 'number') || typeIs(val, 'string')) && !Number.isNaN(Number(val))) {
      return Number(val).toFixed(f); // Math.round(val * Math.pow(10, f)) / Math.pow(10, f),
      // return Number(val) * f
    }
    return val;
  },
  // 乘数
  multiples: (val, f) => {
    if (f && (typeIs(val, 'number') || typeIs(val, 'string')) && !Number.isNaN(Number(val))) {
      return Number(val) * f;
    }
    return val;
  },
  // 除数
  division: (val, f) => {
    if (f && (typeIs(val, 'number') || typeIs(val, 'string')) && !Number.isNaN(Number(val))) {
      return Number(val) / f;
    }
    return val;
  },
  // 零值抹去 return 非number
  zero2null: (val, f) => {
    if (f && !Number.isNaN(Number(val)) && Number(val) === 0) return '';
    return val;
  },
  // 末位零去除
  trim_zero: (val, f) => {
    if (f && (typeIs(val, 'number') || typeIs(val, 'string'))) {
      const numVal = Number(val);
      return Number.isNaN(numVal) ? val : numVal;
    }
    return val;
  },
  // 数字千分位 return 非number
  thousands_sep: (val, f) => {
    if (f && val !== undefined) {
      return thousandSep(val);
    }
    return val;
  },
  reverse_thousands_sep: (val, f) => {
    if (f) {
      return val.toString().replace(/,/g, '');
    }
    return val;
  },
  // 单位显示显示
  unit: (val, f) => (val ? `${val}${f}` : ''),
  // 日期格式化 return 非number
  datetime: (val, f) => (val ? moment(val).format(f) : ''),
  // 合并 return 非number
  join: (val, f) => {
    if (typeIs(val, 'array')) return val.filter(i => i !== '' && i !== undefined).join(f);
    return val;
    // if (typeIs(value[0], 'object') && OBJECT_TEXTCELL_GEN_RULE[columnKey]) {
    //   value = OBJECT_TEXTCELL_GEN_RULE[columnKey](value)
    // } else {
    //   value = value.join(LIST_ARRAY2TEXT_SEPARATE) // 逗号分隔
    // }
  },
};
// 数字
const _numberFormater = [
  'round',
  'multiples',
  'division',
  'zero2null', // 返回非数字
  'trim_zero',
  'thousands_sep', // 返回非数字
  'unit', // 单位
];
// 数组
const _arrayFormater = ['join'];
// 时间 日期
const _dateFormater = ['datetime'];
const dataFormater = function (ilistVal, iformat = {}, ext = {}) {
  let listVal = ilistVal;
  if (isEmptyObj(iformat)) return ilistVal;
  const format = { ...iformat };
  if (ext.exceptKeys) ext.exceptKeys.forEach(k => delete format[k]);
  const formatKeys = Object.keys(format);
  const listValType = typeIs(listVal);
  if (listValType === 'number' || listValType === 'string') {
    const dateFormater = formatKeys.filter(item => _dateFormater.includes(item));
    if (listValType === 'string' && listVal.split(',').length > 0 && dateFormater.length > 0) {
      // 日期时间
      return dateFormater.reduce((p, c) => {
        const formatOpType = c;
        const formatVal = format[c];
        if (p === '') return p; // TODO 异常情况返回
        return p.map(item => (item === '' ? item : formatOpList[formatOpType](item, formatVal))).join(',');
      }, listVal.split(','));
    }
    const numberFormaters = formatKeys.filter(item => _numberFormater.includes(item));
    if (!numberFormaters.length) return ilistVal;
    listVal = parseFloat(listVal, 10);
    // 非数字 直接返回 转换后 是数字的 与原字符串比较 有小数点去除尾零比较
    const hasDot = /\./.test(`${listVal}`) || /\./.test(`${ilistVal}`);
    const isScience = /e/.test(`${listVal}`) || /E/.test(`${ilistVal}`);
    if (
      Number.isNaN(listVal) ||
      !typeIs(listVal, 'number') ||
      (!hasDot && `${listVal}` !== `${ilistVal}`) ||
      (hasDot &&
        !isScience &&
        `${listVal}`.toLowerCase() !== `${ilistVal}`.replace(/0*0$/, '').toLowerCase() &&
        `${listVal}`.toLowerCase() !== `${ilistVal}`.replace(/\.0*0$/, '').toLowerCase())
    )
      return ilistVal;
    // 数字
    return numberFormaters.reduce((p, c) => {
      if (p === '') return p; // TODO 异常情况返回
      return formatOpList[c](p, format[c]);
    }, listVal);
  } else if (listValType === 'array') {
    // 数组数据 sdf
    return formatKeys
      .filter(item => _arrayFormater.includes(item))
      .reduce((p, c) => {
        const formatOpType = c;
        const formatVal = format[c];
        if (p === '') return p; // TODO 异常情况返回
        return formatOpList[formatOpType](
          p.map(item => dataFormater(item, format)),
          formatVal,
        );
      }, listVal);
  }
};
const dataFormatBatch = function (data, header, ext) {
  const newData = [];
  for (const currRow of Object.keys(data)) {
    const tmpData = {};
    for (const currColumn of Object.keys(data[currRow])) {
      tmpData[currColumn] = dataFormater(
        data[currRow][currColumn],
        header[currColumn] && header[currColumn].format ? header[currColumn].format : [],
        ext,
      );
    }
    newData.push(tmpData);
  }
  return newData;
};
const reverseMap = {
  // thousands_sep: 'reverse_thousands_sep',
  division: 'multiples',
  multiples: 'division',
};
const reverseFormate = function (value, formate) {
  if (!formate) return value;
  let formateValue = value;
  Object.keys(reverseMap).forEach(key => {
    if (formate[key]) {
      const reverseKey = reverseMap[key];
      formateValue = formatOpList[reverseKey](formateValue, formate[key]);
    }
  });
  return formateValue;
};
// 乘 或者 除 的格式化
const multiFormate = function (value, formate) {
  if (!formate) return value;
  let formateValue = value;
  Object.keys(reverseMap).forEach(key => {
    if (formate[key]) {
      formateValue = formatOpList[key](formateValue, formate[key]);
    }
  });
  return formateValue;
};
export { dataFormater, dataFormatBatch, reverseFormate, multiFormate };
