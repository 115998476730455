/**
 
 
 * Describe: 客户管理 - 客户tab 侧拉
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import {
  CardBox,
  SlideDrager,
  Button,
  Icon,
  Label,
  PureInput as Input,
  Select,
  AddrSug,
  TextArea,
  UploadFile,
  PopUp,
  Tabs,
  TabPane,
  PureTextarea,
} from 'components';
import { ERROR, CHECK, MOBILE } from 'constants';
import { bem, showInfo, fetchApi as fetch, validateFieldsOf, hasPermission, postJAVA, copyAlert } from 'utils';
import pointSug, { pointHeader } from 'utils/sug/customerPf';
import userSug from 'utils/sug/user';
import SiderDrager from 'pages/CompanyController/companyGroup/components/SiderDrager';
import { getCardHeaderClass, getCardBodyClass, getItemClass, handleFieldSet } from './tools';
import { prefixCls } from './index.scss';

const { FormItem } = CardBox;
const cls = bem(prefixCls);

export default class CustomerPfOp extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    opType: PropTypes.oneOf(['add', 'update', 'view']).isRequired,
    title: PropTypes.string,
    resData: PropTypes.object,
    close: PropTypes.func,
    onConfirm: PropTypes.func,
    togglePopWidth: PropTypes.func,
    popContainer: PropTypes.object,
  };

  static defaultProps = {
    title: '新增客户',
  };

  constructor(props) {
    super(props);
    const resData = props.resData || {};
    const { customer_info = {}, enums, contractList, business_license, wh_operator } = resData;
    const enumData = enums || {};
    const { salesmen, salesmen_name, salesmen_phone } = {};
    const { customer_proj_info = {}, service_info = {}, invoice_info = {} } = customer_info || {};
    if (salesmen && salesmen_name) {
      enumData.salesmen = [{ key: salesmen, name: salesmen_name, telephone: salesmen_phone }];
    }
    enumData.company_id_enums = [
      {
        id: window.company_info.id,
        key: window.company_info.id,
        name: window.company_info.company_name,
        sort_name: window.company_info.sort_name,
      },
    ];
    if (props.opType === 3 || props.opType === 4) {
      enumData.company_id_enums = [
        {
          id: customer_proj_info.company_id,
          key: customer_proj_info.company_id,
          name: customer_proj_info.company_name,
          sort_name: customer_proj_info.company_name,
        },
      ];
    }
    const slave_pid = { id: _.get(customer_proj_info, 'slave_pid') };
    const salesmenItem = { id: _.get(customer_proj_info, 'salesmen') };
    delete customer_proj_info.group_id;
    this.state = {
      slavePidOptions: [],
      card: {},
      enums: enumData,
      contractList,
      business_license,
      // 客户
      customer_proj_info: {
        customer_address: { addr_remark: '' },
        proj_type: '平台客户',
        slave_pid,
        salesmen: salesmenItem,
        company_id: window.company_info.id,
        addr_remark: _.get(customer_proj_info, 'customer_address.addr_remark'),
        ...customer_proj_info,
      },
      // 合同
      service_info: {
        ...service_info,
        assign_check: service_info.assign_check ? service_info.assign_check.split(',') : [],
      },
      // 发票
      invoice_info: {
        ...invoice_info,
      },
      operateType: props.opType || 1, // 1 新增客户 2 新增合同 3 编辑 4详情
      wh_operator,
    };
    this.fieldSet = handleFieldSet(); // 获取系统设置的显示必填数据
  }

  componentDidMount() {
    this.initSug();
  }

  initSug = () => {
    const keyword = _.get(this.state, 'customer_proj_info.slave_pid');
    pointSug({ keyword }).then(data => {
      const customer_proj_info = _.cloneDeep(this.state.customer_proj_info);
      for (const i in data) {
        if (+keyword === +data[i].id) {
          customer_proj_info.slave_pid = data[i];
          break;
        }
      }
      this.setState({ slavePidOptions: data, customer_proj_info });
    });

    const userKeyword = _.get(this.state, 'customer_proj_info.salesmen');
    userSug({ userKeyword }).then(data => {
      const customer_proj_info = _.cloneDeep(this.state.customer_proj_info);
      for (const i in data) {
        if (+userKeyword === +data[i].id) {
          customer_proj_info.salesmen = data[i];
          break;
        }
      }
      this.setState({ salesmenOptions: data, customer_proj_info });
    });
  };

  getSlavePidOptions = () => keyword => pointSug({ keyword }).then(data => this.setState({ slavePidOptions: data }));

  getSalesmenOptions = () => keyword => userSug({ keyword }).then(data => this.setState({ salesmenOptions: data }));

  setCard = (key, value) => this.setState({ card: { ...this.state.card, [key]: value } });
  onChange = (field, form) => e => {
    const value = e.target ? e.target.value : e;
    this.setState({
      [form]: {
        ...this.state[form],
        [field]: value,
      },
    });
  };
  businessLicenseChange = field => e => {
    const value = e.target ? e.target.value : e;
    this.setState({ data: { ...this.state.data, [field]: value } });
  };
  isDisabled = field => this.props.opType === 'view';
  setEnum = obj => this.setState({ enums: { ...this.state.enums, ...obj } });
  handleHide = () => this.customerRef.handleHide();
  addCustomer = async () => {
    const { customer_proj_info } = this.state;
    const url = '/cmm-basic/japi/platformCust/create';
    const req = {
      ...customer_proj_info,
    };
    const { imgs } = req;
    const slave_pid = _.get(req, 'slave_pid.id');
    delete req.slave_pid;
    delete req.imgs;
    const salesmen = _.get(req, 'salesmen.id');
    delete req.salesmen;
    req.slave_pid = slave_pid;
    req.files = imgs;
    req.salesmen = salesmen;
    _.set(req, 'customer_address.addr_remark', req.addr_remark);
    delete req.addr_remark;
    const res = await postJAVA(url, req);
    if (+res.errno !== 0) {
      res.errmsg && showInfo(ERROR, res.errmsg);
      return;
    }
    res.errmsg && showInfo(CHECK, res.errmsg);
    this.handleHide();
    const content = (
      <div className="customer-dialog-content platform_customer">
        <p className="big">平台客户添加成功！请继续完善签约信息</p>
      </div>
    );
    if (!(await copyAlert(CHECK, content, { confirm: '确定' }, null, null, '操作确认'))) return false;
    this.props.onConfirm && this.props.onConfirm(res.res);
    // this.props.onConfirm && this.props.onConfirm({ id: 103 })
  };
  addPlateform = async () => {
    const { service_info, invoice_info } = this.state;
    const customer_id = _.get(this.state.customer_proj_info, 'id');
    const url = '/cmm-basic/japi/platformCust/sign';
    const copy_service_info = _.cloneDeep(service_info);
    copy_service_info.assign_check = copy_service_info.assign_check.join(',');
    const req = {
      files: service_info.imgs,
      invoice_info,
      service_info: copy_service_info,
      customer_id,
    };
    const res = await postJAVA(url, req);
    if (+res.errno !== 0) {
      res.errmsg && showInfo(ERROR, res.errmsg);
      return;
    }
    res.errmsg && showInfo(CHECK, res.errmsg);
    this.handleHide();
    this.props.onConfirm && this.props.onConfirm(res);
  };
  updateCustomer = async () => {
    const { service_info, invoice_info, customer_proj_info } = this.state;
    const customer_id = _.get(this.state.customer_proj_info, 'id');
    const copy_service_info = _.cloneDeep(service_info);
    copy_service_info.assign_check = copy_service_info.assign_check.join(',');
    const url = '/cmm-basic/japi/platformCust/edit';
    const req = {
      customer_files: customer_proj_info.imgs,
      contract_files: service_info.imgs,
      company_id: customer_proj_info.company_id,
      name: customer_proj_info.name,
      contractor_telephone: customer_proj_info.contractor_telephone,
      salesmen: _.get(customer_proj_info.salesmen, 'id'),
      industry: customer_proj_info.industry,
      proj_name: customer_proj_info.proj_name,
      slave_pid: _.get(customer_proj_info, 'slave_pid.id'),
      customer_address: {
        ...customer_proj_info.customer_address,
        addr_remark: customer_proj_info.addr_remark,
      },
      remark: customer_proj_info.remark,
      invoice_info,
      service_info: copy_service_info,
      customer_id,
    };

    const res = await postJAVA(url, req);
    if (+res.errno !== 0) {
      res.errmsg && showInfo(ERROR, res.errmsg);
      return;
    }
    res.errmsg && showInfo(CHECK, res.errmsg);
    this.handleHide();
    this.props.onConfirm && this.props.onConfirm(res);
  };
  handleSubmit = async () => {
    if (!(await validateFieldsOf(this.wrap))) return false;

    if (this.state.operateType === 1) {
      this.addCustomer();
    } else if (this.state.operateType === 2) {
      this.addPlateform();
    } else {
      this.updateCustomer();
    }
  };

  handleSalesmenChange = value => {
    const { show } = this.fieldSet;
    const { salesmenPhoneShow } = show;
    const obj = { salesmen: value.id, salesmen_name: value.name };
    if (salesmenPhoneShow) obj.salesmen_phone = value.telephone;
    this.setState({
      data: {
        ...this.state.data,
        ...obj,
      },
    });
  };
  // 业务员sug
  salesmenSug = async keyword => {
    const req = { slice_num: 50, type: 'sub', user_type: [1, 2], search: keyword };
    const conf = { method: 'POST', body: { req } };
    const url = '/Basic/Org/userSug';
    const res = await fetch(url, conf);

    const enums = (res.res || []).map(item => ({ ...item, key: item.id }));

    this.setEnum({ salesmen: enums });
  };
  // 业务员加号
  onSalesmenClick = () => {
    new PopUp(SiderDrager, {
      popName: 'addSalesman',
      type: 'User',
      opType: 'add',
      inforeq: { User_id: '0', type: 'User', readonly: false, name, src: 'customer_proj', show_type: '1' },
      opreq: { User_id: '', type: 'User' },
      title: '添加业务员',
      currId: window.company_id,
      ref: r => (this.addSalesmanSlider = r),
      onConfirm: (res, req) => {
        console.log(res, req);
        const salesmen_id = res.res[1];
        const info = req.org_info || {};
        const { name, telephone } = info;
        const salesmen_enum = this.state.enums.salesmen || [];
        this.setState({
          data: {
            ...this.state.data,
            salesmen_phone: telephone,
            salesmen: salesmen_id,
          },
          enums: {
            ...this.state.enums,
            salesmen: [...salesmen_enum, { key: salesmen_id, name }],
          },
        });
        this.addSalesmanSlider && this.addSalesmanSlider.handleHide();
      },
    }).show();
  };

  renderItem = ({
    field,
    required,
    label,
    Component,
    className,
    form,
    valueKey = 'value',
    eventKey = 'onChange',
    ...props
  }) => {
    const { enums } = this.state;
    const { state } = this;

    if (field === 'invoice_addr') {
      return (
        <FormItem key={field} required={required} label={label} field={field} className={className}>
          {React.createElement(Component, {
            [valueKey]: state[form][field],
            required,
            [eventKey]: this.onChange(field, form),
            onChange: this.onChange(field, form),
            disabled: this.isDisabled(field),
            data: enums[`${field}_enum`],
            ...props,
          })}
        </FormItem>
      );
    }

    if (label) {
      return (
        <FormItem key={field} required={required} label={label} field={field} className={className}>
          {React.createElement(Component, {
            [valueKey]: state[form][field],
            required,
            [eventKey]: this.onChange(field, form),
            disabled: this.isDisabled(field),
            data: enums[`${field}_enum`],
            ...props,
          })}
        </FormItem>
      );
    }
  };

  addFormChange = (val, key) => {
    const customer_proj_info = _.cloneDeep(this.state.customer_proj_info);
    customer_proj_info[key] = val;
    if (key === 'slave_pid') {
      customer_proj_info.proj_name = val.company_name;
      customer_proj_info.name = val.name;
      customer_proj_info.contractor_telephone = val.telephone;
    }
    this.setState({
      customer_proj_info,
    });
  };

  handleContent = () => {
    const { basicCard, imgsCard } = this.state.card;
    const { slavePidOptions, wh_operator, salesmenOptions = [] } = this.state;
    // 枚举值处理 ⬇
    const { company_id_enums, industry_enum } = this.state.enums;

    const {
      slave_pid,
      proj_name,
      proj_type,
      company_id,
      name,
      addr_remark,
      contractor_telephone,
      salesmen,
      industry,
      customer_address,
      remark,
      imgs = [],
    } = this.state.customer_proj_info;

    // 显示 && 必填处理
    const { show, required, showField } = this.fieldSet;
    const { imgsShow } = show;
    const { imgsRequired } = required;

    // 卡片是否显示的处理
    const attachmentField = ['imgs'];

    const isAttachmentCardShow = attachmentField.filter(item => showField.includes(item)).length > 0;

    const editorDisabled = this.state.operateType === 3 || this.state.operateType === 4;
    const chekDisabled = this.state.operateType === 4;

    return (
      <div className={prefixCls} ref={r => (this.wrap = r)}>
        <div className="set-card">
          <div className={getCardHeaderClass(basicCard)}>
            <Icon iconType="icon-carry-out" onClick={() => this.setCard('basicCard', !basicCard)} />
            <div className="card-title">
              <span className="normal"> 基本信息 </span>
            </div>
          </div>
          <div className={getCardBodyClass(basicCard, 'basic')}>
            <div className={getItemClass('slave_pid')}>
              <Label isRequired className="fn-label">
                网点ID
              </Label>
              {/* slave_pid */}
              <Select
                value={slave_pid}
                // required
                onChange={val => this.addFormChange(val, 'slave_pid')}
                disabled={editorDisabled}
                data={slavePidOptions}
                filter={this.getSlavePidOptions()}
                format="id"
                map
                header={pointHeader}
              />
            </div>
            <div className={getItemClass('proj_name')}>
              {/* proj_name */}
              <Label isRequired className="fn-label">
                客户名称
              </Label>
              <Input
                required
                value={proj_name}
                disabled={chekDisabled}
                onChange={e => this.addFormChange(e.target.value, 'proj_name')}
              />
            </div>

            <div className={getItemClass('proj_type')}>
              <Label isRequired className="fn-label">
                客户类型
              </Label>
              {/* proj_type */}
              <Select
                required
                value={{ key: proj_type, name: proj_type }}
                data={[{ key: '平台客户', name: '平台客户' }]}
                disabled={editorDisabled}
                onChange={val => this.addFormChange(val, 'proj_type')}
              />
            </div>

            <div className={getItemClass('company_id')}>
              <Label isRequired className="fn-label">
                所属组织
              </Label>
              <Select
                required
                value={company_id}
                data={company_id_enums}
                // disabled={chekDisabled}
                disabled={editorDisabled}
                onChange={val => this.addFormChange(val, 'company_id')}
              />
            </div>

            <div className={getItemClass('name')}>
              <Label isRequired className="fn-label">
                联系人
              </Label>
              <Input
                disabled={chekDisabled}
                required
                value={name}
                onChange={e => this.addFormChange(e.target.value, 'name')}
              />
            </div>

            <div className={getItemClass('contractor_telephone')}>
              <Label isRequired className="fn-label">
                联系人手机
              </Label>
              <Input
                disabled={chekDisabled}
                pattern={MOBILE}
                required
                value={contractor_telephone}
                onChange={e => this.addFormChange(e.target.value, 'contractor_telephone')}
              />
            </div>

            <div className={getItemClass('salesmen')}>
              <Label className="fn-label"> 业务员 </Label>
              {/* <Input disabled={chekDisabled} value={salesmen} onChange={e => this.addFormChange(e.target.value, 'salesmen')} /> */}
              <Select
                value={salesmen}
                disabled={chekDisabled}
                onChange={val => this.addFormChange(val, 'salesmen')}
                data={salesmenOptions}
                header={['name']}
                filter={this.getSalesmenOptions()}
                format="name"
                map
              />
            </div>

            <div className={getItemClass('industry')}>
              <Label className="fn-label"> 所属行业 </Label>
              <Select
                value={industry}
                data={industry_enum}
                disabled={chekDisabled}
                onChange={val => this.addFormChange(val, 'industry')}
              />
            </div>

            <div className={getItemClass('customer_address')}>
              <Label isRequired className="fn-label">
                客户地址
              </Label>
              {/* customer_address */}
              <AddrSug
                disabled={chekDisabled}
                required
                mapIcon={false}
                defaultValue={customer_address}
                handleSelected={val => this.addFormChange(val, 'customer_address')}
                onChange={val => this.addFormChange(val, 'customer_address')}
              />
              {/* customer_address addr_remark */}
              <span className={getItemClass('addr_remark')}>
                <Input
                  placeholder="请输入备注地址"
                  disabled={chekDisabled}
                  value={addr_remark}
                  onChange={e => this.addFormChange(e.target.value, 'addr_remark')}
                />
              </span>
            </div>

            <div className={getItemClass('remark')}>
              <Label className="fn-label"> 客户备注 </Label>
              <TextArea
                disabled={chekDisabled}
                style={{ width: '828px', maxWidth: 'none' }}
                defaultValue={remark}
                onChange={e => this.addFormChange(e.target.value, 'remark')}
              />
            </div>
          </div>
        </div>

        <CardBox title={this.renderCodeCardTitle()} className={cls('business')}>
          <p className={`${prefixCls}-msg_card_content`}>
            【{wh_operator}
            】诚挚邀请您成为他的平台客户。通过邀请后，您可与其开通网络货运服务。如您同意邀请，请直接回复W到本条短信上。
          </p>
        </CardBox>

        {/* files isAttachmentCardShow && */}
        {window.permissionObj.balance_upload_file && (
          <div className="set-card">
            <div className={getCardHeaderClass(imgsCard)}>
              <Icon iconType="icon-carry-out" onClick={() => this.setCard('imgsCard', !imgsCard)} />
              <div className="card-title">
                <span className="normal"> 附件信息 </span>
                <span className="text yellow">支持的文件类型：gif、jpg、jpeg、png、doc、docx、pdf、xls、xlsx</span>
              </div>
            </div>
            <div className={getCardBodyClass(imgsCard, 'imgs')}>
              <UploadFile
                className="customer-imgs"
                type="customer_proj"
                fileList={imgs}
                // disabled={chekDisabled}
                onChange={this.onCusChange}
                // onChange={this.handleImgsChange}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  onCusChange = file => {
    const customer_proj_info = { ...this.state.customer_proj_info };
    customer_proj_info.imgs = file;
    this.setState({ customer_proj_info });
  };
  renderCodeCardTitle = () => (
    <p>
      <span>邀请短信</span>
      <span className={`${prefixCls}-msg_card_title`}>
        *
        保存后系统将发送邀请短信到平台客户的联系人手机号上。当客户回复短信邀请码后的2分钟内，系统会更新邀请状态为已通过。
      </span>
    </p>
  );
  renderContracts = () => {
    const { basicCard, imgsCard } = this.state.card;
    const { slavePidOptions, salesmenOptions } = this.state;
    const { proj_name, company_id, salesmen, slave_pid } = this.state.customer_proj_info;
    const { company_id_enums } = this.state.enums;
    console.log('*********', this.state.customer_proj_info);
    const { imgs = [] } = this.state.service_info;
    // 枚举值处理 ⬇

    // 枚举值处理 ⬆

    // // 显示 && 必填处理
    // const { show, required, showField } = this.fieldSet
    // const { imgsShow } = show
    // const { imgsRequired } = required

    // 卡片是否显示的处理
    const attachmentField = ['imgs'];

    // const isAttachmentCardShow = attachmentField.filter(item => showField.includes(item)).length > 0

    const editorDisabled = this.state.operateType !== 1;
    const chekDisabled = this.state.operateType === 4;

    return (
      <div className={prefixCls} ref={r => (this.wrap = r)}>
        <div className="set-card">
          <div className={getCardHeaderClass(basicCard)}>
            <Icon iconType="icon-carry-out" onClick={() => this.setCard('basicCard', !basicCard)} />
            <div className="card-title">
              <span className="normal"> 基本信息 </span>
            </div>
          </div>
          <div className={getCardBodyClass(basicCard, 'basic')}>
            <div className={getItemClass('slave_pid')}>
              <Label isRequired className="fn-label">
                网点ID
              </Label>
              <Select
                disabled={editorDisabled}
                value={slave_pid}
                // required
                onChange={val => this.addFormChange(val, 'slave_pid')}
                data={slavePidOptions}
                filter={this.getSlavePidOptions()}
                format="id"
                map
                header={pointHeader}
              />
            </div>
            <div className={getItemClass('proj_name')}>
              <Label isRequired className="fn-label">
                客户名称
              </Label>
              <Input
                disabled={editorDisabled}
                required
                value={proj_name}
                onChange={e => this.addFormChange(e.target.value, 'proj_name')}
              />
            </div>

            <div className={getItemClass('company_id')}>
              <Label isRequired className="fn-label">
                所属组织
              </Label>
              <Select
                disabled={editorDisabled}
                required
                value={company_id}
                onChange={val => this.addFormChange(val, 'company_id')}
                data={company_id_enums}
              />
            </div>

            <div className={getItemClass('salesmen')}>
              <Label className="fn-label"> 业务员 </Label>
              {/* <Input disabled={editorDisabled} value={salesmen} onChange={e => this.addFormChange(e.target.value, 'salesmen')} /> */}
              <Select
                value={salesmen}
                disabled={editorDisabled}
                onChange={val => this.addFormChange(val, 'salesmen')}
                data={salesmenOptions}
                header={['name']}
                filter={this.getSalesmenOptions()}
                format="name"
                map
              />
            </div>
          </div>
        </div>
        <CardBox title="发票信息" className={cls('business')}>
          <this.renderItem
            disabled={chekDisabled}
            field="invoice_type"
            form="invoice_info"
            required
            label="发票类型"
            Component={Select}
          />
          <this.renderItem
            disabled={chekDisabled}
            field="invoice_title"
            form="invoice_info"
            required
            label="发票抬头"
            Component={Input}
          />
          <this.renderItem
            disabled={chekDisabled}
            field="invoice_tax_no"
            form="invoice_info"
            required
            label="纳税人识别号"
            Component={Input}
          />
          <this.renderItem
            disabled={chekDisabled}
            field="invoice_bank"
            form="invoice_info"
            required
            label="开户银行"
            Component={Input}
          />
          <this.renderItem
            disabled={chekDisabled}
            field="invoice_bank_account"
            form="invoice_info"
            required
            label="银行账号"
            Component={Input}
          />

          <this.renderItem
            disabled={chekDisabled}
            mapIcon={false}
            field="invoice_addr"
            form="invoice_info"
            eventKey="handleSelected"
            valueKey="defaultValue"
            label="注册地址"
            Component={AddrSug}
          />
          <this.renderItem
            disabled={chekDisabled}
            field="invoice_phone"
            form="invoice_info"
            label="公司电话"
            Component={Input}
          />
          <this.renderItem
            disabled={chekDisabled}
            field="service_name"
            form="invoice_info"
            label="服务名称"
            Component={Select}
          />

          <this.renderItem
            disabled={chekDisabled}
            field="invoice_send_addr"
            form="invoice_info"
            label="发票邮寄地址"
            className="x2"
            Component={PureTextarea}
          />
        </CardBox>

        <CardBox title="服务信息" className={cls('business')}>
          <this.renderItem
            disabled={chekDisabled}
            field="assign_check"
            form="service_info"
            multiple
            label="指派校验"
            Component={Select}
          />
          <this.renderItem
            disabled={chekDisabled}
            field="invoice_ratio"
            form="service_info"
            suffix="%"
            label="服务费率"
            required
            Component={Input}
          />
          <this.renderItem
            disabled={chekDisabled}
            field="tax_type"
            form="service_info"
            label="服务费计算方式"
            required
            Component={Select}
          />
          <this.renderItem
            disabled={chekDisabled}
            field="service_get_type"
            form="service_info"
            label="服务费收取方式"
            required
            Component={Select}
          />
          <this.renderItem
            disabled={chekDisabled}
            field="driver_freight_pay_type"
            form="service_info"
            label="司机运费支付方式"
            required
            Component={Select}
          />

          <this.renderItem
            disabled={chekDisabled}
            field="driver_freight_pay_limit"
            form="service_info"
            label="司机运费支付限额"
            Component={Input}
          />
        </CardBox>

        {/* isAttachmentCardShow &&  */}
        {window.permissionObj.balance_upload_file && (
          <div className="set-card">
            <div className={getCardHeaderClass(imgsCard)}>
              <Icon iconType="icon-carry-out" onClick={() => this.setCard('imgsCard', !imgsCard)} />
              <div className="card-title">
                <span className="normal"> 附件信息 </span>
                <span className="text yellow">支持的文件类型：gif、jpg、jpeg、png、doc、docx、pdf、xls、xlsx</span>
              </div>
            </div>
            <div className={getCardBodyClass(imgsCard, 'imgs')}>
              <UploadFile
                className="customer-imgs"
                type="customer_proj"
                fileList={imgs}
                // disabled={chekDisabled}
                onChange={this.onChange('imgs', 'service_info')}
                // onChange={this.handleImgsChange}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  renderContent = () => {
    const { id } = this.props;
    const isShowIntentionProject = hasPermission('viewIntentionProject');
    const isShowCooperativeProject = hasPermission('viewCooperativeProject');
    const isShowContract = hasPermission('viewCustromerProjContract');
    if (this.state.operateType === 1) {
      return this.handleContent();
    }
    if (this.state.operateType === 2) {
      return this.renderContracts();
    }
    return (
      <Tabs defaultActiveKey="info" className={`${prefixCls}-tabs`}>
        <TabPane tab="客户信息" key="info">
          {this.handleContent()}
        </TabPane>

        {isShowContract && (
          <TabPane tab="合同信息" key="合同信息" className="contract-tabs">
            {this.renderContracts()}
          </TabPane>
        )}
      </Tabs>
    );
  };
  handleFooter = () => {
    const { loading } = this.state;
    if (this.state.operateType === 4) {
      return (
        <div className="customer-pop-footer">
          <Button type="primary" onClick={() => this.handleHide()}>
            关闭
          </Button>
        </div>
      );
    }
    return (
      <div className="customer-pop-footer">
        <Button loading={loading} type="primary" onClick={() => this.handleSubmit()}>
          确定
        </Button>
        <Button onClick={() => this.handleHide()}>取消</Button>
      </div>
    );
  };

  getTitle = () => {
    let title = '新增客户';
    if (this.state.operateType === 1) {
      title = '新增客户';
    }

    if (this.state.operateType === 2) {
      title = '平台客户签约';
    }

    if (this.state.operateType === 3) {
      title = '修改客户';
    }

    if (this.state.operateType === 4) {
      title = '客户详情';
    }

    return title;
  };

  render() {
    const { popContainer, togglePopWidth, close } = this.props;
    return (
      <SlideDrager
        isShow
        dragerContainer={popContainer}
        togglePopWidth={togglePopWidth}
        ref={r => (this.customerRef = r)}
        slideWidth={984}
        HeaderTitle={this.getTitle()}
        close={close}
        contentDiv={this.renderContent()}
        footerDiv={this.handleFooter()}
      />
    );
  }
}
