import React from 'react';
import { Select, DataList, Address, DateTimePicker, PureInput as Input, Tips } from 'components';
import { REGULAR, FLOAT_P_3, WARN } from 'constants';
import { ot, postJAVA, hasPermission, confirm } from 'utils';
import _ from 'lodash';
import { startPointSug as utilStartPointSug, multiColPointHeader } from 'utils/sug/org';
import projectSug from 'utils/sug/project';
import { customerProjectSug } from 'utils/sug/customer';
import deviceSug, { GPSHeader } from 'utils/sug/device';
import { lockShow, required } from '../tool';
import { getPoint, getArrInfo, removeArrSug, getRcvStn, checkEntrustNumExisted, getStockPointSug } from './tool';
import addProject from 'utils/business/project/addProject';
import viewProject from 'utils/business/project/viewProject';
import addCustomer from 'utils/business/customer/addCustomer';
import viewCustomer from 'utils/business/customer/viewCustomer';
import { normalHeader } from '../constant';
import { prefixCls } from './index.scss';
import { getOuterPriceParams } from '../info/tool';

const rcvStnHeader = ['short_name'];

const customerStateError = '关联的客户已停用';

const multiColProjectHeader = [
  { key: 'name', title: '项目名称' },
  { key: 'customer_proj_name', title: '客户名称' },
  { key: 'company_name', title: '所属组织' },
];

const customerProjectHeader = [
  { key: 'proj_name', title: '客户名称' },
  { key: 'proj_type', title: '客户类型' },
  { key: 'company_name', title: '所属组织' },
];

function renderTraffic(page) {
  const startPointSug = (keyword, select) => {
    utilStartPointSug({ search: keyword, fetch: select.fetch }).then(res => page.setSug('startPoint', res));
  };
  const arrPointSug = (keyword, select) => {
    const { store } = page;
    const state = store.getState();
    getPoint(keyword, state, select.fetch).then(res => {
      // console.log(res, 'oooooooo')
      page.setSug('arrPoint', res);
    });
  };

  const arrInfoSug = (keyword, select) => {
    const { store } = page;
    const state = store.getState();
    getArrInfo(keyword, state, select.fetch).then(res => {
      page.setSug('arrInfo', res.res);
      res.res[0] && select.needAsyncSelect && !state.arrPoint && page.set('arrInfo', res.res[0]);
    });
  };

  const deviceIdSug = (keyword, select) => {
    const { store } = page;
    const state = store.getState();
    deviceSug({ keyword, cid: state.cid, fetch: select.fetch }).then(res => {
      page.setSug('deviceId', res);
    });
  };

  const rcvStnSug = (keyword, select) => {
    const { store } = page;
    const state = store.getState();
    getRcvStn(keyword, select.fetch, state).then(res => page.setSug('rcvStn', res.res));
  };

  const stockPointSug = (keyword, select) => {
    const { store } = page;
    const state = store.getState();
    getStockPointSug(keyword, state, select.fetch).then(res => {
      page.setSug('stockPoint', res.res);
      res.res[0] && select.needAsyncSelect && !state.stockPoint && page.set('stockPoint', res.res[0]);
    });
  };

  const addressBlur = (key, e) => {
    const state = page.store.getState();
    const val = state[key];
    val && !val.poi && page.editedThisFocus(e.target) && page.set(key, val);
  };

  const arrInfoBlur = e => {
    const state = page.store.getState();
    const val = state.arrInfo;
    val && !val.addr && !val.poi && page.editedThisFocus(e.target) && page.set('arrInfo', val);
  };

  const validateArrInfo = () => {
    const state = page.store.getState();
    const { startArrNeedPoi, arrOnlyRoute, arrInfo } = state;
    const needSelect = startArrNeedPoi || arrOnlyRoute;
    return needSelect && arrInfo && arrInfo.show_val && !arrInfo.addr && !arrInfo.province ? '请从下拉选择' : '';
  };

  const validateArrPoint = () => {
    const state = page.store.getState();
    const val = state.arrPoint;
    return val && val.short_name && !val.company_id ? '请从下拉选择' : '';
  };

  const setGoodsNum = val => {
    page.set('manualGNum', true);
    page.set('goodsNum', val.trim ? val.trim() : '');
  };

  const optionRemovable = option => option.unique_key;

  const onRemoveAddrOption = option => removeArrSug(page.store.getState().startPointId, option);

  const onRemoveArrInfoOption = (option, i) => {
    const state = page.store.getState();
    const newArrInfoEnum = [...state.arrInfoEnum];
    newArrInfoEnum.splice(i, 1);
    page.setSug('arrInfo', newArrInfoEnum);
    removeArrSug(state.startPointId, option);
  };
  const onRemoveArrPointOption = (option, i) => {
    const state = page.store.getState();
    const newArrPointEnum = [...state.arrPointEnum];
    newArrPointEnum.splice(i, 1);
    page.setSug('arrPoint', newArrPointEnum);
    removeArrSug(state.startPointId, option);
  };

  const getProductTypePrice = () => {
    const state = page.store.getState();
    if (state.productTypeShowPrice.length) {
      const order = getOuterPriceParams(state);
      const type = ['budget_pt'];
      const textMap = { budget_trans_f: '预算中转费', budget_delivery_f: '预算送货费' };
      const special = {
        w_fee: Object.entries(textMap)
          .filter(([k]) => state.productTypeShowPrice.includes(k))
          .map(([, v]) => v),
      };
      const enumValues = (state.productTypeEnum || []).map(item => item.key);
      const req = { type, orders: [order], special, enum_attr: 'product_a_pt', enum_values: enumValues };
      postJAVA('/cmm-pricesys/Basic/PriceSystemV2/getOrderEnumOuterPrice', req).then(res => {
        const { productTypeEnum } = state;
        const productTypeEnumNew = productTypeEnum
          .map(item => {
            const result = (res.res.price[0][item.key] || {}).budget_pt || {};
            return {
              ...item,
              budget_trans_f: +(+result.budget_trans_f || 0).toFixed(2) || '',
              budget_delivery_f: +(+result.budget_delivery_f || 0).toFixed(2) || '',
            };
          })
          .sort((a, b) => {
            const sum1 = +a.budget_trans_f + +a.budget_delivery_f || Infinity;
            const sum2 = +b.budget_trans_f + +b.budget_delivery_f || Infinity;
            return sum1 - sum2;
          });
        page.setSug('productType', productTypeEnumNew);
      });
    }
  };

  const onChangeStartPoint = val => {
    val && page.props?.onSaveSuccess?.('changeStartPoint', val);
  };

  return function () {
    const { store } = page;
    const state = store.getState();
    const { startPoint, startPointShow, startPointRequired, startPointEditable, startPointEnum } = state;
    const { obProjectId, obProjectIdShow, obProjectIdEditable, obProjectIdRequired, obProjectIdEnum } = state;
    const { obCustomerId, obCustomerIdShow, obCustomerIdEditable, obCustomerIdRequired, obCustomerIdEnum } = state;
    const { stockPoint, stockPointShow, stockPointRequired, stockPointEditable, stockPointEnum } = state;
    const { arrPoint, arrPointShow, arrPointRequired, arrPointEditable, arrPointEnum, arrPointHeader } = state;
    const { startInfo, startInfoShow, startInfoRequired, startInfoEditable, startArrNeedPoi } = state;
    const { rcvStn, rcvStnShow, rcvStnRequired, rcvStnEditable, rcvStnEnum } = state;
    const { arrInfo, arrInfoShow, arrInfoRequired, arrInfoEditable, arrInfoEnum, arrInfoHeader, arrInfoIsDataList } =
      state;
    const { productLine, productLineShow, productLineRequired } = state;
    const { transMode, transModeShow, transModeRequired, transModeEditable, transModeEnum } = state;
    const { route, routeShow, routeRequired, isDetail } = state;
    const { routeMile, routeMileShow, routeMileRequired } = state;
    const {
      thermosphere,
      thermosphereShow,
      thermosphereRequired,
      thermosphereEditable,
      thermosphereLock,
      thermosphereEnum,
    } = state;
    const {
      productType,
      productTypeShow,
      productTypeRequired,
      productTypeEditable,
      productTypeHeader,
      productTypeEnum,
    } = state;
    const { serviceType, serviceTypeShow, serviceTypeRequired, serviceTypeEditable, serviceTypeEnum } = state;
    const { xpcdArrDate, xpcdArrDateShow, xpcdArrDateRequired, xpcdArrDateEditable } = state;
    const {
      deliveryMode,
      deliveryModeTop,
      deliveryModeRequired,
      deliveryModeEditable,
      deliveryModeEnum,
      deliveryShortcuts,
    } = state;
    const { truckType, truckTypeShow, truckTypeRequired, truckTypeEditable, truckTypeEnum } = state;
    const { truckLength, truckLengthShow, truckLengthRequired, truckLengthEditable, truckLengthEnum } = state;
    const { goodsNum, goodsNumShow, goodsNumRequired, goodsNumEditable } = state;
    const { entrustNum, entrustNumShow, entrustNumRequired, entrustNumEditable } = state;
    const { deviceId, deviceIdShow, deviceIdRequired, deviceIdEditable, deviceIdEnum } = state;
    const { isOpenPayment, useJAVAPrice, valueAddedServiceCardShow } = state;
    const { autoMatchGdAddr } = state;

    const hasAddProjectPer = addProject.hasPer();
    const hasViewProjectPer = viewProject.hasPer();
    const hasAddCustomerPer = addCustomer.hasPer();
    const hasViewCustomerPer = viewCustomer.hasPer();
    const { set } = page;

    const { merge } = page;

    const items = [];

    let afterIndex;
    let firstX2Index;

    const setPoint = (type, data) => {
      set(type, data);
    };
    const setMerge = (type, data) => {
      if (data && data.short_name) {
        set('addressNetworkPoint', true);
      } else {
        set('addressNetworkPoint', false);
      }
      merge(type, data);
    };
    const setArrialStation = (type, data) => {
      set('addressNetworkPoint', true);
      set(type, data);
    };
    const mergeArrialStation = (type, data) => {
      if (data.show_val) {
        set('addressNetworkPoint', true);
      } else {
        set('addressNetworkPoint', false);
      }
      merge(type, data);
    };

    const sugProject =
      cid =>
      (keyword, { fetch }) =>
        projectSug({ fetch, keyword, cid, is_multi: 1 }).then(data => page.setSug('obProjectId', data));
    const sugCustomer =
      cid =>
      (keyword, { fetch }) =>
        customerProjectSug({ fetch, keyword, cid, is_multi: 1 }).then(data => page.setSug('obCustomerId', data));

    const confirmSelect = (key, val, type, errTextArr) => {
      if (errTextArr.length) {
        confirm(WARN, `该${type}${errTextArr.join('，')}，确定要继续选择该${type}吗？`, { confirm: '继续' }).then(
          result => {
            if (result) {
              set(key, val);
            } else {
              set(key, '');
            }
          },
        );
      } else {
        set(key, val);
      }
    };

    const onSelectProject = val => {
      const errTextArr = [];
      const valClone = _.cloneDeep(val);
      if (+val.customer_proj_id && !+val.customer_proj_state) {
        errTextArr.push(customerStateError);
        delete valClone.customer_proj_id;
      }
      confirmSelect('obProjectId', valClone, '项目', errTextArr);
    };

    const onClickAddProject = () => {
      addProject(2).then(res => {
        if (res) {
          projectSug({ query: { id: res.res.id } }).then(res1 => {
            res1[0] && onSelectProject(res1[0]);
          });
        }
      });
    };

    const onSelectCustomer = val => {
      const errTextArr = [];
      const valClone = _.cloneDeep(val);
      confirmSelect('obCustomerId', valClone, '客户', errTextArr);
    };

    const onClickAddCustomer = () => {
      addCustomer().then(res => {
        if (res) {
          const { salesmen_name, req } = res;
          const { proj_name, salesmen } = req.proj_info;
          page.set('customId', {
            id: res.res.id,
            salesmen,
            salesmen_name,
            proj_name,
          });
        }
      });
    };

    const transModeEle = transModeShow && (
      <Select
        data-path="trans_mode"
        placeholder=""
        className="order-trans-mode"
        required={transModeRequired}
        value={transMode}
        format={arrPointShow || arrInfoShow ? 'short_name' : 'name'}
        data={transModeEnum}
        disabled={!transModeEditable || !!(route && +route.route_id)}
        onChange={val => set('transMode', val)}
      >
        {(arrPointShow || arrInfoShow) && (
          <Tips className="input-tip" title={ot('中转方式')}>
            <i className="fn-icon fn-icon-location" />
          </Tips>
        )}
      </Select>
    );

    startPointShow &&
      items.push(
        <div key="startPoint">
          <label className={`fn-label${required(startPointRequired)}`}>{ot('出发网点')}</label>
          <Select
            value={startPoint}
            data={startPointEnum}
            required
            disabled={!startPointEditable}
            compare="id"
            map={false}
            header={multiColPointHeader}
            format="short_name"
            filter={startPointSug}
            onChange={onChangeStartPoint}
          />
        </div>,
      );

    obProjectIdShow &&
      items.push(
        <div>
          <label className={`fn-label${required(obProjectIdRequired)}`}>{ot('项目名称')}</label>
          <Select
            value={obProjectId}
            data={obProjectIdEnum}
            required={obProjectIdRequired}
            disabled={!obProjectIdEditable}
            data-path="ob_project_id"
            map={false}
            compare="id"
            header={multiColProjectHeader}
            onChange={onSelectProject}
            filter={sugProject(startPoint.id)}
            showIcon={!hasAddProjectPer && !hasViewProjectPer}
            placeholder=""
          >
            {hasPermission('cooperativeProjectMgr') &&
              hasAddProjectPer &&
              obProjectIdEditable &&
              (!obProjectId || !obProjectId.id) && (
                <i className="fn-icon fn-icon-add-rad input-icon" onClick={onClickAddProject} />
              )}
            {hasPermission('cooperativeProjectMgr') && hasViewProjectPer && obProjectId && obProjectId.id && (
              <i className="fn-icon fn-icon-preview input-icon" onClick={() => viewProject(obProjectId.id)} />
            )}
          </Select>
        </div>,
      );

    obCustomerIdShow &&
      items.push(
        <div>
          <label className={`fn-label${required(obCustomerIdRequired)}`}>{ot('客户名称')}</label>
          <Select
            value={obCustomerId}
            data={obCustomerIdEnum}
            required={obCustomerIdRequired}
            disabled={!obCustomerIdEditable}
            data-path="ob_customer_id"
            map={false}
            compare="id"
            header={customerProjectHeader}
            onChange={onSelectCustomer}
            filter={sugCustomer(startPoint.id)}
            showIcon={!hasAddCustomerPer && !hasViewCustomerPer}
            placeholder=""
          >
            {hasPermission('customerProjMgr') &&
              hasAddCustomerPer &&
              obCustomerIdEditable &&
              (!obCustomerId || !obCustomerId.id) && (
                <i className="fn-icon fn-icon-add-rad input-icon" onClick={onClickAddCustomer} />
              )}
            {hasPermission('customerProjMgr') && hasViewCustomerPer && obCustomerId && obCustomerId.id && (
              <i className="fn-icon fn-icon-preview input-icon" onClick={() => viewCustomer(obCustomerId.id)} />
            )}
          </Select>
        </div>,
      );
    entrustNumShow &&
      items.push(
        <div key="entrustNum">
          <label className={`fn-label${required(entrustNumRequired)}`}>{ot('委托单号')}</label>
          <Input
            data-path="entrust_num"
            required={entrustNumRequired}
            customValidity={val => checkEntrustNumExisted(val, state)}
            value={entrustNum}
            pattern={REGULAR}
            disabled={!entrustNumEditable}
            onChange={e => set('entrustNum', e.target.value)}
          />
        </div>,
      );

    stockPointShow &&
      items.push(
        <div key="stockPoint">
          <label className={`fn-label${required(stockPointRequired)}`}>{ot('入库网点')}</label>
          <Select
            value={stockPoint}
            data={stockPointEnum}
            disabled={!stockPointEditable}
            compare="id"
            map="id"
            header={['short_name']}
            format="short_name"
            filter={isOpenPayment ? undefined : stockPointSug}
            onChange={val => set('stockPoint', val)}
          />
        </div>,
      );

    startInfoShow &&
      items.push(
        <div key="startInfo">
          <label className={`fn-label${required(startInfoRequired)}`}>{ot('发站')}</label>
          {autoMatchGdAddr ? (
            <Input
              value={startInfo?.show_val}
              placeholder="请输入地址"
              maxLength="30"
              className="node-address"
              required={startInfoRequired}
              disabled={!startInfoEditable}
              data-path="start"
              onChange={e => set('startInfo', { show_val: e.target.value, poi: '1,1' })}
            />
          ) : (
            <Address
              g7Map
              data-path="start"
              required={startInfoRequired}
              needSelect={startArrNeedPoi}
              mode="district"
              mapIcon={false}
              value={startInfo}
              disabled={!startInfoEditable}
              onBlur={(val, name, e) => addressBlur('startInfo', e)}
              onChange={val => merge('startInfo', val)}
              onSelect={val => set('startInfo', val)}
            />
          )}
        </div>,
      );

    arrInfoShow &&
      items.push(
        <div key="arrInfo">
          <label className={`fn-label${required(arrInfoRequired)}`}>{ot('到站')}</label>
          {/* eslint-disable-next-line no-nested-ternary */}
          {autoMatchGdAddr ? (
            <Input
              value={arrInfo?.show_val}
              placeholder="请输入地址"
              maxLength="30"
              className="node-address"
              required={arrInfoRequired}
              disabled={!arrInfoEditable}
              data-path="arr"
              onChange={e => set('arrInfo', { show_val: e.target.value, poi: '2,2' })}
            />
          ) : arrInfoIsDataList ? (
            <DataList
              data-path="arr"
              required={arrInfoRequired}
              customValidity={validateArrInfo}
              value={arrInfo && arrInfo.show_val}
              data={arrInfoEnum}
              disabled={!arrInfoEditable}
              header={arrInfoHeader}
              onBlur={arrInfoBlur}
              onChange={val => mergeArrialStation('arrInfo', { show_val: val, street: val })}
              onSelect={val => setArrialStation('arrInfo', val)}
              filter={arrInfoSug}
              optionRemovable={optionRemovable}
              onRemoveOption={onRemoveArrInfoOption}
              autoActiveFirstOption
            />
          ) : (
            <Address
              g7Map
              data-path="arr"
              required={arrInfoRequired}
              needSelect={startArrNeedPoi}
              mode="district"
              mapIcon={false}
              value={arrInfo}
              disabled={!arrInfoEditable}
              onBlur={(val, name, e) => addressBlur('arrInfo', e)}
              filter={keyword => getArrInfo(keyword, state)}
              onChange={val => merge('arrInfo', val)}
              onSelect={val => set('arrInfo', { ...{ address: false }, ...val })}
              optionRemovable={optionRemovable}
              onRemoveOption={onRemoveAddrOption}
              autoActiveFirstOption
            />
          )}

          {!arrPointShow && transModeEle}
        </div>,
      );

    arrPointShow &&
      items.push(
        <div key="arrPoint" className="traffic-arr-point">
          <label className={`fn-label${required(arrPointRequired)}`}>{ot('目的网点')}</label>
          <DataList
            data-path="arr_point"
            required={arrPointRequired}
            customValidity={validateArrPoint}
            highlight="short_name"
            value={arrPoint && arrPoint.short_name}
            data={arrPointEnum}
            header={arrPointHeader}
            disabled={!arrPointEditable}
            onChange={val => setMerge('arrPoint', { short_name: val, company_id: '' })}
            onSelect={val => setPoint('arrPoint', val)}
            filter={arrPointSug}
            optionRemovable={optionRemovable}
            onRemoveOption={onRemoveArrPointOption}
            autoActiveFirstOption
          />
          {transModeEle}
        </div>,
      );

    !arrPointShow &&
      !arrInfoShow &&
      transModeShow &&
      items.push(
        <div key="transMode">
          <label className={`fn-label${required(transModeRequired)}`}>{ot('中转方式')}</label>
          {transModeEle}
        </div>,
      );

    rcvStnShow &&
      items.push(
        <div key="rcvStn">
          <label className={`fn-label${required(rcvStnRequired)}`}>{ot('收货点')}</label>
          <Select
            data-path="rcv_stn"
            required={rcvStnRequired}
            value={rcvStn}
            data={rcvStnEnum}
            disabled={!rcvStnEditable}
            compare="id"
            format="short_name"
            header={rcvStnHeader}
            map={false}
            filter={rcvStnSug}
            onChange={val => set('rcvStn', val)}
            autoActiveFirstOption
          />
        </div>,
      );

    productLineShow &&
      items.push(
        <div key="productLine">
          <label className={`fn-label${required(productLineRequired)}`}>{ot('产品线路')}</label>
          <Input data-path="product_line" disabled value={productLine ? productLine.name : ''} />
        </div>,
      );

    routeShow &&
      items.push(
        <div key="route" className="x2">
          <label className={`fn-label${required(routeRequired)}`}>{ot('路由')}</label>
          <Input
            data-path="route"
            showTitleTips
            readOnly
            disabled={isDetail}
            required={routeRequired}
            value={route ? route.route_nick : ''}
          />
        </div>,
        'x2',
      );
    routeMileShow &&
      items.push(
        <div key="routeMile" className="routeMile">
          <label className={`fn-label${required(routeMileRequired)}`}>{ot('路由里程')}</label>
          <Input
            data-path="route_mile"
            disabled={isDetail}
            pattern={FLOAT_P_3}
            required={routeMileRequired}
            value={routeMile}
            onChange={e => set('routeMile', e.target.value)}
          />
        </div>,
      );

    thermosphereShow &&
      items.push(
        <div key="thermosphere">
          <label className={`fn-label${required(thermosphereRequired)}`}>{ot('温层')}</label>
          {thermosphereLock && (
            <Select
              data-path="thermosphere"
              value={thermosphere}
              required={thermosphereRequired}
              data={thermosphereEnum}
              disabled={!thermosphereEditable}
              header={normalHeader}
              format={lockShow}
              filter="value"
              onChange={val => set('thermosphere', val)}
            />
          )}
          {!thermosphereLock && (
            <DataList
              data-path="thermosphere"
              value={thermosphere}
              required={thermosphereRequired}
              data={thermosphereEnum}
              disabled={!thermosphereEditable}
              header={normalHeader}
              filter={false}
              onChange={val => set('thermosphere', val)}
              onSelect={data => set('thermosphere', data.value)}
            />
          )}
        </div>,
      );

    productTypeShow &&
      items.push(
        <div key="productType">
          <label className={`fn-label${required(productTypeRequired)}`}>{ot('产品类型')}</label>
          <Select
            data-path="product_type"
            required={productTypeRequired}
            value={productType}
            header={productTypeHeader}
            data={productTypeEnum}
            disabled={!productTypeEditable}
            map={false}
            onChange={val => set('productType', val)}
            onFocus={useJAVAPrice ? getProductTypePrice : undefined}
          />
        </div>,
      );

    !valueAddedServiceCardShow &&
      serviceTypeShow &&
      items.push(
        <div key="serviceType">
          <label className={`fn-label${required(serviceTypeRequired)}`}>{ot('服务类型')}</label>
          <Select
            data-path="service_type"
            required={serviceTypeRequired}
            value={serviceType}
            data={serviceTypeEnum}
            disabled={!serviceTypeEditable}
            filter={false}
            onChange={val => set('serviceType', val)}
          />
        </div>,
      );

    xpcdArrDateShow &&
      items.push(
        <div key="xpcdArrDate">
          <label className={`fn-label${required(xpcdArrDateRequired)}`}>{ot('标准到货日期')}</label>
          <DateTimePicker
            defaultValue={xpcdArrDate}
            disabled={!xpcdArrDateEditable}
            onChange={val => set('xpcdArrDate', val)}
          />
        </div>,
      );

    deliveryModeTop &&
      items.push(
        <div key="deliveryMode">
          <label className={`fn-label${required(deliveryModeRequired)}`}>{ot('送货方式')}</label>
          <Select
            data-path="delivery_mode"
            required={deliveryModeRequired}
            value={deliveryMode}
            data={deliveryModeEnum}
            disabled={!deliveryModeEditable}
            filter="name"
            onChange={val => set('deliveryMode', val)}
            shortcuts={deliveryShortcuts}
          />
        </div>,
      );

    truckTypeShow &&
      items.push(
        <div key="truckType">
          <label className={`fn-label${required(truckTypeRequired)}`}>{ot('需求车型')}</label>
          <Select
            data-path="truck_type"
            required={truckTypeRequired}
            value={truckType}
            data={truckTypeEnum}
            disabled={!truckTypeEditable}
            format="value"
            filter="value"
            header={normalHeader}
            onChange={val => set('truckType', val)}
          />
        </div>,
      );

    truckLengthShow &&
      items.push(
        <div key="truckLength">
          <label className={`fn-label${required(truckLengthRequired)}`}>{ot('需求车长')}</label>
          <Select
            data-path="truck_length"
            required={truckLengthRequired}
            value={truckLength}
            data={truckLengthEnum}
            disabled={!truckLengthEditable}
            format="value"
            filter="value"
            header={normalHeader}
            onChange={val => set('truckLength', val)}
          />
        </div>,
      );

    goodsNumShow &&
      items.push(
        <div key="goodsNum">
          <label className={`fn-label${required(goodsNumRequired)}`}>{ot('货号')}</label>
          <Input
            data-path="goods_num"
            required={goodsNumRequired}
            value={goodsNum}
            pattern={REGULAR}
            disabled={!goodsNumEditable}
            onChange={e => setGoodsNum(e.target.value)}
          />
        </div>,
      );

    deviceIdShow &&
      items.push(
        <div key="deviceId" className="lock-width">
          <label className={`fn-label${required(deviceIdRequired)}`}>{ot('GPS编号')}</label>
          <Select
            data-path="device_id"
            required={deviceIdRequired}
            value={deviceId}
            data={deviceIdEnum}
            disabled={!deviceIdEditable}
            format="device_num"
            compare="id"
            map="id"
            multiple
            filter={deviceIdSug}
            header={GPSHeader}
            onChange={val => set('deviceId', val)}
          />
        </div>,
      );

    items.push(
      <div key="b1" />,
      <div key="b2" />,
      <div key="b3" />,
      <div key="b4" />,
      <div key="b5" />,
      <div key="b6" />,
      <div key="b7" />,
      <div key="b8" />,
      <div key="b9" />,
    );

    return (
      <div className={prefixCls}>
        {items.map((item, index) => {
          // 此处为了hack布局
          if (item === 'x2') {
            if (!firstX2Index) {
              firstX2Index = index;
              afterIndex = 2;
            }
            return null;
          }
          if (firstX2Index && item) afterIndex++;
          if (firstX2Index > 4 && firstX2Index === afterIndex) {
            if (index + 10 > items.length) return <div key={index} className="x2" />;
            const next = items[index + 1];
            afterIndex = 2;
            if (next === 'x2' || items[index + 2] === 'x2') {
              return item;
            }
            items[index + 1] = null;
            return (
              <div key={index} className="x2 combine">
                {item}
                {next}
              </div>
            );
          }
          return item;
        })}
      </div>
    );
  };
}

export default renderTraffic;
