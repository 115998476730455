import React from 'react';
import QRCode from 'qrcode';
import { Button, PopTip, PopUp, ShowInfo } from 'components';
import { CHECK, ERROR, INFO, WARN } from 'constants';
import actions from 'actions';
import {
  alert,
  checkSelected,
  confirm,
  fetchApi,
  postJAVA,
  getPageKey,
  opTips,
  remDub,
  showInfo,
  sleep,
  viewImage,
  createTip,
  fetchJAVA,
  g7Done,
} from 'utils';
import DriverOperat from 'operation/listOperation/DriverOperat';
import MarkBlack from 'operation/listOperation/DriverOperat/markBlack';
import DriverOperatNew from 'operation/listOperation/DriverOperat/ntocc';
import CommentDialog from 'components/commoncomponents/commentDialog';
import DriverBatchEdit from 'operation/listOperation/DriverBatchEdit';
import BatchOp from 'components/commoncomponents/BatchOp';
import Sign from 'operation/listOperation/PlatformOperat/components/Sign';
import Cert from 'operation/listOperation/PlatformOperat/components/Cert';
import DriverPositive from 'operation/listOperation/DriverPositive';
import Receiver from 'operation/listOperation/receiverOperat';
import { batchOpConfig } from './driverOperatConfig';
import './driverOperat.scss';
import { checkIdsHasBillOrShare, ENUMS_TRUCK_DRIVER, getDelComfirmContent } from './commonOp/delTruckDriverConfig';
// import html2canvas from 'html2canvas'

const popTip = (tips = '操作成功', type = INFO) => {
  new PopUp(PopTip, {
    type,
    content: tips,
  }).show();
};

const setCheck = () => {
  const comSet = window.company_setting || {};
  const appKey = comSet.app_key || {};
  const appSecret = comSet.app_secret || {};

  if (!appKey.value || !appSecret.value) {
    return false;
  }
  return true;
};

const doCheck = () => {
  const { hide_cmm } = window.logo_config;
  const contentNotice = (
    <div className="carry-set-check">
      <div className="reason-red">原因说明：</div>
      <p>开通结算服务后才可使用此功能{!hide_cmm && '，如需开通或了解此项服务，请联系客服'}！</p>
    </div>
  );
  alert(WARN, '您还未开通结算服务，请先开通！', { confirm: '关闭' }, contentNotice);
};

const getDriverTitle = ({ category, tab }) => {
  if (category === 'Driver' && tab === 'intention_driver') {
    return '意向';
  }
  return '';
};

export const viewImg = function (that, ele, rowIndex, column, data) {
  console.log(that, ele, rowIndex, column);
  const { category, tab } = that.tableInfo || {};

  const imgTypeConf = {
    Driver_driver: 'driver',
    DriverFollowUp_default: 'dr_follow_up',
    DriverTrain_default: 'dr_train',
  };
  const type = imgTypeConf[`${category}_${tab}`] || 'driver';
  const props = {
    type,
    path: data.path,
    name: data.name,
  };
  viewImage(props);
};
export const addDriver = function (that, ele, isCallBack, ext) {
  const pageKey = getPageKey().pageConfig.key;
  console.log(isCallBack, ele);
  const companyId = (ext && ext.company_id) || undefined;
  const { category, tab } = that.tableInfo || {};
  const _req = {
    tab,
    driver_info: {
      driver_id: -1,
    },
    data: {
      driver_id: -1,
    },
    op_type: 'add',
    company_id: companyId,
  };
  actions.companyServer.postDriverInfo(_req).then(res => {
    if (res.type === 'API_SUCCESS') {
      if (res.data.errno === 0) {
        const resUse = res.data || {};
        const parm = {
          tab,
          category,
          pageType: 'driverMangerList',
          opType: 'add',
          titleName: `新增${getDriverTitle({ category, tab })}司机`,
          listPageRef: that,
          isCallBack,
          fetchRes: resUse.res || {},
          popName: `operatSlideDrag_${pageKey}`,
        };
        const dialog = new PopUp(DriverOperat, parm);
        dialog.show();
      } else {
        popTip(res.data.errmsg, ERROR);
      }
    } else {
      new PopUp(PopTip, {
        classname: 'pop_tip',
        type: ERROR,
        isShow: true,
        content: '网络错误！',
        autoDestroy: true,
      }).show();
    }
  });
};
export const updateDriver = function (that, driverId, tableInfo = {}) {
  const drComId = driverId || that.getSelectesKey('id');
  if (!checkSelected(drComId, true)) return;

  const pageKey = getPageKey().pageConfig.key;
  const { category, tab } = driverId ? tableInfo : that.tableInfo;
  actions.companyServer
    .postDriverInfo({
      tab,
      driver_info: {
        driver_id: drComId,
      },
      data: {
        driver_id: drComId,
      },
      op_type: 'update',
    })
    .then(res => {
      if (res.type === 'API_SUCCESS') {
        if (res.data.errno === 0) {
          const resUse = res.data || {};
          const parm = {
            tab,
            category,
            pageType: 'driverMangerList',
            opType: 'update',
            titleName: `修改${getDriverTitle({ category, tab })}司机`,
            driver_id: drComId,
            listPageRef: that,
            fetchRes: resUse.res || {},
            popName: `operatSlideDrag_${pageKey}`,
          };
          // const dialog = new PopUp(DriverOperat, parm);
          const dialog = new PopUp(tab.indexOf('cert') > -1 && g7Done() ? DriverOperatNew : DriverOperat, parm);
          dialog.show();
        } else {
          popTip(res.data.errmsg, ERROR);
        }
      } else {
        popTip('网络错误', ERROR);
      }
    });
};
export const columnName = function (that, ele) {
  const { category, tab } = that.tableInfo || {};

  const pageKey = getPageKey().pageConfig.key;
  const data = that.getTableList([ele]);
  const dataObj = data[0] || {};

  if (+dataObj.dr_status === 0) {
    return showInfo(ERROR, '司机已删除！');
  }

  const drComId = category === 'Driver' ? dataObj.id : dataObj.dr_id;
  const _req = {
    tab,
    driver_info: {
      driver_id: drComId,
    },
    data: {
      driver_id: drComId,
    },

    op_type: 'view',
  };

  // const urlData = {
  //   controller: 'Schedule',
  //   action: 'taskList'
  // }
  // const pack = {
  //   designateParam: '',
  //   designateFilter: '',
  //   initFilterHeader: ''
  // }
  // urlData.injectProps = pack
  // window.$app.direct(urlData)
  actions.companyServer.postDriverInfo(_req).then(res => {
    if (res.type === 'API_SUCCESS') {
      if (res.data.errno === 0) {
        const resUse = res.data || {};
        const parm = {
          tab,
          category,
          pageType: 'driverMangerList',
          opType: 'view',
          titleName: `查看${getDriverTitle({ category, tab })}司机`,
          driver_id: drComId,
          listPageRef: that,
          fetchRes: resUse.res || {},
          popName: `operatSlideDrag_${pageKey}`,
        };

        const dialog = new PopUp(tab.indexOf('cert') > -1 && g7Done() ? DriverOperatNew : DriverOperat, parm);
        dialog.show();
      } else {
        popTip(res.data.errmsg, ERROR);
      }
    } else {
      new PopUp(PopTip, {
        classname: 'pop_tip',
        type: ERROR,
        isShow: true,
        content: '网络错误！',
        autoDestroy: true,
      }).show();
    }
  });
};
export const delDriver = async function (that) {
  const _comIds = that.getSelectesKey();
  if (_comIds.length < 1) {
    new PopUp(PopTip, {
      classname: 'pop_tip',
      type: WARN,
      isShow: true,
      content: '请选择要删除的司机！',
      autoDestroy: true,
    }).show();
    return false;
  }
  const table = that.getData();
  const seletedList = [];
  table.map((item, i) => {
    const list = JSON.parse(JSON.stringify(item));
    if (_comIds.indexOf(i) > -1) {
      list.wh_cert_status = `wh_cert_status_${item.wh_cert_status}`;
      list.wh_sign_status = `wh_sign_status_${item.wh_sign_status}`;
      seletedList.push(list);
    }
    return item;
  });
  const disabled_driver =
    (window.company_setting.driver_disabled_info && window.company_setting.driver_disabled_info.selc) || [];
  const dis_list = [];
  let deleteId = [];
  console.log(disabled_driver, seletedList);
  if (disabled_driver.length > 0) {
    seletedList.map((item, i) => {
      if (disabled_driver.indexOf(item.wh_cert_status) > -1 || disabled_driver.indexOf(item.wh_sign_status) > -1) {
        dis_list.push(item);
      } else {
        deleteId.push(i);
      }
      return item;
    });
  } else {
    deleteId = JSON.parse(JSON.stringify(_comIds));
  }
  const _drComIds = [];
  const { tab } = that.tableInfo;
  for (const val of deleteId) {
    _drComIds.push(table[val].id);
  }
  const contentNotice = (
    <div className="carry-set-check">
      <h2 className="marginBottom20">以下司机不可删除，是否继续删除其他司机？</h2>
      <div className="reason-content">
        <div className="reason-red">原因说明：</div>
        <div className="reason-red name">司机：</div>
        <div className="reason-name">
          {dis_list.length > 0 &&
            dis_list.map((item, i) => (
              <span className="reason-span">
                {item.name} {i + 1 === dis_list.length ? null : ','}
              </span>
            ))}
        </div>
      </div>
    </div>
  );

  const confirmDeleteDriver = (thisDialog, driverIds) =>
    actions.companyServer
      .postDriverMangerAM({
        tab,
        comId: driverIds,
        data: {
          driver_id: driverIds,
        },
        opType: 'delete',
      })
      .then(res => {
        thisDialog.changeBtnLoadingStatus('confirm', false);
        popup.close();
        if (res.type === 'API_SUCCESS') {
          const resData = res.data;
          const pureRes = resData.res || {};
          if (resData.errno === 0 && pureRes.failed_detail) {
            opTips({
              orderType: '司机',
              opType: '删除',
              resData,
              sucCallback: () => {
                thisDialog.handleHide();

                setTimeout(() => that.handleRefreshData(), 2000);
                return true;
              },
              showSucTips: false,
              continueCallback: (sucId, cb) => {
                cb?.();

                confirmDeleteDriver(thisDialog, sucId);
              },
              ext: {
                failedCallback: () => false,
                // specialTips: specTips,
              },
              tips: '删除',
            });
            return false;
          }
          if (res.data.errno === 0) {
            new PopUp(PopTip, {
              classname: 'pop_tip',
              type: CHECK,
              isShow: true,
              content: '删除成功！',
              autoDestroy: true,
            }).show();
            thisDialog.handleHide();
            that.handleRefreshData();
          } else {
            popTip(res.data.errmsg, ERROR);
            thisDialog.changeBtnLoadingStatus('confirm', false);
          }
        } else {
          popTip('删除失败，请重新删除！', ERROR);
          thisDialog.changeBtnLoadingStatus('confirm', false);
        }
      })
      .catch(() => {
        thisDialog.handleHide();
      });
  const selectDriverIds = that.getSelectesKey('id');
  // 检测被删除的id是否有运单
  that.pageStartLoading();
  const hasBillOrShareRes = await checkIdsHasBillOrShare(selectDriverIds, ENUMS_TRUCK_DRIVER.driverType);
  that.pageStopLoading();
  const confirmContent = getDelComfirmContent(hasBillOrShareRes, ENUMS_TRUCK_DRIVER.driverType);
  const popup = new PopUp(ShowInfo, {
    classname: 'del-customer',
    isShow: true,
    type: WARN,
    content: confirmContent,
    autoDestroy: true,
    title: '删除',
    simpleMode: {
      confirm: thisDialog => confirmDeleteDriver(thisDialog, _drComIds),
      cancel: thisDialog => thisDialog.handleHide(),
    },
    closable: true,
    maskClosable: true,
  });
  const popupTip = new PopUp(ShowInfo, {
    classname: 'del-customer',
    isShow: true,
    type: WARN,
    content: contentNotice,
    autoDestroy: true,
    title: '操作确认',
    simpleMode: {
      confirm: thisDialog => {
        // thisDialog.changeBtnLoadingStatus('confirm', true)
        thisDialog.handleHide();
        popup.show();
      },
      cancel: thisDialog => thisDialog.handleHide(),
    },
    closable: true,
    maskClosable: true,
  });
  if (dis_list.length > 0) {
    if (deleteId.length > 0) {
      popupTip.show();
    } else {
      createTip('选中的司机不允许删除！', WARN).show();
    }
  } else {
    popup.show();
  }
};
export const commentDriver = function (that) {
  const _comIds = that.vTable.getStateSelectes();
  const selectData = [];
  const needData = [];
  _comIds.map(item => selectData.push(that.getData()[item]));
  console.log(selectData);
  selectData.map(item =>
    needData.push({
      car_batch: item.name,
      id: item.id,
    }),
  );
  const ids = that.getSelectesKey('id');
  if (_comIds.length < 1) {
    createTip('请选择要评价的司机！', ERROR).show();
    return false;
  }
  const req = {
    appraise_type: 'driver',
    id: ids[0],
  };
  fetchJAVA('/cmm-batch/appraise/pre', {
    method: 'POST',
    body: { req },
  }).then(res => {
    new PopUp(CommentDialog, {
      isShow: true,
      title: '评价司机',
      subjective: false,
      id: ids[0],
      data: res.res.subjective_appraise,
      appraise_id: res.res.appraise_id,
      appraise_type: 'driver',
      needData,
      dialogTitle: '司机名称',
      batch: needData.length !== 1,
      tips: '评价司机',
      finishCallback: (...rest) => {
        setTimeout(() => {
          that.handleRefreshData();
        }, 2000);
      },
      escBind: false,
    }).show();
  });
};
async function driverCertFetch(that, id, isCheck = 1) {
  await sleep(0);
  const continueCb = async () => {
    new PopUp(BatchOp, {
      title: '司机认证进度',
      requestUrl: '/Basic/Driver/cert',
      tips: '批次认证',
      data: [...that.getStateSelecteRows()],
      header: {
        name: { title: '姓名', type: 'Text', display: 'show', width: 45, align: 'center', flexGrow: 1 },
        tel: { title: '手机号', type: 'Text', display: 'show', width: 45, align: 'center' },
        id_num: { title: '身份证号', type: 'Text', display: 'show', width: 45, align: 'center' },
      },
      prefixalTips: '',
      bodyCellStyle: { lineHeight: '30px' },
      indexCellStyle: { lineHeight: '30px' },
      getPara: dataList => {
        const rowData = dataList[0];
        return { id: rowData.id };
      },
      paceCallback: async res => {
        if (res.errno !== 0) {
          that.enableMenuButton && that.enableMenuButton('driver_cert');
          showInfo(ERROR, res.errmsg);
          return false;
        }
      },
      finishCallback: (...rest) => {
        setTimeout(() => {
          that.handleRefreshData();
          that.enableMenuButton && that.enableMenuButton('driver_cert');
        }, 2000);
      },
      escBind: false,
    }).show();
  };
  continueCb();
}
export const driverCert = function (that) {
  if (!setCheck()) {
    return doCheck();
  }

  const data = that.getSelectesKey('id');
  if (data.length < 1) {
    showInfo(WARN, '请选择要操作的司机！');
    return false;
  }

  const companyIds = that.getSelectesKey('company_id');
  const checkComId = comId => {
    if (comId.length > 1) {
      showInfo(ERROR, '不能操作多网点的数据！');
      return false;
    }
    return true;
  };
  const _comId = remDub(companyIds); // 去重
  if (!checkComId(_comId)) return;
  console.log(data);
  driverCertFetch(that, data);
};

// 平台司机管理 - 上报操作
export const driverPlatformLogink = async (that, selectKey) => {
  const selectData = that.getStateSelecteRows();
  const ids = that.getSelectesKey('id');
  if (!ids.length) {
    showInfo(WARN, '请选择要操作的数据！');
    return;
  }
  // 批量处理 参数
  const param = {
    isJAVA: true,
    title: '司机上报',
    requestUrl: '/cmm-basic/wlhy/report/mancar',
    tips: '1213',
    header: {
      name: { title: '司机姓名', display: 'show' },
      tel: { title: '司机手机号', display: 'show' },
    },
    data: selectData,
    escBind: false,
    getPara: dataList => ({ report_id: dataList[0].id, is_check: 1, report_type: 'wlhysj' }),
    paceCallback: callbackRes => {
      if (+callbackRes.errno !== 0) {
        that.enableMenuButton && that.enableMenuButton(selectKey);
        callbackRes.errmsg && showInfo(ERROR, callbackRes.errmsg);
        return false;
      }
    },
    finishCallback: () => {
      setTimeout(() => {
        that.enableMenuButton && that.enableMenuButton(selectKey);
        that.handleRefreshData();
      }, 2000);
    },
  };
  const batchOpDialog = new PopUp(BatchOp, param);
  batchOpDialog.show();
};

// 平台司机管理 - 认证操作
export const doDriverPlatformCert = (that, index) => {
  const ids = that.getSelectesKey('id');
  if (!ids.length) {
    showInfo(WARN, '请选择要操作的数据！');
    return;
  }
  const op = driver_ids => {
    postJAVA('/cmm-basic/driver/handleAttestationOfPlatform/batch', { ids: driver_ids }).then(res => {
      if (driver_ids.length === 1 && res.res === null) {
        const list = that.getData();
        const a = list.find(item => +item.wh_cert_status !== 1 || +item.wh_cert_status !== 2);
        if (a.length >= 1) {
          showInfo(ERROR, '只有认证中或认证失败的数据才能操作认证');
          return false;
        }
        const req = {
          ids: driver_ids,
        };
        const param = {
          className: 'platform-driver-cert',
          reqData: req,
          title: '司机认证',
          opType: 'driver',
          passCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
          refuseCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
        };
        const dialog = new PopUp(Cert, param);
        dialog.show();
      } else if (res.res === null) {
        const list = that.getData();
        const a = list.find(item => +item.wh_cert_status !== 1 || +item.wh_cert_status !== 2);
        if (a.length >= 1) {
          showInfo(ERROR, '只有认证中或认证失败的数据才能操作认证');
          return false;
        }
        const req = {
          ids: driver_ids,
        };
        const param = {
          className: 'platform-driver-cert',
          reqData: req,
          title: '司机认证',
          opType: 'driver',
          passCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
          refuseCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
        };
        const dialog = new PopUp(Cert, param);
        dialog.show();
      } else {
        opTips({
          orderType: '司机认证',
          opType: '认证',
          resData: res,
          continueCallback: (sucId, cb) => {
            cb();
            // op(ids.filter(id => sucId.includes(+id)))
            const list = that.getData();
            const a = list.find(item => +item.wh_cert_status !== 1 || +item.wh_cert_status !== 2);
            if (a.length >= 1) {
              showInfo(ERROR, '只有认证中或认证失败的数据才能操作认证');
              return false;
            }
            const req = {
              ids: sucId,
            };
            const param = {
              className: 'platform-driver-cert',
              reqData: req,
              title: '司机认证',
              opType: 'driver',
              passCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
              refuseCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
            };
            const dialog = new PopUp(Cert, param);
            dialog.show();
          },
        });
      }
    });
  };
  op(ids);
};

// 代收邀约
export const receiverSign = async (that, index) => {
  const ids = that.getSelectesKey('id');
  if (ids.length === 0) {
    showInfo(ERROR, '请选择司机！');
    return;
  }
  if (ids.length === 1) {
    const list = that.getData();
    const p_c_state = that.getSelectesKey('p_c_state')[0];
    const dr_con_state = that.getSelectesKey('dr_con_state')[0];
    if (!(+p_c_state === 2 && +dr_con_state === 2)) {
      showInfo(ERROR, '司机未认证或签约成功，不能代收签约！');
      return;
    }
    const param = {
      listPageRef: that,
      dr_com_id: that.getSelectesKey('id')[0],
    };
    const dialog = new PopUp(Receiver, param);
    dialog.show();
    return;
  }
  if (ids.length > 100) {
    that.enableMenuButton(index);
    return showInfo(ERROR, '每次最多可操作 100 条数据!');
  }

  const failedDetail = [];
  const successId = [];
  const selcDatas = that.getStateSelecteRows();

  selcDatas.forEach(rowItem => {
    if (+rowItem.p_c_state !== 2) {
      // 未认证
      failedDetail.push({
        ext: [],
        msg: '未完成认证',
        number: `${rowItem.name}(${rowItem.id_num})`,
      });
    }

    if (+rowItem.dr_con_state !== 2) {
      // 未签约
      failedDetail.push({
        ext: [],
        msg: '未签约',
        number: `${rowItem.name}(${rowItem.id_num})`,
      });
    }
    if (+rowItem.p_c_state === 2 && +rowItem.dr_con_state === 2) {
      successId.push(rowItem.id_num);
    }
  });

  const infoRes = {
    errmsg: '',
    errno: 0,
    res: {
      failed_detail: failedDetail,
      success_id: successId,
    },
  };
  await sleep(0);

  // 2.选择收款人
  const handlePayeedata = (okIds, cb) => {
    cb && cb();
    const param = {
      listPageRef: that,
      dr_com_ids: successId,
    };
    new PopUp(Receiver, param).show();
  };

  // 1.操作确认
  opTips({
    orderType: '司机',
    opType: '代收签约',
    resData: infoRes,
    sucCallback: () => {
      // continueCb();
      handlePayeedata();
      return true;
    },
    showSucTips: false,
    continueCallback: handlePayeedata,
    ext: {
      failedCallback: () => {
        that.enableMenuButton(index);
        return false;
      },
      specialTips: '以下司机无法代收签约，是否继续邀请其他司机？',
    },
    tips: '代收签约',
  });
};

// 标记司机黑户
export const driverBlackMark = async (that, index) => {
  const ids = that.getSelectesKey('id');

  if (ids.length === 0) {
    showInfo(WARN, '请先选择司机');
    return;
  }

  const dialog = new PopUp(MarkBlack, {
    ids,
    sucCallback: () => {
      setTimeout(() => {
        that.handleRefreshData();
      }, 1000);
    },
  });
  dialog.show();
};
// 平台司机管理 - 签约操作列
export const doDriverPlatformSign = (that, index) => {
  const ids = that.getSelectesKey('id');
  if (!ids.length) {
    showInfo(WARN, '请选择要操作的数据！');
    return;
  }
  if (ids.length !== 1) {
    showInfo(WARN, '仅支持操作单条数据！');
    return;
  }
  const list = that.getData();
  const { id } = list[index];
  const { wh_cert_status } = list[index];
  const { wh_sign_status } = list[index];
  if (!(+wh_cert_status === 2 && +wh_sign_status === 0)) {
    showInfo(ERROR, '只有认证成功未签约的数据才能操作签约');
    return;
  }

  const param = {
    title: '司机签约',
    reqData: {
      id,
    },
    submitCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
  };
  const dialog = new PopUp(Sign, param);
  dialog.show();
};
// 司机管理 - 提交认证
export const driverPlatformCert = async (that, selectKey) => {
  // 获取所选中的数据
  const selectData = that.getStateSelecteRows();
  if (!checkSelected(selectData)) return;

  const ids = selectData.map(item => item.id);

  // 后端指定一个错误码，提示未开通网货服务。
  const req = { ids, is_check: 1 };
  const res = await postJAVA('/cmm-basic/driver/commitAttestationOfPlatform', req);
  if (+res.errno === -10000) {
    const contentNotice = (
      <div className="carry-set-check">
        <div className="reason-red">原因说明：</div>
        <p>开通网货服务后才可使用此功能，如需开通或了解此项服务，请联系客服 400-611-5656！</p>
      </div>
    );
    alert(WARN, '您还未开通网货服务，请先开通！', { confirm: '关闭' }, contentNotice);
    return false;
  }
  console.log(selectKey);
  // 批量处理
  that.disableMenuButton && that.disableMenuButton(selectKey);
  const batchOpData = selectData;
  // 批量处理 参数
  const param = {
    isJAVA: true,
    title: batchOpConfig[selectKey].title,
    requestUrl: batchOpConfig[selectKey].url,
    tips: batchOpConfig[selectKey].tips,
    header: batchOpConfig[selectKey].header,
    data: batchOpData,
    escBind: false,
    getPara: dataList => ({ ids: [dataList[0].id], is_check: 0 }),
    paceCallback: callbackRes => {
      if (+callbackRes.errno !== 0) {
        that.enableMenuButton && that.enableMenuButton(selectKey);
        callbackRes.errmsg && showInfo(ERROR, callbackRes.errmsg);
        return false;
      }
    },
    finishCallback: () => {
      setTimeout(() => {
        that.enableMenuButton && that.enableMenuButton(selectKey);
        that.handleRefreshData();
      }, 2000);
    },
  };
  console.log(
    batchOpConfig[selectKey].title,
    batchOpConfig[selectKey].url,
    batchOpConfig[selectKey].tips,
    batchOpConfig[selectKey].header,
    batchOpData,
  );
  const batchOpDialog = new PopUp(BatchOp, param);
  batchOpDialog.show();
};
const refreshCert = async (listPage, btnKey) => {
  console.log('listPage, btnKey, rest', listPage, btnKey);
  listPage.disableMenuButton(btnKey);

  const ids = listPage.getSelectesKey('id');

  if (ids.length !== 1) {
    listPage.enableMenuButton(btnKey);
  }
  if (!ids.length) {
    return showInfo(ERROR, '请您选择一条数据！');
  } else if (ids.length > 1) {
    return showInfo(ERROR, '一次只能操作一条数据！');
  }

  let reqObj = { category: 'driver', driver_id: ids[0] };
  const tableInfo = listPage.tableInfo || {};
  if (tableInfo.category === 'Truck') {
    reqObj = { category: 'truck', truck_id: ids[0] };
  }

  const conf = { method: 'POST', body: { req: reqObj } };
  const infoRes = await fetchApi('/Truck/G7/syncImportStatusG7', conf);
  listPage.enableMenuButton(btnKey);
  if (+infoRes.errno !== 0) {
    showInfo(ERROR, infoRes.errmsg);
    return false;
  }
  setTimeout(listPage.handleRefreshData, 2000);
  showInfo(CHECK, infoRes.errmsg);
};

const batchUpdate = async (listPage, btnKey) => {
  const id = listPage.getSelectesKey('id');
  if (id.length < 1) {
    showInfo(ERROR, '请您选择一条数据！');
    return false;
  }
  new PopUp(DriverBatchEdit, { id, listPage, btnKey, popName: 'batchEditDg' }).show();
};

export const bindGPSNo = async (listPage, btnKey, sucIds) => {
  const drComIds = sucIds || listPage.getSelectesKey('id');

  if (!drComIds.length) {
    return showInfo(WARN, '请选择要操作的数据！');
  }
  if (drComIds.length > 50) {
    return showInfo(WARN, '最多支持同时操作50条数据！');
  }

  listPage.disableMenuButton(btnKey);

  await sleep(0);

  const conf = { method: 'POST', body: { req: { dr_com_ids: drComIds, is_check: sucIds ? 0 : 1 } } };
  const infoRes = await fetchApi('/Basic/Driver/bindG7Gpsno', conf);
  if (+infoRes.errno !== 0) {
    infoRes.errmsg && showInfo(WARN, infoRes.errmsg);
    listPage.enableMenuButton && listPage.enableMenuButton(btnKey);
    return false;
  }

  const continueCb = (okIds, cb) => {
    cb && cb();
    bindGPSNo(listPage, btnKey, okIds);
  };

  const sucCb = () => {
    listPage &&
      setTimeout(() => {
        listPage.handleRefreshData && listPage.handleRefreshData();
        listPage.enableMenuButton && listPage.enableMenuButton(btnKey);
      }, 2000);
  };

  opTips({
    orderType: '司机',
    opType: '',
    resData: infoRes,
    sucCallback: sucCb,
    showSucTips: true,
    continueCallback: continueCb,
    ext: {
      failedCallback: () => {
        listPage.enableMenuButton(btnKey);
        return false;
      },
      specialTips: '以下司机无法分配设备，是否继续操作其他司机？',
    },
    tips: '分配设备',
  });
};

export const signContract = async (listPage, rowIndex, column, rowData, colVal) => {
  if (colVal !== '已签约') {
    return false;
  }
  const idCard = rowData.id_num; // '120112197109108358' //
  const conf = { method: 'POST', body: { req: { card: idCard } } };
  const infoRes = await fetchApi('/Basic/G7/getContract', conf);
  if (+infoRes.errno !== 0) {
    showInfo(ERROR, infoRes.errmsg);
    return false;
  }
  const resData = infoRes.res || {};
  const pdfUrl = resData.contractUrl;
  console.log('signContract === pdfUrl ', pdfUrl);
  // pdfUrl && setTimeout(() => window.open(pdfUrl), 2000)
  pdfUrl && window.open(pdfUrl);
};

export const scanSign = async (listPage, btnKey) => {
  if (!setCheck()) {
    return doCheck();
  }

  listPage.disableMenuButton(btnKey);

  const conf = { method: 'POST', body: { req: null } };
  const infoRes = await fetchApi('/Basic/G7/gethash', conf);
  if (+infoRes.errno !== 0) {
    listPage.enableMenuButton(btnKey);
    showInfo(ERROR, infoRes.errmsg);
    return false;
  }
  const resData = infoRes.res || {};
  const qrUrl = resData.url;

  const downloadQr = async proxy => {
    console.log('下载二维码');
    const { invoiceTitle, imgDataUrl } = proxy;

    const canvas = document.getElementById('signQrCanvas');
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#0D6EB8';
    ctx.fillRect(0, 0, 750, 1060);

    ctx.fillStyle = '#FFF';
    ctx.fillRect(27, 27, 700, 900);

    ctx.font = 'bold 38px sans-serif';
    ctx.fillStyle = '#131E27';
    ctx.textAlign = 'center';
    ctx.fillText(`${invoiceTitle}`, 375, 100);

    ctx.font = 'bold 38px sans-serif';
    ctx.fillStyle = '#131E27';
    ctx.textAlign = 'center';
    ctx.fillText('司机签约二维码', 375, 160);

    ctx.fillStyle = '#329FD9';
    ctx.fillRect(90, 254, 576, 576);
    ctx.fillStyle = '#FFF';
    ctx.fillRect(95, 260, 565, 565);

    const qrCodeImg = new Image();
    qrCodeImg.src = imgDataUrl;
    ctx.drawImage(qrCodeImg, 120, 285, 515, 515);

    ctx.font = '30px sans-serif';
    ctx.fillStyle = '#FFF';
    ctx.textAlign = 'center';
    ctx.fillText('司机使用微信扫描二维码即可签署《承运服务合同》', 375, 1000);

    console.log('下载二维码 = ', ctx);

    const imgBase64 = canvas.toDataURL('image/png');

    const base64ToBlob = code => {
      const parts = code.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {
        type: contentType,
      });
    };
    const downloadBase64File = (content, fileName) => {
      // 下载base64图片
      const aLink = document.createElement('a');
      const blob = base64ToBlob(content); // new Blob([content]);
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('click', true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
    };
    downloadBase64File(imgBase64, '签约二维码');
  };

  const imgDataUrl = await QRCode.toDataURL(qrUrl, { margin: 0, width: 280 });
  const invoiceTitleMeta = window.company_setting.invoice_title || {};
  const invoiceTitle = invoiceTitleMeta.value;
  const qrDom = (
    <div className="qr-pop-wrap">
      <div className="title">
        <div className="company-title"> {invoiceTitle} </div>
        <div className="pure-title"> 司机签约二维码 </div>
      </div>
      <div className="qr-img-wrap">
        <img width src={imgDataUrl} />
      </div>
      <div className="qr-foot">司机使用微信扫描二维码即可签署承运服务合同</div>
      <Button classname="down-qr" onClick={() => downloadQr({ invoiceTitle, imgDataUrl })}>
        下载二维码
      </Button>
      <div className="down-load-save">
        <canvas id="signQrCanvas" width="750" height="1060" />
      </div>
    </div>
  );

  if (
    !(await confirm(null, qrDom, { confirm: '下载', cancel: '关闭' }, '签约二维码', null, null, 'driver-cert-scan'))
  ) {
    listPage.enableMenuButton(btnKey);
    return false;
  }
  listPage.enableMenuButton(btnKey);
};

export const getSignQr = async (listPage, btnKey) => {
  listPage.disableMenuButton(btnKey);
  const conf = { method: 'POST', body: { req: null } };
  const infoRes = await fetchApi('/Basic/G7/getSignQr', conf);
  if (+infoRes.errno !== 0) {
    listPage.enableMenuButton(btnKey);
    showInfo(ERROR, infoRes.errmsg);
    return false;
  }
  const resData = infoRes.res || {};
  const { title = '', theme = '', content = '', link = '' } = resData.signQr;
  const qrUrl = link;

  const downloadQr = async proxy => {
    const { title: invoiceTitle, imgDataUrl } = proxy;

    const canvas = document.getElementById('signQrCanvas');
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#0D6EB8';
    ctx.fillRect(0, 0, 750, 1060);

    ctx.fillStyle = '#FFF';
    ctx.fillRect(27, 27, 700, 900);

    ctx.font = 'bold 38px sans-serif';
    ctx.fillStyle = '#131E27';
    ctx.textAlign = 'center';
    ctx.fillText(`${invoiceTitle}`, 375, 100);

    ctx.font = 'bold 38px sans-serif';
    ctx.fillStyle = '#131E27';
    ctx.textAlign = 'center';
    theme && ctx.fillText(theme, 375, 160);

    ctx.fillStyle = '#329FD9';
    ctx.fillRect(90, 254, 576, 576);
    ctx.fillStyle = '#FFF';
    ctx.fillRect(95, 260, 565, 565);

    const qrCodeImg = new Image();
    qrCodeImg.src = imgDataUrl;
    ctx.drawImage(qrCodeImg, 120, 285, 515, 515);

    ctx.font = '30px sans-serif';
    ctx.fillStyle = '#FFF';
    ctx.textAlign = 'center';
    content && ctx.fillText(content, 375, 1000);

    const imgBase64 = canvas.toDataURL('image/png');

    const base64ToBlob = code => {
      const parts = code.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {
        type: contentType,
      });
    };
    const downloadBase64File = (contents, fileName) => {
      // 下载base64图片
      const aLink = document.createElement('a');
      const blob = base64ToBlob(contents); // new Blob([content]);
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('click', true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
    };
    downloadBase64File(imgBase64, '签约二维码');
  };

  const imgDataUrl = await QRCode.toDataURL(qrUrl, { margin: 0, width: 280 });
  const qrDom = (
    <div className="qr-pop-wrap">
      <div className="title">
        <div className="company-title"> {title} </div>
        <div className="pure-title"> {theme} </div>
      </div>
      <div className="qr-img-wrap">
        <img width src={imgDataUrl} />
      </div>
      <div className="qr-foot">{content}</div>
      <Button classname="down-qr" onClick={() => downloadQr({ title, imgDataUrl })}>
        下载二维码
      </Button>
      <div className="down-load-save">
        <canvas id="signQrCanvas" width="750" height="1060" />
      </div>
    </div>
  );

  if (
    !(await confirm(null, qrDom, { confirm: '下载', cancel: '关闭' }, '签约二维码', null, null, 'driver-cert-scan'))
  ) {
    listPage.enableMenuButton(btnKey);
    return false;
  }
  listPage.enableMenuButton(btnKey);
};

// 邀请签约
export const inviteSign = async (listPage, btnKey) => {
  if (!setCheck()) {
    return doCheck();
  }

  const idCards = listPage.getSelectesKey('id_num');

  if (!idCards.length) {
    return showInfo(ERROR, '请您选择一条数据！');
  }
  listPage.disableMenuButton(btnKey);
  if (idCards.length === 1) {
    const rowData = (listPage.getStateSelecteRows() || [])[0] || {};

    if ([0, 3].includes(+rowData.p_c_state)) {
      listPage.enableMenuButton(btnKey);
      return showInfo(ERROR, '认证完成后才可签约！');
    }
    const conf = { method: 'POST', body: { req: { cards: `${idCards[0]}`, cert_status: +rowData.p_c_state } } };
    const infoRes = await fetchApi('/Basic/G7/inviteContract', conf);
    if (+infoRes.errno !== 0) {
      listPage.enableMenuButton(btnKey);
      showInfo(ERROR, infoRes.errmsg);
      return false;
    }
    infoRes.errmsg && showInfo(CHECK, infoRes.errmsg);
    listPage.enableMenuButton(btnKey);
    listPage.handleRefreshData();
    return false;
  }
  if (idCards.length > 100) {
    listPage.enableMenuButton(btnKey);
    return showInfo(ERROR, '每次最多可操作 100 条数据!');
  }

  const failedDetail = [];
  const successId = [];
  const selcDatas = listPage.getStateSelecteRows();

  selcDatas.forEach(rowItem => {
    if (+rowItem.p_c_state !== 2) {
      // 未认证
      failedDetail.push({
        ext: [],
        msg: '未完成认证',
        number: `${rowItem.name}(${rowItem.id_num})`,
      });
    }

    // if (+rowItem.dr_con_state === 2) {
    //   // 已签约
    //   failedDetail.push({
    //     ext: [],
    //     msg: '已签约',
    //     number: `${rowItem.name}(${rowItem.id_num})`,
    //   });
    // }
    if (+rowItem.p_c_state !== 0) {
      successId.push(rowItem.id_num);
    }
  });

  const infoRes = {
    errmsg: '',
    errno: 0,
    res: {
      failed_detail: failedDetail,
      success_id: successId,
    },
  };

  await sleep(0);

  const continueCb = async (okIds, cb) => {
    cb && cb();

    const filterData = selcDatas.filter(item => successId.includes(item.id_num));

    new PopUp(BatchOp, {
      title: '邀请签约进度',
      requestUrl: '/Basic/G7/inviteContract',
      tips: '邀请签约',
      data: [...filterData],
      header: {
        name: { title: '司机姓名', type: 'Text', display: 'show', width: 45, align: 'center', flexGrow: 1 },
        id_num: { title: '身份证号', type: 'Text', display: 'show', width: 45, align: 'center' },
        tel: { title: '手机号', type: 'Text', display: 'show', width: 45, align: 'center' },
      },
      prefixalTips: '',
      getPara: dataList => {
        const rowData = dataList[0];
        return { cards: `${rowData.id_num}`, cert_status: +rowData.p_c_state };
      },
      paceCallback: async res => {
        console.log('paceCallback res');
        if (res.errno !== 0) {
          listPage.enableMenuButton(btnKey);
          showInfo(ERROR, res.errmsg);
          return false;
        }
      },
      finishCallback: (...rest) => {
        setTimeout(() => {
          listPage.handleRefreshData();
          listPage.enableMenuButton(btnKey);
        }, 2000);
      },
      escBind: false,
    }).show();
  };

  opTips({
    orderType: '司机',
    opType: '邀请签约',
    resData: infoRes,
    sucCallback: () => {
      continueCb();
      return true;
    },
    showSucTips: false,
    continueCallback: continueCb,
    ext: {
      failedCallback: () => {
        listPage.enableMenuButton(btnKey);
        return false;
      },
      specialTips: '以下司机无法邀请签约，是否继续邀请其他司机？',
    },
    tips: '邀请签约',
  });
};

export const driverToFull = async (listPage, btnKey) => {
  const ids = listPage.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(INFO, '请选择要操作的数据！');
    return false;
  }
  listPage.disableMenuButton(btnKey);
  await sleep(0);
  new PopUp(DriverPositive, { ids, listPage, btnKey, popName: 'DriverPositiveDg' }).show();
};

export const columnAppraiseTtCount = function (that, ele) {
  const table = that.getData();
  const { id } = table[ele];
  console.log(ele, id, table[ele]);
  const urlData = {
    controller: 'Schedule',
    action: 'taskList',
  };
  const pack = {
    designateParam: { ignore_default: true },
    designateFilter: {
      b_dr_id: id,
      show_batch_flags: ['appraised_driver'],
    },
    initFilterHeader: '',
  };
  urlData.injectProps = pack;
  window.$app.direct(urlData);
};

export const platformSign = async (listPage, btnKey) => {
  const idAry = listPage.getSelectesKey('id');
  if (!idAry.length) {
    return showInfo(WARN, '请选择要操作的数据！');
  }

  const reason = '';
  const infoRes = await postJAVA('/cmm-batch/esign/signValid', idAry);

  const { errno, errmsg } = infoRes;
  if (+errno !== 0) {
    errmsg && showInfo(ERROR, errmsg);
    return false;
  }

  const doBatchOp = sudIds => {
    const batchIds = sudIds || idAry || [];
    const batchIdAry = Array.isArray(batchIds) ? batchIds.map(x => +x) : [];
    const oriBatchOpData = listPage.getStateSelecteRows();
    const batchOpData = oriBatchOpData.filter(x => batchIdAry.includes(+x.id));
    new PopUp(BatchOp, {
      // title: '取消备案进度',
      requestUrl: '/cmm-batch/esign/inviteSign',
      tips: '邀请签约',
      // pace: 1,
      // classname: 'g7-pay-sts-wrap',
      prefixalTips: '',
      data: [...batchOpData],
      tableWidth: 600,
      hideCarBatchTips: true,
      header: {
        name: { title: '司机姓名', type: 'Text', display: 'show', width: 100, align: 'center', flexGrow: 1 },
        id_num: { title: '身份证号', type: 'Text', display: 'show', width: 120, align: 'center' },
        tel: { title: '手机号', type: 'Text', display: 'show', width: 120, align: 'center' },
      },
      isJAVA: true,
      brTips: true,
      // rowHeight: 30,
      bodyCellStyle: { lineHeight: '30px' },
      indexCellStyle: { lineHeight: '30px' },
      tableWrapStyle: { width: '600px' },
      getPara: dataList => {
        const rowData = dataList[0];
        return { id: rowData.id };
      },
      paceCallback: async res => {
        if (res.errno !== 0) {
          // listPage.enableMenuButton && listPage.enableMenuButton(btnKey)
          showInfo(ERROR, res.errmsg);
          return false;
        }
      },
      // getBatchOpRef: (ref) => this.BatchOpRef = ref,
      // pacesCallback: batchPaceCallback, // 一次传送多条记录时的回调函数
      finishCallback: () => {
        setTimeout(listPage.handleRefreshData, 2000);
      },
      escBind: false,
    }).show();
    console.log(' doBatchOp  infoRes === ', infoRes, reason);
  };

  const continueCb = (okIds, cb) => {
    cb && cb();
    doBatchOp(okIds);
    console.log(' continueCb  infoRes === ', infoRes);
  };

  opTips({
    orderType: '司机平台',
    opType: '邀请签约',
    resData: infoRes,
    sucCallback: doBatchOp,
    continueCallback: continueCb,
    ext: {
      failedCallback: () => {
        listPage.enableMenuButton(btnKey);
        return false;
      },
      // specialTips: '所选批次中包含支付中的费用项，无法清除，请等待支付完成后重新选择批次数据',
    },
    tips: '邀请签约',
    showSucTips: false,
  });

  console.log(' === platformSign === ', btnKey, infoRes);
};

export const viewContractOfCarriage = (listPage, idx, colKey, rowData) => {
  const { contract_of_carriage, contract_of_carriage_url } = rowData;

  if (!contract_of_carriage || !contract_of_carriage_url) {
    return false;
  }
  window.open(contract_of_carriage_url, '_blank');
};

export function init(emt) {
  emt.on('addDriver', addDriver);
  emt.on('updateDriver', listPage => updateDriver(listPage));
  emt.on('delDriver', delDriver);
  emt.on('driverCert', driverCert);
  emt.on('doDriverPlatformCert', doDriverPlatformCert); // 平台司机管理 - 认证操作列
  emt.on('driverDoDriverPlatformSign', doDriverPlatformSign); // 平台司机管理 - 签约操作列
  emt.on('driverPlatformCert', driverPlatformCert); // 司机管理 - 提交认证
  // logink
  emt.on('dLogink', driverPlatformLogink); // 平台司机管理 - 上报
  emt.on('refreshCert', refreshCert);
  emt.on('driverName', columnName);
  emt.on('driverFollowUpDrId', columnName);
  emt.on('driverTrainDrId', columnName);
  emt.on('driverIdImg', viewImg);
  emt.on('driverLicenseImg', viewImg);
  emt.on('driverDrImg', viewImg);
  emt.on('driverIdFrontImg', viewImg);
  emt.on('driverIdBackImg', viewImg);
  emt.on('driverDLImg', viewImg);
  emt.on('driverCertificateImg', viewImg);
  emt.on('driverFollowUpDrFollowUpImg', viewImg);
  emt.on('driverTrainDrTrainImg', viewImg);
  emt.on('batchUpdate', batchUpdate);
  emt.on('bindGpsno', (listPage, btnKey) => bindGPSNo(listPage, btnKey));
  emt.on('scanSign', scanSign);
  emt.on('getSignQr', getSignQr);
  emt.on('inviteSign', inviteSign);
  emt.on('driverDrConState', signContract);
  emt.on('driverToFull', driverToFull);
  // Driver_driver_appraise_tt_count
  emt.on('driverDriverAppraiseTtCount', columnAppraiseTtCount);
  emt.on('driverAppraise', commentDriver);
  emt.on('platformSign', (listPage, btnKey) => platformSign(listPage, btnKey));
  emt.on('platformDriverSign', (listPage, btnKey) => platformSign(listPage, btnKey));
  emt.on('driverContractOfCarriage', viewContractOfCarriage);
  emt.on('receiverSign', receiverSign);
  emt.on('driverBlackMark', driverBlackMark);
  // 办证工作台
  emt.on('driverUpdateDriver', (listPage, rowIndex, columnKey, rowData) => {
    const dirverId = rowData.id;
    const { tableInfo } = listPage;
    updateDriver(listPage, [dirverId], tableInfo);
  });
}
