import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { fadeIn } from 'constants/animation';
import { Tooltip } from 'components';
import { animateClass, EventListener } from 'utils';
import { EMIT_EVENT } from 'constants';
import { datHelper, getDatTitle } from '@/utils/dataAnalytic';
import './index.scss';

function hasSomeStr(node, str) {
  const classes = node.className || '';
  if (classes.indexOf(str) > -1) {
    return true;
  }
  return false;
}

export default class SlideDrager extends Component {
  static propTypes = {
    slideWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dragerWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    HeaderTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isShow: PropTypes.bool,
    isHasBiggest: PropTypes.bool,
    isHasDrager: PropTypes.bool,
    children: PropTypes.node,
    handleHide: PropTypes.func,
    contentDiv: PropTypes.node,
    footerDiv: PropTypes.node,
    onClose: PropTypes.func,
    dragerContainer: PropTypes.object,
    autoDestroy: PropTypes.bool,
    close: PropTypes.func,
    togglePopWidth: PropTypes.func,
    isBiggest: PropTypes.bool,
    nail: PropTypes.bool,
    showNail: PropTypes.bool, // 是否显示钉子
    nailTips: PropTypes.array,
    autoFocus: PropTypes.bool,
    onWidthChange: PropTypes.func,
    wrapCls: PropTypes.string,
  };
  static defaultProps = {
    slideWidth: 400,
    dragerWidth: '8px',
    HeaderTitle: '我是侧拉',
    isShow: false, // 默认是false
    isHasBiggest: true, // 默认是true
    isHasDrager: true, // 默认是true
    autoDestroy: true,
    isBiggest: false, // 初始状态是否为最大化
    nail: false, // 初始化的时候默认不显示
    showNail: false, // 初始化的时候默认不显示
    autoFocus: true,
    nailTips: ['点击操作完成后，当前页面不关闭', '当操作完成后，当前页面不关闭'], // 初始化的时候默认不显示tips
  };

  static footerClass = 'slide__drager__footer__content';

  getSliderStyle = props => {
    if (props.isBiggest) {
      return { width: '100%', left: 0 };
    }
    const main = document.body.querySelector('.main');
    const maxWith = main && main.offsetWidth;
    const width = maxWith && maxWith < +props.slideWidth ? maxWith : props.slideWidth;
    return {
      width,
      left: 'auto',
    };
  };

  constructor(prop) {
    super(prop);
    this.state = {
      isShow: this.props.isShow,
      style: this.getSliderStyle(this.props),
      biggest: this.props.isBiggest ? 1 : 0,
      nail: prop.nail,
      showNail: prop.showNail,
      nailTips: prop.nailTips,
    };
    this.isDraged = false;
  }

  UNSAFE_componentWillReceiveProps(nextProp) {
    if ('isShow' in nextProp) {
      this.setState({
        isShow: nextProp.isShow,
      });
    }
    // 不确定加的原因，暂时注释掉
    // this.setState({
    //   style: this.getSliderStyle(nextProp),
    //   biggest: nextProp.isBiggest ? 1 : 0
    // })
  }

  dragData = {
    startX: 0,
    isMouseDown: false,
  };

  componentDidMount() {
    this.setFocusOnce = false; // 焦点只获取一次
    if (this.props.dragerContainer) {
      // eslint-disable-next-line react/no-find-dom-node
      EventListener.listen(ReactDOM.findDOMNode(this.props.dragerContainer), 'mouseup', this.finishDrag);
      // eslint-disable-next-line react/no-find-dom-node
      EventListener.listen(ReactDOM.findDOMNode(this.props.dragerContainer), 'mousemove', this.onDrag);
    }
    this.props.autoFocus && this.setFocus();
    const width = this.state.style ? this.state.style.width : this.props.slideWidth;
    this.setPopUpWidth(this.dialog, width); // 设置侧拉宽度，列表的this.props.dragerContainer 为undefined

    datHelper(() => {
      const title = getDatTitle({ prefixSys: false });
      window._paq.push([
        'trackContentImpression',
        '打开侧拉栏',
        `【${title}】-${this.props.HeaderTitle}`,
        this.props.HeaderTitle,
      ]);

      // 可用于跟踪，打开的侧拉栏是否有被交互
      // window._paq.push([
      //   'trackContentInteraction',
      //   'tabActivated',
      //   '打开侧拉栏',
      //   `【${title}】-${this.props.HeaderTitle}`,
      //   this.props.HeaderTitle,
      // ]);
    });
  }

  componentDidUpdate(prevProps) {
    // 接口返回数据的需要在这里设置
    if (!this.setFocusOnce && this.props.autoFocus) {
      setTimeout(() => {
        this.setFocus();
      }, 0);
    }

    // slideWidth 变化时，支持动态渲染宽度
    if (this.props.slideWidth > 0 && this.props.slideWidth !== prevProps.slideWidth) {
      this.setPopUpWidth(this.dialog, this.props.slideWidth);
      this.setState({
        style: {
          ...this.state.style,
          width: this.props.slideWidth,
        },
      });
    }
  }

  setPopUpWidth = (node = this.dialog, width) => {
    // 递归获取popup元素改变其width
    const parent = node.parentNode;
    if (!parent) {
      return;
    }
    if (hasSomeStr(parent, 'popup_wrap')) {
      if (width === '100%') {
        parent.style.width = '100%';
      } else {
        parent.style.width = `${width}px`;
      }
      if (this.props.onWidthChange) {
        this.props.onWidthChange(width);
      }
      return;
    }
    this.setPopUpWidth(parent, width);
  };
  setFocus = () => {
    const popupWrap = this.props.dragerContainer;
    if (popupWrap && popupWrap.nodeType) {
      // 车辆配载批次侧拉不让table的input获取焦点
      const batchDetailSlider = popupWrap.getAttribute('id');
      if (batchDetailSlider && batchDetailSlider.indexOf('BatchDetailSlider') > -1) {
        return false;
      }
    }
    const inputFocus = this.setInputFocus(); // 是否有input获取了焦点
    let textareaFocus = false; // 是否有textarea获取了焦点
    let buttonFocus = false; // 是否让btn获取焦点
    if (!inputFocus) {
      textareaFocus = this.setTextareaFocus();
    }
    if (!inputFocus && !textareaFocus) {
      buttonFocus = true;
    }
    this.handleButtonFocus(buttonFocus);
  };
  setInputFocus = () => {
    if (!this.dialog) return true; // 若在关闭时触发focus 阻断focus执行
    let inputs = this.dialog.querySelectorAll('input');
    inputs = Array.from(inputs);
    let inputFocus = false;
    inputs &&
      inputs.forEach(item => {
        if (
          !inputFocus &&
          !item.hasAttribute('disabled') &&
          // && !(item.hasAttribute('readonly'))
          !(item.getAttribute('type') === 'checkbox') &&
          !(item.getAttribute('type') === 'radio') &&
          !(item.getAttribute('value') !== '') &&
          !(item.getAttribute('type') === 'file') &&
          !(item.getAttribute('type') === 'hidden') &&
          !item.classList.contains('fn-input-pure')
        ) {
          item.focus();
          inputFocus = true;
          this.setFocusOnce = true;
        }
      });
    return inputFocus;
  };
  setTextareaFocus = () => {
    let textareas = this.dialog.querySelectorAll('textarea');
    textareas = Array.from(textareas);
    let textareaFocus = false;
    textareas &&
      textareas.forEach(item => {
        if (!textareaFocus && !item.hasAttribute('disabled')) {
          item.focus();
          textareaFocus = true;
          this.setFocusOnce = true;
        }
      });
    return textareaFocus;
  };
  handleButtonFocus = noFocus => {
    if (!noFocus) {
      return;
    }
    let buttons = this.dialog.querySelectorAll('button');
    buttons = Array.from(buttons);
    if (noFocus && buttons.length) {
      // 如果input和textarea都没有获取焦点 btn获取焦点
      buttons[0].focus();
      this.setFocusOnce = true;
    }
    const len = buttons.length;
    buttons.forEach((item, index, arr) => {
      const each = item;
      each.onkeydown = e => {
        const ev = e || window.event;
        if (ev.keyCode === 39) {
          if (index < len - 1) {
            // 右键时的边界值
            each.blur();
            arr[index + 1].focus();
          }
        }
        if (ev.keyCode === 37) {
          if (index > 0) {
            // 左键时的边界值
            each.blur();
            arr[index - 1].focus();
          }
        }
      };
    });
  };
  isShow = () => this.state.isShow;
  handleShow = () => {
    !this.state.isShow && this.setState({ isShow: true }, () => animateClass(this.dialog, fadeIn));
  };
  handleHide = () => {
    // 关闭侧拉的动画放在popup类里面统一做，为了解决编辑类侧拉关闭时需要提示弹框的需求
    if (this.props.autoDestroy && typeof this.props.close === 'function') {
      // this.state.isShow && animateClass(this.dialog, fadeOut, () => this.props.close())
      this.state.isShow && this.props.close();
    } else {
      // this.state.isShow && animateClass(this.dialog, fadeOut, () => this.setState({ isShow: false }))
      this.state.isShow && this.setState({ isShow: false });
    }
    this.props.onClose && this.props.onClose();
  };
  handleClose = () => {
    this.handleHide();
  };
  // 设置侧拉最大化
  setBiggest = () => {
    window.emt && window.emt.emit(EMIT_EVENT.SLIDER_EXTEND_BIG, true);
    this.setState({
      style: {
        width: '100%',
        left: 0,
      },
      biggest: 1,
    });
    this.props.togglePopWidth && this.props.togglePopWidth(true);
  };
  handleBiggest = () => {
    window.emt && window.emt.emit(EMIT_EVENT.SLIDER_EXTEND_BIG, !this.state.biggest);
    if (this.state.biggest) {
      this.setState({
        // 最小化不能调用togglePopWidth，需在这里重新这是popup宽度
        style: {
          width: this.props.slideWidth,
          left: 'auto',
        },
        biggest: 0,
      });
      this.setPopUpWidth(this.dialog, this.props.slideWidth);
      // this.props.togglePopWidth ? this.props.togglePopWidth(true) : this.setPopUpWidth(this.dialog, '100%')
      this.props.togglePopWidth
        ? this.props.togglePopWidth(undefined)
        : this.setPopUpWidth(this.dialog, this.props.slideWidth);
    } else {
      this.setState({
        style: {
          width: '100%',
          left: 0,
        },
        biggest: 1,
      });
      this.props.togglePopWidth ? this.props.togglePopWidth(true) : this.setPopUpWidth(this.dialog, '100%');
    }
  };
  onDragStyle = style => {
    this.applyStyle(style);
  };
  applyStyle = style => {
    this.setState({ style });
  };
  parseWidth = currX => {
    // const newState = (state) => Object.assign({}, state)
    const X = currX || 0;
    const oX = this.dragData.startX;
    const diff = X - oX;
    const style = Object.assign({}, this.state.style);
    let rightWidth = style.width;
    if (rightWidth === '100%') {
      rightWidth = document.querySelector('.tabs-active-content').getBoundingClientRect().width;
      style.left = 'auto';
    }
    const biggestWidth = document.querySelector('.main').getBoundingClientRect().width;
    const wWidth = document.body.clientWidth;
    if ((diff >= 0 && X < wWidth - 300) || (diff <= 0 && X > 0)) {
      rightWidth -= diff;
    }
    style.width = rightWidth || 0;
    // style.left = ''
    this.dragData.startX = X;
    if (style.width >= biggestWidth) {
      style.width = biggestWidth;
    }
    return { width: style.width, left: style.left };
  };
  startDrag = e => {
    const event = e || window.event;
    // event.stopPropagation()
    this.dragData.startX = event.clientX || 0;
    this.dragData.isMouseDown = true;
    this.isDraged = true;
    this.props.togglePopWidth ? this.props.togglePopWidth(true) : this.setPopUpWidth(this.dialog, '100%');
  };
  onDrag = e => {
    if (!this.dragData.isMouseDown) return false;
    const event = e || window.event;
    const currX = event.clientX;
    // event.stopPropagation()
    this.onDragStyle(this.parseWidth(currX));
  };
  finishDrag = e => {
    const event = e || window.event;
    // event.stopPropagation()
    this.dragData.startX = event.clientX;
    this.dragData.isMouseDown && this.props.togglePopWidth && this.props.togglePopWidth(undefined);
    this.dragData.isMouseDown = false;
    if (this.isDraged) {
      // 只在拖动后设置宽度
      const currX = event.clientX;
      const { width } = this.parseWidth(currX);
      this.setPopUpWidth(this.dialog, width);
      this.isDraged = false;
    }
  };
  handleNail = () => {
    const { nail } = this.state;
    this.setState({
      nail: !nail,
    });
  };

  render() {
    const { isHasDrager, isHasBiggest, HeaderTitle, dragerWidth, wrapCls = '' } = this.props;
    const { style } = this.state;
    const { showNail, nail, nailTips, biggest } = this.state;
    let _dragerDiv = '';
    if (isHasDrager) {
      _dragerDiv = <div className="slide__drager__div" style={{ width: dragerWidth }} onMouseDown={this.startDrag} />;
    }
    // 判断是否显示钉子上的提示
    const _nailTips = nail ? nailTips[1] : nailTips[0];
    // 判断是否显示钉子
    let _nail = '';
    if (showNail) {
      _nail = (
        <Tooltip content={<div style={{ fontSize: '12px', color: '#fff', 'white-space': 'nowrap' }}>{_nailTips}</div>}>
          <span className="slide__drager__nail" onClick={this.handleNail}>
            {nail ? <i className="fn-icon fn-icon-nail-1" /> : <i className="fn-icon fn-icon-nail" />}
          </span>
        </Tooltip>
      );
    }
    let _biggest = '';
    if (isHasBiggest) {
      _biggest = (
        <span className="slide__drager__biggest" onClick={this.handleBiggest}>
          {this.state.biggest ? '' : ''}
          <i className={`fn-icon fn-icon-zoom-${biggest ? 'in' : 'out'}`} />
        </span>
      );
    }
    const classPrefix = 'slide__drager';
    const cx = classnames({
      [`${classPrefix}-hide`]: this.state.isShow ? 0 : 1,
      slide__drager: true,
    });
    const contentCla = classnames({
      [`${classPrefix}__content`]: true,
      [`${classPrefix}__content__padding__bottom`]: this.props.footerDiv,
      // [`${classPrefix}__content_footer`]: this.props.footerDiv
    });
    let height = 46;
    if (this.props.footerDiv) height = 87;
    // onMouseUp={this.finishDrag}
    // onMouseMove={this.onDrag}
    return (
      <div className={`${cx} ${wrapCls}`} style={style} ref={r => (this.dialog = r)}>
        {_dragerDiv}
        <div className="slide__drager__all">
          {/* header  */}
          <div className="slide__drager__header">
            <h2 className="slide__drager__title">{HeaderTitle}</h2>
            <div className="slide__drager__right">
              {_nail}
              {_biggest}
              <span className="slide__drager__close" onClick={this.handleClose}>
                <i className="fn-icon fn-icon-close" />
              </span>
            </div>
          </div>
          {/* content  */}
          <div className={contentCla} style={{ height: `calc( 100% - ${height}px)` }} ref={this.props.contentRef}>
            {this.props.contentDiv}
          </div>
          {/* footer  */}
          {this.props.footerDiv && (
            <div className="slide__drager__footer" onClick={this.props.handleHide}>
              {this.props.footerDiv}
            </div>
          )}
        </div>
      </div>
    );
  }
}
