import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ERROR, CHECK } from 'constants';
import { PopupTypeset } from 'components';
import actions from 'actions';
import { showInfo } from 'utils';

class pathSettingModal extends PureComponent {
  static propTypes = {
    pathSetting: PropTypes.object,
    settingKey: PropTypes.string,
    pathMap: PropTypes.object,
    close: PropTypes.func,
    title: PropTypes.string,
    onSaveSuccess: PropTypes.func,
  };
  static defaultProps = {
    title: '光标路径设置',
  };

  constructor(prop) {
    super(prop);
    const { pathSetting, pathMap } = this.props;
    const rightList = pathSetting.show.filter(k => pathMap[k]).map(key => ({ key, title: pathMap[key] }));
    const leftList = Object.keys(pathMap)
      .filter(key => !pathSetting.show.includes(key))
      .map(key => ({ key, title: pathMap[key] }));
    this.state = { leftList, rightList };
  }

  save = async pop => {
    const hidden = pop.typeSet.state.leftList.filter(item => item && item.key).map(item => item.key);
    const show = pop.typeSet.state.rightList.filter(item => item && item.key).map(item => item.key);
    const diffData = { show, hidden, can_switch: true, switch_set: false };
    const setPathData = { [this.props.settingKey]: { __meta: diffData } };
    const res = await actions.settingServer.setOrderSet(setPathData, false, '', 'psn');
    if (res.data.errno !== 0) {
      showInfo(ERROR, res.data.errmsg);
      return false;
    }
    Object.assign(window.psn_setting, setPathData);
    showInfo(CHECK, '保存成功');
    this.typeset.vModalDialog.handleHide();
    this.props.onSaveSuccess && this.props.onSaveSuccess('path_set');
  };

  reset = async () => {
    const res = await actions.settingServer.resetPsn('psn', this.props.settingKey);
    if (res.data.errno !== 0) {
      showInfo(ERROR, res.data.errmsg);
      return false;
    }
    showInfo(CHECK, '保存成功');
    this.typeset.vModalDialog.handleHide();
    this.props.onSaveSuccess && this.props.onSaveSuccess('path_set');
  };

  render() {
    const { leftList, rightList } = this.state;

    return (
      <PopupTypeset
        handleSubmit={this.save}
        handleReset={this.reset}
        autoDestroy
        isShow
        isModal
        ref={r => (this.typeset = r)}
        title={this.props.title}
        close={this.props.close}
        typesetProps={{
          leftListProps: {
            showHeader: false,
            tableHeader: { title: 'title' },
          },
          rightListProps: {
            showHeader: false,
            tableHeader: { title: 'title' },
          },
          uniqueKey: 'key',
          leftList,
          rightList,
        }}
      />
    );
  }
}

export default pathSettingModal;
