import React from 'react';
import { DataList, Select, PureInput as Input, Icon, PureCheckbox as Checkbox, PopUp, UploadFile } from 'components';
import { FLOAT_P, NUMBER, FLOAT_P_3, FLOAT_P_6 } from 'constants';
import { buildTipMixin, ot, bigComputed } from 'utils';
import VolumeEditModal from '../../volumeEditModal';
import { MERGE_DATA, SELECT_GOODS_SUG, goodsKeys } from '../constant';
import { required, calcField, lockShow, setPerson } from '../tool';
import { setAttachments } from '../person/tool';
import { filterGoods, whHeader, resolveSpecialVal } from './tool';
import { prefixCls } from './index.scss';
import { dangerGoodsFields } from 'constants/setting/rosSetting';
import _ from 'lodash';

const header = ['value'];

const goodsAddTipMixin = buildTipMixin('每单最多可填80种货物！');

// 货物属性
function renderGoods(page) {
  const drag = (e, key) => {
    const start = e.clientX;
    const { target } = e;
    const th = target.parentNode;
    const width = th.offsetWidth;
    const table = th.parentNode.parentNode.parentNode.parentNode;
    const height = table.offsetHeight - 2;
    let end = start;
    const onMove = e1 => {
      e1.preventDefault();
      end = e1.clientX;
      const delta = end - start;
      width + delta > 0 && (target.style.transform = `translate3d(${delta}px, 0 ,0)`);
    };
    const onEnd = () => {
      const { store } = page;
      const state = store.getState();
      const goodsStyle = { ...state.goodsStyle };
      const delta = end - start;
      const newWidth = Math.max(width + delta, 4);
      target.style.height = '';
      target.style.transform = '';
      target.className = 'table-drager';
      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('mouseup', onEnd);
      goodsStyle[key] = { flex: `0 0 ${newWidth}px` };
      setPerson({ goods_style: { __meta: goodsStyle } });
      store.dispatch({ type: MERGE_DATA, payload: { goodsStyle } });
    };
    target.className = 'table-drager active';
    target.style.height = `${height}px`;
    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', onEnd);
  };

  return function () {
    const { store } = page;
    const state = store.getState();
    const { goodsNameHeader, showGoodsTotalRow, goodsStyle, feeSource } = state;
    const { boNumShow, boNumEditable, boNumRequired } = state;
    const { nameShow, nameRequired, nameLock, nameEnum, nameEditable, addEditable } = state;
    const { codeShow, codeRequired, codeEditable } = state;
    const { pkgShow, pkgRequired, pkgLock, pkgEnum, pkgEditable, pkgServiceShow, pkgServiceEditable } = state;
    const { suitShow, suitRequired, suitEditable, suitUnitPriceShow, suitUnitPriceRequired, suitUnitPriceEditable } =
      state;
    const { numShow, numRequired, numEditable, numUnitPriceShow, numUnitPriceRequired, numUnitPriceEditable } = state;
    const { numPerTrayShow, numPerTrayRequired, numPerTrayEditable } = state;
    const { trayCountShow, trayCountRequired, trayCountEditable } = state;
    const { weightShow, weightRequired, weightEditable, weightUnit, weightUnitIsT } = state;
    const { whIdShow, whIdRequired, whIdEditable } = state;
    const { whAreaIdShow, whAreaIdRequired, whAreaIdEditable } = state;
    const { whLocationIdShow, whLocationIdRequired, whLocationIdEditable } = state;
    const { inBillNumShow, inBillNumRequired } = state;
    const { outBillNumShow, outBillNumRequired } = state;
    const { practicalWShow, practicalWRequired, practicalWEditable } = state;
    const { weightPerNumShow, weightPerNumRequired, weightPerNumEditable } = state;
    const { weightUnitPriceShow, weightUnitPriceRequired, weightUnitPriceEditable } = state;
    const {
      lengthShow,
      lengthRequired,
      lengthEditable,
      widthShow,
      widthRequired,
      widthEditable,
      highShow,
      highRequired,
      highEditable,
    } = state;
    const { volumePerNumShow, volumePerNumRequired, volumePerNumEditable } = state;
    const { volumeShow, volumeRequired, volumeEditable, calcInfoShow } = state;
    const { practicalVShow, practicalVRequired, practicalVEditable } = state;
    const { volumeUnitPriceShow, volumeUnitPriceRequired, volumeUnitPriceEditable } = state;
    const { unitPShow, unitPRequired, unitPEditable, unitEnum } = state;
    const { subtotalPriceShow, subtotalPriceRequired, subtotalPriceEditable } = state;
    const { countPerNumShow, countPerNumRequired, countPerNumEditable } = state;
    const { countShow, countRequired, countEditable } = state;
    const { unitShow, unitRequired, unitEditable } = state;
    const { catShow, catRequired, catLock, catEnum, catEditable } = state;
    const { specShow, specRequired, specEditable } = state;
    const { modelShow, modelRequired, modelEditable } = state;
    const { specialShow, specialRequired, specialLock, specialEnum, specialEditable, specialCheckbox } = state;
    const { goods, defaultGoods, isDetail, isPointTrans, isCreate } = state;

    // 设置收发货人附件相关信息
    const setCeeCorAttachments = () => {
      setAttachments({ page, type: 'consignee' });
      setAttachments({ page, type: 'consignor' });
    };

    const {
      goodsSugSource,
      dangerousGnameShow,
      dangerousGnameRequired,
      dangerousUnCodeShow,
      dangerousUnCodeRequired,
      dangerousGcodeShow,
      dangerousGcodeRequired,
      dangerousCmNameShow,
      dangerousCmNameRequired,
      dangerousCasCodeShow,
      dangerousCasCodeRequired,
      dangerousPkgCodeShow,
      dangerousPkgCodeRequired,
      dangerousCatNameShow,
      dangerousCatNameRequired,
      dangerousPkgSpecShow,
      dangerousPkgSpecRequired,
      attachmentsShow,
      attachmentsRequired,
    } = state;

    // 货物重量、体积自动计算保留位数
    const goods_weight_max_decimal_places =
      window.company_setting?.goods_weight_max_decimal_places?.value || (weightUnitIsT ? 6 : 3);
    const goods_volume_max_decimal_places = window.company_setting?.goods_volume_max_decimal_places?.value || 6;

    // 货物来源只能是 货物管理
    const isGoodsManager = goodsSugSource?.length === 1 && goodsSugSource[0] === 'goods_mgr';
    // 货源来源只能是 自定义输入
    const isUserInput = goodsSugSource?.length === 1 && goodsSugSource[0] === 'user_input';
    // 货物Sug 使用货物管理
    const isGoodsManagerSug = goodsSugSource?.includes('goods_mgr');
    // 是否设置了 【1.0订运单货物数据来源】 设置项
    const isSetGoodsSource = goodsSugSource?.length;

    const onChange = (i, key, val) => {
      if (key === 'subtotal_price') {
        // 标记运费小计是否手动修改
        feeSource.setGoodsSource(i, 'subtotal_source', 1);
      }

      const changes = {};
      // 修改货物时，清空危化字段
      if (['name', 'code'].includes(key)) {
        changes.attachments = null; // 目前附件只与货物管理相关，无法自定义上传，所以修改货物时这里清空
        Object.keys(dangerGoodsFields).forEach(k => {
          if (k !== key) changes[k] = null;
        });
      }

      page.setTable('goods', i, { ...changes, [key]: val });
      if (key === 'name') {
        setCeeCorAttachments();
      }
    };

    const goodsSug = (field, keyword, i, dataList) => {
      // 如果设置了 【1.0订运单货物数据来源】 设置项，则使用货物管理SUG
      filterGoods(field, keyword, { ...state, isGoodsManager: isGoodsManagerSug }, dataList.fetch).then(data => {
        store.dispatch({ type: MERGE_DATA, payload: { [`${field}Enum${i}`]: data } });
      });
    };
    const whSug = async ({ keyword, select, i, wh_id }) => {
      const req = { type: 1, search: keyword, wh_id };
      const conf = { method: 'POST', body: { req } };
      const locationSugRes = await select.fetch('/Warehouse/WmsWh/whSug', conf);
      store.dispatch({ type: MERGE_DATA, payload: { [`whEnum${i}`]: locationSugRes.res } });
    };

    const nameSugHasData = i => !state[`nameEnum${i}`] || state[`nameEnum${i}`].length;

    const onChangeNum = (i, key, val, row) => {
      const tempRow = { ...row, num: val };
      const changes = { [key]: val };
      const weightPerNum = row.weight_per_num;
      const volumePerNum = row.volume_per_num;
      const countPerNum = row.count_per_num;
      const numPerTray = row.num_per_tray;
      if (val === '0' || +val) {
        if ((weightPerNum === '0' || +weightPerNum) && !page.edited(`weight_${i + 1}`)) {
          tempRow.weight = changes.weight = _.round(
            bigComputed([val, weightPerNum], 'times'),
            goods_weight_max_decimal_places,
          );
        }
        if ((volumePerNum === '0' || +volumePerNum) && !page.edited(`volume_${i + 1}`)) {
          tempRow.volume = changes.volume = _.round(
            bigComputed([val, volumePerNum], 'times'),
            goods_volume_max_decimal_places,
          );
        }
        if ((countPerNum === '0' || +countPerNum) && !page.edited(`count_${i + 1}`)) {
          tempRow.count = changes.count = Math.ceil(val * countPerNum);
        }
        if (+numPerTray && !page.edited(`tray_count_${i + 1}`)) {
          tempRow.tray_count = changes.tray_count = Math.ceil(val / numPerTray);
        }
      }
      // page.setTable('goods', i, {
      //   ...changes,
      //   ...calcSubTotal(tempRow, 'num', page, i),
      //   ...calcSubTotal(tempRow, 'weight', page, i),
      // });
      page.setTable('goods', i, { ...changes });
    };

    const onChangeUnitPUnit = (i, key, val, row) => {
      if (row.unit_p) {
        feeSource.setGoodsSource(i, 'unit_source', 1);
      }

      // 如果手动编辑过 则不进行单价带入
      if (page.edited(`unit_p_${i + 1}`)) {
        onChangeFreightKey(i, key, val, row);
        return;
      }
      const { priceSnapshot } = state;
      const coFreightF = priceSnapshot?.co_freight_f?.[i]?.unit_price ?? {};
      const weightUnitPrice = (weightUnit === '吨' ? coFreightF.吨 : coFreightF.公斤) || 0;
      const volumeUnitPrice = +coFreightF.方 || 0;
      // 工单8023 经与产品确认 续价只取每件价格 不取按件价格
      const numUnitPrice = +coFreightF.每件价格 || 0;
      const suitUnitPrice = +coFreightF.每套价格 || 0;
      const unitPriceMap = {
        per_num: numUnitPrice, // 件数单价
        per_v: volumeUnitPrice, // 体积单价
        per_w: weightUnitPrice, // 重量单价
        per_s: suitUnitPrice, // 重量单价
      };

      // 切换《单价单位》时，价格计算快照中《续价》字段带入《单价》字段中
      const unitP = unitPriceMap[val]; // 单价
      page.setTable('goods', i, { [key]: val, unit_p: unitP });
    };

    const onChangeFreightKey = (i, key, val, row) => {
      const tempRow = { ...row, [key]: val };
      // page.setTable('goods', i, { [key]: val, ...calcSubTotal(tempRow, key, page, i) });
      page.setTable('goods', i, { [key]: val });
    };

    const mergeGoods = (i, key, val, row) => {
      const newGoods = [...goods];
      const tempRow = { ...row, [key]: val };
      newGoods[i] = { ...row, [key]: val };
      if (key === 'volume') {
        // page.setTable('goods', i, { [key]: val, ...calcSubTotal(tempRow, key, page, i) });
        page.setTable('goods', i, { [key]: val });
      } else {
        page.merge('goods', newGoods);
      }
    };

    const calcVolume = (i, e, row) => {
      try {
        const volume = eval(e.target.value); // eslint-disable-line

        if (!window.isNaN(volume)) {
          onChangeFreightKey(i, 'volume', +volume.toFixed(6), row);
          feeSource.setGoodsSource(i, 'volume_source', 1);
        }
      } catch (exception) {
        console.log(exception);
      }
    };

    const calcVolumePerNum = (i, e, row) => {
      if (page.edited(`volume_per_num_${i + 1}`)) return;
      const tempRow = { ...row };
      const volumePerNum = (row.length * row.width * row.high) / 1000000;
      const changes = { volume_per_num: volumePerNum };
      if (!page.edited(`volume_${i + 1}`)) {
        const volume = _.round(bigComputed([volumePerNum, row.num || 0], 'times'), goods_volume_max_decimal_places);
        changes.volume = tempRow.volume = volume;
      }
      // page.setTable('goods', i, { ...changes, ...calcSubTotal(tempRow, 'volume', page, i) });
      page.setTable('goods', i, { ...changes });
    };

    const onChangeVolumePerNum = (i, key, val, row) => {
      const tempRow = { ...row };
      const changes = { [key]: val };
      const { num } = row;
      if (!page.edited(`volume_${i + 1}`) && (val === '0' || +val) && (num === '0' || +num)) {
        tempRow.volume = changes.volume = _.round(bigComputed([val, num], 'times'), goods_volume_max_decimal_places);
      }
      // page.setTable('goods', i, { ...changes, ...calcSubTotal(tempRow, 'volume', page, i) });
      page.setTable('goods', i, { ...changes });
    };

    const showVolumeEditModal = (i, row) => {
      page.unbindShortcuts && page.unbindShortcuts();
      new PopUp(VolumeEditModal, {
        data: row.calc_info,
        isView: !volumeEditable,
        onConfirm: data => {
          const newRow = store.getState().goods[i];
          const volume = calcField(data, 'v');

          onChangeFreightKey(i, 'volume', +volume.toFixed(2), newRow);
          feeSource.setGoodsSource(i, 'volume_source', 1);

          page.setTable('goods', i, { calc_info: data });
          page.focus(`volume_${i + 1}`);
        },
        handleHideCallback: () => {
          if (state.isCreate) {
            page.initShortcuts();
          }
        },
      }).show();
    };

    const onChangeWeightPerNum = (i, key, val, row) => {
      const tempRow = { ...row };
      const changes = { [key]: val };
      const { num } = row;
      if (!page.edited(`weight_${i + 1}`) && (val === '0' || +val) && (num === '0' || +num)) {
        tempRow.weight = changes.weight = _.round(bigComputed([val, num], 'times'), goods_weight_max_decimal_places);
      }
      // page.setTable('goods', i, { ...changes, ...calcSubTotal(tempRow, 'weight', page, i) });
      page.setTable('goods', i, { ...changes });
    };

    const onChangeNumPerTray = (i, key, val, row) => {
      const changes = { [key]: val };
      const { num } = row;
      if (!page.edited(`tray_count_${i + 1}`) && +val && (num === '0' || +num)) {
        changes.tray_count = Math.ceil(num / +val);
      }
      page.setTable('goods', i, changes);
    };

    const onChangeCountPerNum = (i, key, val, row) => {
      const changes = { [key]: val };
      const { num } = row;
      if (!page.edited(`count_${i + 1}`) && (val === '0' || +val) && (num === '0' || +num)) {
        changes.count = Math.ceil(val * num);
      }
      page.setTable('goods', i, changes);
    };

    const selectGoodsSug = (field, data, i) => {
      const isFromGoodsManage = +data.type === 2;
      const changes = {};
      const { keyMap, goodsSugSetting } = state;
      const row = goods[i];
      goodsKeys.forEach(key => {
        const dataKey = `g_${key}`;
        // 从货物带出来的 不改变单位
        if (isFromGoodsManage && ['unit_p_unit'].includes(key)) {
          return;
        }
        // 系统设置的勾选了key  货物管理有对应的字段 则把货物管理的对应值更新到货物上
        const needFill =
          field === key ||
          (isFromGoodsManage && data[dataKey] && state[`${keyMap[key]}Show`] && goodsSugSetting[key]?.required);
        needFill && (changes[key] = data[dataKey] && data[dataKey][0]);

        if (needFill && key === 'subtotal_price') {
          changes.subtotal_price_src = 'goods';
        }
        if (needFill && key === 'name') {
          changes.id = data.id ? data.id : data.g_id?.[0];
        }
      });
      // 货物管理是单件单位 所以需要处理下数据
      if (isFromGoodsManage) {
        changes.volume_per_num = data.g_volume_per_num?.[0] || row.volume_per_num;
        changes.weight_per_num = data.g_weight_per_num?.[0] || row.weight_per_num;
        changes.num_per_tray = data.g_num_per_tray?.[0] || row.num_per_tray;
        changes.count_per_num = data.g_count_per_num?.[0] || row.count_per_num;
        // 有货物代码 更新货物代码 系统设置里面没有这个字段
        changes.code = data.g_code?.[0] || row.code;
      }

      // 危险货物字段，始终带入并覆盖（即使被隐藏也带入）
      Object.keys(dangerGoodsFields).forEach(k => {
        changes[k] = data[k] && data[k][0];
      });
      changes.attachments = data.attachments?.[0];

      const newVal = { ...row, ...changes };

      // 单件体积  货物管理单件体积没值
      if (isFromGoodsManage && !data.g_volume_per_num) {
        const volumePerNum = (newVal.length * newVal.width * newVal.high) / 1000000 || 0;
        changes.volume_per_num = newVal.volume_per_num = volumePerNum;
      }
      // 体积  货物管理单件体积  系统设置勾选体积
      if (isFromGoodsManage || (!goodsSugSetting.volume.required && numShow && volumePerNumShow && volumeShow)) {
        const calcVal = (newVal.volume = _.round(
          bigComputed([newVal.num, newVal.volume_per_num || 0], 'times'),
          goods_volume_max_decimal_places,
        ));
        if (calcVal) {
          changes.volume = calcVal;
        }
      }
      // 重量  货物管理单件重量  系统设置勾选重量
      if (isFromGoodsManage || (!goodsSugSetting.weight.required && numShow && weightPerNumShow && weightShow)) {
        const calcVal = (newVal.weight = _.round(
          bigComputed([newVal.num, newVal.weight_per_num || 0], 'times'),
          goods_weight_max_decimal_places,
        ));
        if (calcVal) {
          changes.weight = calcVal;
        }
      }
      // 托数  货物管理单托件数 有值
      if (isFromGoodsManage && data.g_num_per_tray && +data.g_num_per_tray[0]) {
        changes.tray_count = newVal.tray_count = Math.ceil(newVal.num / newVal.num_per_tray || 0);
      }
      // 数量  货物管理单件数量 有值
      if (isFromGoodsManage && data.g_count_per_num && data.g_count_per_num[0]) {
        changes.count = newVal.count = Math.ceil(newVal.num * newVal.count_per_num || 0);
      }
      // 重量和运费小计要自动计算
      // page.setTable('goods', i, { ...changes, ...calcSubTotal(newVal, 'unit_p', page, i), id: changes.id });
      page.setTable('goods', i, { ...changes, id: changes.id });
      store.dispatch({ type: SELECT_GOODS_SUG, payload: data });

      setCeeCorAttachments();
    };

    const onSpecialCheck = (i, key, checked, row) => {
      const specialNew = [...(row.special || [])];
      if (checked) {
        specialNew.push(key);
      } else {
        const index = specialNew.indexOf(key);
        index > -1 && specialNew.splice(index, 1);
      }
      page.setTable('goods', i, { special: specialNew });
    };

    const add = () => {
      let isEmpty = true;
      for (const i in defaultGoods) {
        if (defaultGoods[i]) {
          isEmpty = true;
        }
        break;
      }
      if (!isCreate) {
        defaultGoods.wh_id = goods?.[0]?.wh_id;
        defaultGoods.wh_name = goods?.[0]?.wh_name;
      }
      const noCalPrice = (goods[0].num && goods[0].num) || isEmpty;
      goods.length < 80 && page.setTable('goods', goods.length, defaultGoods, undefined, noCalPrice);
      state.feeSource.addGoodsSource();
      setTimeout(() => page.initTips(), 100);
    };

    const minus = index => {
      page.setTable('goods', index);
      state.feeSource.removeGoodsSource(index);
      // 删除货物行时，当前行有货物名称才会去重新匹配附件
      if (goods[index]?.name) {
        setCeeCorAttachments();
      }
    };

    const whChange = (val, item, i) => {
      onChangeFreightKey(i, 'wh_id', val.wh_id, item);
      onChangeFreightKey(i, 'wh_name', val.wh_name, item);
      onChangeFreightKey(i, 'wh_area_id', val.wh_area_id, item);
      onChangeFreightKey(i, 'wh_area_name', val.wh_area_name, item);
      onChangeFreightKey(i, 'wh_location_id', val.wh_location_id, item);
      onChangeFreightKey(i, 'wh_location_name', val.wh_location_name, item);
    };

    return (
      <div className={prefixCls}>
        {!isDetail && !isPointTrans && addEditable && (
          <div className="subtract">
            {goods.map((item, i) =>
              i === 0 ? (
                <div key={i} className="add-minus" {...(goods.length === 80 ? goodsAddTipMixin : undefined)}>
                  <Icon iconType="icon-add-rad" disabled={goods.length === 80} onClick={add} />
                </div>
              ) : (
                <div key={i} className="add-minus">
                  <Icon iconType="icon-minus-rad" onClick={() => minus(i)} />
                </div>
              ),
            )}
            {showGoodsTotalRow && goods.length > 1 && <div className="add-minus goods-total-row" />}
          </div>
        )}
        <div className="goods-list">
          {goods.map((item, i) => (
            <div key={i}>
              {boNumShow && (
                <div style={goodsStyle.bo_num}>
                  <label className={`fn-label${required(boNumRequired)}`}>{ot('商流订单号')}</label>
                  <Input
                    data-path={`bo_num_${i + 1}`}
                    value={item.bo_num}
                    required={boNumRequired}
                    disabled={!boNumEditable}
                    onChange={e => onChange(i, 'bo_num', e.target.value)}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'bo_num')} />}
                </div>
              )}
              {codeShow && (
                <div style={goodsStyle.code}>
                  <label className={`fn-label${required(codeRequired)}`}>{ot('货物代码')}</label>
                  {/* eslint-disable no-nested-ternary */}
                  {isUserInput ? (
                    <Input
                      data-path={`code_${i + 1}`}
                      value={item.code}
                      required={codeRequired}
                      disabled={!codeEditable}
                      onChange={e => onChange(i, 'code', e.target.value)}
                    />
                  ) : null}
                  {isGoodsManager ? (
                    <Select
                      data-path={`code_${i + 1}`}
                      value={item.code}
                      data={state[`codeEnum${i}`]}
                      required={codeRequired}
                      disabled={!codeEditable}
                      highlight="g_code"
                      header={goodsNameHeader}
                      map={false}
                      compare="id"
                      clear
                      format={value => value.map(itam => itam.g_code || itam).join(',')}
                      onChange={val => selectGoodsSug('code', val, i)}
                      filter={(keyword, dataList) => goodsSug('code', keyword, i, dataList)}
                    />
                  ) : null}
                  {!isUserInput && !isGoodsManager ? (
                    <DataList
                      data-path={`code_${i + 1}`}
                      value={item.code}
                      data={state[`codeEnum${i}`]}
                      required={codeRequired}
                      disabled={!codeEditable}
                      highlight="g_code"
                      header={goodsNameHeader}
                      clear
                      onChange={val => onChange(i, 'code', val)}
                      onSelect={data => selectGoodsSug('code', data, i)}
                      filter={(keyword, dataList) => goodsSug('code', keyword, i, dataList)}
                    />
                  ) : null}

                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'code')} />}
                </div>
              )}
              {nameShow && (
                <div style={goodsStyle.name}>
                  <label className={`fn-label${required(nameRequired)}`}>{ot('货物名称')}</label>
                  {/* 产品上自己去保证 「货物名称锁定」和「货物数据来源」两个设置项互斥 */}
                  {/* eslint-disable no-nested-ternary */}
                  {isUserInput ? (
                    <Input
                      data-path={`name_${i + 1}`}
                      value={item.name}
                      required={nameRequired}
                      disabled={!nameEditable}
                      onChange={e => onChange(i, 'name', e.target.value)}
                    />
                  ) : null}
                  {isGoodsManager ? (
                    <Select
                      data-path={`name_${i + 1}`}
                      value={item.name}
                      data={state[`nameEnum${i}`]}
                      required={nameRequired}
                      disabled={!nameEditable}
                      highlight="g_name"
                      header={goodsNameHeader}
                      map={false}
                      compare="id"
                      clear
                      format={value => value.map(itam => itam.g_name || itam).join(',')}
                      onChange={val => selectGoodsSug('name', val, i)}
                      filter={(keyword, dataList) => goodsSug('name', keyword, i, dataList)}
                    />
                  ) : null}
                  {!isUserInput && !isGoodsManager && isSetGoodsSource ? (
                    <DataList
                      data-path={`name_${i + 1}`}
                      value={item.name}
                      data={state[`nameEnum${i}`]}
                      required={nameRequired}
                      disabled={!nameEditable}
                      highlight="g_name"
                      header={goodsNameHeader}
                      clear
                      onChange={val => onChange(i, 'name', val)}
                      onSelect={data => selectGoodsSug('name', data, i)}
                      filter={(keyword, dataList) => goodsSug('name', keyword, i, dataList)}
                    />
                  ) : null}
                  {nameLock && !isSetGoodsSource && (
                    <Select
                      data-path={`name_${i + 1}`}
                      value={item.name}
                      data={nameEnum}
                      required={nameRequired}
                      disabled={!nameEditable}
                      header={header}
                      format={lockShow}
                      filter="value"
                      clear
                      onChange={val => onChange(i, 'name', val)}
                    />
                  )}
                  {!nameLock && !isSetGoodsSource && goodsNameHeader && !!goodsNameHeader.length && (
                    <DataList
                      data-path={`name_${i + 1}`}
                      value={item.name}
                      data={nameSugHasData(i) ? state[`nameEnum${i}`] : nameEnum}
                      required={nameRequired}
                      disabled={!nameEditable}
                      highlight={nameSugHasData(i) ? 'g_name' : 'value'}
                      header={nameSugHasData(i) ? goodsNameHeader : header}
                      clear
                      onChange={val => onChange(i, 'name', val)}
                      onSelect={
                        nameSugHasData(i)
                          ? data => selectGoodsSug('name', data, i)
                          : data => onChange(i, 'name', data.value)
                      }
                      filter={(keyword, dataList) => goodsSug('name', keyword, i, dataList)}
                    />
                  )}
                  {!nameLock && !isSetGoodsSource && !(goodsNameHeader && goodsNameHeader.length) && (
                    <DataList
                      data-path={`name_${i + 1}`}
                      value={item.name}
                      data={nameEnum}
                      required={nameRequired}
                      disabled={!nameEditable}
                      highlight="value"
                      header={header}
                      filter="value"
                      clear
                      onChange={val => onChange(i, 'name', val)}
                      onSelect={data => onChange(i, 'name', data.value)}
                    />
                  )}
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'name')} />}
                </div>
              )}
              {dangerousGnameShow && (
                <div style={goodsStyle.dangerous_gname}>
                  <label className={`fn-label${required(dangerousGnameRequired)}`}>{ot('危险货物名称')}</label>
                  <Input
                    data-path={`dangerous_gname_${i + 1}`}
                    required={dangerousGnameRequired}
                    value={item.dangerous_gname}
                    disabled
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'dangerous_gname')} />}
                </div>
              )}
              {dangerousUnCodeShow && (
                <div style={goodsStyle.dangerous_un_code}>
                  <label className={`fn-label${required(dangerousUnCodeRequired)}`}>{ot('UN编号')}</label>
                  <Input
                    data-path={`dangerous_un_code_${i + 1}`}
                    required={dangerousUnCodeRequired}
                    value={item.dangerous_un_code}
                    disabled
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'dangerous_un_code')} />}
                </div>
              )}
              {dangerousGcodeShow && (
                <div style={goodsStyle.dangerous_gcode}>
                  <label className={`fn-label${required(dangerousGcodeRequired)}`}>{ot('危险品类别代码')}</label>
                  <Input
                    data-path={`dangerous_gcode_${i + 1}`}
                    required={dangerousGcodeRequired}
                    value={item.dangerous_gcode}
                    disabled
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'dangerous_gcode')} />}
                </div>
              )}
              {dangerousCmNameShow && (
                <div style={goodsStyle.dangerous_cm_name}>
                  <label className={`fn-label${required(dangerousCmNameRequired)}`}>{ot('危险化学品名称')}</label>
                  <Input
                    data-path={`dangerous_cm_name_${i + 1}`}
                    required={dangerousCmNameRequired}
                    value={item.dangerous_cm_name}
                    disabled
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'dangerous_cm_name')} />}
                </div>
              )}
              {dangerousCasCodeShow && (
                <div style={goodsStyle.dangerous_cas_code}>
                  <label className={`fn-label${required(dangerousCasCodeRequired)}`}>{ot('CAS编号')}</label>
                  <Input
                    data-path={`dangerous_cas_code_${i + 1}`}
                    required={dangerousCasCodeRequired}
                    value={item.dangerous_cas_code}
                    disabled
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'dangerous_cas_code')} />}
                </div>
              )}
              {dangerousPkgCodeShow && (
                <div style={goodsStyle.dangerous_pkg_code}>
                  <label className={`fn-label${required(dangerousPkgCodeRequired)}`}>{ot('包装类型代码')}</label>
                  <Input
                    data-path={`dangerous_pkg_code_${i + 1}`}
                    required={dangerousPkgCodeRequired}
                    value={item.dangerous_pkg_code}
                    disabled
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'dangerous_pkg_code')} />}
                </div>
              )}
              {dangerousCatNameShow && (
                <div style={goodsStyle.dangerous_cat_name}>
                  <label className={`fn-label${required(dangerousCatNameRequired)}`}>{ot('货物分类名称')}</label>
                  <Input
                    data-path={`dangerous_cat_name_${i + 1}`}
                    required={dangerousCatNameRequired}
                    value={item.dangerous_cat_name}
                    disabled
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'dangerous_cat_name')} />}
                </div>
              )}
              {dangerousPkgSpecShow && (
                <div style={goodsStyle.dangerous_pkg_spec}>
                  <label className={`fn-label${required(dangerousPkgSpecRequired)}`}>{ot('包装规格')}</label>
                  <Input
                    data-path={`dangerous_pkg_spec_${i + 1}`}
                    required={dangerousPkgSpecRequired}
                    value={item.dangerous_pkg_spec}
                    disabled
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'dangerous_pkg_spec')} />}
                </div>
              )}
              {pkgShow && (
                <div style={goodsStyle.pkg}>
                  <label className={`fn-label${required(pkgRequired)}`}>{ot('包装')}</label>
                  {pkgLock && (
                    <Select
                      data-path={`pkg_${i + 1}`}
                      value={item.pkg}
                      data={pkgEnum}
                      required={pkgRequired}
                      disabled={!pkgEditable}
                      header={header}
                      format={lockShow}
                      filter="value"
                      onChange={val => onChange(i, 'pkg', val)}
                    />
                  )}
                  {!pkgLock && (
                    <DataList
                      data-path={`pkg_${i + 1}`}
                      value={item.pkg}
                      data={pkgEnum}
                      required={pkgRequired}
                      disabled={!pkgEditable}
                      highlight="value"
                      header={header}
                      filter="value"
                      onChange={val => onChange(i, 'pkg', val)}
                      onSelect={data => onChange(i, 'pkg', data.value)}
                    />
                  )}
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'pkg')} />}
                </div>
              )}
              {pkgServiceShow && (
                <div style={goodsStyle.pkg_service}>
                  <label className="fn-checkbox-label">
                    <Checkbox
                      checked={item.pkg_service}
                      disabled={!pkgServiceEditable}
                      onChange={e => onChange(i, 'pkg_service', e.target.checked)}
                    />
                    {ot('包装服务')}
                  </label>
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'pkg_service')} />}
                </div>
              )}
              {suitShow && (
                <div style={goodsStyle.suit}>
                  <label className={`fn-label${required(suitRequired)}`}>{ot('套数')}</label>
                  <Input
                    data-path={`suit_${i + 1}`}
                    required={suitRequired}
                    value={item.suit}
                    disabled={!suitEditable}
                    onChange={e => {
                      onChangeFreightKey(i, 'suit', e.target.value, item);
                      feeSource.setGoodsSource(i, 'suit_source', 1);
                    }}
                    pattern={FLOAT_P_3}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'suit')} />}
                </div>
              )}
              {suitUnitPriceShow && (
                <div style={goodsStyle.suit_unit_price}>
                  <label className={`fn-label${required(suitUnitPriceRequired)}`}>{ot('套数单价', '元/套')}</label>
                  <Input
                    data-path={`suit_unit_price_${i + 1}`}
                    disabled={!suitUnitPriceEditable}
                    value={item.suit_unit_price}
                    onChange={e => onChange(i, 'suit_unit_price', e.target.value)}
                    pattern={FLOAT_P_6}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'suit_unit_price')} />}
                </div>
              )}
              {numShow && (
                <div style={goodsStyle.num}>
                  <label className={`fn-label${required(numRequired)}`}>{ot('件数')}</label>
                  <Input
                    data-path={`num_${i + 1}`}
                    required={numRequired}
                    value={item.num}
                    disabled={!numEditable}
                    onChange={e => {
                      onChangeNum(i, 'num', e.target.value, item);
                      feeSource.setGoodsSource(i, 'num_source', 1);
                    }}
                    pattern={NUMBER}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'num')} />}
                </div>
              )}
              {numUnitPriceShow && (
                <div style={goodsStyle.num_unit_price}>
                  <label className={`fn-label${required(numUnitPriceRequired)}`}>{ot('件数单价', '元/件')}</label>
                  <Input
                    data-path={`num_unit_price_${i + 1}`}
                    disabled={!numUnitPriceEditable}
                    value={item.num_unit_price}
                    onChange={e => onChange(i, 'num_unit_price', e.target.value)}
                    pattern={FLOAT_P_6}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'num_unit_price')} />}
                </div>
              )}
              {numPerTrayShow && (
                <div style={goodsStyle.num_per_tray}>
                  <label className={`fn-label${required(numPerTrayRequired)}`}>{ot('单托件数')}</label>
                  <Input
                    data-path={`num_per_tray_${i + 1}`}
                    required={numPerTrayRequired}
                    value={item.num_per_tray}
                    disabled={!numPerTrayEditable}
                    onChange={e => onChangeNumPerTray(i, 'num_per_tray', e.target.value, item)}
                    pattern={NUMBER}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'num_per_tray')} />}
                </div>
              )}
              {trayCountShow && (
                <div style={goodsStyle.tray_count}>
                  <label className={`fn-label${required(trayCountRequired)}`}>{ot('托数')}</label>
                  <Input
                    data-path={`tray_count_${i + 1}`}
                    required={trayCountRequired}
                    value={item.tray_count}
                    disabled={!trayCountEditable}
                    onChange={e => onChange(i, 'tray_count', e.target.value, item)}
                    pattern={NUMBER}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'tray_count')} />}
                </div>
              )}
              {weightPerNumShow && (
                <div style={goodsStyle.weight_per_num}>
                  <label className={`fn-label${required(weightPerNumRequired)}`}>
                    {ot('单件重量')}({weightUnit})
                  </label>
                  <Input
                    data-path={`weight_per_num_${i + 1}`}
                    required={weightPerNumRequired}
                    value={item.weight_per_num}
                    disabled={!weightPerNumEditable}
                    onChange={e => onChangeWeightPerNum(i, 'weight_per_num', e.target.value, item)}
                    pattern={weightUnit === 'kg' ? FLOAT_P_3 : FLOAT_P_6}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'weight_per_num')} />}
                </div>
              )}
              {weightShow && (
                <div style={goodsStyle.weight}>
                  <label className={`fn-label${required(weightRequired)}`}>
                    {ot('重量')}({weightUnit})
                  </label>
                  <Input
                    data-path={`weight_${i + 1}`}
                    required={weightRequired}
                    value={item.weight}
                    disabled={!weightEditable}
                    onChange={e => {
                      onChangeFreightKey(i, 'weight', e.target.value, item);
                      feeSource.setGoodsSource(i, 'weight_source', 1);
                    }}
                    pattern={weightUnit === 'kg' ? FLOAT_P_3 : FLOAT_P_6}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'weight')} />}
                </div>
              )}
              {attachmentsShow && (
                <div style={goodsStyle.attachments}>
                  <label className={`fn-label${required(attachmentsRequired)}`}>{ot('货物附件')}</label>
                  <UploadFile
                    type="goods"
                    showType="link"
                    fileList={item.attachments}
                    displayName="hover"
                    ellipsis={2}
                    disabled
                    isOss
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'attachments')} />}
                </div>
              )}
              {whIdShow && (
                <div style={goodsStyle.wh_id}>
                  <label className={`fn-label${required(whIdRequired)}`}>{ot('仓库')}</label>
                  <Select
                    data-path={`wh_id_${i + 1}`}
                    value={item.wh_id}
                    data={state[`whEnum${i}`] || [item]}
                    required={whIdRequired}
                    disabled={!whIdEditable || !isCreate}
                    compare="wh_id"
                    format="wh_name"
                    map={false}
                    header={whHeader}
                    filter={(keyword, select) => whSug({ keyword, select, i })}
                    onChange={val => whChange(val, item, i)}
                  />
                </div>
              )}
              {whAreaIdShow && (
                <div style={goodsStyle.wh_area_id}>
                  <label className={`fn-label${required(whAreaIdRequired)}`}>{ot('库区')}</label>
                  <Select
                    data-path={`wh_area_id_${i + 1}`}
                    value={item.wh_area_id}
                    data={state[`whEnum${i}`] || [item]}
                    required={whAreaIdRequired}
                    disabled={!whAreaIdEditable}
                    compare="wh_area_id"
                    format="wh_area_name"
                    map={false}
                    header={whHeader}
                    filter={(keyword, select) =>
                      whSug({ keyword, select, i, wh_id: isCreate ? undefined : goods?.[0]?.wh_id })
                    }
                    onChange={val => whChange(val, item, i)}
                  />
                </div>
              )}
              {whLocationIdShow && (
                <div style={goodsStyle.wh_location_id}>
                  <label className={`fn-label${required(whLocationIdRequired)}`}>{ot('货位')}</label>
                  <Select
                    data-path={`wh_location_id_${i + 1}`}
                    value={item.wh_location_id}
                    data={state[`whEnum${i}`] || [item]}
                    required={whLocationIdRequired}
                    disabled={!whLocationIdEditable}
                    compare="wh_location_id"
                    format="wh_location_name"
                    map={false}
                    header={whHeader}
                    filter={(keyword, select) =>
                      whSug({ keyword, select, i, wh_id: isCreate ? undefined : goods?.[0]?.wh_id })
                    }
                    onChange={val => whChange(val, item, i)}
                  />
                </div>
              )}
              {inBillNumShow && (
                <div style={goodsStyle.in_bill_num}>
                  <label className={`fn-label${required(inBillNumRequired)}`}>{ot('入库单号')}</label>
                  <Input
                    data-path={`in_bill_num`}
                    required={inBillNumRequired}
                    value={item.in_bill_num}
                    disabled
                    onChange={e => onChangeFreightKey(i, 'in_bill_num', e.target.value, item)}
                  />
                </div>
              )}
              {outBillNumShow && (
                <div style={goodsStyle.out_bill_num}>
                  <label className={`fn-label${required(outBillNumRequired)}`}>{ot('出库单号')}</label>
                  <Input
                    data-path={`out_bill_num`}
                    required={outBillNumRequired}
                    value={item.out_bill_num}
                    disabled
                    onChange={e => onChangeFreightKey(i, 'out_bill_num', e.target.value, item)}
                  />
                </div>
              )}
              {practicalWShow && (
                <div style={goodsStyle.practical_w}>
                  <label className={`fn-label${required(practicalWRequired)}`}>
                    {ot('货物实际重量', '货物实际重量')}({weightUnit})
                  </label>
                  <Input
                    data-path={`practical_w_${i + 1}`}
                    required={practicalWRequired}
                    value={item.practical_w}
                    disabled={!practicalWEditable}
                    onChange={e => onChange(i, 'practical_w', e.target.value, item)}
                    pattern={weightUnit === 'kg' ? FLOAT_P_3 : FLOAT_P_6}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'practical_w')} />}
                </div>
              )}
              {weightUnitPriceShow && (
                <div style={goodsStyle.weight_unit_price}>
                  <label className={`fn-label${required(weightUnitPriceRequired)}`}>
                    {ot('重量单价', `元/${weightUnit}`)}
                  </label>
                  <Input
                    data-path={`weight_unit_price_${i + 1}`}
                    disabled={!weightUnitPriceEditable}
                    value={item.weight_unit_price}
                    onChange={e => onChange(i, 'weight_unit_price', e.target.value)}
                    pattern={FLOAT_P_6}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'weight_unit_price')} />}
                </div>
              )}
              {lengthShow && (
                <div style={goodsStyle.length}>
                  <label className={`fn-label${required(lengthRequired)}`}>{ot('长(cm)')}</label>
                  <Input
                    data-path={`length_${i + 1}`}
                    required={lengthRequired}
                    maxLength="8"
                    value={item.length}
                    disabled={!lengthEditable}
                    onChange={e => mergeGoods(i, 'length', e.target.value, item)}
                    onBlur={e => calcVolumePerNum(i, e, item)}
                    pattern={FLOAT_P}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'length')} />}
                </div>
              )}
              {widthShow && (
                <div style={goodsStyle.width}>
                  <label className={`fn-label${required(widthRequired)}`}>{ot('宽(cm)')}</label>
                  <Input
                    data-path={`width_${i + 1}`}
                    required={widthRequired}
                    maxLength="8"
                    value={item.width}
                    disabled={!widthEditable}
                    onChange={e => mergeGoods(i, 'width', e.target.value, item)}
                    onBlur={e => calcVolumePerNum(i, e, item)}
                    pattern={FLOAT_P}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'width')} />}
                </div>
              )}
              {highShow && (
                <div style={goodsStyle.high}>
                  <label className={`fn-label${required(highRequired)}`}>{ot('高(cm)')}</label>
                  <Input
                    data-path={`high_${i + 1}`}
                    required={highRequired}
                    maxLength="8"
                    value={item.high}
                    disabled={!highEditable}
                    onChange={e => mergeGoods(i, 'high', e.target.value, item)}
                    onBlur={e => calcVolumePerNum(i, e, item)}
                    pattern={FLOAT_P}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'high')} />}
                </div>
              )}
              {volumePerNumShow && (
                <div style={goodsStyle.volume_per_num}>
                  <label className={`fn-label${required(volumePerNumRequired)}`}>{ot('单件体积')}</label>
                  <Input
                    data-path={`volume_per_num_${i + 1}`}
                    required={volumePerNumRequired}
                    value={item.volume_per_num}
                    disabled={!volumePerNumEditable}
                    onChange={e => onChangeVolumePerNum(i, 'volume_per_num', e.target.value, item)}
                    pattern={FLOAT_P_6}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'volume_per_num')} />}
                </div>
              )}
              {volumeShow && (
                <div style={goodsStyle.volume}>
                  <label className={`fn-label${required(volumeRequired)}`}>{ot('体积')}(方)</label>
                  <Input
                    data-path={`volume_${i + 1}`}
                    required={volumeRequired}
                    value={item.volume}
                    disabled={!volumeEditable}
                    placeholder="长*宽*高*件数(米)"
                    onChange={e => {
                      mergeGoods(i, 'volume', e.target.value, item);
                      feeSource.setGoodsSource(i, 'volume_source', 1);
                    }}
                    onBlur={e => calcVolume(i, e, item)}
                    pattern={FLOAT_P_6}
                  >
                    {(calcInfoShow || volumeEditable) && (
                      <i
                        className="fn-icon fn-icon-calculate input-icon"
                        data-path={i === 0 ? 'volume_calc' : undefined}
                        onClick={() => showVolumeEditModal(i, item)}
                      />
                    )}
                  </Input>
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'volume')} />}
                </div>
              )}
              {practicalVShow && (
                <div style={goodsStyle.practical_v}>
                  <label className={`fn-label${required(practicalVRequired)}`}>
                    {ot('货物实际体积', '货物实际体积')}(方)
                  </label>
                  <Input
                    data-path={`practical_v_${i + 1}`}
                    required={practicalVRequired}
                    value={item.practical_v}
                    disabled={!practicalVEditable}
                    onChange={e => onChange(i, 'practical_v', e.target.value, item)}
                    pattern={FLOAT_P_6}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'practical_v')} />}
                </div>
              )}
              {volumeUnitPriceShow && (
                <div style={goodsStyle.volume_unit_price}>
                  <label className={`fn-label${required(volumeUnitPriceRequired)}`}>{ot('体积单价', '元/方')}</label>
                  <Input
                    data-path={`volume_unit_price_${i + 1}`}
                    disabled={!volumeUnitPriceEditable}
                    value={item.volume_unit_price}
                    onChange={e => onChange(i, 'volume_unit_price', e.target.value)}
                    pattern={FLOAT_P_6}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'volume_unit_price')} />}
                </div>
              )}
              {unitPShow && (
                <div className="price-unit" style={goodsStyle.unit_p}>
                  <label className={`fn-label${required(unitPRequired)}`}>{ot('单价')}</label>
                  <Input
                    data-path={`unit_p_${i + 1}`}
                    required={unitPRequired}
                    value={item.unit_p}
                    title={item.unit_p}
                    disabled={!unitPEditable}
                    onChange={e => {
                      onChangeFreightKey(i, 'unit_p', e.target.value, item);
                      feeSource.setGoodsSource(i, 'unit_source', 1);
                    }}
                    pattern={FLOAT_P_6}
                  />
                  <Select
                    data-path={`unit_p_unit_${i + 1}`}
                    value={item.unit_p_unit}
                    data={unitEnum}
                    disabled={!unitPEditable}
                    filter={false}
                    onChange={val => onChangeUnitPUnit(i, 'unit_p_unit', val, item)}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'unit_p')} />}
                </div>
              )}
              {subtotalPriceShow && (
                <div style={goodsStyle.subtotal_price}>
                  <label className={`fn-label${required(subtotalPriceRequired)}`}>{ot('运费小计')}</label>
                  <Input
                    data-path={`subtotal_price_${i + 1}`}
                    required={subtotalPriceRequired}
                    disabled={!subtotalPriceEditable}
                    value={item.subtotal_price}
                    onChange={e => onChange(i, 'subtotal_price', e.target.value)}
                    pattern={FLOAT_P}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'subtotal_price')} />}
                </div>
              )}
              {countPerNumShow && (
                <div style={goodsStyle.count_per_num}>
                  <label className={`fn-label${required(countPerNumRequired)}`}>{ot('单件数量')}</label>
                  <Input
                    data-path={`count_per_num_${i + 1}`}
                    required={countPerNumRequired}
                    disabled={!countPerNumEditable}
                    value={item.count_per_num}
                    onChange={e => onChangeCountPerNum(i, 'count_per_num', e.target.value, item)}
                    pattern={NUMBER}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'count_per_num')} />}
                </div>
              )}
              {countShow && (
                <div style={goodsStyle.count}>
                  <label className={`fn-label${required(countRequired)}`}>{ot('数量')}</label>
                  <Input
                    data-path={`count_${i + 1}`}
                    required={countRequired}
                    disabled={!countEditable}
                    value={item.count}
                    onChange={e => onChange(i, 'count', e.target.value)}
                    pattern={NUMBER}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'count')} />}
                </div>
              )}
              {unitShow && (
                <div style={goodsStyle.unit}>
                  <label className={`fn-label${required(unitRequired)}`}>{ot('单位')}</label>
                  <Input
                    data-path={`unit_${i + 1}`}
                    required={unitRequired}
                    disabled={!unitEditable}
                    value={item.unit}
                    onChange={e => onChange(i, 'unit', e.target.value)}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'unit')} />}
                </div>
              )}
              {catShow && (
                <div style={goodsStyle.cat}>
                  <label className={`fn-label${required(catRequired)}`}>{ot('货物类型')}</label>
                  {catLock && (
                    <Select
                      data-path={`cat_${i + 1}`}
                      required={catRequired}
                      value={item.cat}
                      data={catEnum}
                      header={header}
                      format={lockShow}
                      filter="value"
                      disabled={!catEditable}
                      onChange={val => onChange(i, 'cat', val)}
                    />
                  )}
                  {!catLock && (
                    <DataList
                      data-path={`cat_${i + 1}`}
                      required={catRequired}
                      value={item.cat}
                      data={catEnum}
                      disabled={!catEditable}
                      header={header}
                      filter="value"
                      onChange={val => onChange(i, 'cat', val)}
                      onSelect={data => onChange(i, 'cat', data.value)}
                    />
                  )}
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'cat')} />}
                </div>
              )}
              {specShow && (
                <div style={goodsStyle.spec}>
                  <label className={`fn-label${required(specRequired)}`}>{ot('规格')}</label>
                  <Input
                    data-path={`spec_${i + 1}`}
                    required={specRequired}
                    disabled={!specEditable}
                    value={item.spec}
                    onChange={e => onChange(i, 'spec', e.target.value)}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'spec')} />}
                </div>
              )}
              {modelShow && (
                <div style={goodsStyle.model}>
                  <label className={`fn-label${required(modelRequired)}`}>{ot('型号')}</label>
                  <Input
                    data-path={`model_${i + 1}`}
                    required={modelRequired}
                    disabled={!modelEditable}
                    value={item.model}
                    onChange={e => onChange(i, 'model', e.target.value)}
                  />
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'model')} />}
                </div>
              )}
              {specialShow && (
                <div style={goodsStyle.special} className={specialCheckbox ? 'special-checkbox' : undefined}>
                  <label className={`fn-label${required(specialRequired)}`}>{ot('特殊货物')}</label>
                  {specialCheckbox &&
                    (specialEnum || []).map(sp => (
                      <label className="fn-checkbox-label">
                        <Checkbox
                          checked={(item.special || []).includes(sp.key)}
                          disabled={!specialEditable}
                          onChange={e => onSpecialCheck(i, sp.key, e.target.checked, item)}
                        />
                        {sp.value}
                      </label>
                    ))}
                  {!specialCheckbox && specialLock && (
                    <Select
                      data-path={`special_${i + 1}`}
                      required={specialRequired}
                      value={item.special}
                      data={specialEnum}
                      header={header}
                      format={lockShow}
                      filter="value"
                      multiple
                      disabled={!specialEditable}
                      onChange={val => onChange(i, 'special', val)}
                    />
                  )}
                  {!specialCheckbox && !specialLock && (
                    <DataList
                      data-path={`special_${i + 1}`}
                      required={specialRequired}
                      value={resolveSpecialVal(item.special)}
                      data={specialEnum}
                      disabled={!specialEditable}
                      header={header}
                      filter={false}
                      format="value"
                      multiple
                      onChange={val => onChange(i, 'special', val && val.split(','))}
                      onSelect={data => onChange(i, 'special', DataList.formatMultipleValue(data, 'value').split(','))}
                    />
                  )}
                  {i === 0 && <i className="table-drager" onMouseDown={e => drag(e, 'special')} />}
                </div>
              )}
            </div>
          ))}
          {showGoodsTotalRow && goods.length > 1 && (
            <div className="goods-total-row">
              {boNumShow && <div style={goodsStyle.bo_num} />}
              {codeShow && <div style={goodsStyle.code} />}
              {nameShow && (
                <div style={goodsStyle.name} className="total-row-num">
                  <span style={{ left: '34px', right: 'auto' }}>合计 :</span>
                  <span style={{ left: '66px' }}>{goods.length}</span>
                </div>
              )}
              {dangerousGnameShow && <div style={goodsStyle.dangerous_gname} />}
              {dangerousUnCodeShow && <div style={goodsStyle.dangerous_un_code} />}
              {dangerousGcodeShow && <div style={goodsStyle.dangerous_gcode} />}
              {dangerousCmNameShow && <div style={goodsStyle.dangerous_cm_name} />}
              {dangerousCasCodeShow && <div style={goodsStyle.dangerous_cas_code} />}
              {dangerousPkgCodeShow && <div style={goodsStyle.dangerous_pkg_code} />}
              {dangerousCatNameShow && <div style={goodsStyle.dangerous_cat_name} />}
              {dangerousPkgSpecShow && <div style={goodsStyle.dangerous_pkg_spec} />}
              {pkgShow && <div style={goodsStyle.pkg} />}
              {pkgServiceShow && <div style={goodsStyle.pkg_service} />}
              {suitShow && (
                <div style={goodsStyle.suit}>
                  <span style={{ left: '36px' }}>{calcField(goods, 'suit')}</span>
                </div>
              )}
              {suitUnitPriceShow && <div style={goodsStyle.suit_unit_price} />}
              {numShow && (
                <div style={goodsStyle.num}>
                  <span style={{ left: '36px' }}>{calcField(goods, 'num')}</span>
                </div>
              )}
              {numUnitPriceShow && <div style={goodsStyle.num_unit_price} />}
              {numPerTrayShow && <div style={goodsStyle.num_per_tray} />}
              {trayCountShow && (
                <div style={goodsStyle.tray_count}>
                  <span style={{ left: '36px' }}>{calcField(goods, 'tray_count')}</span>
                </div>
              )}
              {weightPerNumShow && <div style={goodsStyle.weight_per_num} />}
              {weightShow && (
                <div style={goodsStyle.weight}>
                  <span style={{ left: '61px' }}>
                    {+calcField(goods, 'weight').toFixed(weightUnit === '吨' ? 6 : 3)}
                  </span>
                </div>
              )}
              {attachmentsShow && <div style={goodsStyle.attachments} />}
              {practicalWShow && (
                <div style={goodsStyle.practical_w}>
                  <span style={{ left: '92px' }}>
                    {+calcField(goods, 'practical_w').toFixed(weightUnit === '吨' ? 6 : 3)}
                  </span>
                </div>
              )}
              {weightUnitPriceShow && <div style={goodsStyle.weight_unit_price} />}
              {lengthShow && <div style={goodsStyle.length} />}
              {widthShow && <div style={goodsStyle.width} />}
              {highShow && <div style={goodsStyle.high} />}
              {volumePerNumShow && <div style={goodsStyle.volume_per_num} />}
              {volumeShow && (
                <div style={goodsStyle.volume}>
                  <span style={{ left: '63px' }}>{+calcField(goods, 'volume').toFixed(6)}</span>
                </div>
              )}
              {practicalVShow && (
                <div style={goodsStyle.practical_v}>
                  <span style={{ left: '92px' }}>{+calcField(goods, 'practical_v').toFixed(4)}</span>
                </div>
              )}
              {volumeUnitPriceShow && <div style={goodsStyle.volume_unit_price} />}
              {unitPShow && <div style={goodsStyle.unit_p} />}
              {subtotalPriceShow && (
                <div style={goodsStyle.subtotal_price}>
                  <span style={{ left: '66px' }}>{+calcField(goods, 'subtotal_price').toFixed(2)}</span>
                </div>
              )}
              {countPerNumShow && <div style={goodsStyle.count_per_num} />}
              {countShow && (
                <div style={goodsStyle.count}>
                  <span style={{ left: '36px' }}>{calcField(goods, 'count')}</span>
                </div>
              )}
              {unitShow && <div style={goodsStyle.unit} />}
              {catShow && <div style={goodsStyle.cat} />}
              {specShow && <div style={goodsStyle.spec} />}
              {modelShow && <div style={goodsStyle.model} />}
              {specialShow && (
                <div style={goodsStyle.special} className={specialCheckbox ? 'special-checkbox' : undefined}>
                  {specialCheckbox && <label className="fn-label">{ot('特殊货物')}</label>}
                  {specialCheckbox &&
                    (specialEnum || []).map(sp => (
                      <label className="fn-checkbox-label">
                        <Checkbox />
                        {sp.value}
                      </label>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default renderGoods;
