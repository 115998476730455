import React from 'react';
import _ from 'lodash';
import commonErrorTip from './commonErrorTip';

const priceErrorTip = res => {
  const msg = res.errmsg;
  const title = (
    <div className="dialog_detail">
      <p className="notice">
        {msg} <br />
        <span className="text-gray fs12">请检查费用填写是否正确，或联系管理员调整价格。</span>
      </p>
    </div>
  );
  const detail = _.get(res, 'res.failed_detail[0].ext.summary', []);
  return commonErrorTip({ title, detail });
};

export default priceErrorTip;
