import { formatDeppProptotype, getNum, typeIs, mathHelper } from 'utils';
import { isInOtherPropsArray } from './../utils';

export function getSumVal(selectedRows, columnKey, excludeDisable = false) {
  let _sum = 0;
  selectedRows.forEach(dataItem => {
    if (!(excludeDisable && isInOtherPropsArray(dataItem, columnKey, 'disable'))) {
      let _value = formatDeppProptotype(columnKey, dataItem);
      if (typeIs(_value, 'object')) {
        _value = _value.text;
      } else if (typeIs(_value, 'array')) {
        _value = _value.reduce((per, cur) => mathHelper.accAdd(cur, getNum(per)), 0);
      }
      _sum = mathHelper.accAdd(_sum, getNum(_value));
      // _sum += getNum(_value)
    }
  });
  return _sum;
}

// 判断此列的每行数据是否为disable状态，如果是，合计同样为disable状态
export function showTotalCheckbox(dataLen, ilistStore, col) {
  let disable = true;
  const indexList = Array.from({ length: dataLen }, (v, k) => k);
  indexList.forEach(rowIndex => {
    disable = disable && isInOtherPropsArray(ilistStore.getObjectAt(rowIndex), col, 'disable');
  });
  return !disable;
}
