import { dangerGoodsFields } from 'constants/setting/rosSetting';
import React from 'react';
import { buildTipMixin, ot, directCompleteStr } from 'utils';

const _directCompleteStr = directCompleteStr();

export const FETCH_ORDER_DATA = 'FETCH_ORDER_DATA';
export const RESTORE_ORDER_DATA = 'RESTORE_ORDER_DATA';
export const SET_ORDER_DATA = 'SET_ORDER_DATA';
export const SELECT_COR_SUG = 'SELECT_COR_SUG';
export const SELECT_CEE_SUG = 'SELECT_CEE_SUG';
export const SET_SUG_DATA = 'SET_SUG_DATA';
export const MERGE_DATA = 'MERGE_DATA';
export const CEE_ARR_MATCHED = 'CEE_ARR_MATCHED';
export const CO_DELIVERY_FEE_BLUR = 'CO_DELIVERY_FEE_BLUR';
export const SELECT_GOODS_SUG = 'SELECT_GOODS_SUG';
export const RE_CALC_OUTER_PRICE = 'RE_CALC_OUTER_PRICE';
export const CALC_OUTER_PRICE_IMMEDIATELY = 'CALC_OUTER_PRICE_IMMEDIATELY';

export const routeHeader = [
  { key: 'route_nick', title: '路由' },
  {
    key: 'mile',
    title: (
      <span>
        里程<small>(km)</small>
      </span>
    ),
  },
  {
    key: 'route_time',
    title: (
      <span>
        时效<small>(h)</small>
      </span>
    ),
  },
];

export const pointHeader = [{ key: 'type_name', title: '组织类型' }];

export const effectGoodsNumKeyMap = {
  arr_point_code: 'arrPoint',
  co_point_code: 'startPoint',
  num_last: 'orderNum',
  order_num: 'orderNum',
  rcv_stn_code: 'rcvStn',
  num: 'num',
};

export const memberKeys = {
  open_bank: 1,
  bank_card_num: 1,
  card_holder: 1,
  contact_phone: 1,
};

export const corKeys = {
  cor_customer_no: 1,
  cor_industry: 1,
  cor_com: 1,
  cor_name: 1,
  cor_mobile: 1,
  cor_phone: 1,
  cor_id_num: 1,
  cor_addr_info: 1,
};

export const ceeKeys = {
  cee_customer_no: 1,
  cee_industry: 1,
  cee_com: 1,
  cee_name: 1,
  cee_mobile: 1,
  cee_phone: 1,
  cee_id_num: 1,
  cee_addr_info: 1,
};

export const normalHeader = ['value'];

export const mgrHeader = [
  { key: 'name', title: '经办人' },
  { key: 'telephone', title: '电话' },
];

export const serviceTypeEnum = [
  { key: 'door_door', name: '门到门' },
  { key: 'door_site', name: '门到站' },
  { key: 'site_door', name: '站到门' },
  { key: 'site_site', name: '站到站' },
];

// 送货方式枚举
export const deliveryModeEnum = [
  { key: 'self_pick', name: '自提' },
  { key: 'pure_delivery', name: '送货' },
  { key: 'delivery_door', name: '送货上门' },
  { key: 'delivery_floor', name: '送货上楼(有电梯)' },
  { key: 'delivery_floor_no', name: '送货上楼(无电梯)' },
  { key: 'delivery_handle', name: '送货卸货' },
  { key: 'delivery_set', name: '送货安装' },
  { key: 'direct_complete', name: _directCompleteStr },
  { key: 'direct_truck', name: '大车直送' },
  { key: 'delivery_warehouse', name: '送货进仓' },
  { key: 'delivery_not_up', name: '送货不上楼' },
  { key: 'without_arr', name: '不经到货' },
  { key: 'direct_delivery', name: '大车送货' },
].map(item => ({ ...item, name: ot(item.name) }));

// 支付方式枚举
export const paymentModeEnum = [
  { key: 'settle_cash', name: '现金' },
  { key: 'settle_card', name: '银行卡' },
  { key: 'settle_wechat', name: '微信' },
  { key: 'settle_alipay', name: '支付宝' },
  { key: 'settle_cheque', name: '支票' },
];

// 紧急度枚举
export const urgencyEnum = [
  { key: 'normal', name: '正常' },
  { key: 'urgent', name: '急' },
  { key: 'much_urgent', name: '加急' },
  { key: 'extremely_urgent', name: '特急' },
];

export const directDelivery = { direct_complete: 1, direct_truck: 1 };

export const withoutArr = { without_arr: 1, direct_delivery: 1 };

export const transModeEnum = [
  { key: '', name: '请选择', short_name: '' },
  { key: 3, name: '无需外转', short_name: '不转' },
  { key: 1, name: '发站外转', short_name: '发转' },
  { key: 2, name: '到站外转', short_name: '到转' },
];

export const scanShowHide = {
  order_num: { show: true, required: true },
  arr_info: { show: true, required: false },
  arr_point: { show: true, required: false },
  route: { show: true, required: false },
  delivery_mode: { show: true, required: true },
  pay_mode: { show: true, required: true },
  mgr_id: { show: true, required: true },
};

export const goodsKeys = [
  'id',
  'bo_num',
  'name',
  'code',
  'pkg',
  'pkg_service',
  'suit',
  'suit_unit_price',
  'num',
  'num_unit_price',
  'num_per_tray',
  'tray_count',
  'weight_per_num',
  'weight',
  'practical_w',
  'weight_unit_price',
  'length',
  'width',
  'high',
  'volume_per_num',
  'volume',
  'practical_v',
  'volume_unit_price',
  'unit_p',
  'unit_p_unit',
  'subtotal_price',
  'count_per_num',
  'count',
  'unit',
  'cat',
  'spec',
  'model',
  'special',
  'calc_info',
  'wh_id',
  'wh_name',
  'wh_area_id',
  'wh_area_name',
  'wh_location_id',
  'wh_location_name',
  'in_bill_num',
  'out_bill_num',
  ...Object.keys(dangerGoodsFields),
  'attachments',
];

export const noticePickName = {
  hx_code: { name: ot('需核销码', '核销码'), 1: '核', 2: '核销' },
  need_dispatch: { name: ot('需要配安'), 1: '安', 2: '配安' },
  pickup: { name: ot('上门提货', '提货'), 1: '提', 2: '提货' },
  notice_delivery: { name: ot('是否控货', '控货'), 1: '控', 2: '控货' },
  insured: { name: '投保', 1: '保', 2: '投保' },
};

// export const valueAddedServiceKeys = [
//   'delivery_mode', 'service_type', 'urgency', 'declared_value', ...Object.keys(noticePickName), 'receipt_info', 'receipt_num',
//   'receipt_require', 'expected_release_time'
// ]

export const payModeField = [
  'payBilling',
  'payArrival',
  'payCredit',
  'payMonthly',
  'payReceipt',
  'payOwed',
  'payCoDelivery',
];

export const PAY_ARRIVAL = 'pay_arrival';
export const OUTER_TRANS = 1;
export const INNER_TRANS = 2;
export const POINT_TRANS = 3;

export const tableNames = {
  point_cost_info: '支出费用',
  cost_info: '成本信息',
  std_cost: '标准费用',
  trans_info: '中转信息',
  budget_info: '预算费用',
};
export const tableKeysMap = {
  pointCostInfo: 'point_cost_info',
  costInfo: 'cost_info',
  stdCost: 'std_cost',
  transInfo: 'trans_info',
  budgetInfo: 'budget_info',
  trInfo: 'tr_info',
  shuttleInfo: 'shuttle_info',
  signInfo: 'sign_info',
  deliveryInfo: 'delivery_info',
  pickupInfo: 'pickup_info',
  innerRemark: 'inner_remark',
};
export const tableKeys = ['pointCostInfo', 'costInfo', 'stdCost', 'transInfo', 'budgetInfo'];
export const onlyShowWhenPickup = ['pickup_dr_name', 'pickup_tr_num', 'pickup_dr_phone', 'b_pickup_f'];
export const tableInfo = {
  point_cost_info: { name: '支出费用', dataKey: 'pointCostInfo', headerKey: 'pointCostInfoHeader' },
  cost_info: { name: '成本信息', dataKey: 'costInfo', headerKey: 'costInfoHeader' },
  std_cost: { name: '标准费用', dataKey: 'stdCost', headerKey: 'stdCostHeader' },
  tr_info: { name: '配载信息', dataKey: 'trInfo', headerKey: 'trInfoHeader' },
  shuttle_info: { name: '短驳信息', dataKey: 'shuttleInfo', headerKey: 'shuttleInfoHeader' },
  sign_info: { name: '签收信息', dataKey: 'signInfo', headerKey: 'signInfoHeader' },
  trans_info: {
    name: '中转信息',
    dataKey: 'transInfo',
    headerKey: 'transInfoHeader',
    showSubtractKey: 'showTransSubtract',
  },
  delivery_info: { name: '送货信息', dataKey: 'deliveryInfo', headerKey: 'deliveryInfoHeader' },
  pickup_info: { name: '提货信息', dataKey: 'pickupInfo', headerKey: 'pickupInfoHeader' },
  budget_info: { name: '预算费用', dataKey: 'budgetInfo', headerKey: 'budgetInfoHeader' },
  inner_remark: { name: '内部备注', dataKey: 'innerRemarkInfo', headerKey: 'innerRemarkInfoHeader' },
};

export const innerRemarkHeader = [
  { title: '备注时间', key: 'state_time', display: 'show', style: { minWidth: '180px' } },
  { title: '备注内容', key: 'record_content', display: 'show', style: { minWidth: '400px' } },
  { title: '备注网点', key: 'op_com_name', display: 'show', style: { minWidth: '180px' } },
  { title: '备注人', key: 'op_user_name', display: 'show', style: { minWidth: '180px' } },
];
export const transFeeKeys = {
  trans_freight_f: 1,
  trans_pickup_f: 1,
  trans_delivery_f: 1,
  trans_upstairs_f: 1,
  trans_in_wh_f: 1,
  trans_pkg_f: 1,
  trans_storage_f: 1,
  trans_insurance: 1,
  trans_misc_f: 1,
  trans_handling_f: 1,
  trans_pay_adv: 1,
};
export const tableFeeKeys = [
  'b_billing_f',
  'b_fuel_card_f',
  'b_receipt_f',
  'pickup_f',
  'b_loc_tr_f',
  'tax',
  'b_insur_f',
  'b_loc_misc_f',
  'b_arr_f',
  'b_info_f',
  'std_mn_f',
  'std_trans_f',
  'std_delivery_f',
  'std_pickup_f',
  'pci_uld_f',
  'trans_std_trans_f',
  'trans_freight_f',
  'trans_pickup_f',
  'trans_in_wh_f',
  'trans_delivery_f',
  'trans_install_f',
  'trans_handling_f',
  'trans_pay_adv',
  'trans_upstairs_f',
  'trans_pkg_f',
  'trans_storage_f',
  'trans_insurance',
  'trans_misc_f',
  'trans_f',
  'tax_r',
  'std_f',
  'trans_settle_money',
  'trans_profit',
  'trans_f',
  'trans_pay_billing',
  'trans_pay_arrival',
  'trans_pay_receipt',
  'trans_pay_monthly',
  'trans_pay_owed',
  'trans_pay_co_delivery',
  'trans_pay_credit',
].reduce((allSet, key) => ({ ...allSet, [key]: 1 }), {});

export const continueTxt = {
  confirm: '继续',
};

export const needTrimKeys = [
  'cor_com',
  'cor_name',
  'cor_mobile',
  'cor_phone',
  'cee_com',
  'cee_name',
  'cee_mobile',
  'cee_phone',
  'name',
  'pkg',
  'receipt_require',
  'remark',
  'order_trans_remark',
  'b_dr_name',
  'b_dr_phone',
  'b_tr_num',
  'pickup_dr_name',
  'pickup_dr_phone',
  'pickup_tr_num',
  'b_info_name',
  'b_info_phone',
  'loc_tr_name',
  'loc_tr_phone',
].reduce((allSet, key) => ({ ...allSet, [key]: 1 }), {});

export const alwaysSaveKeys = [
  'cor_com',
  'cor_name',
  'cor_mobile',
  'cor_phone',
  'cor_is_month',
  'cor_addr_info',
  'cor_id_num',
  'cor_customer_no',
  'cor_industry',
  'cor_addr_remark',
  'cor_pick_dist',
  'cee_com',
  'cee_name',
  'cee_mobile',
  'cee_phone',
  'cee_is_month',
  'cee_addr_info',
  'cee_id_num',
  'cee_customer_no',
  'cee_industry',
  'cee_addr_remark',
  'cee_pick_dist',
  'trans_mode',
  'receipt_cat',
  'receipt_n',
  'trsp_mode',
  'service_type',
  'declared_value',
  'matched_cor_attachment',
  'matched_cee_attachment',
];

export const payModeKeys = [
  'pay_billing',
  'pay_arrival',
  'pay_credit',
  'pay_monthly',
  'pay_receipt',
  'pay_co_delivery',
  'pay_owed',
];

export const distributeKeys = {
  member_code: ot('会员号'),
  co_delivery_mode: ot('发放方式'),
  card_holder: ot('开户人'),
  bank_card_num: ot('银行卡号'),
  member_code_diy: ot('自定义会员号'),
  contact_phone: ot('开户电话'),
  id_card_num: ot('身份证号'),
  open_bank: ot('开户行'),
};

export const paidTip = buildTipMixin('已付');

export const returnedTip = buildTipMixin('已返');

export const moveKeys = {
  move_pay_billing: '现付异动',
  move_pay_monthly: '月结异动',
  move_pay_owed: '欠付异动',
  move_pay_receipt: '回付异动',
  move_pay_arrival: '到付异动',
  move_pay_co_delivery: '货款扣异动',
  move_pay_credit: '货到打卡异动',
};
