import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { deleteProps } from 'utils';
import { Input, Icon } from 'components';
import emptyFunction from 'utils/core/emptyFunction';
import { prefixCls } from './index.scss';

export default class InputIcon extends PureComponent {
  static propTypes = {
    classname: PropTypes.string,
    iconProps: PropTypes.object,
    inputProps: PropTypes.object,
    inputClass: PropTypes.string,
    spanContent: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    handleBlur: PropTypes.func,
    onBlur: PropTypes.func,
    iconClick: PropTypes.func,
    dataPath: PropTypes.string, // 光标路径
    iconTips: PropTypes.string, // icon 提示
    handleIconClick: PropTypes.func,
  };
  iconClick = e => {
    const { iconClick = emptyFunction, iconProps, handleIconClick } = this.props;
    const { onClick = handleIconClick } = iconProps;
    iconClick(e);
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };
  handleBlur = e => {
    const { handleBlur = emptyFunction, inputProps = {}, onBlur = emptyFunction } = this.props;
    const { onBlur: _onBlur = emptyFunction } = inputProps;
    _onBlur(e);
    onBlur(e);
    handleBlur(e);
  };

  render() {
    const {
      classname,
      inputClass,
      iconProps,
      spanContent,
      type,
      dataPath,
      disabled,
      inputProps = {},
      iconTips,
      ...rest
    } = this.props;
    const inputDisabled = inputProps.disabled || false;
    const icon = iconProps ? (
      <Icon key="0" {...iconProps} tips={iconTips} disabled={disabled} onClick={disabled ? false : this.iconClick} />
    ) : undefined;
    return (
      <div className={classnames({ [prefixCls]: true, [classname]: classname, [`${prefixCls}--${type}`]: type })}>
        <Input
          key="2"
          {...deleteProps(rest, 'iconType', 'iconClick', 'handleBlur')}
          {...{ ...inputProps, classname: classnames(inputProps.classname, inputClass) }}
          disabled={disabled || inputDisabled}
          data-path={dataPath}
          ref={r => {
            this.vInput = r;
            r && (this._input = r._input);
          }}
          onBlur={this.handleBlur}
        />
        {icon}
        {spanContent ? <span key="1">{spanContent}</span> : undefined}
      </div>
    );
  }
}
