import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { PureInput as Input } from 'components';
import { prefixCls } from './index.scss';
import { emptyFunction } from 'utils';

export default class InputMulti extends PureComponent {
  static propTypes = {
    classname: PropTypes.string,
    data: PropTypes.array,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
  };
  constructor(prop) {
    super(prop);
    this.state = {
      defaultValue: this.props.defaultValue || [],
    };
    this.input = [];
  }
  handleResetState = () => {
    this._input.value = '';
  };
  UNSAFE_componentWillUpdate() {
    // this.setState({
    //   defaultValue: this.props.defaultValue
    // })
  }
  handleChange = index => event => {
    console.log(this.state.defaultValue, 'defaultValue1');
    // eslint-disable-next-line react/no-access-state-in-setstate
    const _defaultValue = [...this.state.defaultValue] || []; // 上一次 [1,2,3]
    _defaultValue[index] = event.target.value; // new
    this.setState({
      defaultValue: _defaultValue,
    });
    console.log(this.state.defaultValue, 'defaultValue2');
    const { onChange = emptyFunction } = this.props;
    onChange(_defaultValue);
  };
  checkValidity = async () => {
    for (const i of this.input) {
      const tips = await i.checkValidity();
      if (tips) {
        return tips;
      }
    }
  };
  render() {
    const { classname, data = [], disabled, pattern } = this.props;
    const { defaultValue = [] } = this.state;
    return (
      <div
        className={classnames({
          [prefixCls]: true,
          [classname]: classname,
        })}
      >
        <ul>
          {data.map((item, index) => (
            <li style={{ width: `calc((100%/${data.length}) - 10px)` }} key={index}>
              <Input
                value={defaultValue[index] || ''}
                pattern={pattern}
                {...item.inputProps}
                ref={r => {
                  this._input = r;
                  this.input[index] = r;
                }}
                onChange={this.handleChange(index)}
                disabled={disabled}
              />
              <span>{item.text}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
