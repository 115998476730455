import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Button } from 'components';
import classnames from 'classnames';
import { typeIs, isEmptyObj, getStrWidth } from 'utils';
import reactComposition from 'react-composition';
import { prefixCls, inputPrefixCls } from './index.scss';

export default class ListInput extends Component {
  constructor(prop) {
    super(prop);
    const data = prop.data || [];
    const inputPosition = data.length;
    this.state = {
      data,
      inputWidth: 12,
      inputPosition,
      // eslint-disable-next-line react/no-unused-state
      isFocus: false,
    };
    this.btnList = [];
    this._isClickFocus = false;
    this._isItemClick = false;
    this.alwaysFocus = false;
    this.continuousL = 0;
  }

  static propTypes = {
    classname: PropTypes.string,
    data: PropTypes.array, // listbutton 数据 即当前值
    showKey: PropTypes.string,
    uniqueKey: PropTypes.string,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handleListUpdated: PropTypes.func,
    handleDelete: PropTypes.func,
    handleInsert: PropTypes.func,
    getInputRef: PropTypes.func,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    inputClass: PropTypes.string,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    valiType: PropTypes.string,
    type: PropTypes.string,
    dataPath: PropTypes.string,
    alwaysFocus: PropTypes.bool,
  };
  static defaultProps = {
    classname: '',
    alwaysFocus: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const data = nextProps.data || [];
    this.setState({ data });
  }

  componentDidUpdate() {
    this._input && this.alwaysFocus && this._input.focus();
  }

  setAlwaysFocus = f => (this.alwaysFocus = f);
  onItemClick = (e, index) => {
    this._isItemClick = true;
    e.stopPropagation && this.props.alwaysFocus && e.stopPropagation();
    const newPoi = index + 1;
    newPoi !== this.state.inputPosition && (this._input.value = '');
    // ??
    this._input && this._input.focus();
    this.setState({ inputWidth: 12 });
    this.setInputPoi(index + 1);
  };
  setInputWidth = val => {
    const fontSize = 14;
    const inputWidth = getStrWidth(val, fontSize, undefined, r => (this.strWidthObj = r), this.strWidthObj) || fontSize;
    this.setState({ inputWidth });
  };
  onClick = e => {
    e.stopPropagation(); // 2017.11.20 列表内下拉点击时不选中该行
    e.persist();
    if (this.props.disabled) return false;
    this._isClickFocus = true;
    this._input && this._input.focus();
    this.props.onClick && this.props.onClick(Object.assign({}, e, { target: this._input }));
  };
  onChange = e => {
    e.persist();
    const inputVal = e.target.value;
    this.continuousL = inputVal.length;
    if (this.continuousL > 200) return;
    if (e.reactComposition.composition === false) {
      // 中文输入时 从英文字切为中文时 会产生实际的两次onchang 第二次的值才是实际需要的值
      const newEvent = Object.assign({}, e, { target: this._input });
      setTimeout(() => {
        this.setInputWidth(inputVal);
        this.props.onChange && this.props.onChange(newEvent);
      });
    } else {
      this.setInputWidth(inputVal);
    }
  };
  onKeyDown = e => {
    if (e.target.value === '') {
      let newPoi = this.state.inputPosition || 0;
      switch (e.keyCode) {
        case 37: {
          // handle Left
          newPoi -= 1;
          if (newPoi >= 0) {
            this.setState({ inputPosition: newPoi });
          }
          break;
        }
        case 39: {
          // handle Right
          newPoi += 1;
          const maxL = this.props.data.length;
          if (newPoi <= maxL) {
            this.setState({ inputPosition: newPoi });
          }
          break;
        }
        case 8: {
          // backspace
          if (newPoi) {
            this.setState({ inputPosition: newPoi - 1 });
            this.props.handleDelete && this.props.handleDelete(e, newPoi);
          }
          break;
        }
        case 46: {
          // delete
          if (newPoi !== this.props.data.length) {
            this.props.handleDelete && this.props.handleDelete(e, newPoi, true);
          }
          break;
        }
        default:
          break;
      }
    }
    this.props.onKeyDown && this.props.onKeyDown(e);
  };
  onFocus = e => {
    // this.setState({ isFocus: true })
    // if (this._isClickFocus) return
    this.props.onFocus && this.props.onFocus(e);
  };
  onBlur = e => {
    setTimeout(() => {
      this._isItemClick = false;
      this._isClickFocus = false;
    }, 450);
    // if (this._isItemClick || this._isClickFocus) {
    //   this._isItemClick = false
    //   this._isClickFocus = false
    //   return
    // }
    // this.setState({ isFocus: false })
    setTimeout(() => {
      this.props.onBlur && this.props.onBlur(e, this._isItemClick || this._isClickFocus);
    }, 250);
  };
  setValue = val => (this._input.value = val);
  getValue = () => this._input.value;
  setInputPoi = poi => this.setState({ inputPosition: poi });
  getInputPoi = () => {
    let p = this.state.inputPosition;
    p = typeIs(p, 'undefined') || p === null ? this.state.data.length : p;
    return p;
  };
  focus = () => this._input.focus();
  blur = () => this._input.blur();
  renderItems = () => {
    const { showKey, uniqueKey, defaultValue, inputClass, readOnly, placeholder, disabled, valiType, dataPath } =
      this.props;
    const { inputWidth } = this.state;
    let { inputPosition } = this.state;

    const data = isEmptyObj(this.state.data) || !typeIs(this.state.data, 'array') ? [] : this.state.data;
    const dataL = data.length;
    let content = [];
    const inputCls = classnames(`${prefixCls}_input`, {
      [inputClass]: inputClass,
      [`${inputPrefixCls}--${valiType}`]: valiType,
      [`${inputPrefixCls}--readonly`]: readOnly,
      [`${inputPrefixCls}--disabled`]: disabled,
    });
    const getInput = index => (
      <input
        style={{ width: inputWidth }}
        className={inputCls}
        key={`button_input_${index}`}
        data-path={dataPath}
        ref={r => (this._input = r)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        readOnly={readOnly}
        disabled={disabled}
        {...reactComposition({ onChange: this.onChange })}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        onFocus={this.onFocus}
      />
    );
    if (inputPosition > dataL || inputPosition < 0) {
      inputPosition = dataL;
    }
    if (dataL) {
      data.forEach((item, index) => {
        if (!item || item[uniqueKey] === '') {
          inputPosition === index && inputPosition === 0 && content.push(getInput(index));
          inputPosition === index + 1 && inputPosition !== 0 && content.push(getInput(index));
          return;
        }
        // 输入框在最后 且 非空时  最后一个显示逗号
        const button = (
          <Button
            type="default"
            useDefaultCls={false}
            classname={`${prefixCls}_item`}
            ref={r => (this.btnList[index] = r)}
            key={index}
            clickLimit={false}
            isAnimate={false}
            onClick={e => this.onItemClick(e, index)}
          >
            {`${item[showKey] || ''}${
              index === dataL - 1 && (defaultValue === '' || inputPosition !== dataL) ? '' : '，'
            }`}
          </Button>
        );
        inputPosition === index && inputPosition === 0 && content.push(getInput(index));
        content.push(button);
        inputPosition === index + 1 && inputPosition !== 0 && content.push(getInput(index));
      });
    } else {
      content = [getInput(0)];
    }
    return (
      <div ref={r => (this.btnParent = r)} className={`${prefixCls}_items`}>
        {content}
      </div>
    );
  };

  render() {
    // console.log('listinputrender')
    const { width = '100%', inputClass, onMouseDown, onMouseLeave, onMouseEnter, disabled, valiType } = this.props;
    const cls = classnames(`${prefixCls} ${inputClass}`, {
      // [`${prefixCls}_focus`]: this.state.isFocus,
      [`${prefixCls}_disabled`]: disabled,
      [`${prefixCls}--${valiType}`]: valiType,
    });
    return (
      <div
        value={this._input && this._input.value}
        className={cls}
        style={{ width }}
        onClick={this.onClick}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        {this.renderItems()}
      </div>
    );
  }
}
