import React from 'react';
import { PureInput as Input, Icon, DateTimePicker, PureCheckbox as Checkbox, Tips } from 'components';
import { REGULAR } from 'constants';
import { buildTipMixin, ot } from 'utils';
import { prefixCls } from './index.scss';
import { oNumCheck } from '../tool';

const dateTip = buildTipMixin('下次开单日期同本单日期');
const menualNumTip = buildTipMixin('临时手动单号，下次开单不自增！');

function renderHeader(page) {
  return function () {
    const { store } = page;
    const state = store.getState();
    const { orderNum, orderNumEditable, originOrderNum, manualNum, manualNumShow } = state;
    const { backJourney, backJourneyShow, backJourneyEditable } = state;
    const { billingDate, billingDateEditable, billingDateShow } = state;
    const { dateRange, minDate, maxDate } = state;
    const { orderCreatorName, orderCreatorNameShow } = state;
    const { setting, prevDate, isToday, prevDateEditable } = state;
    const setPrevDate = setting.previous_date;
    const repeatOp = setting.order_repeat ? setting.order_repeat.value : 'auto_up_by_rule';
    const oNumInclude = setting.o_num_include ? setting.o_num_include.value : ['char', 'capital', 'lower', 'spec'];

    const disabledDate = current => (dateRange ? minDate > current.valueOf() || current.valueOf() > maxDate : false);

    const { set } = page;
    const { usedFor, i18n } = page.props;

    return (
      usedFor !== 'detail' && (
        <header className={`${prefixCls} clearfix`}>
          {(usedFor === 'create' || usedFor === 'updatePre' || usedFor === 'pointTrans') && (
            <h1>{i18n.get('order_page_form_title', '货物托运单')}</h1>
          )}
          {usedFor === 'copy' && <h1>复制订单</h1>}
          <div className="fr">
            {orderCreatorNameShow && (
              <span className="creator">
                <label className="fn-label">
                  <Icon iconType="icon-avtor" tips={ot('制单员')} />
                </label>
                <span>{orderCreatorName}</span>
              </span>
            )}
            {billingDateShow && (
              <span className="bill-date">
                <label className="fn-label r">{ot('开单时间')}:</label>
                <DateTimePicker
                  classname={isToday}
                  defaultValue={billingDate}
                  showTime
                  disabled={!billingDateEditable}
                  disabledDate={disabledDate}
                  onChange={val => set('billingDate', val)}
                />
                {setPrevDate && setPrevDate.checked && (
                  <Checkbox
                    disabled={!prevDateEditable}
                    checked={prevDate}
                    onChange={e => set('prevDate', e.target.checked)}
                    {...dateTip}
                  />
                )}
              </span>
            )}
          </div>
          <div className="fl co-num">
            <label className="fn-label r">{ot('运单号')}</label>
            <Tips title={orderNum}>
              <Input
                data-path="order_num"
                required
                value={orderNum}
                pattern={REGULAR}
                customValidity={val => oNumCheck(val, oNumInclude, repeatOp, originOrderNum, usedFor)}
                disabled={!orderNumEditable}
                onChange={e => set('orderNum', e.target.value)}
              >
                {manualNumShow && (
                  <Checkbox
                    data-path="manual_num"
                    checked={manualNum}
                    onChange={e => set('manualNum', e.target.checked)}
                    {...menualNumTip}
                  />
                )}
              </Input>
            </Tips>

            {backJourneyShow && (
              <label className="fn-checkbox-label back-journey">
                <Checkbox
                  data-path="back_journey"
                  checked={+backJourney === 1}
                  disabled={!backJourneyEditable}
                  onChange={e => set('backJourney', e.target.checked ? 1 : 2)}
                />
                返程配货
              </label>
            )}
          </div>
        </header>
      )
    );
  };
}

export default renderHeader;
