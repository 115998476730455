import { getSumVal } from './getSumVal';
import { bigComputed } from 'utils';
import _ from 'lodash';

export function getFormulaVal(selectedRows, formula, format = 'percent_2') {
  let result;
  const operatorMap = {
    add: '+',
    sub: '-',
    mult: '*',
    divi: '/',
  };
  let expression = '';
  formula?.forEach((item, index) => {
    const { operator, itemList, ratio } = item;
    const operate = index > 0 ? ` ${operatorMap[operator]} ` : '';
    let sum = '';
    itemList?.forEach((key, keyIndex) => {
      let val = getSumVal(selectedRows, key);
      Number.isNaN(val) && (val = 0);
      sum += `${keyIndex > 0 ? ' + ' : ''}${val}`;
    });
    if (itemList.length > 1) {
      sum = `(${sum})`;
    }
    expression += `${operate}(${sum} * ${ratio})`;
  });
  try {
    result = eval(expression); // eslint-disable-line no-eval
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  if (Number.isNaN(result) || (result && !Number.isFinite(result))) result = 0;
  return formatVal(result, format);
}

function formatVal(val, format) {
  let result = val;
  switch (format) {
    case 'digits_0':
      result = _.round(val, 0);
      break;
    case 'digits_1':
      result = _.round(val, 1);
      break;
    case 'digits_2':
      result = _.round(val, 2);
      break;
    case 'digits_3':
      result = _.round(val, 3);
      break;
    case 'digits_4':
      result = _.round(val, 4);
      break;
    case 'percent_none':
      result = `${_.round(bigComputed([val, 100], 'times'))}%`;
      break;
    case 'percent_2':
      result = `${_.round(bigComputed([val, 100], 'times'), 2)}%`;
      break;
    default:
      break;
  }
  return result;
}
