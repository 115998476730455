/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { CardForm } from 'components';
import { getCardFormData, getComVal } from 'utils';
import { prefixCls } from './index.scss';
// cardForm 默认配置
const defaultG = {
  SelectDrop: {
    type: 'SelectDrop',
    valueKey: 'defaultSelected',
    eventNames: ['onClick', 'handleSelected', 'handleClick'],
    validate: [],
    otherProps: {
      data: [],
      disabled: false,
      defaultSelected: [],
      showKey: 'name',
      uniqueKey: 'key',
      filterKey: 'null',
      'data-path': '',
      formKey: '',
      isMultiple: false,
      isKeyboard: true,
    },
  },
  InputIcon: {
    type: 'InputIcon',
    valueKey: 'defaultValue',
    eventNames: ['onChange'],
    validate: [],
    otherProps: {
      iconProps: {},
      inputProps: {
        defaultValue: '',
      },
      classname: '',
      tips: '',
      disabled: false,
      readOnly: false,
      'data-path': '',
      spanContent: '',
      type: 'text',
      placeholder: '',
      defaultValue: '',
    },
  },
  Input: {
    type: 'Input',
    valueKey: 'defaultValue',
    eventNames: ['onChange', 'onBlur', 'onKeyDown'],
    validate: [],
    otherProps: {
      classname: '',
      tips: '',
      disabled: false,
      readOnly: false,
      'data-path': '',
      type: 'text',
      placeholder: '',
      defaultValue: '',
    },
  },
  CheckBox: {
    type: 'CheckBox',
    valueKey: 'checked',
    eventNames: ['onClick'],
    validate: [],
    otherProps: {
      label: '',
      name: '',
      disabled: false,
      tips: '',
      display: true,
      direction: 'left',
      'data-path': '',
      checked: false,
    },
  },
  Radio: {
    type: 'Radio',
    valueKey: 'value',
    eventNames: ['onChange'],
    validate: [],
    otherProps: {
      label: '',
      name: '',
      value: '',
      disabled: false,
      direction: 'left',
      'data-path': '',
      checked: false,
    },
  },
  Label: {
    type: 'Label',
    valueKey: 'children',
    otherProps: {
      classname: '',
      hasClass: false,
      children: '',
    },
  },
  Icon: {
    type: 'Icon',
    valueKey: 'iconType',
    eventNames: ['onClick'],
    otherProps: {
      classname: '',
      iconType: '',
    },
  },
  Button: {
    type: 'Button',
    valueKey: 'children',
    eventNames: ['onClick'],
    otherProps: {
      classname: '',
      children: '',
    },
  },
};
export default class Ccombiner extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {};
    this.events = {
      onChange: (...rest) => {
        const r = [].concat(rest);
        r[6] = 'onChange';
        this.handleEvents(...r);
      },
      handleSelected: (...rest) => {
        const r = [].concat(rest);
        r[6] = 'handleSelected';
        this.handleEvents(...r);
      },
      treeSelected: (...rest) => {
        const r = [].concat(rest);
        r[6] = 'treeSelected';
        this.handleEvents(...r);
      },
      onBlur: (...rest) => {
        const r = [].concat(rest);
        r[6] = 'onBlur';
        this.handleEvents(...r);
      },
      onFocus: (...rest) => {
        const r = [].concat(rest);
        r[6] = 'onFocus';
        this.handleEvents(...r);
      },
      onClick: (...rest) => {
        if (rest[0] === 'warning_type_icon') {
          return;
        }
        const r = [].concat(rest);
        r[6] = 'onClick';
        this.handleEvents(...r);
      },
      handleAddTodo: (...rest) => {
        const r = [].concat(rest);
        r[6] = 'handleAddTodo';
        this.handleEvents(...r);
      },
      handleIconClick: (...rest) => {
        const r = [].concat(rest);
        r[6] = 'handleIconClick';
        this.handleEvents(...r);
      },
      onKeyDown: (...rest) => {
        const r = [].concat(rest);
        r[6] = 'onKeyDown';
        this.handleEvents(...r);
      },
      handleHideCallback: (...rest) => {
        const r = [].concat(rest);
        r[6] = 'handleHideCallback';
        this.handleEvents(...r);
      },
      handleClick: (...rest) => {
        const r = [].concat(rest);
        r[6] = 'handleClick';
        this.handleEvents(...r);
      },
      // handleSelected: this.handleEvents,
      // treeSelected: this.handleEvents,
      // onBlur: this.handleEvents,
      // onFocus: this.handleEvents,
      // onClick: this.handleEvents,
      // handleAddTodo: this.handleEvents,
      // handleIconClick: this.handleEvents,
      // onKeyDown: this.handleEvents,
      // handleHideCallback: this.handleEvents,
      // handleClick: this.handleEvents
    };
    this.modeToCls = {
      right_in: 'right_in',
      left_in: 'left_in',
      right_out: 'right_out',
      left_out: 'left_out',
    };
  }

  static propTypes = {
    classname: PropTypes.string,
    eventNames: PropTypes.array,
    validate: PropTypes.array,
    data: PropTypes.any,
    handleEvents: PropTypes.func,
    useDefaultCls: PropTypes.bool,
    mode: PropTypes.string, // right/left _in/out  right_in
    dataPathIndex: PropTypes.number, // 光标路径组件序号
    dataPath: PropTypes.string,
    style: PropTypes.object,
  };
  static defaultProps = {
    classname: '',
    useDefaultCls: true,
    mode: 'right_in',
    dataPathIndex: 0,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.data !== nextProps.data && this._cardForm) {
      this.setState({
        data: nextProps.data,
      });
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      data: this.props.data,
    });
  }

  handleEvents = (_key, _value, _form, _data, saveAs, _type, eventName) => {
    const cValue = getCardFormData(_data);
    // Object.keys(cValue).forEach(key => {
    //   data[key] = setComVal(_data[key], cValue[key]).toJS() || {}
    //   data[key].value = cValue[key]
    // })
    // let { data } = this.props
    // Object.keys(cValue).forEach(key => data[key].value = cValue[key])
    this.props.handleEvents && this.props.handleEvents(cValue, _key, _value, _form, _data, _type, eventName);
  };
  getCardFormData = data => {
    const coms = {};
    const { dataPath, dataPathIndex } = this.props;
    Object.keys(data).forEach((key, i) => {
      // 组合中有不存在的情况
      const com = data[key];
      if (com.type === undefined) return;
      const defaultProp = defaultG[com.type];
      const { valueKey } = defaultProp;
      // 不要传value的形式
      const otherProps = Object.assign({}, defaultProp.otherProps, com.otherProps || {}, {
        [valueKey]: com.value || getComVal(com),
      });
      if (i === dataPathIndex && dataPath) {
        otherProps['data-path'] = dataPath;
      }
      const cardFormItem = Object.assign({}, defaultProp, {
        type: com.type,
        classname: `item__${i}`, // 每个组件样式
        otherProps,
      });
      delete cardFormItem.valueKey;
      coms[key] = cardFormItem;
    });
    return coms;
  };

  render() {
    const { classname, useDefaultCls, mode, style, dataPathIndex, dataPath } = this.props;
    const classes = classnames({
      // 最外层样式
      [prefixCls]: useDefaultCls,
      [classname]: classname,
    });
    const bodyCls = classnames({
      // 内层1样式
      [this.modeToCls[mode]]: this.modeToCls[mode],
    });
    const { data } = this.state;
    const cardFormProps = {
      ref: r => (this._cardForm = r),
      data: this.getCardFormData(data || {}),
      style,
      classname: classes,
      bodyClass: bodyCls,
      isHeader: false,
      useDefaultCls: false,
      handleEvents: { ...this.events },
    };
    return <CardForm {...cardFormProps} />;
  }
}
