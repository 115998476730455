/*
 * @Description: 司机或车辆列表删除的公共方法和配置
 * @Date: 2024-12-24 14:13:55
 */
import { fetchApi } from 'utils';
import React from 'react';

// 车辆，司机字段常量
export const ENUMS_TRUCK_DRIVER = {
  truckType: 'Truck',
  driverType: 'Driver',
  // 用于接口返回值获取车辆或司机的字段展示
  truckFeild: 'tr_num',
  driverFeild: 'name',
  truckLable: '车辆',
  driverLable: '司机',
};
/**
 * 检测传入的车辆或者司机是否有运单或共享
 * @param {*} truckOrDriverIds 车辆或者司机id [xxx,xxx,xxx]
 * @param {*} type Truck or Driver
 * @returns promise
 */
export const checkIdsHasBillOrShare = (truckOrDriverIds, type) => {
  return new Promise(resolve => {
    if (!truckOrDriverIds || truckOrDriverIds.length === 0 || !type) {
      resolve(null);
      return;
    }
    fetchApi('/Table/Search/deleteTruckAndDriverChecked', {
      method: 'POST',
      body: {
        req: {
          category: type,
          ids: truckOrDriverIds,
        },
      },
    })
      .then(({ res }) => {
        resolve(res);
      })
      .catch(e => {
        console.log(e);
        resolve(null);
      });
  });
};

/**
 * 获取匹配的车辆详情
 * @param {*} list 司机或车辆数组对象
 * @param {*} title 标题
 * @param {*} feild 从接口中获取的展示字段
 * @returns
 */
function getShowLabel({ list, title, feild }) {
  return (
    <div>
      <div style={{ color: '#000' }}>{title}</div>
      <div style={{ color: '#999', maxHeight: '200px', overflow: 'auto' }}>
        {list.map((item, index) => {
          return (
            <span key={item.tr_num}>
              <span style={{ wordBreak: 'keep-all' }}>{item[feild]}</span>
              {index === list.length - 1 ? '' : '，'}
            </span>
          );
        })}
      </div>
    </div>
  );
}

/**
 * 获取删除司机、车辆的确认内容
 * @param {*} hasBillOrShareRes 车辆或司机返回值 null || { from_type: [], batch: [] }
 * @param {*} type Truck or Driver
 * @returns ''
 */
export function getDelComfirmContent(hasBillOrShareRes, type) {
  const normalText = '您确定删除吗？';
  if (!hasBillOrShareRes) {
    return normalText;
  }
  // 找出共享的车辆
  const shareTruck = hasBillOrShareRes?.from_type || [];
  // 找出有运单的车
  const billTruck = hasBillOrShareRes?.batch || [];
  // 提示文案展示类型，车辆或司机
  let showTypeLabel = '';
  let extraLabel = '';
  // 接口返回值中需要获取的字段
  let feild = '';
  switch (type) {
    case ENUMS_TRUCK_DRIVER.truckType:
      showTypeLabel = ENUMS_TRUCK_DRIVER.truckLable;
      feild = ENUMS_TRUCK_DRIVER.truckFeild;
      extraLabel = '车牌';
      break;
    case ENUMS_TRUCK_DRIVER.driverType:
      showTypeLabel = ENUMS_TRUCK_DRIVER.driverLable;
      feild = ENUMS_TRUCK_DRIVER.driverFeild;
      break;
    default:
      break;
  }
  let shareContent = '';
  let billContent = '';
  // 有共享数据
  if (shareTruck && shareTruck.length > 0) {
    shareContent = getShowLabel({
      list: shareTruck,
      title: `以下${showTypeLabel}删除后，将自动取消共享关系`,
      feild,
    });
  }
  // 有运单关联
  if (billTruck && billTruck.length > 0) {
    billContent = getShowLabel({
      list: billTruck,
      title: `以下${showTypeLabel}近30天有运单记录，删除将不能按${extraLabel || showTypeLabel}查询运单`,
      feild,
    });
  }
  return shareContent || billContent ? [shareContent, billContent] : normalText;
}
