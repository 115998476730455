/**
 * 车辆管理页面。车辆管理和车辆使用记录页签
 */
import React from 'react';
import { PopTip, PopUp, ShowInfo, CheckBox, DialogDetail } from 'components';
import { CHECK, ERROR, INFO, WARN, REQUEST_PROC_STATUS } from 'constants';
import actions from 'actions';
import {
  alert,
  checkSelected,
  confirm,
  fetchApi,
  postJAVA,
  getPageKey,
  opTips,
  remDub,
  showInfo,
  sleep,
  viewImage,
  createTip,
  flatV1,
  g7Done,
} from 'utils';
import browserHistory from 'routes/history';
import TruckOperat from 'operation/listOperation/TruckOperat';
import TruckOperatNew from 'operation/listOperation/TruckOperat/ntocc';
import TruckRecordOp from 'operation/listOperation/TruckRecordOperate';
import Cert from 'operation/listOperation/PlatformOperat/components/Cert';
import _ from 'lodash';
import BatchOp from 'components/commoncomponents/BatchOp';
import TruckBatchEdit from 'operation/listOperation/TruckBatchEdit';
import { batchOpConfig } from './truckManagerOpConfig';
import { getUrlParams } from 'utils/utils';
import { INSURANCE_KEYS } from 'pages/CompanyController/CardWorkbench/constant.js';
import './truckManagerOp.scss';
import { checkIdsHasBillOrShare, ENUMS_TRUCK_DRIVER, getDelComfirmContent } from './commonOp/delTruckDriverConfig';

const popTip = (tips = '操作成功', type = INFO) => {
  new PopUp(PopTip, {
    type,
    content: tips,
  }).show();
};
export const viewImg = function (that, ele, rowIndex, column, data) {
  console.log(data, column, 'llll');
  const type = data.type || 'truck';
  const props = {
    type,
    path: data.path,
    name: data.name,
  };
  viewImage(props);
};
export const addTruck = function (that, ele, isCallBack, defaultValue) {
  const pageKey = getPageKey().pageConfig.key;
  const companyId = (defaultValue && defaultValue.company_id) || undefined;
  const tab = flatV1(_.get(that, 'tableInfo.tab', ''));
  const _req = {
    tab,
    truck_info: {
      truck_id: -1,
    },
    data: {
      tr_id: -1,
    },
    op_type: 'add',
    company_id: companyId,
  };
  actions.companyServer.postTruckMangerInfo(_req).then(res => {
    if (res.type === 'API_SUCCESS' && res.data.errno === 0) {
      const resUse = res.data || {};
      const parm = {
        tab,
        pageType: 'truckMangerList',
        opType: 'add',
        titleName: '新增车辆',
        listPageRef: that,
        truck_id: -1,
        isCallBack,
        defaultValue,
        fetchRes: resUse.res || {},
        popName: `updateTruck_${pageKey}`,
      };

      const dialog = new PopUp(TruckOperat, parm);
      dialog.show();
    } else {
      new PopUp(PopTip, {
        classname: 'pop_tip',
        type: ERROR,
        isShow: true,
        content: '网络错误！',
        autoDestroy: true,
      }).show();
    }
  });
};

// 批量加入运力池
export const addToFrightPool = that => {
  const selectsData = that.getStateSelecteRows() || [];
  let batchAuditPopup = null;
  let batchOpRef = null;
  let locateSwitch = 1; // 是否需要定位 0 不需要定位 1 需要定位
  let isCancelled = false;

  if (!checkSelected(selectsData)) return;

  // 批量操作弹窗
  const showBatchOP = originData => {
    const param = {
      title: '批量操作',
      tips: '加入运力池',
      tableWrapStyle: { width: '500px' },
      tableWidth: 500,
      dealHeaderWidth: 200,
      pace: 1000,
      header: {
        tr_num: { title: '车牌号', display: 'show', width: 120 },
      },
      data: originData,
      escBind: false,
      finishCallback: () => {
        isCancelled = true;
        that.handleRefreshData();
      },
      customSendRequest: (reqIndex, p) => {
        // 批量加入运力池的操作没有中途取消的逻辑，所以发起批量操作后，就认为操作已完成
        batchOpRef?.setState({ isFinish: true });
        return postJAVA('/ge-truck/truck/freight_pool/batch/create', p).then(res => res);
      },
      async customDealMultiRes({ resData, that: thisComponent }) {
        if (resData.error) return showInfo(ERROR, resData.errmsg);
        if (isCancelled) return;
        postJAVA('/ge-truck/truck/freight_pool_create_result/batch/query', { identifier: resData.res }).then(res => {
          res = res.res || [];
          let currentIdx = 0;
          let sucNum = 0;
          let failNum = 0;
          const hashMap = {};
          for (let i = 0; i < res.length; i++) {
            if (res[i].status === 1 && currentIdx === 0) currentIdx = i;
            hashMap[res[i].tr_num] = res[i];
          }
          const newList = originData.map(item => {
            const status = hashMap[item.tr_num]?.status ?? 1;
            // 0 操作成功 1 正在处理中 2 操作失败
            let status_icon = '';
            let status_text = '';
            if (status === 0) {
              sucNum++;
              status_icon = 'icon-check-o';
              status_text = REQUEST_PROC_STATUS.suc;
            }
            if (status === 1) {
              status_icon = 'icon-info-o';
              status_text = REQUEST_PROC_STATUS.doing;
            }
            if (status === 2) {
              failNum++;
              status_icon = 'icon-error-o';
              status_text = hashMap[item.tr_num]?.message ?? REQUEST_PROC_STATUS.fail;
            }
            return {
              ...item,
              status_icon,
              status_text,
            };
          });
          thisComponent.setState({ currentIdx, sucNum, failNum, data: [...newList] });
          if (sucNum + failNum < originData.length) {
            setTimeout(() => {
              this.customDealMultiRes({ resData, that: thisComponent });
            }, 1000);
          }
        });
      },
      getPara: dataList => {
        return {
          locateSwitch,
          id: dataList.map(item => item.id),
        };
      },
      getBatchOpRef: ref => {
        batchOpRef = ref;
      },
    };
    batchAuditPopup = new PopUp(BatchOp, param);
    batchAuditPopup.show();
  };

  // 二次确认弹窗
  new PopUp(ShowInfo, {
    classname: 'detail_info',
    isShow: true,
    type: WARN,
    title: '操作确认',
    buttons: [
      {
        key: 'cancel',
        name: '取消',
        btnType: 'default',
        cb: dialog => {
          dialog.handleHide();
        },
      },
      {
        key: 'confirm',
        name: '继续',
        btnType: 'primary',
        cb: dialog => {
          dialog.handleHide();
          showBatchOP(selectsData);
        },
      },
    ],
    content: (
      <DialogDetail
        noticeTitle={<span />}
        ext={{
          specialTips: '加入运力池前请确保车辆关联主驾司机及手机号信息完整',
          reason: (
            <div style={{ color: 'black' }}>
              <label>
                <CheckBox
                  checked
                  onChange={val => {
                    locateSwitch = val ? 1 : 0;
                  }}
                />
                所选车辆需要定位
              </label>
            </div>
          ),
        }}
      />
    ),
  }).show();
};

// 编辑车辆
export const updateTruck = function (that, truckId, tabKey) {
  const pageKey = getPageKey().pageConfig.key;
  const tab = tabKey || flatV1(_.get(that, 'tableInfo.tab'));
  const drComId = truckId || that.getSelectesKey('id');

  // 平台车辆管理
  if (tab === 'platform_truck') {
    const trNum = that.getSelectesKey('tr_num');
    if (drComId.length === 1) {
      actions.companyServer
        .postTruckMangerInfo({
          tab,
          truck_info: { truck_id: drComId[0] },
          data: { truck_id: drComId[0] },
          op_type: 'update',
        })
        .then(res => {
          if (res.type === 'API_SUCCESS') {
            const resUse = res.data || {};
            if (resUse.errno === 0) {
              const parm = {
                tab,
                pageType: 'truckMangerList',
                opType: 'update',
                titleName: '修改车辆',
                listPageRef: that,
                truck_id: drComId,
                fetchRes: resUse.res || {},
                popName: `updateTruck_${pageKey}`,
              };
              const dialog = new PopUp(TruckOperat, parm);
              dialog.show();
            } else {
              popTip(resUse.errmsg, ERROR);
            }
          } else {
            popTip('网络错误', ERROR);
          }
        });
    } else {
      for (let i = 0; i < trNum.length; i++) {
        if (trNum[i] !== trNum[0]) {
          showInfo(WARN, '只能对相同车牌号车辆操作修改!');
          return;
        }
      }
    }
    // const truckId = that.getSelectesKey('status')
    // console.log(truckId.find(item => +item === 2))
    // if (truckId.find(item => +item === 2).length !== 0) {
    //   showInfo(WARN, '只有已认证且未上报成功的数据才能修改!')
    //   return
    // }
  }
  // 车辆认证
  if (tab === 'truck') {
    if (!checkSelected(drComId, true)) return;
  }

  const truck_id = drComId[0];

  actions.companyServer
    .postTruckMangerInfo({
      tab,
      truck_info: { truck_id },
      data: { truck_id },
      op_type: 'update',
    })
    .then(res => {
      if (res.type === 'API_SUCCESS') {
        const resUse = res.data || {};
        if (resUse.errno === 0) {
          const certStatus = resUse?.res?.truck_info?.wh_cert_status_new || 0;
          const isCerted = [1, 2, 3].indexOf(+certStatus) > -1; // 认证过

          const parm = {
            tab,
            pageType: 'truckMangerList',
            opType: 'update',
            titleName: '修改车辆',
            listPageRef: that,
            truck_id: drComId,
            fetchRes: resUse.res || {},
            popName: `updateTruck_${pageKey}`,
            isCerted,
          };
          const dialog = new PopUp(tab === 'cert' && g7Done() ? TruckOperatNew : TruckOperat, parm);
          dialog.show();
        } else {
          popTip(resUse.errmsg, ERROR);
        }
      } else {
        popTip('网络错误', ERROR);
      }
    });
};
export const truckTrNum = function (that, ele, col, opitons = {}) {
  const pageKey = getPageKey().pageConfig.key;
  const table = that.getData();
  const { category } = that.tableInfo || {};
  const tab = flatV1((that.tableInfo || {}).tab);

  const rowData = table[ele] || {};
  let trId = rowData.tr_id || rowData.id;
  if (col === 'cost_tr_num') {
    trId = rowData.cost_tr_id;
  }
  const _req = {
    tab,
    truck_info: {
      truck_id: trId,
    },
    data: {
      truck_id: trId,
    },
    op_type: 'view',
  };
  actions.companyServer.postTruckMangerInfo(_req).then(res => {
    if (res.type === 'API_SUCCESS') {
      const resUse = res.data || {};

      if (resUse.errno === 0) {
        const { operateProps = {} } = opitons;
        const parm = {
          category,
          tab,
          pageType: 'truckMangerList',
          opType: 'view',
          titleName: '查看车辆',
          listPageRef: that,
          truck_id: trId,
          fetchRes: resUse.res || {},
          popName: `updateTruck_${pageKey}`,
          tabProps: {
            truck_record: {
              canOp: false,
            },
          },
          ...operateProps,
          // defaultActiveTab: 'tr_carmaintenance',
        };

        const dialog = new PopUp(tab === 'cert' && g7Done() ? TruckOperatNew : TruckOperat, parm);
        dialog.show();
      } else {
        popTip(res.data.errmsg, ERROR);
      }
    } else {
      new PopUp(PopTip, {
        classname: 'pop_tip',
        type: ERROR,
        isShow: true,
        content: '网络错误！',
        autoDestroy: true,
      }).show();
    }
  });
};

// 车辆管理 - 二维码超链接
export const truckQrCode = (that, index) => {
  const table = that.getData();
  const _data = table[index];
  const trNum = _data.tr_num || '';
  const trId = _data.tr_id || _data.id || '';
  const company_id = _data.company_id || window.company_id || '';
  const url = encodeURIComponent(
    `${window.location.origin}/static/h5/index.html#/car/condition?tr_num=${trNum}&tr_id=${trId}&group_id=${window.group_id}&company_id=${company_id}`,
  );
  const src = `/api/Trade/Base/qrCode?url=${url}`;
  const props = {
    src,
    width: '280px',
    height: '280px',
    title: '车辆二维码',
    showDownloadButton: true,
    download: `${trNum}.jpg`,
  };
  viewImage(props);
};
export const deleteTruck = async function (that) {
  const _drComIds = that.getSelectesKey('id');
  const { tab } = that.tableInfo;
  if (!_drComIds.length) {
    showInfo(ERROR, '请选择一条数据！');
    return;
  }
  // if (!checkSelected(_drComIds, true)) return
  const table = that.getData();
  // console.log(table, _drComIds, 'mmmmm');
  const seletedList = [];
  table.map((item, i) => {
    const list = JSON.parse(JSON.stringify(item));
    if (_drComIds.indexOf(item.id) > -1) {
      list.wh_cert_status = `wh_cert_status_${item.wh_cert_status}`;
      seletedList.push(list);
    }
    return item;
  });
  const disabled_driver =
    (window.company_setting.tr_source_disable_driver && window.company_setting.tr_source_disable_driver.selc) || [];
  const dis_list = [];
  const deleteId = [];
  // console.log(seletedList, disabled_driver, 'mmmmmmmm');
  if (disabled_driver.length > 0) {
    seletedList.map((item, i) => {
      if (disabled_driver.indexOf(item.wh_cert_status) > -1) {
        dis_list.push(item);
      } else {
        deleteId.push(item.id);
      }
      return item;
    });
  } else {
    seletedList.map((item, i) => {
      deleteId.push(item.id);
      return item;
    });
  }

  const contentNotice = (
    <div className="carry-set-check">
      <h2 className="marginBottom20">以下车辆不可删除，是否继续删除其他车辆？</h2>
      <div className="reason-content">
        <div className="reason-red">原因说明：</div>
        <div className="reason-red name">司机：</div>
        <div className="reason-name">
          {dis_list.length > 0 &&
            dis_list.map((item, i) => (
              <span className="reason-span">
                {item.tr_num} {i + 1 === dis_list.length ? null : ','}
              </span>
            ))}
        </div>
      </div>
    </div>
  );

  const confirmDeleteTruck = (thisDialog, truckIds, cb) =>
    actions.companyServer
      .postTruckMangerAM({
        tab,
        truck_info: {
          comId: truckIds,
        },
        data: {
          truck_id: truckIds,
        },
        opType: 'delete',
        comId: truckIds,
      })
      .then(res => {
        cb && cb?.();
        if (res.type === 'API_SUCCESS') {
          const resData = res.data;
          const pureRes = resData.res || {};
          if (resData.errno === 0 && pureRes.failed_detail) {
            opTips({
              orderType: '车辆',
              opType: '删除',
              resData,
              sucCallback: () => {
                thisDialog.handleHide();
                new PopUp(PopTip, {
                  classname: 'pop_tip',
                  type: CHECK,
                  isShow: true,
                  content: '删除成功！',
                  autoDestroy: true,
                }).show();
                setTimeout(() => that.handleRefreshData(), 2000);
                return true;
              },
              showSucTips: false,
              continueCallback: (sucId, callBack) => {
                // cb?.();
                confirmDeleteTruck(thisDialog, sucId, callBack);
              },
              ext: {
                failedCallback: () => {
                  thisDialog.handleHide();
                  setTimeout(() => that.handleRefreshData(), 500);
                },
                // specialTips: specTips,
              },
              tips: '删除',
            });
            return false;
          }
          if (res.data.errno === 0) {
            new PopUp(PopTip, {
              classname: 'pop_tip',
              type: CHECK,
              isShow: true,
              content: '删除成功！',
              autoDestroy: true,
            }).show();
            thisDialog.handleHide();
            setTimeout(() => that.handleRefreshData(), 500);
          } else {
            popTip(res.data.errmsg, ERROR);
            thisDialog.changeBtnLoadingStatus('confirm', false);
          }
        } else {
          popTip('删除失败，请重新删除！', ERROR);
          thisDialog.changeBtnLoadingStatus('confirm', false);
        }
      })
      .catch(() => {
        thisDialog.handleHide();
      });
  // 检测被删除的id是否有运单
  that.pageStartLoading();
  const hasBillOrShareRes = await checkIdsHasBillOrShare(deleteId, ENUMS_TRUCK_DRIVER.truckType);
  that.pageStopLoading();
  const confirmContent = getDelComfirmContent(hasBillOrShareRes, ENUMS_TRUCK_DRIVER.truckType);
  const popup = new PopUp(ShowInfo, {
    classname: 'del-customer',
    isShow: true,
    type: WARN,
    content: confirmContent,
    autoDestroy: true,
    title: '删除',
    simpleMode: {
      confirm: thisDialog => confirmDeleteTruck(thisDialog, deleteId),
      cancel: thisDialog => thisDialog.handleHide(),
    },
    closable: true,
    maskClosable: true,
  });
  const popupTip = new PopUp(ShowInfo, {
    classname: 'del-customer',
    isShow: true,
    type: WARN,
    content: contentNotice,
    autoDestroy: true,
    title: '操作确认',
    simpleMode: {
      confirm: thisDialog => {
        // thisDialog.changeBtnLoadingStatus('confirm', true)
        thisDialog.handleHide();
        popup.show();
      },
      cancel: thisDialog => thisDialog.handleHide(),
    },
    closable: true,
    maskClosable: true,
  });
  if (dis_list.length > 0) {
    if (deleteId.length > 0) {
      popupTip.show();
    } else {
      createTip('选中的车辆不允许删除！', WARN).show();
    }
  } else {
    popup.show();
  }
};
export const viewTrPos = function (that) {
  const _comIds = that.vTable.getStateSelectes();
  if (_comIds.length < 1) {
    new PopUp(PopTip, {
      classname: 'pop_tip',
      type: ERROR,
      isShow: true,
      content: '请选择要查看位置的车辆！',
      autoDestroy: true,
    }).show();
    return false;
  } else if (_comIds.length > 1) {
    new PopUp(PopTip, {
      classname: 'pop_tip',
      type: ERROR,
      isShow: true,
      content: '只能选择一个车辆！',
      autoDestroy: true,
    }).show();
    return false;
  }
  const id = that.getSelectesKey('id');
  browserHistory.pushWithQuery({
    pathname: '/Company/TruckMonitor',
    query: { car_id: id[0] },
  });
};

async function truckCertFetch(that, id, isCheck = 1) {
  await sleep(0);
  const continueCb = async () => {
    const data = that.getStateSelecteRows().map(t => {
      let tr_colo_text = '黄色';
      if (t.tr_color === '2') {
        tr_colo_text = '蓝色';
      } else if (t.tr_color === '3') {
        tr_colo_text = '蓝色';
      }
      return {
        ...t,
        tr_colo_text,
      };
    });
    new PopUp(BatchOp, {
      title: '车辆认证进度',
      requestUrl: '/Basic/Truck/cert',
      tips: '批次认证',
      data: [...data],
      header: {
        tr_num: { title: '车牌号', type: 'Text', display: 'show', width: 45, align: 'center', flexGrow: 1 },
        tr_colo_text: { title: '车牌颜色', type: 'Text', display: 'show', width: 45, align: 'center' },
      },
      prefixalTips: '',
      bodyCellStyle: { lineHeight: '30px' },
      indexCellStyle: { lineHeight: '30px' },
      getPara: dataList => {
        const rowData = dataList[0];
        return { id: rowData.id };
      },
      paceCallback: async res => {
        if (res.errno !== 0) {
          that.enableMenuButton && that.enableMenuButton('driver_cert');
          showInfo(ERROR, res.errmsg);
          return false;
        }
      },
      finishCallback: (...rest) => {
        setTimeout(() => {
          that.handleRefreshData();
          that.enableMenuButton && that.enableMenuButton('driver_cert');
        }, 2000);
      },
      escBind: false,
    }).show();
  };
  continueCb();
}
export const truckCert = function (that) {
  const comSet = window.company_setting || {};
  const appKey = comSet.app_key || {};
  const appSecret = comSet.app_secret || {};
  const { hide_cmm } = window.logo_config;

  if (!appKey.value || !appSecret.value) {
    const contentNotice = (
      <div className="carry-set-check">
        <div className="reason-red">原因说明：</div>
        <p>开通结算服务后才可使用此功能{!hide_cmm && '，如需开通或了解此项服务，请联系G7易流客服 400-611-5656'}！</p>
      </div>
    );
    alert(ERROR, '您还未开通结算服务，请先开通！', { confirm: '关闭' }, contentNotice);
    return false;
  }

  const data = that.getSelectesKey('id');
  if (data.length < 1) {
    showInfo(ERROR, '请选择要操作的车辆！');
    return false;
  }

  const companyIds = that.getSelectesKey('company_id');
  const checkComId = comId => {
    if (comId.length > 1) {
      showInfo(ERROR, '不能操作多网点的数据！');
      return false;
    }
    return true;
  };
  const _comId = remDub(companyIds); // 去重
  if (!checkComId(_comId)) return;

  truckCertFetch(that, data);
};
// 平台车辆管理 - 认证操作列
export const doTruckPlatformCert = (that, index) => {
  const ids = that.getSelectesKey('id');
  if (!ids.length) {
    showInfo(WARN, '请选择要操作的数据！');
    return;
  }
  const op = truck_ids => {
    postJAVA('/cmm-basic/truck/handleAttestationOfPlatform/batch', { ids: truck_ids }).then(res => {
      if (truck_ids.length === 1 && res.res === null) {
        const list = that.getData();
        const statusCert = list.find(item => +item.wh_cert_status !== 1 || +item.wh_cert_status !== 2);
        if (statusCert.length >= 1) {
          showInfo(ERROR, '只有认证中或认证失败的数据才能操作认证');
          return false;
        }
        const req = {
          ids: truck_ids,
        };
        const param = {
          className: 'platform-truck-cert',
          reqData: req,
          title: '车辆认证',
          opType: 'truck',
          passCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
          refuseCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
        };
        const dialog = new PopUp(Cert, param);
        dialog.show();
      } else if (res.res === null) {
        const list = that.getData();
        const statusCert = list.find(item => +item.wh_cert_status !== 1 || +item.wh_cert_status !== 2);
        if (statusCert.length >= 1) {
          showInfo(ERROR, '只有认证中或认证失败的数据才能操作认证');
          return false;
        }
        const req = {
          ids: truck_ids,
        };
        const param = {
          className: 'platform-truck-cert',
          reqData: req,
          title: '车辆认证',
          opType: 'truck',
          passCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
          refuseCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
        };
        const dialog = new PopUp(Cert, param);
        dialog.show();
      } else {
        opTips({
          orderType: '车辆',
          opType: '车辆',
          resData: res,
          continueCallback: (sucId, cb) => {
            cb();
            // op(ids.filter(id => sucId.includes(+id)))
            const list = that.getData();
            const statusCert = list.find(item => +item.wh_cert_status !== 1 || +item.wh_cert_status !== 2);
            if (statusCert.length >= 1) {
              showInfo(ERROR, '只有认证中或认证失败的数据才能操作认证');
              return false;
            }
            const req = {
              ids: sucId,
            };
            const param = {
              className: 'platform-truck-cert',
              reqData: req,
              title: '司机认证',
              opType: 'truck',
              passCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
              refuseCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
            };
            const dialog = new PopUp(Cert, param);
            dialog.show();
          },
        });
      }
      // if (res.errno) {
      //   opTips({
      //     orderType: '车辆认证',
      //     opType: '认证',
      //     resData: res,
      //     continueCallback: (sucId, cb) => {
      //       cb()
      //     }
      //   })
      // } else {
      //   const list = that.getData()
      //   const id = list[index].id
      //   const wh_cert_status = list[index].wh_cert_status
      //   if (+wh_cert_status !== 1 || +wh_cert_status !== 2) {
      //     showInfo(ERROR, '只有认证中或认证失败的数据才能操作认证')
      //     return false
      //   }
      //   const req = {
      //     id
      //   }
      //   const param = {
      //     className: 'platform-truck-cert',
      //     reqData: req,
      //     title: '车辆认证',
      //     opType: 'truck',
      //     passCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
      //     refuseCallback: () => setTimeout(() => that.handleRefreshData && that.handleRefreshData(), 2000),
      //   }
      //   const dialog = new PopUp(Cert, param)
      //   dialog.show()
      // }
    });
  };

  op(ids);
};

// 平台车辆管理 - 上报操作
export const truckPlatformLogink = async (that, selectKey) => {
  const selectData = that.getStateSelecteRows();
  const batchOpData = selectData.map(item => {
    const obj = { ...item };
    // 获取 车牌颜色 的值 (key => value)
    if (item.tr_color) {
      const trColorEnum = that.getEnumItem('tr_color', item.tr_color);
      obj.tr_color_text = trColorEnum[0].name;
    }
    return { ...obj };
  });
  console.log(batchOpData);
  const ids = that.getSelectesKey('id');
  if (!ids.length) {
    showInfo(WARN, '请选择要操作的数据！');
    return;
  }
  // 批量处理 参数
  const param = {
    isJAVA: true,
    title: '车辆上报',
    requestUrl: '/cmm-basic/wlhy/report/mancar',
    tips: '1213',
    header: {
      tr_num: { title: '车牌号', display: 'show' },
      tr_color_text: { title: '车牌颜色', display: 'show' },
    },
    data: batchOpData,
    escBind: false,
    getPara: dataList => ({ report_id: dataList[0].id, is_check: 1, report_type: 'wlhycl' }),
    paceCallback: callbackRes => {
      console.log(callbackRes);
      if (+callbackRes.errno !== 0) {
        that.enableMenuButton && that.enableMenuButton(selectKey);
        callbackRes.errmsg && showInfo(ERROR, callbackRes.errmsg);
        return false;
      }
    },
    finishCallback: () => {
      setTimeout(() => {
        that.enableMenuButton && that.enableMenuButton(selectKey);
        that.handleRefreshData();
      }, 2000);
    },
  };
  const batchOpDialog = new PopUp(BatchOp, param);
  batchOpDialog.show();
};

// 车辆管理 - 提交认证
export const truckPlatformCert = async (that, selectKey) => {
  // 获取所选中的数据
  const selectData = that.getStateSelecteRows();
  if (!checkSelected(selectData)) return;

  const ids = selectData.map(item => item.id);
  // 后端指定一个错误码，提示未开通网货服务。
  const req = { ids, is_check: 1 };
  const res = await postJAVA('/cmm-basic/truck/commitAttestationOfPlatform', req);
  if (+res.errno === -10000) {
    const contentNotice = (
      <div className="carry-set-check">
        <div className="reason-red">原因说明：</div>
        <p>开通网货服务后才可使用此功能，如需开通或了解此项服务，请联系G7易流客服 400-611-5656！</p>
      </div>
    );
    alert(WARN, '您还未开通网货服务，请先开通！', { confirm: '关闭' }, contentNotice);
    return false;
  }

  // 批量处理
  that.disableMenuButton && that.disableMenuButton(selectKey);
  const batchOpData = selectData.map(item => {
    const obj = { ...item };
    // 获取 车牌颜色 的值 (key => value)
    if (item.tr_color) {
      const trColorEnum = that.getEnumItem('tr_color', item.tr_color);
      obj.tr_color_text = trColorEnum[0].name;
    }
    return { ...obj };
  });
  // 批量处理 参数
  const param = {
    isJAVA: true,
    title: batchOpConfig[selectKey].title,
    requestUrl: batchOpConfig[selectKey].url,
    tips: batchOpConfig[selectKey].tips,
    header: batchOpConfig[selectKey].header,
    data: batchOpData,
    escBind: false,
    getPara: dataList => ({ ids: [dataList[0].id], is_check: 0 }),
    paceCallback: callbackRes => {
      if (+callbackRes.errno !== 0) {
        that.enableMenuButton && that.enableMenuButton(selectKey);
        callbackRes.errmsg && showInfo(ERROR, callbackRes.errmsg);
        return false;
      }
    },
    finishCallback: () => {
      setTimeout(() => {
        that.enableMenuButton && that.enableMenuButton(selectKey);
        that.handleRefreshData();
      }, 2000);
    },
  };
  const batchOpDialog = new PopUp(BatchOp, param);
  batchOpDialog.show();
};
// 车辆管理 - 使用记录按钮
export const truckUsedRecord = that => {
  const trNumList = that.getSelectesKey('tr_num');
  that.handleChangeTabs('truck_record', {
    defaultFilter: {
      tr_num: trNumList || '',
    },
    designateFilter: {
      tr_num: trNumList || '',
    },
    shouldRefresh: true,
  });
};

/* 车辆使用记录页签 */
// 添加车辆使用记录
export const addTruckRecord = async that => {
  const pageKey = getPageKey().pageConfig.key;
  const tab = flatV1((that.tableInfo || {}).tab);
  const url = 'Basic/TruckRecord/get';
  const tabTypeMap = {
    truck_record: 10,
    truck_stop_record: 20,
  };
  const conf = { method: 'POST', body: { req: { type: tabTypeMap[tab] } } };
  const res = await fetchApi(url, conf);
  const enums = _.get(res, 'res.enums', []);
  const settings = _.get(res, 'res.settings', {});
  const popupParam = {
    popName: `slide_drag_${pageKey}`,
    tab,
    enums,
    settings,
    title: '新增',
    opType: 'add',
    isShow: true,
    dragerContainer: that,
    listPageRef: that,
  };
  const dialog = new PopUp(TruckRecordOp, popupParam);
  dialog.show();
};
// 车辆使用记录 - 批量审核 & 批量取消审核
export const truckRecordAuditOp = async (that, btnKey) => {
  const data = that.getStateSelecteRows();
  if (!data.length) return showInfo(ERROR, '请选择要操作的数据！');
  new PopUp(BatchOp, {
    title: btnKey === 'truck_record_audit' ? '批量审核' : '批量取消审核',
    isJAVA: true,
    requestUrl:
      btnKey === 'truck_record_audit' ? '/cmm-basic/truck_record/audit' : '/cmm-basic/truck_record/cancelAudit',
    tips: btnKey === 'truck_record_audit' ? '批量审核' : '批量取消审核',
    data,
    header: {
      tr_num: { title: '车牌号', type: 'Text', display: 'show', width: 45, align: 'center', flexGrow: 1 },
      record_user_name: { title: '登记人', type: 'Text', display: 'show', width: 45, align: 'center', flexGrow: 1 },
      create_time: { title: '登记时间', type: 'Text', display: 'show', width: 45, align: 'center', flexGrow: 1 },
    },
    prefixalTips: '',
    bodyCellStyle: { lineHeight: '30px' },
    indexCellStyle: { lineHeight: '30px' },
    getPara: dataList => {
      const rowData = dataList[0];
      return { id: rowData.id };
    },
    paceCallback: async res => {
      if (res.errno !== 0) {
        that.enableMenuButton && that.enableMenuButton(btnKey);
        showInfo(ERROR, res.errmsg);
        return false;
      }
    },
    finishCallback: (...rest) => {
      setTimeout(() => {
        that.handleRefreshData();
        that.enableMenuButton && that.enableMenuButton(btnKey);
      }, 2000);
    },
    escBind: false,
  }).show();
};

// 修改车辆使用记录
export const updateTruckRecord = async that => {
  const ids = that.getSelectesKey('id');
  if (!checkSelected(ids, true)) return;
  const pageKey = getPageKey().pageConfig.key;
  const tab = _.get(that, 'tableInfo.tab', '');
  const url = 'Basic/TruckRecord/get';
  const conf = { method: 'POST', body: { req: { id: +ids[0] } } };
  const res = await fetchApi(url, conf);
  const enums = _.get(res, 'res.enums', []);
  const data = _.get(res, 'res.info', []);
  const settings = _.get(res, 'res.settings', {});
  enums.truck = [{ key: data.tr_id, name: data.tr_num }];
  const popupParam = {
    popName: `slide_drag_${pageKey}`,
    tab,
    data,
    enums,
    settings,
    id: +ids[0],
    title: '修改',
    opType: 'update',
    isShow: true,
    dragerContainer: that,
    listPageRef: that,
  };
  const dialog = new PopUp(TruckRecordOp, popupParam);
  dialog.show();
};
// 点击车牌号的查看详情
export const truckRecordTrNum = async (that, index) => {
  const pageKey = getPageKey().pageConfig.key;
  const table = that.getData();
  const id = +table[index].id;
  const { category, tab } = that.tableInfo || {};
  const url = 'Basic/TruckRecord/get';
  const conf = { method: 'POST', body: { req: { id } } };
  const res = await fetchApi(url, conf);
  const pic = res?.res?.info?.pic || {};
  for (const key in pic) {
    pic[key].type = 'truck_record';
  }
  const enums = _.get(res, 'res.enums', []);
  const data = _.get(res, 'res.info', []);
  const settings = _.get(res, 'res.settings', {});
  enums.truck = [{ key: data.tr_id, name: data.tr_num }];
  const popupParam = {
    popName: `slide_drag_${pageKey}`,
    category,
    tab,
    data,
    enums,
    settings,
    id,
    title: '查看',
    opType: 'view',
    isShow: true,
    dragerContainer: that,
    listPageRef: that,
  };
  const dialog = new PopUp(TruckRecordOp, popupParam);
  dialog.show();
};
// 删除车辆使用记录
export const deleteTruckRecord = async that => {
  const ids = that.getSelectesKey('id');
  if (!checkSelected(ids)) return;

  const contentTxt = '确定要删除选中的跟进记录吗？';
  const contentNoticeElem = <div style={{ color: 'red', marginLeft: '68px' }}>删除后不可恢复，请谨慎操作！</div>;
  if (!(await confirm(WARN, contentTxt, {}, '删除司机跟进记录', contentNoticeElem))) {
    return false;
  }
  const url = 'Basic/TruckRecord/delete';
  const conf = { method: 'POST', body: { req: { ids } } };
  const res = await fetchApi(url, conf);
  if (+res.errno !== 0) {
    res.errmsg && showInfo(ERROR, res.errmsg);
    return;
  }
  showInfo(CHECK, res.errmsg || '删除成功！');
  setTimeout(() => that.handleRefreshData(), 500);
};

export const reportBatch = (that, rowIndex, columnKey, column, data) => {
  console.log(that, rowIndex, columnKey, column);
  const pack = {
    designateParam: {},
    designateFilter: {
      car_batch: data.split(','),
    },
  };
  const action = {
    controller: 'Operate',
    action: 'carStowage',
    injectProps: pack,
  };
  window.$app.direct(action);
};

export const truckBatchUpdate = async (listPage, btnKey) => {
  const id = listPage.getSelectesKey('id');
  const { category, tab } = listPage.tableInfo || {};
  if (id.length < 1) {
    showInfo(ERROR, '请您选择一条数据！');
    return false;
  }
  new PopUp(TruckBatchEdit, { id, category, tab, listPage, btnKey, popName: 'truckBatchEditDg' }).show();
};

export const truckRecordSlider = async (listPage, tab) => {
  const trAry = listPage.getStateSelecteRows();
  if (trAry.length > 1) {
    return showInfo(WARN, '只能选择一条数据！');
  }

  const pageKey = getPageKey().pageConfig.key;
  // const { category, tab } = (listPage.tableInfo ||     {})
  const url = 'Basic/TruckRecord/get';
  const tabTypeMap = {
    truck_record: 10,
    truck_stop_record: 20,
  };
  const conf = { method: 'POST', body: { req: { type: tabTypeMap[tab] } } };
  const res = await fetchApi(url, conf);
  const resData = res.res || {};
  const enums = resData.enums || {};
  const settings = _.get(res, 'res.settings', {});
  const popupParam = {
    popName: `slide_drag_${pageKey}`,
    tab,
    enums,
    settings,
    title: '新增',
    opType: 'add',
    isShow: true,
    dragerContainer: listPage,
    listPageRef: listPage,
  };

  if (!trAry.length) {
    return new PopUp(TruckRecordOp, popupParam).show();
  }

  const trData = trAry[0] || {};
  const trId = trData.id || '';
  const trNum = trData.tr_num || '';

  const srcEnumRes = listPage.getTableEnum('source');
  const comIdEnumRes = listPage.getTableEnum('company_id');
  const userCorpTypeEnumRes = listPage.getTableEnum('use_corp_type');

  const comSet = window.company_setting || {};
  const { record_type = {}, stop_driver_type = {} } = comSet;
  const enumSet = tab === 'truck_record' ? record_type : stop_driver_type;
  const recordTypeEnum = enumSet.selc_value || [];

  const mergeInfo = {
    enums: {
      truck: [{ id: trId, key: trId, tr_num: trNum, name: trNum }],
      source: Array.isArray(srcEnumRes) ? srcEnumRes : [],
      record_type: recordTypeEnum.map(x => ({ ...x, name: x.value })),
      company: (Array.isArray(comIdEnumRes) ? comIdEnumRes : []).map(x => ({ ...x, key: `${x.id}`, name: x.display })),
      use_corp_type: Array.isArray(userCorpTypeEnumRes) ? userCorpTypeEnumRes : [],
    },
    data: {
      tr_id: trId,
      source: trData.source || '',
      company: trData.company_id || '',
      use_corp_type: trData.use_corp_type || '',
    },
  };

  popupParam.enums = { ...enums, ...mergeInfo.enums };
  popupParam.data = { ...mergeInfo.data };
  new PopUp(TruckRecordOp, popupParam).show();
};

export const truckStateChange = async (listPage, btnKey, sucIds) => {
  const drComIds = sucIds || listPage.getSelectesKey('id');

  if (!drComIds.length) {
    return showInfo(WARN, '请选择要操作的数据！');
  }

  const isEnable = btnKey === 'truck_enable';
  const opTxt = isEnable ? '启用' : '停用';
  const contentNotice = isEnable ? '' : `${opTxt}后，该车辆将无法再承运业务，请谨慎操作！`;

  if (
    !sucIds &&
    !(await confirm(
      WARN,
      `您确定要${opTxt}选中车辆吗？`,
      {},
      `${opTxt}车辆`,
      contentNotice,
      null,
      'truck-state-change',
    ))
  ) {
    return false;
  }

  listPage.disableMenuButton(btnKey);
  const conf = {
    method: 'POST',
    body: { req: { truck_id: drComIds, is_check: sucIds ? 0 : 1, op_type: isEnable ? 1 : 0 } },
  };
  const infoRes = await fetchApi('/Basic/Truck/opTruckState', conf);
  if (+infoRes.errno !== 0) {
    infoRes.errmsg && showInfo(ERROR, infoRes.errmsg);
    listPage.enableMenuButton && listPage.enableMenuButton(btnKey);
    return false;
  }

  const continueCb = (okIds, cb) => {
    cb && cb();
    truckStateChange(listPage, btnKey, okIds);
  };

  const sucCb = () => {
    listPage &&
      setTimeout(() => {
        listPage.handleRefreshData && listPage.handleRefreshData();
        listPage.enableMenuButton && listPage.enableMenuButton(btnKey);
      }, 2000);
  };

  opTips({
    orderType: '车辆',
    opType: '',
    resData: infoRes,
    sucCallback: sucCb,
    showSucTips: true,
    continueCallback: continueCb,
    ext: {
      failedCallback: () => {
        listPage.enableMenuButton(btnKey);
        return false;
      },
      specialTips: '以下车辆已停用，是否继续操作其他车辆？',
    },
    tips: opTxt,
  });
};

// 工作台看板，车辆到期信息——修改
const truckUpdateTruck = async (listPage, rowIndex, columnKey, rowData) => {
  const truckId = rowData.id;
  const fieldKey = getUrlParams('fieldKey');
  // 从保养点进来的
  const isFromMaintain = fieldKey === 'next_maintain_date';
  // 保险相关来的
  const isFromInsurance = INSURANCE_KEYS.includes(fieldKey);
  // 这两种，需要打开详情弹框，并且默认打开的是对应tab
  if (isFromMaintain || isFromInsurance) {
    let defaultActiveTab = '';
    if (isFromInsurance) {
      defaultActiveTab = 'BsInsurance';
    }
    if (isFromMaintain) {
      defaultActiveTab = 'tr_carmaintenance';
    }
    return truckTrNum(listPage, rowIndex, columnKey, {
      operateProps: {
        defaultActiveTab,
      },
    });
  }
  updateTruck(listPage, [truckId]);
};

export function init(emt) {
  emt.on('addTruck', addTruck); // 车辆管理 - 新增
  emt.on('addToFrightPool', addToFrightPool); // 车辆管理 - 批量加入运力池
  emt.on('truckRecordAdd', addTruckRecord);
  emt.on('truckRecordAudit', truckRecordAuditOp); // 车辆使用记录 - 批量审核
  emt.on('truckRecordCancelAudit', truckRecordAuditOp); // 车辆使用记录 - 批量取消审核
  emt.on('updateTruck', listPage => updateTruck(listPage)); // 车辆管理 - 修改
  emt.on('truckRecordModify', updateTruckRecord);
  emt.on('deleteTruck', deleteTruck); // 车辆管理 - 删除
  emt.on('truckRecordDelete', deleteTruckRecord);

  emt.on('truckUsedRecord', listPage => truckRecordSlider(listPage, 'truck_record')); // 车辆管理 - 使用登记
  emt.on('addTruckStopRecord', listPage => truckRecordSlider(listPage, 'truck_stop_record')); // 车辆管理 - 停驶登记

  emt.on('truckCert', truckCert);
  emt.on('doTruckPlatformCert', doTruckPlatformCert); // 平台车辆管理 - 认证操作列
  emt.on('truckPlatformCert', truckPlatformCert); // 车辆管理 - 提交认证
  emt.on('truckTrNum', truckTrNum); // 车辆管理 - 车牌号超链
  emt.on('bsCarmaintenanceTrId', truckTrNum);
  emt.on('bsCarmaintenanceItemTrId', truckTrNum);
  emt.on('bsInsuranceTrId', truckTrNum);
  emt.on('bsCarDepreciationChargeTrId', truckTrNum); // 折旧费
  emt.on('truckRecordTrNum', truckTrNum); // 车辆记录 - 车牌号超链
  emt.on('truckQrCode', truckQrCode); // 车辆管理 - 二维码超链
  emt.on('truckRecordQrCode', truckQrCode); // 车辆使用记录 - 二维码超链
  emt.on('truckLicenseImg', viewImg);
  emt.on('truckOperImg', viewImg);
  emt.on('truckTrImg', viewImg);
  emt.on('truckRTpImg', viewImg);
  emt.on('truckPTrImg', viewImg);
  emt.on('truckInsureImg', viewImg);
  emt.on('truckStatementImg', viewImg);
  emt.on('truckRecordPic', viewImg); // 车辆使用记录 - 登记照片
  emt.on('viewTrPos', viewTrPos); // 查看位置
  emt.on('truckReportCarBatch', reportBatch); // 报表的批次号
  emt.on('batchUpdateTrucks', truckBatchUpdate); // 批量修改车辆
  emt.on('truckRecordTruckRecordView', truckRecordTrNum); // 车辆记录的操作列， 查看
  emt.on('tLogink', truckPlatformLogink); // 平台车辆管理 上报操作
  emt.on('truckDisable', (listPage, btnKey) => truckStateChange(listPage, btnKey));
  emt.on('truckEnable', (listPage, btnKey) => truckStateChange(listPage, btnKey));
  // 车辆到期登记/信息 - 修改
  emt.on('truckUpdateTruck', truckUpdateTruck);
}
