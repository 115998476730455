/* eslint no-loop-func: 0 */
/* eslint-disable no-unused-vars */

import React from 'react';

export function browser(navigator) {
  let tem;
  const ua = navigator.userAgent;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return `IE ${tem[1] || ''}`;
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem) {
    M.splice(1, 1, tem[1]);
  }
  return M.join(' ');
}

// console.log(filterParentPosition(
// ['0-2', '0-3-3', '0-10', '0-10-0', '0-0-1', '0-0', '0-1-1', '0-1']
// ))
function stripTail(str) {
  const arr = str.match(/(.+)(-[^-]+)$/);
  let st = '';
  if (arr && arr.length === 3) {
    st = arr[1];
  }
  return st;
}

function splitPosition(pos) {
  return pos.split('-');
}

/* eslint-disable */
export function getOffset(ele) {
  let doc, win, docElem, rect;
  if (!ele.getClientRects().length) {
    return { top: 0, left: 0 };
  }
  rect = ele.getBoundingClientRect();
  if (rect.width || rect.height) {
    doc = ele.ownerDocument;
    win = doc.defaultView;
    docElem = doc.documentElement;
    return {
      top: rect.top + win.pageYOffset - docElem.clientTop,
      left: rect.left + win.pageXOffset - docElem.clientLeft,
    };
  }
  return rect;
}

function getChildrenlength(children) {
  let len = 1;
  if (Array.isArray(children)) {
    len = children.length;
  }
  return len;
}

function getSiblingPosition(index, len, siblingPosition) {
  if (len === 1) {
    siblingPosition.first = true;
    siblingPosition.last = true;
  } else {
    siblingPosition.first = index === 0;
    siblingPosition.last = index === len - 1;
  }
  return siblingPosition;
}

export function loopAllChildren(childs, callback, parent) {
  const loop = (children, level, _parent) => {
    const len = getChildrenlength(children);
    React.Children.forEach(children, (item, index) => {
      const pos = `${level}-${index}`;
      if (item && item.type && item.type.isTreeNode && item.props.children) {
        loop(item.props.children, pos, { node: item, pos });
      }
      // if (item) {
      //   callback(item, index, pos, item.key || pos, getSiblingPosition(index, len, {}), _parent)
      // }
      callback(item, index, pos, item.key || pos, getSiblingPosition(index, len, {}), _parent, item.isChecked || false);
    });
  };
  loop(childs, 0, parent);
}

export function isInclude(smallArray, bigArray) {
  if (typeof bigArray === 'undefined') {
    return false;
    // return smallArray.every((ii, i) => false)
  }
  return smallArray.every((ii, i) => ii === bigArray[i]);
}

// console.log(isInclude(['0', '1'], ['0', '10', '1']))
// arr.length === 628, use time: ~20ms
export function filterParentPosition(arr) {
  const levelObj = {};
  arr.forEach(item => {
    const posLen = item.split('-').length;
    if (!levelObj[posLen]) {
      levelObj[posLen] = [];
    }
    levelObj[posLen].push(item);
  });
  const levelArr = Object.keys(levelObj).sort();
  for (let i = 0; i < levelArr.length; i++) {
    if (levelArr[i + 1]) {
      levelObj[levelArr[i]].forEach(ii => {
        for (let j = i + 1; j < levelArr.length; j++) {
          levelObj[levelArr[j]].forEach((_i, index) => {
            if (isInclude(ii.split('-'), _i.split('-'))) {
              levelObj[levelArr[j]][index] = null;
            }
          });
          levelObj[levelArr[j]] = levelObj[levelArr[j]].filter(p => p);
        }
      });
    }
  }
  let nArr = [];
  levelArr.forEach(i => {
    nArr = nArr.concat(levelObj[i]);
  });
  return nArr;
}

/* eslint-disable */

// todo: do optimization.
export function handleCheckState(obj, checkedPositionArr, checkIt, isThreeState = false) {
  // console.log(stripTail('0-101-000'))
  let objKeys = Object.keys(obj);
  // let s = Date.now()
  if (!isThreeState) {
    objKeys.forEach((i, index) => {
      const iArr = splitPosition(i);
      let saved = false;
      checkedPositionArr.forEach(_pos => {
        // 设置子节点，全选或全不选
        const _posArr = splitPosition(_pos);
        if (iArr.length > _posArr.length && isInclude(_posArr, iArr)) {
          obj[i].halfChecked = false;
          obj[i].checked = checkIt;
          obj[i].pnsChecked = false;
          objKeys[index] = null;
        }
        if (iArr[0] === _posArr[0] && iArr[1] === _posArr[1]) {
          // 如果
          saved = true;
        }
      });
      if (!saved) {
        objKeys[index] = null;
      }
    });
  }
  // TODO: 循环 2470000 次耗时约 1400 ms。 性能瓶颈！
  // console.log(Date.now()-s, checkedPositionArr.length * objKeys.length)
  objKeys = objKeys.filter(i => i); // filter non null
  for (let pIndex = 0; pIndex < checkedPositionArr.length; pIndex++) {
    // 循环设置父节点的 全选 自选 或 半选状态
    const loop = __pos => {
      const _posLen = splitPosition(__pos).length;
      if (_posLen <= 2) {
        // e.g. '0-0', '0-1'
        return;
      }
      let sibling = 0;
      let siblingChecked = 0;
      const parentPosition = stripTail(__pos);
      objKeys.forEach((i /* , index*/) => {
        const iArr = splitPosition(i);
        if (iArr.length === _posLen && isInclude(splitPosition(parentPosition), iArr)) {
          sibling++;
          if (obj[i].checked) {
            siblingChecked++;
            const _i = checkedPositionArr.indexOf(i);
            if (_i > -1) {
              checkedPositionArr.splice(_i, 1);
              if (_i <= pIndex) {
                pIndex--;
              }
            }
          } else if (obj[i].halfChecked) {
            siblingChecked += 0.5;
          }
          // objKeys[index] = null
        }
      });
      // objKeys = objKeys.filter(i => i) // filter non null
      const parent = obj[parentPosition];
      // sibling 不会等于0
      // 全不选 - 全选 - 半选
      if (siblingChecked === 0) {
        parent.checked = false;
        parent.halfChecked = false;
      } else if (siblingChecked === sibling && isThreeState) {
        parent.checked = false;
        parent.halfChecked = true;
      } else if (siblingChecked === sibling && isThreeState && parent.checked) {
        parent.checked = true;
        parent.halfChecked = false;
      } else if (siblingChecked === sibling && !isThreeState) {
        parent.checked = true;
        parent.halfChecked = false;
      } else {
        parent.halfChecked = true;
        parent.checked = false;
      }
      // if (siblingChecked === 0 && isThreeState) {
      //   parent.checked = false
      //   parent.halfChecked = false
      // } else if (siblingChecked === sibling && isThreeState) {
      //   parent.checked = true
      //   parent.halfChecked = false
      // } else {
      //   parent.halfChecked = true
      //   parent.checked = false
      // }
      loop(parentPosition);
    };
    loop(checkedPositionArr[pIndex], pIndex);
  }
  // console.log(Date.now()-s, objKeys.length, checkIt)
}

export function handleParentCheckState(obj, checkedPositionArr, checkIt, isThreeState) {
  //  checkIt 改为 是否为三态
  // 即父节点本身为选中且子节点非全选是 为自选状态；
  //  父节点本身非选中 则状态同二态
  let objKeys = Object.keys(obj);
  // TODO: 循环 2470000 次耗时约 1400 ms。 性能瓶颈！
  // console.log(Date.now()-s, checkedPositionArr.length * objKeys.length)
  objKeys = objKeys.filter(i => i); // filter non null
  for (let pIndex = 0; pIndex < checkedPositionArr.length; pIndex++) {
    // 循环设置父节点的 选中 或 半选状态
    const loop = __pos => {
      const _posLen = splitPosition(__pos).length;
      if (_posLen <= 2) {
        // e.g. '0-0', '0-1'
        return;
      }
      let sibling = 0;
      let siblingChecked = 0;
      const parentPosition = stripTail(__pos);
      objKeys.forEach((i /* , index*/) => {
        const iArr = splitPosition(i);
        if (iArr.length === _posLen && isInclude(splitPosition(parentPosition), iArr)) {
          sibling++;
          if (obj[i].checked || (obj[i].pnsChecked && isThreeState)) {
            siblingChecked++;
            const _i = checkedPositionArr.indexOf(i);
            if (_i > -1) {
              checkedPositionArr.splice(_i, 1);
              if (_i <= pIndex) {
                pIndex--;
              }
            }
          } else if (obj[i].halfChecked) {
            siblingChecked += 0.5;
          }
          // objKeys[index] = null
        }
      });
      // objKeys = objKeys.filter(i => i) // filter non null
      const parent = obj[parentPosition];
      // sibling 不会等于0
      // 全不选 - 全选 - 半选
      if (isThreeState && (parent.pnsChecked || parent.checked)) {
        if (siblingChecked === sibling) {
          parent.checked = true;
          parent.halfChecked = false;
          parent.pnsChecked = false;
        } else {
          parent.halfChecked = false;
          parent.checked = false;
          parent.pnsChecked = true;
        }
        // if (siblingChecked === 0) {
        //   parent.checked = false
        //   parent.halfChecked = false
        //   parent.pnsChecked = true
        // } else if (siblingChecked === sibling) {
        //   parent.checked = true
        //   parent.halfChecked = false
        //   parent.pnsChecked = false
        // } else {
        //   parent.halfChecked = false
        //   parent.checked = false
        //   parent.pnsChecked = true
        // }
      } else {
        if (siblingChecked === 0) {
          parent.checked = false;
          parent.halfChecked = false;
          parent.pnsChecked = false;
        }
        // else if (siblingChecked === sibling) {
        //   parent.checked = true
        //   parent.halfChecked = false
        //   parent.pnsChecked = false
        // }
        else {
          parent.halfChecked = true;
          parent.checked = false;
          parent.pnsChecked = false;
        }
      }
      loop(parentPosition);
    };
    loop(checkedPositionArr[pIndex], pIndex);
  }
}

export function handleChildrenCheckState(obj, checkedPositionArr, checkIt, isThreeState) {
  let objKeys = Object.keys(obj);
  objKeys.forEach((i, index) => {
    const iArr = splitPosition(i);
    let saved = false;
    checkedPositionArr.forEach(_pos => {
      // 设置子节点，全选或全不选
      const _posArr = splitPosition(_pos);
      if (iArr.length > _posArr.length && isInclude(_posArr, iArr)) {
        obj[i].halfChecked = false;
        obj[i].checked = checkIt;
        obj[i].pnsChecked = false;
        objKeys[index] = null;
      }
      if (iArr[0] === _posArr[0] && iArr[1] === _posArr[1]) {
        // 如果
        saved = true;
      }
    });
    if (!saved) {
      objKeys[index] = null;
    }
  });
}

/* eslint-enable */
export function getCheck(treeNodesStates, isThreeState = false) {
  const halfCheckedKeys = [];
  const checkedKeys = [];
  const checkedNodes = [];
  const checkedNodesPositions = [];
  Object.keys(treeNodesStates).forEach(item => {
    const itemObj = treeNodesStates[item];
    if (itemObj.checked || (itemObj.pnsChecked && isThreeState)) {
      checkedKeys.push(itemObj.key);
      checkedNodes.push(itemObj.node);
      checkedNodesPositions.push({ node: itemObj.node, pos: item });
    } else if (itemObj.halfChecked) {
      halfCheckedKeys.push(itemObj.key);
    }
  });
  return {
    halfCheckedKeys,
    checkedKeys,
    checkedNodes,
    checkedNodesPositions,
    treeNodesStates,
  };
}

export function getStrictlyValue(checkedKeys, halfChecked) {
  if (halfChecked) {
    return { checked: checkedKeys, halfChecked };
  }
  return checkedKeys;
}

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a === null || typeof a === 'undefined' || b === null || typeof b === 'undefined') {
    return false;
  }
  if (a.length !== b.length) return false;
  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function isChildrenAllChecked(children, checkedKeys) {
  return !children.some(child => {
    const hasChildren = child.props.children && child.props.children.length > 0;
    return (
      checkedKeys.indexOf(child.key) === -1 || (hasChildren && !isChildrenAllChecked(child.props.children, checkedKeys))
    ); // 有没勾选的
  });
}

export function getValuePropValue(child) {
  const { props } = child;
  if ('value' in props) {
    return props.value;
  }
  if (child.key) {
    return child.key;
  }
  throw new Error(`no key or value for ${child}`);
}

export function getPropValue(child, prop) {
  const props = child.props || {};
  if (prop === 'value') {
    return getValuePropValue(child);
  }
  if (props.title) {
    return props.title;
  }
  return child.props[prop];
}

export function reactToArray(children) {
  const ret = [];
  React.Children.forEach(children, c => {
    ret.push(c);
  });
  return ret;
}

export function isCombobox(props) {
  return props.combobox;
}

export function isMultipleOrTags(props) {
  return props.multiple || props.tags || props.treeCheckable;
}

export function isMultipleOrTagsOrCombobox(props) {
  return isMultipleOrTags(props) || isCombobox(props);
}

export function isSingleMode(props) {
  return !isMultipleOrTagsOrCombobox(props);
}

export function toArray(value) {
  let ret = value;
  if (value === undefined) {
    ret = [];
  } else if (!Array.isArray(value)) {
    ret = [value];
  }
  return ret;
}

export function preventDefaultEvent(e) {
  e.preventDefault();
}

export const UNSELECTABLE_STYLE = {
  userSelect: 'none',
  WebkitUserSelect: 'none',
};
export const UNSELECTABLE_ATTRIBUTE = {
  unselectable: 'unselectable',
};

export function labelCompatible(prop) {
  let newProp = prop;
  if (newProp === 'label') {
    newProp = 'title';
  }
  return newProp;
}

export function flatToHierarchy(arr) {
  if (!arr.length) {
    return arr;
  }
  const hierarchyNodes = [];
  const levelObj = {};
  arr.forEach(item => {
    if (!item.pos) {
      return;
    }
    const posLen = item.pos.split('-').length;
    if (!levelObj[posLen]) {
      levelObj[posLen] = [];
    }
    levelObj[posLen].push(item);
  });
  const levelArr = Object.keys(levelObj).sort((a, b) => b - a);
  // const s = Date.now()
  // todo: there are performance issues!
  levelArr.reduce((pre, cur) => {
    if (cur && cur !== pre) {
      levelObj[pre].forEach(item => {
        let haveParent = false;
        levelObj[cur].forEach(iii => {
          const ii = iii;
          if (isInclude(ii.pos.split('-'), item.pos.split('-'))) {
            haveParent = true;
            if (!ii.children) {
              ii.children = [];
            }
            ii.children.push(item);
          }
        });
        if (!haveParent) {
          hierarchyNodes.push(item);
        }
      });
    }
    return cur;
  });
  // console.log(Date.now() - s)
  return levelObj[levelArr[levelArr.length - 1]].concat(hierarchyNodes);
}

// Select.js 中用到
// RcTree 的render 中的相关处理可替换为此方法
export function getTreeNodesStates(children, values, isThreeState) {
  const checkedPositions = [];
  const treeNodesStates = {};
  loopAllChildren(children, (item, index, pos, keyOrPos, siblingPosition) => {
    treeNodesStates[pos] = {
      node: item,
      key: keyOrPos,
      checked: false,
      pnsChecked: false,
      halfChecked: false,
      siblingPosition,
    };
    const hasChildren = !!item.props.children && item.props.children.length > 0;
    if (values.indexOf(getValuePropValue(item)) !== -1) {
      treeNodesStates[pos].checked = !hasChildren;
      treeNodesStates[pos].pnsChecked = hasChildren;
      treeNodesStates[pos].halfChecked = false;
      checkedPositions.push(pos);
    }
  });
  handleCheckState(treeNodesStates, filterParentPosition(checkedPositions.sort()), true, isThreeState);
  return getCheck(treeNodesStates, checkedPositions, isThreeState);
}

// can add extra prop to every node.
export function recursiveCloneChildren(children, cb = ch => ch) {
  // return React.Children.map(children, child => {
  return Array.from(children).map(child => {
    const newChild = cb(child);
    if (newChild && newChild.props && newChild.props.children) {
      return React.cloneElement(newChild, {}, recursiveCloneChildren(newChild.props.children, cb));
    }
    return newChild;
  });
}

// const newChildren = recursiveCloneChildren(children, child => {
//   const extraProps = {}
//   if (child && child.type && child.type.xxx) {
//     extraProps._prop = true
//     return React.cloneElement(child, extraProps)
//   }
//   return child
// })
function recursiveGen(children, level = 0) {
  return React.Children.map(children, (child, index) => {
    const pos = `${level}-${index}`;
    const o = {
      title: child.props.title,
      label: child.props.label || child.props.title,
      value: child.props.value,
      key: child.key,
      _pos: pos,
    };
    if (child.props.children) {
      o.children = recursiveGen(child.props.children, pos);
    }
    return o;
  });
}

function recursive(children, cb) {
  children.forEach(item => {
    cb(item);
    if (item.children) {
      recursive(item.children, cb);
    }
  });
}

// Get the tree's checkedNodes (todo: can merge to the `handleCheckState` function)
// If one node checked, it's all children nodes checked.
// If sibling nodes all checked, the parent checked.
export function filterAllCheckedData(vs, treeNodes) {
  const vals = [...vs];
  if (!vals.length) {
    return vals;
  }
  const data = recursiveGen(treeNodes);
  const checkedNodesPositions = [];

  function checkChildren(children) {
    children.forEach(iitem => {
      const item = iitem;
      if (item.__checked) {
        return;
      }
      const ci = vals.indexOf(item.value);
      const childs = item.children;
      if (ci > -1) {
        item.__checked = true;
        checkedNodesPositions.push({ node: item, pos: item._pos });
        vals.splice(ci, 1);
        if (childs) {
          recursive(childs, ichild => {
            const child = ichild;
            child.__checked = true;
            checkedNodesPositions.push({ node: child, pos: child._pos });
          });
        }
      } else {
        childs && checkChildren(childs);
      }
    });
  }

  function checkParent(children, iparent = { root: true }) {
    const parent = iparent;
    let siblingChecked = 0;
    children.forEach(item => {
      const childs = item.children;
      if (childs && !item.__checked && !item.__halfChecked) {
        const p = checkParent(childs, item);
        if (p.__checked) {
          siblingChecked++;
        } else if (p.__halfChecked) {
          siblingChecked += 0.5;
        }
      } else if (item.__checked) {
        siblingChecked++;
      } else if (item.__halfChecked) {
        siblingChecked += 0.5;
      }
    });
    const len = children.length;
    if (siblingChecked === len) {
      parent.__checked = true;
      checkedNodesPositions.push({ node: parent, pos: parent._pos });
    } else if (siblingChecked < len && siblingChecked > 0) {
      parent.__halfChecked = true;
    }
    if (parent.root) {
      return children;
    }
    return parent;
  }

  checkChildren(data);
  checkParent(data);
  checkedNodesPositions.forEach((i, index) => {
    // clear private metadata
    delete checkedNodesPositions[index].node.__checked;
    delete checkedNodesPositions[index].node._pos;
    // create the same structure of `onCheck`'s return.
    checkedNodesPositions[index].node.props = {
      title: checkedNodesPositions[index].node.title,
      label: checkedNodesPositions[index].node.label || checkedNodesPositions[index].node.title,
      value: checkedNodesPositions[index].node.value,
    };
    if (checkedNodesPositions[index].node.children) {
      checkedNodesPositions[index].node.props.children = checkedNodesPositions[index].node.children;
    }
    delete checkedNodesPositions[index].node.title;
    delete checkedNodesPositions[index].node.label;
    delete checkedNodesPositions[index].node.value;
    delete checkedNodesPositions[index].node.children;
  });
  return checkedNodesPositions;
}

export function processSimpleTreeData(treeData, format) {
  function unflatten2(iarray, iparent = { [format.id]: format.rootPId }) {
    const parent = iparent;
    const array = iarray;
    const children = [];
    for (let i = 0; i < array.length; i++) {
      array[i] = { ...array[i] }; // copy, can not corrupts original data
      if (array[i][format.pId] === parent[format.id]) {
        array[i].key = array[i][format.id];
        children.push(array[i]);
        array.splice(i--, 1);
      }
    }
    if (children.length) {
      parent.children = children;
      children.forEach(child => unflatten2(array, child));
    }
    if (parent[format.id] === format.rootPId) {
      return children;
    }
  }

  return unflatten2(treeData);
}

// function loopTreeData(data, level = 0) {
//   return data.map((item, index) => {
//     const pos = `${level}-${index}`
//     const props = {
//       title: item.label,
//       value: item.value,
//       // value: item.value || String(item.key || item.label), // cause onChange callback error /* eslint-disable */
//       key: item.key || item.value || pos,
//       disabled: item.disabled || false,
//       // selectable: item.hasOwnProperty('selectable') ? item.selectable : true,
//       selectable: item.prototype.selectable ? item.selectable : true,
//     }
//     let ret
//     if (item.children && item.children.length) {
//       ret = (<TreeNode {...props}>{loopTreeData(item.children, pos)}</TreeNode>)
//     } else {
//       ret = (<TreeNode {...props} isLeaf={item.isLeaf} />)
//     }
//     return ret
//   })
// }
/*
 export function getCheckedKeys(node, checkedKeys, allCheckedNodesKeys) {
 const nodeKey = node.props.eventKey
 let newCks = [...checkedKeys]
 let nodePos
 const unCheck = allCheckedNodesKeys.some(item => {
 if (item.key === nodeKey) {
 nodePos = item.pos
 return true
 }
 })
 if (unCheck) {
 const nArr = nodePos.split('-')
 newCks = []
 allCheckedNodesKeys.forEach(item => {
 const iArr = item.pos.split('-')
 if (item.pos === nodePos ||
 nArr.length > iArr.length && isInclude(iArr, nArr) ||
 nArr.length < iArr.length && isInclude(nArr, iArr)) {
 return
 }
 newCks.push(item.key)
 })
 } else {
 newCks.push(nodeKey)
 }
 return newCks
 }
 */
// export function getOffset(el) {
//   const obj = el.getBoundingClientRect()
//   return {
//     left: obj.left + document.body.scrollLeft,
//     top: obj.top + document.body.scrollTop,
//     width: obj.width,
//     height: obj.height
//   }
// }
// // iscroll offset
// offset = function (el) {
//   var left = -el.offsetLeft,
//     top = -el.offsetTop
//   // jshint -W084
//   while (el = el.offsetParent) {
//     left -= el.offsetLeft
//     top -= el.offsetTop
//   }
//   // jshint +W084
//   return {
//     left: left,
//     top: top
//   }
// }
