import PropTypes from 'prop-types';
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { TreeSelect } from 'components';
import TableCell from './TableCell';
import { isInOtherPropsArray } from './../utils';

export default class TreeSelectCell extends TableCell {
  static propTypes = {
    onChange: PropTypes.func,
    uniqueKey: PropTypes.string,
    showKey: PropTypes.string,
  };
  onTChange = checkedVal => {
    this.props.onChange && this.props.onChange(this.props.rowIndex, this.props.columnKey, checkedVal);
  };

  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data, tableKey, colIndex } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const { showKey, uniqueKey, enumData = [], threeState } = columnProps;
    const defaultValue = data.getShowObjectAt(rowIndex)[columnKey];
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <TreeSelect
          dataPath={`${tableKey}_${columnKey}_${rowIndex}_${colIndex}`}
          treeData={enumData}
          value={defaultValue}
          disabled={isInOtherPropsArray(data.getObjectAt(rowIndex), columnKey, 'disable')}
          uniqueKey={uniqueKey || 'key'}
          showKey={showKey || 'name'}
          threeState={threeState}
          multiple
          treeCheckable
          showArrow
          allowClear
          labelInValue={false}
          defaultOpen={false}
          treeLine
          treeDefaultExpandAll
          showSearch={false}
          notFoundContent="无相关数据"
          treeNodeFilterProp={showKey || 'name'}
          treeNodeLabelProp={showKey || 'name'}
          showCheckedStrategy={TreeSelect.SHOW_ALL}
          onChange={this.onTChange}
        />
      </Cell>
    );
  }
}
