import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { showInfo, createTip } from 'utils/checkInfo';
import { ERROR, WARN, validateTips, regulars } from 'constants';
import { BaseList, InputIcon, LazyRender } from 'components';
import { prefixCls } from './index.scss';
// const deleteItem = ['receipt', 'e_receipt', 'r_receipt', 'o_receipt', 'dock_receipt', 'envelop_receipt']
const deleteItem = ['receipt', '运单', '标签', '回单', '其他']; // bug34787  将默认的回单类型只留"回单"一个。
export default class ToDoDrop extends PureComponent {
  static defaultProps = {
    width: '200px',
    iconType: 'icon-add-rad',
    uniqueKey: 'key',
    defaultData: [],
    tableHeader: {},
    isAsyncAdd: false,
    allowDuplicate: false,
  };
  static propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleAddTodo: PropTypes.func,
    handleSubtractTodo: PropTypes.func,
    uniqueKey: PropTypes.string,
    classname: PropTypes.string,
    iconType: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.string,
    inputMaxLength: PropTypes.number,
    // 高级功能，开启后，会识别 a:b 为 { key:a, value: b}
    advanced: PropTypes.bool,
    defaultData: PropTypes.array,
    disabled: PropTypes.bool,
    allowDuplicate: PropTypes.bool,
    isShowNoDataTips: PropTypes.bool,
    showHeader: PropTypes.bool,
    isAsyncAdd: PropTypes.bool, // 是否是异步添加  添加时会有弹框
    handleAddTodoAsync: PropTypes.func,
    pattern: PropTypes.string,
    validateTips: PropTypes.string,
    onBlur: PropTypes.func,
    tableHeader: PropTypes.object, // 头部字段
    noDelete: PropTypes.array,
  };

  constructor(prop) {
    super(prop);
    this.state = {
      value: '',
      listData: prop.defaultData,
    };
    this.isAddClick = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      listData: nextProps.defaultData,
    });
  }

  _otherClick() {
    this.isAddClick = true;
    this.vLazyRender.handleShow();
  }

  handleBlur = () => {
    const { listData } = this.state;
    setTimeout(() => {
      if (this.isAddClick) {
        this.isAddClick = false;
      }
    }, 150);
    this.props.onBlur && this.props.onBlur(listData);
  };
  formatValue = value => {
    let val = {};
    if (this.props.isAsyncAdd) {
      const { uniqueKey } = this.props;
      val = value;
      val.key = val[uniqueKey];
      val.value = val[uniqueKey];
    } else {
      const obj = value.split(':');
      if (this.props.advanced && obj.length > 1) {
        val.key = obj[0];
        val.value = value.replace(`${obj[0]}:`, '');
      } else {
        val.key = value;
        val.value = value;
      }
    }
    return val;
  };
  handleAddTodo = () => {
    this._otherClick();
    const value = typeof this.state.value === 'string' ? this.state.value.trim() : '';
    if (!value) return;
    const item = this.formatValue(value);
    const oldListData = Array.isArray(this.state.listData) ? this.state.listData : [];
    const exist = oldListData.some(itemObj => itemObj && (itemObj.value === item.value || itemObj.key === item.key));
    if (exist && !this.props.allowDuplicate) {
      showInfo(ERROR, '添加的字段重复，请重新添加!');
      this.setState({
        value: '',
      });
      return;
    }
    let { pattern, validateTips: tips } = this.props;
    const { maxLength } = this.props;
    if (maxLength && oldListData.length >= maxLength) {
      showInfo(ERROR, `最多允许添加${maxLength}个`);
      this.setState({ value: '' });
      return;
    }
    pattern = regulars[pattern] || pattern;
    tips = tips || validateTips[pattern];
    if (pattern && tips) {
      const regExp = new RegExp(pattern);
      if (!regExp.test(item.value)) {
        createTip(tips, ERROR, null, 2000, '', true).show();
        this.setState({
          value: '',
        });
        return;
      }
    }
    const listData = [...oldListData, item];
    this.setState({
      listData,
      value: '',
    });
    this.props.handleAddTodo && this.props.handleAddTodo(listData);
  };
  handleAddTodoAsync = () => {
    const listData = Array.isArray(this.state.listData) ? this.state.listData : [];
    const { maxLength } = this.props;
    if (maxLength && listData.length >= maxLength) {
      showInfo(ERROR, `最多允许添加${maxLength}个`);
      this.setState({ value: '' });
      return;
    }
    this.props.handleAddTodoAsync && this.props.handleAddTodoAsync();
  };
  // 异步操作
  handleAddItemAsync = (iitem, uniqueKeys = [this.props.uniqueKey]) => {
    const item = this.formatValue(iitem);
    const oldListData = Array.isArray(this.state.listData) ? this.state.listData : [];
    // 验重校验
    const exist = oldListData.some(
      itemObj => itemObj && uniqueKeys.every(uniqueKey => itemObj[uniqueKey] === iitem[uniqueKey]),
    );
    if (exist && !this.props.allowDuplicate) {
      showInfo(ERROR, '添加的字段重复，请重新添加!');
      this.setState({
        value: '',
      });
      return;
    }
    let { pattern, validateTips: tips } = this.props;
    const { maxLength } = this.props;
    if (maxLength && oldListData.length >= maxLength) {
      showInfo(ERROR, `最多允许添加${maxLength}个`);
      this.setState({ value: '' });
      return;
    }
    pattern = regulars[pattern] || pattern;
    tips = tips || validateTips[pattern];
    if (pattern && tips) {
      const regExp = new RegExp(pattern);
      if (!regExp.test(item.value)) {
        showInfo(tips, ERROR, null, 2000, '', true).show();
        this.setState({
          value: '',
        });
        return;
      }
    }
    const listData = [...oldListData];
    listData.push(item);
    this.setState({
      listData,
    });
    this.props.handleAddTodo && this.props.handleAddTodo(listData);
  };
  handleSubtract = item => {
    this._otherClick();
    const { uniqueKey, classname = '', noDelete = [] } = this.props;
    if (!uniqueKey || !item) return;
    if (deleteItem.includes(item.key) && classname.includes('no-delt-plus')) {
      showInfo(WARN, `${item.value}不能删除`);
      return null;
    }
    if (noDelete.includes(item.key)) {
      showInfo(WARN, `${item.value}不能删除`);
      return null;
    }
    const listData = this.state.listData.filter(_item => _item[uniqueKey] !== item[uniqueKey]);
    this.setState({
      listData,
    });
    this.props.handleSubtractTodo && this.props.handleSubtractTodo(listData);
  };
  handleKeyDown = e => {
    if (e.keyCode === 13 && e.target.value !== '') {
      this.handleAddTodo();
    }
  };
  getDOMInput = () => this.wraper;
  getContentNode = () => this && this.vList() && this.vList().ul;
  handleClick = () => this.vLazyRender.toggleDisplay();
  vList = () => this._vList && this._vList.child;

  render() {
    const {
      width,
      classname,
      iconType,
      uniqueKey,
      placeholder,
      disabled,
      isShowNoDataTips,
      title,
      dataPath,
      inputMaxLength,
    } = this.props;
    const { tableHeader, isAsyncAdd, showHeader } = this.props;
    const classes = classnames({ [prefixCls]: true, [classname]: classname });
    return (
      <div ref={r => (this.wraper = r)} className={classes}>
        <InputIcon
          dataPath={dataPath}
          inputProps={{ maxLength: inputMaxLength }}
          onBlur={this.handleBlur}
          disabled={disabled}
          onChange={e => this.setState({ value: e.target.value })}
          defaultValue={this.state.value}
          placeholder={placeholder}
          iconProps={{ iconType, onClick: isAsyncAdd ? this.handleAddTodoAsync : this.handleAddTodo }}
          onKeyDown={this.handleKeyDown}
          onClick={this.handleClick}
          style={{ width }}
          title={title}
        />
        <LazyRender
          getDomNode={this.getDOMInput}
          isAutoHeight
          autoPosition
          ref={r => (this.vLazyRender = r)}
          getContentNode={this.getContentNode}
        >
          <BaseList
            data={this.state.listData}
            isTodoSelect
            isShow
            isShowNoDataTips={isShowNoDataTips}
            tableHeader={tableHeader}
            showHeader={showHeader}
            handleIconClick={this.handleSubtract}
            ref={r => (this._vList = r)}
            uniqueKey={uniqueKey}
            iconType="icon-del"
            style={{
              top: '30px',
              textAlign: 'left',
              width,
            }}
          />
        </LazyRender>
      </div>
    );
  }
}
