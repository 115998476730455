import React from 'react';
import actions from 'actions';
import { ShowInfo, PopUp, LodopFunc } from 'components';
import printSlnDialog from 'components/commoncomponents/printSlnDialog';
import printSlnDialogError from 'components/commoncomponents/printSlnDialog/printSlnDialogError';
import { NETERR, INFO, CHECK } from 'constants';

import { getPrintComId } from 'utils';

import { restartLODOP } from 'utils/business/print/checkLODOP';
import './printFaultTip.scss';

export function printFaultTip(errorNo) {
  return new Promise(resolve => {
    const title = '打印故障智能检修程序';
    let content = (
      <div>
        Clodop未安装！请<a href="www.baidu.com">【下载】</a>安装后，再重新打印！
      </div>
    );
    const buttons = [];
    let iconType = '';
    let className = 'print-fault-tip';
    let type = '';
    const { globalLodopObj = {} } = window;
    const printPort = (globalLodopObj.strHostURI || '').split(':')[2] || '';
    const printLogProxy = {};
    const confirmBtn = {
      name: '下一步',
      btnType: 'primary',
      cb: thisDialog => {
        resolve(true);
        thisDialog.handleHide();
        printFaultTip(1);
        resolve(restartLODOP());
      },
    };
    const cencelBtn = {
      name: '取消',
      btnType: 'default',
      cb: thisDialog => {
        resolve(false);
        window.PRINTERRORSTEP = 0;
        window.localStorage.setItem('lastPrintTime', '');
        thisDialog.handleHide();
      },
    };

    window.printFaultModal && window.printFaultModal.close();

    switch (errorNo) {
      case 1:
        content = (
          <div>
            <p>打印故障智能修复中，请耐心等待……</p>
            <p>注意：修复程序会通过打印测试页来效验故障是否被排除</p>
          </div>
        );
        type = NETERR;
        iconType = INFO;
        break;
      case 2:
        content = (
          <div>
            <p>恭喜您！打印故障已排除，祝您使用愉快！</p>
          </div>
        );
        iconType = CHECK;
        type = NETERR;
        break;
      case 5:
        content = (
          <div>
            <p>
              请点击
              <a
                href="http://download.chemanman.com/ucmm/desktop/repairTools.exe"
                rel="nofollow me noopener noreferrer"
                target="_blank"
              >
                【修复工具】
              </a>
              下载打印机故障修复工具。
            </p>
            <p className="error-tip">注意：请在下载后，先双击运行“修复工具.bat“，执行成功后，再点击”下一步“</p>
          </div>
        );
        iconType = INFO;
        buttons.push(confirmBtn);
        break;
      case 6:
        // printLogProxy = {
        //   logType: '3: 已走完全流程',
        //   CVERSION: globalLodopObj.CVERSION,
        //   printPort,
        //   cDownload: globalLodopObj.cDownload,
        //   fixDownload: globalLodopObj.fixDownload,
        //   oriPrintSln: globalLodopObj.oriPrintSln,
        //   printSln: globalLodopObj.printSln,
        // }
        // recordLog({ message: printLogProxy })
        content = (
          <div className="fn-show_info__text">
            <div className="dialog_detail">
              <p className="notice">自动修复完成！请确认打印是否正常？</p>
              <div className="checkinfo-detail">
                <p className="notice_title">接下来：</p>
                <p className="tips error-tip notice_title">
                  如果仍然无法打印。请打印测试页，确认是打印机自身的问题还是系统设置的问题？
                </p>
                <div className="detail-content">
                  <p className="notice_list">
                    <em>●</em>
                    情形一：如果测试页面无法打印，这个是打印机自身的问题，确保打印机是开机状态和线路连接正常后，请联系打印机厂家客服寻求帮助
                  </p>
                  <p className="notice_list">
                    <em>●</em>
                    情形二：如果测试页正常打印，请重启电脑后，再次测试使用系统是否能正常打印，如果还有问题，请在工作时间联系系统维护
                    <a
                      href="http://crm2.qq.com/page/portalpage/wpa.php?uin=4008275656&amp;aty=1&amp;a=0&amp;curl=&amp;ty=1"
                      rel="nofollow me noopener noreferrer"
                      target="_blank"
                    >
                      【客服QQ】
                    </a>
                    寻求帮助
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
        className = `${className}-fail`;
        iconType = CHECK;
        cencelBtn.name = '知道了';
        buttons.push(cencelBtn);
        break;
      default:
        break;
    }

    const popup = (window.printFaultModal = new PopUp(ShowInfo, {
      classname: className,
      popName: 'printFault',
      isModal: true,
      isShow: true,
      autoDestroy: true,
      title,
      content,
      buttons,
      type,
      onClose: () => {
        delete window.printFaultModal;
        resolve(false);
      },
      iconType,
      // showIcon,
      closable: true,
      maskClosable: false,
    }));
    popup.show();
  });
}

export const apiPrintTpl = (resDt, selected, odLinkIdList, printCallBack, ext, type) => {
  const resResult = resDt.res;
  let printSlnDgRef = null; // eslint-disable-line
  if (resResult) {
    resResult.data.printer.otherProps.data = LodopFunc.getAllPrinters(LodopFunc.getLodop());
  }
  const slecType = ext ? ext[0] || false : false;
  const companyId = ext ? ext[1] || {} : {};

  const popup = (window.printSlnDialogModel = new PopUp(type === 'error' ? printSlnDialogError : printSlnDialog, {
    dialogKey: 'printSln',
    popName: 'printSlnDialog',
    isModal: true,
    dialogInfo: resResult || {},
    cid: window.globalPrintHostId,
    ref: r => (printSlnDgRef = r),
    tplId: selected.tpl_key || selected,
    tplType: selected.tpl_type,
    companyId: companyId.company_id,
    groupId: companyId.group_id,
    callback: () => {
      type === 'error' && printFaultTip(1);
    }, // 错误的时候再进行错误检查
    closePrintSln: (where, testSlnSet) => {
      if (where === 'confirm') {
        actions.orderServer
          .getPrintInfo({
            cid: window.globalPrintHostId,
            tpl_id: selected.tpl_key,
            fields_type: selected.tpl_type,
            ids: odLinkIdList,
            company_id: getPrintComId(companyId.company_id),
            group_id: companyId.group_id,
          })
          .then(rest => {
            const resData = rest.data;
            testSlnSet && (resData.res.tpl_setting = { ...resData.res.tpl_setting, ...testSlnSet });
            printCallBack(resData, selected, odLinkIdList, slecType, companyId);
          });
      } else {
        printSlnDgRef = null;
      }
    },
  }));
  popup.show();
};

export const getPrintSln = (tplId, checkResult) =>
  new Promise(resolve => {
    let cancelable = true;
    actions.orderServer
      .getPrintSln({
        cid: window.globalPrintHostId,
        tpl_id: tplId,
      })
      .then(res => {
        const resResult = res.data.res;
        if (resResult && checkResult === 2) {
          //      resResult.data.print_tpl_name.labelProps.children = window.globalPrintHostName
          resResult.data.printer.otherProps.data = LodopFunc.getAllPrinters(LodopFunc.getLodop());
        }
        const popup = (window.printSlnDialogModel = new PopUp(printSlnDialogError, {
          dialogKey: 'printSln',
          dialogInfo: resResult || {},
          cid: window.globalPrintHostId,
          tplId,
          isModal: true,
          popName: 'printSlnDialog',
          callback: pack => {
            cancelable = false;
            // printFaultTip(1, pack)   // 注释掉原因：correctResult 取得是closePrintSln中resolve的值
          },
          onClose: () => cancelable && resolve(false),
          closePrintSln: (where, pack) => {
            if (where === 'confirm') {
              // window.printFaultModal && window.printFaultModal.close()
              // resolve(printFaultTip(1, pack))  // 注释掉原因： 进度条打印，只打印最后一个
              // resolve(pack) // 进度条打印循环执行 的 原因
              // printFaultTip(1)
              restartLODOP();
              setTimeout(() => {
                // printFaultTip(6)
                resolve(pack || {});
              }, 10 * 1000);
              console.log('closePrintSln, where === confirm');
            } else if (where === 'cancel') {
              resolve(false);
            }
          },
        }));
        const GIFPop = (window.printGIFPop = new PopUp(
          (
            <div className="print-GIF-pop-dg">
              <img src={require('images/print/print-wait.gif')} alt="" />
            </div>
          ),
          {
            isModal: true,
            popName: 'printGIFPopDg',
          },
        ));
        setTimeout(() => {
          window.printGIFPop && window.printGIFPop.close();
          popup.show();
        }, 2000);
        GIFPop.show();
      });
  });

/*
 * 1 正常
 * 2 成功-提示
 * 3 错误-提示
 */
export function printFaultCorrect(checkResult, printSetting) {
  return new Promise(resolve => {
    window.PRINTERRORSTEP = (window.PRINTERRORSTEP || 1) + 1;
    // 记录 printFaultCorrect 执行次数
    // 阻止循环提示

    if ([2, 3].includes(checkResult) && window.PRINTERRORSTEP === 2) {
      // 弹新的打印方案弹框
      // if ([2, 3].includes(checkResult) && (window.PRINTERRORSTEP >= 2)) {   // 弹新的打印方案弹框
      resolve(getPrintSln(printSetting && printSetting.tpl_id, checkResult));
      // resolve(false)
    } else {
      resolve(false);
    }

    /*
    window.PRINTERRORSTEP = (window.PRINTERRORSTEP || 1) + 1  // 不知道干啥使的呢
    if (+res === 4) {
      resolve(printFaultTip(5))
      window.PRINTERRORSTEP = 3
    } else if (res === 3 && window.PRINTERRORSTEP === 2) {   // 第二步
      resolve(getPrintSln(printSetting && printSetting.tpl_id))
    } else if (res === 3 && window.PRINTERRORSTEP === 3) {   // 第三步
      resolve(printFaultTip(5))
    } else if (res === 1 && window.PRINTERRORSTEP === 4) {
      resolve(printFaultTip(2))
    } else if (res === 2 && window.PRINTERRORSTEP && window.PRINTERRORSTEP > 2) {
      resolve(false)
      resolve(printFaultTip(2))        // 第四步 正确
    } else if (res === 3 && window.PRINTERRORSTEP === 4) {   // 第四步 错误
      resolve(false)
      printFaultTip(6)
    } else {
      resolve(false)
    }
    */
  });
}
