import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { TimePicker } from 'components';
import { deleteProps } from 'utils';
import { prefixCls } from './index.scss';

export default class PureTimePicker extends Component {
  constructor(props) {
    super(props);
    let { defaultValue } = props;
    const { format = 'HH:mm:ss' } = props;
    if (defaultValue && Object.prototype.toString.call(defaultValue) === '[object String]') {
      defaultValue = moment(defaultValue, format);
    }
    this.state = {
      defaultValue,
      format,
    };
  }

  static propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    classname: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    format: PropTypes.string,
    strValue: PropTypes.bool,
    allowEmpty: PropTypes.bool,
  };
  static defaultProps = {
    format: 'HH:mm:ss',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      let { defaultValue } = nextProps;
      if (defaultValue && Object.prototype.toString.call(defaultValue) === '[object String]') {
        defaultValue = moment(defaultValue, nextProps.format);
      }
      this.setState({ defaultValue });
    }
    if (nextProps.format !== this.props.format) {
      this.setState({ format: nextProps.format });
    }
  }

  handleChange = (time, strTime) => {
    const value = this.props.strValue ? strTime : time;
    this.props.onChange && this.props.onChange(value);
  };

  render() {
    const { classname, type, style, disabled, ...rest } = this.props;
    let { defaultValue } = this.state;
    const { format } = this.state;
    defaultValue = defaultValue ? moment(defaultValue) : undefined;
    const classes = classnames({
      [classname]: classname,
      [`${prefixCls}--${type}`]: type,
      [`${prefixCls}--disabled`]: disabled,
    });
    const prop = deleteProps(rest, 'defaultValue');
    return (
      <TimePicker
        {...prop}
        style={{ width: '140px', ...style }}
        className={classes}
        format={format}
        onChange={this.handleChange}
        value={defaultValue}
        disabled={disabled}
      />
    );
  }
}
