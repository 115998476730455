// mode district address
export function isMunicipality(cityName = '') {
  const mCitys = ['北京', '上海', '天津', '重庆'];
  return mCitys.some(item => `${cityName}`.indexOf(item) !== -1);
}

export function exitValue(ivalue, mode) {
  const pois = ivalue;
  return false;
}

function getResultList(result, mode) {
  if (mode === 'district') {
    // return result.districtList
    return result.poiList.pois;
  } else if (mode === 'address') {
    return result.poiList.pois;
  }
  return result.poiList.pois;
}

function resolveAddress(pois, poiDatasRet, poiDatas, selectDropData) {
  pois.forEach((d, i) => {
    const rdata = {
      name: d.name,
      showpname: d.showpname,
      pcode: d.pcode,
      citycode: d.citycode,
      adcode: d.adcode,
      pname: d.pname,
      cityname: d.cityname,
      adname: d.adname,
      address: d.address,
      street: d.street || '', // 街道
      type: d.type || '', // 地址类型
      typecode: d.typecode || '', // 地址类型码
      lng: d.location.getLng(),
      lat: d.location.getLat(),
      lnglat: [d.location.getLng(), d.location.getLat()].join(','),
    };
    // values
    const addressInfoName =
      rdata.name === rdata.pname || rdata.name === rdata.cityname || rdata.name === rdata.adname ? '' : rdata.name;
    const isMCity = isMunicipality(rdata.pname);
    let value = `${rdata.cityname}${rdata.adname}${addressInfoName}`;
    let info = `${rdata.pname}${rdata.cityname}${rdata.adname}`;
    if (isMCity) {
      rdata.cityname = rdata.adname ? rdata.pname : ''; // 有区一级信息 则市信息显示
      value = `${rdata.pname}${rdata.adname}${addressInfoName}`;
      info = `${rdata.pname}${rdata.adname}`;
    }
    const listVal = rdata.name;
    // 普通地址直接显示 省市区 + 搜索值， 四级地址 显示地址值
    if (['190101', '190102', '190103', '190104', '190105', '190106', '190107'].includes(rdata.typecode)) {
      info = rdata.showpname;
      value = `${rdata.pname}${isMCity ? '' : rdata.cityname}${rdata.adname}${rdata.street}`;
    }
    if (!exitValue(value)) {
      poiDatas.push({
        pcode: rdata.pcode,
        citycode: rdata.citycode,
        adcode: rdata.adcode,
        pname: rdata.pname,
        cityname: rdata.cityname,
        adname: rdata.adname,
        address: rdata.address,
        street: rdata.street || '', // 街道
        type: rdata.type, // 地址类型
        lng: rdata.lng,
        lat: rdata.lat,
        lnglat: rdata.lnglat,
      });
      selectDropData.push({
        index: i,
        value,
        info,
        listVal,
      });
      poiDatasRet.push({
        show_val: value,
        province: rdata.pname,
        city: rdata.cityname,
        district: rdata.adname,
        street: rdata.street || '',
        adcode: rdata.adcode,
        poi: rdata.lnglat,
      });
    }
  });
}

function resolveDistrict(pois, poiDatasRet, poiDatas, selectDropData, fullName) {
  pois.forEach((d, i) => {
    const rdata = {
      id: d.id,
      name: d.name,
      pcode: d.pcode,
      citycode: d.citycode,
      adcode: d.adcode,
      pname: d.pname,
      showpname: d.showpname,
      cityname: d.cityname,
      adname: d.adname,
      address: d.address,
      street: d.street || '', // 街道
      type: d.type, // 地址类型
      typecode: d.typecode,
      lng: d.lng || (d.location ? d.location.getLng() : ''),
      lat: d.lat || (d.location ? d.location.getLat() : ''),
      lnglat: d.lnglat || (d.location ? [d.location.getLng(), d.location.getLat()] : []).join(','),
    };
    // values
    const value = fullName ? `${d.showpname}${d.name}` : rdata.name; // rdata.adname || rdata.cityname || rdata.pname
    const listVal = rdata.name; // rdata.adname || rdata.cityname || rdata.pname
    const info = rdata.showpname;
    if (!exitValue(value)) {
      poiDatas.push({
        id: rdata.id,
        name: rdata.name,
        pcode: rdata.pcode,
        citycode: rdata.citycode,
        adcode: rdata.adcode,
        pname: rdata.pname,
        cityname: rdata.cityname,
        adname: rdata.adname,
        address: rdata.address,
        street: rdata.street || '', // 街道
        type: rdata.type, // 地址类型
        typecode: rdata.typecode, // 地址类型码
        lng: rdata.lng,
        lat: rdata.lat,
        lnglat: rdata.lnglat,
      });
      selectDropData.push({
        index: i,
        value,
        info,
        unique_key: d.unique_key,
        listVal,
      });
      poiDatasRet.push({
        id: rdata.id,
        show_val: value,
        province: rdata.pname,
        city: rdata.cityname,
        district: rdata.adname,
        street: rdata.street || '',
        adcode: rdata.adcode,
        poi: rdata.lnglat,
      });
    }
  });
}

export function getReturnData(status, result, mode, fullName) {
  let pois = [];
  const poiDatasRet = [];
  const poiDatas = [];
  let selectDropData = [];
  if (status !== 'no_data') {
    pois = getResultList(result, mode);
  }
  if (status === 'no_data' || pois.length <= 0 || !pois) {
    return { poiDatasRet, poiDatas, selectDropData };
  }
  selectDropData = [];
  if (mode === 'district') {
    resolveDistrict(pois, poiDatasRet, poiDatas, selectDropData, fullName);
  } else {
    resolveAddress(pois, poiDatasRet, poiDatas, selectDropData);
  }
  return { poiDatasRet, poiDatas, selectDropData };
}

// pois.forEach((d, i) => {
//   let rdata = {
//     name: d.name,
//     pcode: d.pcode,
//     citycode: d.citycode,
//     adcode: d.adcode,
//     pname: d.pname,
//     cityname: d.cityname,
//     adname: d.adname,
//     address: d.address,
//     street: d.street || '', // 街道
//     type: d.type, // 地址类型
//     lng: d.location.getLng(),
//     lat: d.location.getLat(),
//     lnglat: [d.location.getLng(), d.location.getLat()].join(','),
//   }
//   // values
//   let value = rdata.name  // rdata.adname || rdata.cityname || rdata.pname
//   let listVal = rdata.name  // rdata.adname || rdata.cityname || rdata.pname
//   let info = isMunicipality(rdata.pname) ? `${rdata.pname}` :
//     `${rdata.pname}${rdata.cityname}`
//   if (rdata.name === rdata.pname) {
//     // 省
//     rdata.cityname = ''
//     rdata.adname = ''
//     info = `${rdata.pname}`
//   } else if (rdata.name === rdata.cityname) {
//     // 市
//     rdata.adname = ''
//     info = `${rdata.pname}${rdata.cityname}`
//   }
//   // values
//   if (mode === 'address') {
//     value = `${rdata.cityname}${rdata.adname}${rdata.name}`
//     listVal = rdata.name
//     info = isMunicipality(rdata.pname) ? `${rdata.pname}${rdata.adname}` :
//       `${rdata.pname}${rdata.cityname}${rdata.adname}`
//   } else if (mode === 'district') {
//     value = rdata.name  // rdata.adname || rdata.cityname || rdata.pname
//     listVal = rdata.name  // rdata.adname || rdata.cityname || rdata.pname
//     info = isMunicipality(rdata.pname) ? `${rdata.pname}` :
//       `${rdata.pname}${rdata.cityname}`
//     if (rdata.name === rdata.pname) {
//       // 省
//       rdata.cityname = ''
//       rdata.adname = ''
//       info = `${rdata.pname}`
//     } else if (rdata.name === rdata.cityname) {
//       // 市
//       rdata.adname = ''
//       info = `${rdata.pname}${rdata.cityname}`
//     }
//     // if (rdata.type.indexOf('街道级') !== -1 || rdata.type.indexOf('乡镇级') !== -1) {
//     //   rdata.street = rdata.name
//     //   info = `${info}${rdata.adname}`
//     // }
//   }
//   // if (rdata.type.indexOf('街道级') !== -1 || rdata.type.indexOf('乡镇级') !== -1) {
//   if (['190106', '190107'].includes(rdata.typecode)) {
//     rdata.street = rdata.name
//     info = `${info}${rdata.adname}`
//   }
//   if (!exitValue(value)) {
//     poiDatas.push({
//       pcode: rdata.pcode,
//       citycode: rdata.citycode,
//       adcode: rdata.adcode,
//       pname: rdata.pname,
//       cityname: rdata.cityname,
//       adname: rdata.adname,
//       address: rdata.address,
//       street: rdata.street || '', // 街道
//       type: rdata.type, // 地址类型
//       lng: rdata.lng,
//       lat: rdata.lat,
//       lnglat: rdata.lnglat,
//     })
//     selectDropData.push({
//       index: i,
//       value,
//       info,
//       listVal
//     })
//     poiDatasRet.push({
//       show_val: value,
//       province: rdata.pname,
//       city: rdata.cityname,
//       district: rdata.adname,
//       street: rdata.street || '',
//       adcode: rdata.adcode,
//       poi: rdata.lnglat
//     })
//   }
// })
// if (mode === 'address') {
//   pois.forEach((d, i) => {
//     let rdata = {
//       name: d.name,
//       pcode: d.pcode,
//       citycode: d.citycode,
//       adcode: d.adcode,
//       pname: d.pname,
//       cityname: d.cityname,
//       adname: d.adname,
//       address: d.address,
//       street: d.street, // 街道
//       type: d.type, // 地址类型
//       lng: d.location.getLng(),
//       lat: d.location.getLat(),
//       lnglat: [d.location.getLng(), d.location.getLat()].join(','),
//     }
//     let value = `${rdata.cityname}${rdata.adname}${rdata.name}`
//     let listVal = rdata.name
//     let info = isMunicipality(rdata.pname) ? `${rdata.pname}${rdata.adname}` :
//       `${rdata.pname}${rdata.cityname}${rdata.adname}`
//     if (!exitValue(value)) {
//       poiDatas.push({
//         pcode: rdata.pcode,
//         citycode: rdata.citycode,
//         adcode: rdata.adcode,
//         pname: rdata.pname,
//         cityname: rdata.cityname,
//         adname: rdata.adname,
//         address: rdata.address,
//         street: rdata.street, // 街道
//         type: rdata.type, // 地址类型
//         lng: rdata.lng,
//         lat: rdata.lat,
//         lnglat: rdata.lnglat,
//       })
//       selectDropData.push({
//         index: i,
//         value,
//         info,
//         listVal
//       })
//       poiDatasRet.push({
//         show_val: value,
//         province: rdata.pname,
//         city: rdata.cityname,
//         district: rdata.adname,
//         adcode: rdata.adcode,
//         poi: rdata.lnglat
//       })
//     }
//   })
// } else {
//   pois.forEach((d, i) => {
//     let rdata = {
//       name: d.name,
//       pcode: d.pcode,
//       citycode: d.citycode,
//       adcode: d.adcode,
//       pname: d.pname,
//       cityname: d.cityname,
//       adname: d.adname,
//       address: d.address,
//       street: d.street, // 街道
//       type: d.type, // 地址类型
//       lng: d.location.getLng(),
//       lat: d.location.getLat(),
//       lnglat: [d.location.getLng(), d.location.getLat()].join(','),
//     }
//     let value = rdata.name  // rdata.adname || rdata.cityname || rdata.pname
//     let listVal = rdata.name  // rdata.adname || rdata.cityname || rdata.pname
//     let info = isMunicipality(rdata.pname) ? `${rdata.pname}` :
//       `${rdata.pname}${rdata.cityname}`
//     if (rdata.name === rdata.pname) {
//       // 省
//       rdata.cityname = ''
//       rdata.adname = ''
//       info = `${rdata.pname}`
//     } else if (rdata.name === rdata.cityname) {
//       // 市
//       rdata.adname = ''
//       info = `${rdata.pname}${rdata.cityname}`
//     }
//     if (!exitValue(value)) {
//       poiDatas.push({
//         pcode: rdata.pcode,
//         citycode: rdata.citycode,
//         adcode: rdata.adcode,
//         pname: rdata.pname,
//         cityname: rdata.cityname,
//         adname: rdata.adname,
//         address: rdata.address,
//         street: rdata.street, // 街道
//         type: rdata.type, // 地址类型
//         lng: rdata.lng,
//         lat: rdata.lat,
//         lnglat: rdata.lnglat,
//       })
//       selectDropData.push({
//         index: i,
//         value,
//         info,
//         listVal
//       })
//       poiDatasRet.push({
//         show_val: value,
//         province: rdata.pname,
//         city: rdata.cityname,
//         district: rdata.adname,
//         adcode: rdata.adcode,
//         poi: rdata.lnglat
//       })
//     }
//   })
// }
