import PropTypes from 'prop-types';

import React, { Component } from 'react';
import RcTree from './../RcTree';
import TreeNode from './../TreeNode';
import './index.scss';

RcTree.TreeNode = TreeNode;
export { RcTree, TreeNode };
export default class Tree extends Component {
  constructor(props) {
    super(props);
    this.pNodeKeys = [];
    this.lNodeKeys = [];
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
    nodeProps: PropTypes.object,
    addNodeProps: PropTypes.func,
    children: PropTypes.node,
    nodeNameFormat: PropTypes.func,
  };
  static defaultProps = {
    data: {},
    nodeProps: {},
  };
  getNodeTitle = (item, defaultValue) => {
    const { nodeNameFormat } = this.props;
    if (typeof nodeNameFormat === 'function') {
      return nodeNameFormat(item);
    }
    return defaultValue;
  };
  loopNode = treeData => {
    const tree = treeData.data;
    if (!tree) return null;
    const { showKey } = treeData;
    const { uniqueKey } = treeData;
    this.pNodeKeys = [];
    this.lNodeKeys = [];
    const loopData = (idata, ishowKey, iuniqueKey, parent) =>
      Object.entries(idata).map(([, item]) => {
        // let item = iitem[1]
        const addNodeProps = this.props.addNodeProps && this.props.addNodeProps(item);
        if (item.children && Object.entries(item.children).length > 0) {
          this.pNodeKeys.push(item[uniqueKey]);
          return (
            <TreeNode
              key={item[uniqueKey]}
              title={this.getNodeTitle(item, item[showKey])}
              parent={parent}
              {...addNodeProps}
              {...this.props.nodeProps}
            >
              {loopData(item.children, ishowKey, iuniqueKey, item)}
            </TreeNode>
          );
        }
        this.lNodeKeys.push(item[uniqueKey]);
        return (
          <TreeNode
            key={item[uniqueKey]}
            title={this.getNodeTitle(item, item[showKey])}
            parent={parent}
            {...addNodeProps}
            {...this.props.nodeProps}
          >
            {this.props.children}
          </TreeNode>
        );
      });
    return loopData(tree, showKey, uniqueKey, null);
  };
  getCheckedKeys = () => this._rcTree.getCheckedKeys();
  getHalfCheckedKeys = () => this._rcTree.getHalfCheckedKeys();
  getCurrSelectedNode = () => this._rcTree.getCurrSelectedNode();
  getLNodeKeys = () => this.lNodeKeys || [];
  getPNodeKeys = () => this.pNodeKeys || [];

  render() {
    const { ..._props } = this.props;
    delete _props.nodeProps;
    delete _props.addNodeProps;
    // const treeNodes = this.props.data.data || []
    const treeData = this.props.data || null;
    return (
      <RcTree {..._props} ref={t => (this._rcTree = t)}>
        {treeData && this.loopNode(treeData)}
      </RcTree>
    );
  }
}
