import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { fetchApi as fetch, ot } from 'utils';
import { ModalDialog, Button, Icon, Tips } from 'components';
import adaptor from '../adaptor';
import { getErrorMsg } from '../tool';
import saveOrder from '../public/saveOrder';
import calcCeeDist from '../public/calcCeeDist';
import calcCorDist from '../public/calcCorDist';
import { prefixCls } from './index.scss';

const DOING = 1;
const SUCCESS = 2;
const FAIL = 3;

const iconMap = {
  [DOING]: 'icon-info-o',
  [SUCCESS]: 'icon-check-o',
  [FAIL]: 'icon-error-o',
};

const statusMap = {
  [DOING]: '正在处理中…',
  [SUCCESS]: '操作成功',
  [FAIL]: '操作失败',
};
const getFailedNum = ({ list, status }, reservationError = {}) =>
  status === FAIL ? list.length : list.filter(item => reservationError[item.id]).length;

class MergeReservationModal extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    close: PropTypes.func,
  };

  constructor(prop) {
    super(prop);
    const data = {};
    const { errList, sucList, reservationError } = this.check(this.props.data);
    const group = this.chunk(sucList);
    this.state = { data: group, total: this.props.data.length, reservationError, errList };
  }

  async componentDidMount() {
    const groups = Object.values(this.state.data);
    for (const group of groups) !this.cancel && (await this.process(group));
  }

  fetchOrder = async group => {
    if (!group.list.length) return { error: '无可操作订单' };
    const url = '/Order/Order/co?raw=1';
    const conf = {
      method: 'POST',
      body: { req: { reservation_num: group.list.map(item => item.reservation_num), co_mode: 0, is_merge: true } },
    };
    const res = await fetch(url, conf);
    return res.errno === 0 ? { order: adaptor({ data: res, usedFor: 'create' }) } : { error: getErrorMsg(res) };
  };
  check = list => {
    const mergeGoodsLimit = +(
      window.company_setting.merge_goods_limit && window.company_setting.merge_goods_limit.value
    );
    const reservationError = {};
    const errList = [];
    const sucList = [];
    list.forEach(item => {
      if (item.od_basic_id) {
        reservationError[item.id] = '已关联运单';
      } else if (+item.accept === 0) {
        reservationError[item.id] = '订单未受理，请先受理再合单';
      } else if (+item.obsolete === 1) {
        reservationError[item.id] = '订单已作废';
      } else if (+item.del_status) {
        reservationError[item.id] = '订单已删除';
      } else if (+item.accept === 2) {
        reservationError[item.id] = '订单已拒绝';
      } else if (mergeGoodsLimit && (item.g_name || []).length > mergeGoodsLimit) {
        reservationError[item.id] =
          '订单货物行数大于“自动合单货物行数上限”设置的货物上限，无法自动合单。可通过转运单生成相应运单。';
      }
      reservationError[item.id] ? errList.push(item) : sucList.push(item);
    });
    return { errList, reservationError, sucList };
  };
  chunk = reservationList => {
    const mergeGoodsLimit = +(
      window.company_setting.merge_goods_limit && window.company_setting.merge_goods_limit.value
    );
    const mergeSet = (window.company_setting.merge_set && window.company_setting.merge_set.value) || ['1'];
    const data = {};
    reservationList.forEach((item, idx) => {
      let id = '';
      const mergeSetData = [];
      mergeSet.forEach(i => {
        switch (i) {
          case '1':
            return mergeSetData.push(
              `${item.cee_mobile || `cee_mobile_${idx}`}`,
              `${item.cee_name || `cee_name_${idx}`}`,
            );
          case '2':
            // eslint-disable-next-line no-case-declarations
            const date1 = item.reservation_date;
            return mergeSetData.push(date1.split(' ')[0] || `date_${idx}`);
          case '3':
            return mergeSetData.push(`${item.cor_com || `cor_com_${idx}`}`);
          case '4':
            return mergeSetData.push(`${item.cee_com || `cee_com_${idx}`}`);
          case '5':
            return mergeSetData.push(`${item.cee_addr_info || `cee_addr_info_${idx}`}`);
          default:
            break;
        }
      });
      id = mergeSetData.join('|');
      const group = data[id] || { id, list: [] };
      const { list } = group;
      list.push(item);
      data[id] = group;
    });
    if (!mergeGoodsLimit) {
      return data;
    }
    // 根据merge_goods_limit给订单分组
    Object.values(data).forEach(({ id, list }) => {
      let goodsNum = 0;
      let group = [];
      let num = 0;
      delete data[id];
      list.forEach((item, index) => {
        goodsNum += (item.g_name || []).length;
        if (goodsNum > mergeGoodsLimit) {
          goodsNum = 0;
          data[`${id}-${num}`] = {
            id: `${id}-${num}`,
            list: [...group],
          };
          num += 1;
          goodsNum += (item.g_name || []).length;
          group = [];
        }
        group.push(item);
        if (index === list.length - 1) {
          data[`${id}-${num}`] = {
            id: `${id}-${num}`,
            list: [...group],
          };
        }
      });
    });
    return data;
  };

  saveOrder = async (group, order) => {
    const res = await saveOrder(order, 'merge', { is_merge: true });
    return res.errno === 0 ? { orderNum: res.res.order_data.order_num } : { error: getErrorMsg(res) };
  };

  process = async group => {
    const { data } = this.state;
    const { id } = group;
    this.setState({ data: { ...data, [id]: { ...group, status: DOING } } });
    const { order, error } = await this.fetchOrder(group);
    if (error) {
      this.setState({ data: { ...data, [id]: { ...group, status: FAIL, error } } });
      return false;
    }
    const [corPickDist, ceePickDist] = await Promise.all([calcCorDist(order), calcCeeDist(order)]);
    const { orderNum, error: saveError } = await this.saveOrder(group, { ...order, corPickDist, ceePickDist });
    if (saveError) {
      this.setState({ data: { ...data, [id]: { ...group, status: FAIL, error: saveError } } });
      return false;
    }
    this.setState({ data: { ...data, [id]: { ...group, status: SUCCESS, orderNum } } });
  };

  close = done => {
    this.cancel = !done;
    this.modal.handleHide();
  };

  render() {
    const { data, total, reservationError = {}, errList = [] } = this.state;
    const groups = Object.values(data);
    const cur = groups.reduce((pre, { status, list }) => (status ? pre + list.length : pre), 0);
    const done = groups.every(({ status }) => status === SUCCESS || status === FAIL);
    const failed = done && groups.reduce((pre, group) => pre + getFailedNum(group, reservationError), errList.length);
    const succeeded = done && total - failed;
    let index = 0;
    const content = (
      <div className={prefixCls}>
        <div className="process">
          <Icon iconType={done ? 'icon-check-o' : 'icon-info-o'} />
          {!done && (
            <span>
              合单进度（第<span className="fs22 text-orange">{cur}</span>个/共{total}个），
              <span className="text-red">操作进行中，请勿关闭！</span>
            </span>
          )}
          {done && (
            <span>
              合单已完成，<span className="fs22 text-green">{succeeded}</span>个操作成功，
              <span className="fs22 text-red">{failed}</span>个操作失败！
            </span>
          )}
        </div>
        <div className="fn-table-wrap">
          <table className="fn-table-a">
            <thead>
              <tr>
                <th width={30}>序号</th>
                <th>{ot('订单号')}</th>
                <th>{ot('收货人')}</th>
                <th>{ot('收货人手机')}</th>
                <th>{ot('件数')}</th>
                <th>{ot('运单号')}</th>
                <th width={100}>操作状态</th>
              </tr>
            </thead>
            <tbody>
              {errList.map(row => {
                index++;
                const errorText = reservationError[row.id];
                const statusText = (
                  <Fragment>
                    <Icon iconType={iconMap[FAIL]} />
                    <span>{statusMap[FAIL]}</span>
                  </Fragment>
                );
                return (
                  <tr key={row.id}>
                    <td>{index}</td>
                    <td>{row.reservation_num}</td>
                    <td>{row.cee_name}</td>
                    <td>{row.cee_mobile}</td>
                    <td>{row.g_num && row.g_num.join(',')}</td>
                    <td>{row.order_num}</td>
                    <td>
                      <Tips className="status-bar" title={errorText}>
                        {statusText}
                      </Tips>
                    </td>
                  </tr>
                );
              })}
              {groups.map(({ list, orderNum, status, error }) =>
                list.map(row => {
                  index++;
                  const errorText = reservationError[row.id] || error;
                  const statusText = (
                    <Fragment>
                      <Icon iconType={iconMap[reservationError[row.id] ? FAIL : status]} />
                      <span>{statusMap[reservationError[row.id] ? FAIL : status]}</span>
                    </Fragment>
                  );
                  return (
                    <tr key={row.id}>
                      <td>{index}</td>
                      <td>{row.reservation_num}</td>
                      <td>{row.cee_name}</td>
                      <td>{row.cee_mobile}</td>
                      <td>{row.g_num && row.g_num.join(',')}</td>
                      <td>{row.order_num || orderNum}</td>
                      <td>
                        {status &&
                          (errorText ? (
                            <Tips className="status-bar" title={errorText}>
                              {statusText}
                            </Tips>
                          ) : (
                            <span className="status-bar">{statusText}</span>
                          ))}
                      </td>
                    </tr>
                  );
                }),
              )}
            </tbody>
          </table>
        </div>
      </div>
    );

    const bottom = (
      <Button type="primary" onClick={() => this.close(done)}>
        {done ? '关闭' : '取消'}
      </Button>
    );

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={content}
        bottom={bottom}
        isShow
        isModal
        close={this.props.close}
        title="合单进度"
        contentStyle={{ width: '868px' }}
      />
    );
  }
}

export default MergeReservationModal;
