import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fetchApi as fetch, ot } from 'utils';
import { directToOrderDetail } from 'utils/business/order/direct';
import { ModalDialog, Button, Load, Icon } from 'components';
// eslint-disable-next-line import/no-cycle
import { sugCorCee, formatSugData } from '../tool';
import { prefixCls } from './index.scss';

class HistoryModal extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    store: PropTypes.object,
    close: PropTypes.func,
    page: PropTypes.object,
    cid: PropTypes.any,
  };

  constructor(prop) {
    super(prop);
    const { type, store } = this.props;
    const outerState = store.getState();
    const text = type === 'cor_name' ? '发' : '收';
    this.state = {
      text,
      header: type === 'cor_name' ? outerState.corHeader : outerState.ceeHeader,
      placeholder: `搜索${text}货人`,
      data: [],
      name: type === 'cor_name' ? outerState.corName : outerState.ceeName,
      mobile: type === 'cor_name' ? outerState.corMobile : outerState.ceeMobile,
      remark: type === 'cor_name' ? outerState.corRemark : outerState.ceeRemark,
      weightUnit: outerState.weightUnit,
      weightUnitIsTon: outerState.weightUnit === '吨',
      unitMap: outerState.unitMap,
      tableHidden: window.localStorage.tableHidden || '',
      remarkHidden: window.localStorage.remarkHidden || '',
    };
    this.state.name && this.search();
  }

  toggle = cardKey => {
    const newHidden = !this.state[cardKey] ? 'hidden' : '';
    window.localStorage[cardKey] = newHidden;
    this.setState({ [cardKey]: newHidden });
  };

  search = async () => {
    this.setState({ loading: true });
    const { name, mobile, weightUnitIsTon, unitMap } = this.state;
    const isCor = this.props.type === 'cor_name';
    const url = '/Table/Search/orderList';
    const req = {
      category: 'Order',
      tab: 'cor_cee_search',
      sort: { billing_date: 'desc' },
      page_num: 1,
      page_size: 10,
      fetch_mode: 'body',
      query: {
        'cor_name._exact_': isCor ? name : undefined,
        'cee_name._exact_': !isCor ? name : undefined,
        com_id: this.props.cid,
      },
      filter: {},
      fields: [
        'order_num',
        'billing_date',
        'g_name',
        'arr',
        'cor_name',
        'cee_name',
        'total_price',
        'g_unit_price_disp',
        'g_n',
        'g_weight',
        'g_volume',
        'od_basic_id',
        'od_link_id',
        'b_inner_trans_in_batch_id',
      ],
    };

    // 37021
    if (mobile) {
      req.query['cor_mobile._exact_'] = isCor ? mobile : undefined;
      req.query['cee_mobile._exact_'] = !isCor ? mobile : undefined;
    } else {
      const notLogicKey = isCor ? 'cor_mobile._exact_' : 'cee_mobile._exact_';
      req.query['0'] = {
        _logic: 'or',
        'cor_mobile._exact_': isCor ? mobile : undefined,
        'cee_mobile._exact_': !isCor ? mobile : undefined,
        0: {
          _logic: 'not',
          [notLogicKey]: 'RETRIEVER::EXIST_FIELD',
        },
      };
    }

    const conf = { method: 'POST', body: { req } };
    const res = await fetch(url, conf);
    const data = res.res.data.map(row => ({
      ...row,
      num: row.g_n && row.g_n.join(','),
      volume: row.g_volume && row.g_volume.join(','),
      // eslint-disable-next-line no-nested-ternary
      weight: row.g_weight && row.g_weight.map(w => (w ? (weightUnitIsTon ? +(w / 1000).toFixed(6) : w) : 0)).join(','),
      unit:
        row.g_unit_price_disp &&
        row.g_unit_price_disp
          .map(item => {
            const unit = item && item.unit_p_unit;
            const unitP = item && item.unit_p;
            // eslint-disable-next-line no-nested-ternary
            const price = unitP ? (weightUnitIsTon && unit === 'per_w' ? +(1000 * unitP).toFixed(6) : unitP) : '';
            return `${price}${unitMap[unit]}`;
          })
          .join(','),
      goodsName: row.g_name && row.g_name.join(','),
    }));
    this.setState({ data, loading: false });
  };

  sug = async (name, datalist) => {
    const { type } = this.props;
    const res = await sugCorCee(type === 'cor_name', type, name, {}, datalist.fetch);
    this.setState({ nameEnum: formatSugData(res.res) });
  };

  onChange = name => this.setState({ name, mobile: '' });

  onSelect = data => this.setState({ name: data.name, mobile: data.telephone }, this.search);

  directToOrderDetail = item =>
    directToOrderDetail({
      orderNum: item.order_num,
      odBasicId: item.od_basic_id,
      odLinkId: item.od_link_id,
      transInBatchId: item.b_inner_trans_in_batch_id,
    });

  render() {
    const { text, tableHidden, remarkHidden, data, weightUnit, remark, loading } = this.state;
    const skin = (window.psn_setting.order_skin && window.psn_setting.order_skin.__meta.value) || '';
    const content = (
      <Load spinning={loading} className={`${prefixCls} ${skin}`}>
        {/*
        <DataList
          placeholder={placeholder}
          autoActiveFirstOption
          value={name}
          data={nameEnum}
          header={header}
          onChange={this.onChange}
          onSelect={this.onSelect}
          filter={this.sug}
        />
        <ButtonIcon iconType="icon-search" onClick={this.search}>查询</ButtonIcon>
        */}
        <div className="person-history-info">
          <div className={`history-card ${tableHidden} table`}>
            <h3>
              <Icon iconType="icon-carry-out" onClick={() => this.toggle('tableHidden')} />
              {`最近10次${text}货记录`}
            </h3>
            <div className="table-wrap">
              <div className="fn-table-wrap">
                <table className="fn-table-a">
                  <thead>
                    <tr>
                      <th width={30}>&nbsp;</th>
                      <th>{ot('运单号')}</th>
                      <th>{ot('开单时间')}</th>
                      <th>{ot('货物名称')}</th>
                      <th>{ot('到站')}</th>
                      <th>{ot('发货人')}</th>
                      <th>{ot('收货人')}</th>
                      <th>{ot('合计运费')}</th>
                      <th>{ot('单价')}</th>
                      <th>{ot('件数')}</th>
                      <th>
                        {ot('重量')}
                        <small>({weightUnit})</small>
                      </th>
                      <th>
                        {ot('体积')}
                        <small>(方)</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <a onClick={() => this.directToOrderDetail(row)}>{row.order_num}</a>
                        </td>
                        <td>{row.billing_date}</td>
                        <td>{row.goodsName}</td>
                        <td>{row.arr}</td>
                        <td>{row.cor_name}</td>
                        <td>{row.cee_name}</td>
                        <td className="text-red">{row.total_price}</td>
                        <td className="text-red">{row.unit}</td>
                        <td>{row.num}</td>
                        <td>{row.weight}</td>
                        <td>{row.volume}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {!data.length && (
                <div className="no-data-tip">
                  <i className="fn-icon fn-icon-info-o-2" />
                  {`该客户没有${text}货记录`}
                </div>
              )}
            </div>
          </div>
          <div className={`history-card ${remarkHidden} note`}>
            <h3>
              <Icon iconType="icon-carry-out" onClick={() => this.toggle('remarkHidden')} />
              {`${text}货客户备注`}
            </h3>
            <div className="remark-wrap">
              {remark || (
                <div className="no-data-tip">
                  <i className="fn-icon fn-icon-info-o-2" />
                  {`该客户没有维护${text}货备注`}
                </div>
              )}
            </div>
          </div>
        </div>
      </Load>
    );

    const bottom = (
      <Button type="primary" onClick={() => this.modal.handleHide()}>
        确定
      </Button>
    );

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={content}
        bottom={bottom}
        isShow
        isModal
        close={this.props.close}
        title="客户信息"
        contentStyle={{ width: '868px', paddingTop: '10px' }}
      />
    );
  }
}

export default HistoryModal;
