import React from 'react';
import renderConsignorTo from './consignor';
import renderConsigneeTo from './consignee';
import { prefixCls } from './index.scss';

function renderPerson(page) {
  const renderConsignor = renderConsignorTo(page);
  const renderConsignee = renderConsigneeTo(page);

  return () => (
    <div className={prefixCls}>
      {renderConsignor()}
      {renderConsignee()}
    </div>
  );
}

export default renderPerson;
