/* eslint-disable no-return-assign */
import classnames from 'classnames';
import { Dropdown, Icon } from 'components';
import { validateTips } from 'constants';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import reactComposition from 'react-composition';
import { addTipsByLen, hideTip, shallowCompareIgnoreFunc, showTip } from 'utils';
import { prefixCls } from './index.scss';

export default class PureInput extends Component {
  static propTypes = {
    value: PropTypes.any,
    type: PropTypes.string,
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onAddIconClick: PropTypes.func,
    className: PropTypes.string,
    required: PropTypes.bool,
    pattern: PropTypes.string,
    customValidity: PropTypes.func,
    onMouseEnter: PropTypes.func,
    children: PropTypes.any,
    frozen: PropTypes.bool,
    recordEdited: PropTypes.bool,
    recordScan: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    showTitleTips: PropTypes.bool,
    wrapStyle: PropTypes.object,
    suffix: PropTypes.string,
    showNegativesPlaceholder: PropTypes.string, // 用于判断是否显示 Placeholder=‘-’
  };

  static defaultProps = {
    value: '',
    type: 'text',
    recordEdited: true,
    showTitleTips: false,
    maxLength: 200,
  };

  isPureInput = true;

  state = {};

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    !this.focused && this.state.tip && this.needCheckValidity(nextProps) && setTimeout(this.checkValidity, 50);
  }

  shouldComponentUpdate = shallowCompareIgnoreFunc;

  needCheckValidity = nextProps => ['value', 'required', 'pattern'].some(key => this.props[key] !== nextProps[key]);

  handleChange = e => {
    const { target } = e;
    const { value } = target;
    if (this.props.frozen) return e.preventDefault();
    if (this.props.recordScan) {
      const now = Date.now();
      now - this.lastInputTime < 30 ? (this.input.dataset.isScan = '1') : delete this.input.dataset.isScan;
      this.lastInputTime = now;
    }
    if (e.reactComposition.composition === false) {
      this.composition = false;
      this.props.onChange && this.props.onChange(e);
      this.props.recordEdited && (target.dataset.isEdited = 1);
    } else {
      this.composition = true;
    }
    this.setState({ value, tip: '' });
  };

  handleFocus = e => {
    this.focused = true;
    delete this.input.dataset.isScan;
    e.target.select();
    this.props.onFocus && this.props.onFocus(e);
    setTimeout(() => this.tipIcon && this.showTip({ target: this.tipIcon }), 100);
  };

  handleBlur = e => {
    this.focused = false;
    this.props.onBlur && this.props.onBlur(e);
    setTimeout(this.checkValidity, 30);
    hideTip();
  };

  handleMouseEnter = e => {
    if (
      this.props.showTitleTips &&
      (this.props.disabled || this.props.readOnly) &&
      addTipsByLen(e.target, this.spanWrap)
    ) {
      this.setState({ titleShowed: true }, () => this.drop.open());
      this.titleFixed = false;
    }
    this.props.onMouseEnter && this.props.onMouseEnter(e);
  };

  fixedTitle = () => (this.titleFixed = true);

  hideTitle = () =>
    this.state.titleShowed && !this.titleFixed && this.setState({ titleShowed: false }, () => this.drop.close());

  checkValidity = async () => {
    if (!this.input) return '';
    const { validity } = this.input;
    let tip = '';
    if (this.props.required && !this.input.value.trim()) {
      tip = '必填';
    } else if (validity.patternMismatch) {
      tip = validateTips[this.props.pattern];
    } else if (this.props.customValidity) {
      tip = await this.props.customValidity(this.input.value);
      this.input.setCustomValidity(tip && tip.msg ? tip.msg : tip);
    }
    this.setState({ tip });
    return tip;
  };

  getTipType = tip => (tip && tip.type) || 'error';

  getTipMsg = tip => (tip && tip.msg ? tip.msg : tip);

  showTip = e => {
    const { tip } = this.state;
    const type = this.getTipType(tip);
    const msg = this.getTipMsg(tip);
    showTip(e.target, { content: <span>{msg}</span>, type });
  };

  setTip = tip => this.setState({ tip }, () => this.input.setCustomValidity(tip && tip.msg ? tip.msg : tip));

  refInput = r => (this.input = r);

  refTipIcon = r => (this.tipIcon = r);

  refSpan = r => (this.spanWrap = r);

  refDrop = drop => (this.drop = drop);

  focus = () => this.input.focus();

  blur = () => this.input.blur();

  compositionOnChange = reactComposition({ onChange: this.handleChange });

  render() {
    const {
      className,
      value = '',
      customValidity,
      showTitleTips,
      children,
      frozen,
      recordEdited,
      title,
      disabled,
      readOnly,
      wrapStyle,
      recordScan,
      suffix,
      onAddIconClick,
      ...rest
    } = this.props; // eslint-disable-line
    const { tip, titleShowed } = this.state;
    const wrapClass = classnames({ 'fn-input-pure-wrap': true, [className]: className });
    const classes = classnames({ [prefixCls]: true, invalid: tip && !tip.type, warn: tip && tip.type, field: true });
    const displayValue = this.composition ? this.state.value : value;
    const content = (
      <span ref={this.refSpan} className={wrapClass} style={wrapStyle}>
        <input
          ref={this.refInput}
          {...rest}
          className={classes}
          value={displayValue || ''}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onMouseEnter={this.handleMouseEnter}
          disabled={disabled}
          readOnly={readOnly}
          title={showTitleTips && (readOnly || disabled) ? undefined : title}
          {...this.compositionOnChange}
        />
        {!!onAddIconClick && <Icon iconType="icon-add-rad" onClick={onAddIconClick} />}
        {children}
        {!!suffix && <span className={`${prefixCls}__suffix`}>{suffix}</span>}
        {tip && (
          <i
            className={`input-tip input-tip--${(tip && tip.type) || 'invalid'} fn-icon fn-icon-warn-o`}
            ref={this.refTipIcon}
            onMouseEnter={this.showTip}
            onMouseLeave={hideTip}
          />
        )}
      </span>
    );
    if (showTitleTips) {
      return (
        <Dropdown
          ref={this.refDrop}
          menu={
            titleShowed ? (
              <div className="input-title" onClick={this.fixedTitle} onMouseLeave={this.hideTitle}>
                {value}
              </div>
            ) : null
          }
          alignWidth
          overlap
        >
          {content}
        </Dropdown>
      );
    }
    return content;
  }
}
