import { SHARE_TYPE } from 'constants/listOperation';
import React from 'react';
import { FLOAT_I_9_P_2_NO_0 } from '../../../../constants/validate';

const billAdjustTable = {
  header: {
    substract: {
      display: 'show',
      flexGrow: 0,
      minWidth: 36,
      title: 'icon-add-rad',
      type: 'Subtract',
      width: 36,
    },
    expense: {
      columnKey: 'expense',
      flexGrow: 1,
      display: 'show',
      type: 'SelectDrop',
      title: '费用名称',
      showKey: 'name',
      uniqueKey: 'key',
      showHeader: false,
      isMultiple: false,
      refEnum: 'expense',
      selectedOnlyKey: true,
      minWidth: 90,
      summable: true,
    },
    fee_direct: {
      columnKey: 'fee_direct',
      display: 'show',
      type: 'SelectDrop',
      flexGrow: 1,
      title: '费用方向',
      refEnum: 'fee_direct',
      showKey: 'name',
      uniqueKey: 'key',
      showHeader: false,
      isMultiple: false,
      selectedOnlyKey: true,
      minWidth: 90,
    },
    share_type_name: {
      columnKey: 'share_type_name',
      display: 'show',
      type: 'Text',
      flexGrow: 1,
      title: '分摊方式',
      minWidth: 90,
      tips: (
        <span>
          1. 分摊方式可在费用管理中进行修改；
          <br />
          2. “不分摊”的费用，调账后，在对应的订单/运单上无法看到此笔费用；
          <br />
          3. “按单均摊、分摊到任一单”的费用，调账后，在对应的订单/运单上可看到此笔费用；
        </span>
      ),
    },
    amount: {
      display: 'show',
      flexGrow: 1,
      minWidth: 90,
      pattern: FLOAT_I_9_P_2_NO_0,
      title: '金额',
      trigger: 'blur',
      type: 'PureInput',
      allowClear: true,
      columnKey: 'amount',
      columnType: 'float',
      summable: true,
    },
    adjustment_remark: {
      display: 'show',
      flexGrow: 1,
      minWidth: 90,
      title: '备注',
      trigger: 'blur',
      type: 'PureInput',
      allowClear: true,
      columnKey: 'adjustment_remark',
      maxLength: '100',
    },
  },
  _table: {
    sortIner: true,
    filterIner: true,
    isOrderNumberColumn: true,
    rowClickable: false,
    isShowTotalRow: true,
    isShowFilterRow: false,
    ifShowFilterRowIcon: false,
    isShowOperation: false,
    // isAutoSize: true,
    showScrollbarX: true,
    showScrollbarY: true,
    canSaveTableStyle: false,
    isShowSort: false,
    // enableDataPath: true,
    rowViewColumn: 'order_num',
    rowCountColumn: 'expense/ 条',
  },
  enumerations: {
    expense: [],
    fee_direct: [
      {
        key: 'inCome',
        display: '收入',
        name: '收入',
      },
      {
        key: 'out',
        display: '支出',
        name: '支出',
      },
    ],
    share_type: SHARE_TYPE,
  },
};

const summary_header = {
  实对应付运输费: {
    title: '实对应付运输费合计',
    color: '#FF8E0A',
    value: 0,
    key: 'szyfysfhj',
    style: '',
  },
  实对应收运输费: {
    title: '实对应收运输费合计',
    color: '#FF8E0A',
    value: 0,
    style: '',
  },
  实对在途费用: {
    title: '实对在途费用合计',
    color: '#222222',
    value: 0,
    key: 'szztfyhj',
    style: '',
  },
  实对运力考核费: {
    title: '实对运力考核费合计',
    color: '#36B084',
    value: 0,
    key: 'szylkhfhj',
    style: '',
  },
  实对客户考核费: {
    title: '实对客户考核费合计',
    color: '#36B084',
    value: 0,
    style: '',
  },
  账单调整: {
    title: '账单调整合计',
    color: '#FF8E0A',
    value: 0,
    key: 'dztzhj',
    style: '',
  },
  对账总金额: {
    title: '对账总金额',
    color: '#FFFFFF',
    value: 0,
    key: 'dzzje',
    style: {
      background: '#D42F2F',
      padding: '4px 8px',
    },
  },
  报销总金额: {
    title: '报销总金额',
    color: '#FFFFFF',
    value: 0,
    key: 'bxzje',
    style: {
      background: '#D42F2F',
      padding: '4px 8px',
    },
  },
};

// 表格服务「齿轮-设置」组件的Props
const component_props = {
  DriverSalaryFile_salary_model_user_pick_left: {
    setSortProps: {
      isModal: true,
    },
    setHeaderProps: {
      isModal: true,
    },
  },
  DriverSalaryFile_salary_model_user_pick_right: {
    setSortProps: {
      isModal: true,
    },
    setHeaderProps: {
      isModal: true,
    },
  },
};

export { billAdjustTable, summary_header, component_props };
