import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Tips, Icon } from 'components';
import { MULTI_LINE_HEIGHT } from 'constants';
import Cell from './Cell';
import TableCell from './TableCell';
import { getOtherPropsInfo } from './../utils';

// PureComponent必须 保持滚动时不render,
// 不必自己写shouldComponentUpdate
// TODO 梳理cell 所属的全部props,单独列出来， 其他都为内容的props
export default class TextCell extends TableCell {
  static propTypes = {
    showingRows: PropTypes.object,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    showContentTip: PropTypes.bool,
  };

  render() {
    const { cellProps, cellOtherProps, columnProps, formatRender, getCopyValue } = this.props;
    const { data, isPopCopyOn, isAutoRowHeight, adaptiveCols = [], rowHeight } = cellOtherProps;
    const { rowIndex, columnKey, width, height, style, className } = cellProps;
    const { colClass, showContentTip, align, keepSpace, columnTipProps = {} } = columnProps;
    // 列样式、单元格样式，二选其一
    const dataItem = data.getShowObjectAt(rowIndex);
    let value =
      // eslint-disable-next-line no-nested-ternary
      columnKey === 'reference'
        ? rowIndex + 1
        : dataItem[columnKey] === undefined || dataItem[columnKey] === null
        ? ''
        : dataItem[columnKey];

    const cellTips = getOtherPropsInfo(dataItem, columnKey, 'tips');
    // 添加表格中col中的cellClass, 位置： otherProps.cellclass.columnKey
    const cellclass = getOtherPropsInfo(dataItem, columnKey, 'cellclass') || colClass || '';
    const cellTipsCustomeExt = getOtherPropsInfo(dataItem, columnKey, 'extRender');
    const cellNoIcon = getOtherPropsInfo(dataItem, columnKey, 'noIcon');
    const contentStyle = getOtherPropsInfo(dataItem, columnKey, 'style');
    const shouldWrap = !(isAutoRowHeight || adaptiveCols.includes(columnKey) || height > rowHeight);

    let valueWithoutIcon = null; // 给cell复制用的值 是字符串
    if (formatRender && getCopyValue) {
      valueWithoutIcon = getCopyValue(value);
      value = formatRender(value); // 带有img的render
    }
    const getContentVal = () => {
      if (columnTipProps.isDom) {
        return null;
      }
      const content = [];
      if (cellTips && !cellNoIcon) {
        content.push(<Icon iconType="icon-help" tips={cellTips} />);
      }
      if (cellTipsCustomeExt) {
        const rowData = data.getAll()[rowIndex] || {};
        content.push(cellTipsCustomeExt({ rowData, columnKey, rowIndex, type: 'TextCell' }));
      }

      return content;
    };

    const getTipVal = () => {
      if (columnTipProps.isDom) {
        const rowData = data.getAll()[rowIndex] || {};
        const columnTip = getOtherPropsInfo(rowData, columnKey, 'tips');
        return columnTip;
      }
      return cellTips || value;
    };
    let content = (
      <span
        className={classnames({
          'fn-table__cell_nowrap': shouldWrap,
          'fn-table__cell__content': true,
          'fn-table_cell_keepSpace': keepSpace,
        })}
        data-tip={value}
        data-for={`${columnKey}_${rowIndex}`}
        title={valueWithoutIcon || `${value}` || ''}
        style={
          contentStyle || {
            width,
            lineHeight: `${height > rowHeight ? MULTI_LINE_HEIGHT : rowHeight}px`,
            padding: '0 4px',
          }
        }
      >
        {value}
        {getContentVal()}
      </span>
    );
    if (showContentTip && value !== '' && value !== null && value !== undefined) {
      content = (
        <Tips className={`textcell_tip_${columnKey}`} title={getTipVal()} {...columnTipProps}>
          {content}
        </Tips>
      );
    }

    const cellWrapClass = classnames({ [className]: className, [cellclass]: cellclass });

    return (
      <Cell
        {...this.props.cellProps}
        copyerValue={isPopCopyOn && columnKey !== 'reference' ? value : undefined}
        popStyle={{ align, lineHeight: `${height > rowHeight ? MULTI_LINE_HEIGHT : rowHeight}px`, ...style }}
        tableKey={cellOtherProps.tableKey}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
        className={cellWrapClass}
        shouldWrap={shouldWrap}
        valueWithoutIcon={valueWithoutIcon}
      >
        {content}
      </Cell>
    );
  }
}
