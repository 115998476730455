/**
 
 * 运单列表 & 列表设置弹框中使用
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Tips } from 'components';
import { prefixCls } from './index.scss';

export default class CheckBox extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = { checked: prop.checked };
  }

  static defaultProps = {
    classname: '',
    disabled: false,
    display: true,
    checked: false,
    direction: 'right',
    useState: false,
    stopPropagation: true,
  };
  static propTypes = {
    label: PropTypes.any,
    name: PropTypes.string,
    tips: PropTypes.string, // tips
    tipsGetter: PropTypes.func, // tips
    tipDirect: PropTypes.string, // tips  位置
    direction: PropTypes.oneOf(['left', 'right']), // label 左或右
    display: PropTypes.bool,
    disabled: PropTypes.bool,
    classname: PropTypes.string,
    dataPath: PropTypes.string, // 光标路径
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    useState: PropTypes.bool,
    stopPropagation: PropTypes.bool,
    isSwitchStyle: PropTypes.bool, // 启用开关样式
    stopLabelClick: PropTypes.bool, // label点击是否触发checkbox
    onLabelClick: PropTypes.func, // label点击事件
    onKeyDown: PropTypes.func,
  };
  setValue = checked => this.setState({ checked });
  getValue = () => this.state.checked;
  resetCheck = () => {
    this.setState({ checked: false });
  };
  // TODO 替换旧版时 参数顺序变更
  onChange = e => {
    if (this.props.stopLabelClick && this.labelClick) {
      this.labelClick = false;
      return;
    }
    this.props.useState && this.setState({ checked: e.target.checked });
    this.props.onClick && this.props.onClick(e, e.target.checked);
  };
  handleKeyDown = e => {
    this.props.onKeyDown && this.props.onKeyDown(e);
  };
  onLabelClick = e => {
    this.props.stopPropagation && e.stopPropagation();
    if (this.props.stopLabelClick) {
      this.labelClick = true;
    }
    this.props.onLabelClick && this.props.onLabelClick(e);
  };
  onClick = e => {
    // (this.props.stopLabelClick || this.props.stopPropagation) && e.stopPropagation()
    this.props.stopPropagation && e.stopPropagation();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ checked: nextProps.checked });
  }

  render() {
    const { direction, label, dataPath, name, disabled, classname } = this.props;
    const tips = (this.props.tipsGetter && this.props.tipsGetter(this.state.checked)) || this.props.tips;
    const showTip = tips !== undefined && tips !== '';
    const labelAtRight = direction === 'right';
    const labelSpan = (
      <span data-ctype="checkbox" title={label}>
        {!label ? '' : label}
      </span>
    );
    const content = (
      <label
        data-ctype="checkbox"
        className={classnames({
          [classname]: classname,
          [`${prefixCls}`]: [`${prefixCls}`],
          [`${prefixCls}__hide`]: !this.props.display,
          [`${prefixCls}__checked`]: this.state.checked,
          [`${prefixCls}__disabled`]: disabled,
          [`${prefixCls}-animbg`]: this.props.isSwitchStyle,
        })}
        onClick={this.onLabelClick}
      >
        {!labelAtRight && labelSpan}
        <span data-ctype="checkbox" className="check-mark" />
        {
          <input
            key={0}
            name={name}
            type="checkbox"
            data-ctype="checkbox"
            disabled={disabled}
            checked={this.state.checked}
            data-path={dataPath}
            onClick={this.onClick}
            onKeyDown={this.handleKeyDown}
            onChange={this.onChange}
          />
        }
        {labelAtRight && labelSpan}
      </label>
    );
    return showTip ? (
      <Tips title={tips} className={`${classname}_tips`}>
        {content}
      </Tips>
    ) : (
      content
    );
  }
}
