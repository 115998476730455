import React from 'react';
import { Select, DataList, PureInput as Input, ButtonIcon, Table } from 'components';
import { REGULAR, WARN } from 'constants';
import { throttle, showInfo, ot } from 'utils';
import { mgrHeader } from '../constant';
import { sugHeaderMap } from '../info/constant';
import { getMgr } from '../extra/tool';
import { getArrInfo, getPoint } from '../traffic/tool';
import { prefixCls } from './index.scss';

const header = { number: { display: 'show', title: ot('货物流水号'), width: 200 } };

function renderScan(page) {
  const scanGoodsSn = throttle(val => {
    if (!val) return;
    const { goodSn = [] } = page.store.getState();
    const has = goodSn.find(item => item.number === val);
    page.merge('goodSnCur', '');
    !has &&
      page.merge('goodSn', [
        ...goodSn,
        { number: val, otherProps: { operate: [{ key: 'remove', title: '移除', enable: 'true' }] } },
      ]);
    has && showInfo(WARN, `${ot('货物流水号')}重复！`);
  }, 100);

  const scanOrderNum = throttle(ele => page.path.next(ele, 13), 100);

  return function () {
    const { store } = page;
    const state = store.getState();
    const {
      orderNum,
      goodSn = [],
      goodSnCur,
      arrInfo,
      arrInfoEnum,
      arrInfoHeader,
      startArrNeedPoi,
      arrOnlyRoute,
      arrPoint,
      arrPointEnum,
      arrPointHeader,
      route,
      payMode,
      payModeEnum,
      deliveryMode,
      deliveryModeEnum,
      deliveryShortcuts,
      mgrId,
      mgrIdEnum,
      stdCost = [{}],
      btnType,
      shortcutsDisplay,
    } = state;

    // console.log(route, 'oooooooo')
    const { set } = page;

    const { merge } = page;

    const arrInfoSug = (keyword, select) => {
      if (keyword && keyword.length < 2) return;
      getArrInfo(keyword, state, select.fetch).then(res => page.setSug('arrInfo', res.res));
    };

    const arrPointSug = (keyword, select) =>
      getPoint(keyword, state, select.fetch).then(res => page.setSug('arrPoint', res));

    const sugMgr = (keyword, select) => getMgr(keyword, select.fetch, state).then(res => page.setSug('mgrId', res.res));

    const sugSalesman = (search, { fetch }) =>
      fetch('Basic/Org/salesmanSug', { method: 'POST', body: { req: { search } } }).then(res =>
        page.setSug('stdCost0std_mgr_id', res.res),
      );

    const validateArrPoint = () => (arrPoint && arrPoint.short_name && !arrPoint.company_id ? '请从下拉选择' : '');

    const validateArrInfo = () => {
      const needSelect = startArrNeedPoi || arrOnlyRoute;
      return needSelect && arrInfo && arrInfo.show_val && !arrInfo.addr && !arrInfo.province ? '请从下拉选择' : '';
    };

    const setOrderNUm = e => {
      merge('orderNum', e.target.value);
      e.target.dataset.isScan && scanOrderNum(e.target);
    };

    const setGoodsSn = e => {
      merge('goodSnCur', e.target.value);
      e.target.dataset.isScan && scanGoodsSn(e.target.val);
    };

    const handleOperateClick = index => {
      const goodSnNew = [...goodSn];
      goodSnNew.splice(index, 1);
      page.merge('goodSn', goodSnNew);
    };

    const clear = () => page.merge('goodSn', []);

    const setStdMgrId = val => merge('stdCost', [{ std_mgr_id: val }]);

    return (
      // eslint-disable-next-line react/no-this-in-sfc
      <div className={prefixCls} ref={this.refWrap}>
        <div className="scan-left">
          <h3 className="panel-header">运单信息</h3>
          <div className="scan-left-content">
            <h1>扫码开单</h1>
            <div className="order-num">
              <label className="fn-label r">{ot('运单号')}</label>
              <Input
                data-path="order_num"
                required
                value={orderNum}
                pattern={REGULAR}
                recordScan
                onChange={setOrderNUm}
              >
                <i className="input-icon fn-icon fn-icon-scan" />
              </Input>
            </div>
            <div className="goods-num">
              <label className="fn-label r">{ot('件数')}</label>
              <div>{goodSn.length}</div>
            </div>
            <div className="x2 goods-sn">
              <label className="fn-label">{ot('货物流水号')}</label>
              <Input
                data-path="good_sn"
                recordScan
                value={goodSnCur}
                onChange={setGoodsSn}
                onKeyDown={e => e.keyCode === 13 && goodSnCur && scanGoodsSn(goodSnCur)}
              >
                <i className="input-icon fn-icon fn-icon-scan" />
              </Input>
            </div>
            <div>
              <label className="fn-label">{ot('到站')}</label>
              <DataList
                data-path="arr"
                customValidity={validateArrInfo}
                value={arrInfo && arrInfo.show_val}
                data={arrInfoEnum}
                header={arrInfoHeader}
                onChange={val => merge('arrInfo', { show_val: val, street: val })}
                onSelect={val => set('arrInfo', val)}
                filter={arrInfoSug}
              />
            </div>
            <div>
              <label className="fn-label">{ot('目的网点')}</label>
              <DataList
                data-path="arr_point"
                customValidity={validateArrPoint}
                highlight="short_name"
                value={arrPoint && arrPoint.short_name}
                data={arrPointEnum}
                header={arrPointHeader}
                onChange={val => merge('arrPoint', { short_name: val, company_id: '' })}
                onSelect={val => merge('arrPoint', val)}
                filter={arrPointSug}
                autoActiveFirstOption
              />
            </div>
            <div className="x2">
              <label className="fn-label">{ot('路由')}</label>
              <Input disabled className="route" value={route ? route.route_nick : ''} />
            </div>
            <div>
              <label className="fn-label r">{ot('送货方式')}</label>
              <Select
                data-path="delivery_mode"
                required
                value={deliveryMode}
                data={deliveryModeEnum}
                filter="name"
                onChange={val => merge('deliveryMode', val)}
                shortcuts={deliveryShortcuts}
              />
            </div>
            <div>
              <label className="fn-label r">{ot('付款方式')}</label>
              <Select
                data-path="pay_mode"
                required
                value={payMode}
                data={payModeEnum}
                filter={false}
                onChange={val => merge('payMode', val)}
              />
            </div>
            <div>
              <label className="fn-label r">{ot('经办人')}</label>
              <Select
                data-path="mgr_id"
                required
                compare="id"
                value={mgrId}
                data={mgrIdEnum}
                header={mgrHeader}
                filter={sugMgr}
                map={false}
                onChange={val => set('mgrId', val)}
              />
            </div>
            <div>
              <label className="fn-label">{ot('发货业务员')}</label>
              <Select
                value={stdCost[0].std_mgr_id}
                data={state.stdCost0std_mgr_idEnum}
                header={sugHeaderMap.std_mgr_id}
                map={false}
                showIcon={false}
                compare="id"
                filter={sugSalesman}
                onChange={setStdMgrId}
              />
            </div>
            <footer>
              <div className="in-bk">
                <ButtonIcon
                  iconType="icon-file-add"
                  data-path="add_save"
                  disabled={state.scanSaving && btnType !== 'scan_new'}
                  loading={state.scanSaving && btnType === 'scan_new'}
                  onClick={() => page.handleSaveBtnClick('scan_new')}
                >
                  保存并新增({shortcutsDisplay.add_save})
                </ButtonIcon>
                <ButtonIcon
                  iconType="icon-save"
                  data-path="co_save"
                  disabled={state.scanSaving && btnType !== 'scan_save'}
                  loading={state.scanSaving && btnType !== 'scan_save'}
                  onClick={() => page.handleSaveBtnClick('scan_save')}
                >
                  保存({shortcutsDisplay.co_save})
                </ButtonIcon>
              </div>
            </footer>
          </div>
        </div>
        <div className="scan-right">
          <h3 className="panel-header">
            扫码信息
            <ButtonIcon iconType="icon-del" onClick={clear}>
              清空
            </ButtonIcon>
          </h3>
          <div className="scan-right-content">
            <Table
              canSaveTableStyle={false}
              canFreeClumn={false}
              ifShowFilterRowIcon
              isOrderNumberColumn
              isShowOperation
              data={goodSn}
              header={header}
              isShowTotalRow={false}
              handleOperateClick={handleOperateClick}
              isShowFilterRow
              isRowSelect={false}
              sortIner
              isShowSort
              filterIner
            />
          </div>
        </div>
      </div>
    );
  };
}

export default renderScan;
