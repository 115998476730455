import PropTypes from 'prop-types';
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { MenuBtn } from 'components';
import { formatDeppProptotype, isEmptyObj } from 'utils';
import TableCell from './TableCell';

const style = {
  enable: { color: '#0097cf' },
  disable: { color: '#ccc' },
};
export default class OperateCell extends TableCell {
  static propTypes = {
    onClick: PropTypes.func,
    gatherOperates: PropTypes.bool,
  };

  render() {
    const { onClick, cellProps, cellOtherProps, columnProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const { gatherOperates } = columnProps;
    const rowData = data.getShowObjectAt(rowIndex);
    const operate = rowData.operate || formatDeppProptotype('otherProps.operate', rowData) || [];
    const _onClick = (selected, e, index) => {
      e.stopPropagation();
      !this.clicks && (this.clicks = {});
      const cTime = Date.now();
      const lTime = this.clicks[index] || 0;
      const interval = 1000; // 1500 // 防短时多次点击
      if (isEmptyObj(selected)) {
        return;
      }
      const { enable = true } = selected;
      if (!enable) return;
      if (cTime - lTime < interval) {
        console.log('click too fast');
        return;
      }
      this.clicks[index] = cTime;
      onClick(rowIndex, selected.key, columnKey, e);
    };
    let operateContent = null;
    if (gatherOperates) {
      const subList = operate.map(item => ({ ...item, disabled: !item.enable }));
      const menuBtnProps = {
        direction: 'right-middle',
        buttonProps: {
          iconType: 'icon-setting',
          useIconForPoi: true,
        },
        classname: 'fn-menu-btn_table_operates',
        onClick: _onClick,
        subList,
        display: subList.length > 0,
        uniqueKey: 'key',
        tableHeader: { title: '' },
        // menuStyle
      };
      operateContent = <MenuBtn {...menuBtnProps} />;
    } else {
      operateContent = operate.map((item = {}, index) => {
        const { enable = true } = item;
        return (
          <span
            className={enable ? 'under-line operation-col' : null}
            key={index}
            onClick={e => _onClick(item, e, index)}
            style={enable ? style.enable : style.disable}
          >
            [{item.title}]
          </span>
        );
      });
    }
    return (
      <Cell
        type="operate"
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        {operateContent}
      </Cell>
    );
  }
}
