import PropTypes from 'prop-types';
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { Button } from 'components';
import TableCell from './TableCell';
import { isInOtherPropsArray } from './../utils';
// import { formatValue } from '../helper/format'
export default class ButtonCell extends TableCell {
  static propTypes = {
    onClick: PropTypes.func,
  };

  // TODO Button 增加data组件可接收独立数据
  render() {
    const { cellProps, cellOtherProps, onClick } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const defaultValue = data.getShowObjectAt(rowIndex)[columnKey];
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <Button
          onClick={() => onClick(rowIndex, columnKey)}
          disabled={isInOtherPropsArray(data.getObjectAt(rowIndex), columnKey, 'disable')}
        >
          {defaultValue}
        </Button>
      </Cell>
    );
  }
}
