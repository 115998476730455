import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { SUPPORT_DATETIME_FORMATS, DATE_TIME_UNLIMIT } from 'constants';
import { importantStyle, DatePicker } from 'components';
import 'moment/locale/zh-cn';
import { prefixCls } from './index.scss';

const AMonthPicker = importantStyle(DatePicker.MonthPicker);
moment.locale('zh-cn');
@importantStyle
export default class MonthPicker extends Component {
  constructor(props) {
    super(props);
    const { format = 'YYYY-MM' } = props;
    let { defaultValue } = props;
    if (typeof defaultValue !== 'string') {
      defaultValue = '';
    }
    this.state = {
      defaultValue,
      format,
    };
  }

  static propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    style: PropTypes.object,
    startStyle: PropTypes.object,
    endStyle: PropTypes.object,
    format: PropTypes.any,
    allowClear: PropTypes.bool,
    isRange: PropTypes.bool,
  };
  static defaultProps = {
    allowClear: false,
    isRange: true,
    style: {},
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { defaultValue } = nextProps;
    const { format } = nextProps;
    if (format !== this.props.format) {
      this.setState({ format: nextProps.format });
    }
    if (defaultValue !== this.props.defaultValue) {
      if (typeof defaultValue !== 'string') {
        defaultValue = '';
      }
      this.setState({ defaultValue });
    }
  }

  onChangeDate = (index, dates) => {
    let defaultValue;
    if (this.props.isRange) {
      const defaultValueArr = this.state.defaultValue.split(',');
      defaultValueArr[index] = dates ? moment(dates).format('YYYY-MM') : '';
      defaultValue = `${defaultValueArr.join(',')}`;
      this.setState({ defaultValue });
      defaultValueArr.length === 2 &&
        defaultValue.length > 8 &&
        this.props.onChange &&
        this.props.onChange(defaultValue);
    } else {
      defaultValue = dates ? moment(dates).format('YYYY-MM') : '';
      this.setState({ defaultValue });
      this.props.onChange && this.props.onChange(defaultValue);
    }
  };

  render() {
    const { style, startStyle, endStyle, disabled, allowClear, isRange } = this.props;
    const { format, defaultValue = '' } = this.state;
    const dataList =
      defaultValue && defaultValue !== DATE_TIME_UNLIMIT
        ? defaultValue.split(',').map(item => (item ? moment(item, SUPPORT_DATETIME_FORMATS) : undefined))
        : [];
    const startPlaceholder = isRange ? '开始月份' : '请选择月份';
    return (
      <div
        className={classnames(prefixCls, { [`${prefixCls}_disabled`]: disabled })}
        style={{ width: '145px', ...style }}
      >
        <div className={isRange ? 'start-month' : 'single-month'}>
          <AMonthPicker
            disabled={disabled}
            style={{
              // width: isRange ? '55px !important' : '140px !important',
              width: '100% !important',
              // paddingLeft: '5px',
              ...startStyle,
            }}
            placeholder={startPlaceholder}
            value={dataList[0]}
            format={format}
            onChange={(...args) => this.onChangeDate(0, ...args)}
            allowClear={allowClear}
            disabledDate={this.props.disabledDate}
          />
        </div>
        {isRange ? (
          <div className="end-month">
            <span style={{ width: '10%', display: 'inline-block' }}> ~ </span>
            <AMonthPicker
              disabled={disabled}
              style={{ width: '90% !important', ...endStyle }}
              placeholder="结束月份"
              value={dataList[1]}
              format={format}
              onChange={(...args) => this.onChangeDate(1, ...args)}
              allowClear={allowClear}
              disabledDate={this.props.disabledDate}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
