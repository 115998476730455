import { round } from '../tool';

const calcCommission = (effectValue, commissionSetting, freeDelivery, maxCoDeliveryFee) => {
  const type = commissionSetting.checked;

  const defaultCalcRule = commissionSetting.calc_rule;

  const { coDelivery, coDeliveryRatio, transMode, arrPoint, route, coDeliveryReleaseDays } = effectValue;

  let min = 0,
    rate = +coDeliveryRatio,
    rule = defaultCalcRule;

  if (!+coDelivery > 0 || (freeDelivery && coDelivery <= freeDelivery)) return 0;

  if (type === 'unity' && !rate) {
    min = commissionSetting.min;
    rate = commissionSetting.rate;
  }

  if (type === 'point' && !rate && arrPoint) {
    min = commissionSetting.point_min;
    rate = commissionSetting.point_rate_btn_dialog[arrPoint.company_id];
    rule = commissionSetting.point_calc_rule;
  }

  if (type === 'trans_mode' && !rate) {
    min = commissionSetting.trans_mode_min;
    rate = commissionSetting[`trans_mode_${transMode || 3}`];
    rule = commissionSetting.trans_mode_calc_rule;
  }

  if (type === 'line' && route && route.route) {
    const routeNodes = route.route;
    const isTrans = routeNodes.length > 2 || routeNodes.some(item => item.company_id > 0);
    min = commissionSetting.line_min;
    rate = isTrans ? commissionSetting.trans_rate : commissionSetting.dirt_rate;
    rule = commissionSetting.line_calc_rule;
  }

  if (type === 'days' && coDeliveryReleaseDays) {
    const daysRateBtnDialog = commissionSetting.days_rate_btn_dialog || [];
    const found = daysRateBtnDialog.find(item => item.return_days === coDeliveryReleaseDays);
    min = commissionSetting.days_min;
    rate = found ? found.days_rate : 0;
    rule = commissionSetting.days_calc_rule;
  }

  const actualVal = Math.max(min || 0, round((+rate * +coDelivery) / 1000, rule));
  const finalVal = maxCoDeliveryFee ? (+actualVal > +maxCoDeliveryFee ? maxCoDeliveryFee : actualVal) : actualVal;
  return +(+finalVal).toFixed(2);
};

export default calcCommission;
