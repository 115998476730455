import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { WARN, ERROR, INFO, CHECK, NETERR } from 'constants';
import { Icon, ModalDialog, Button } from 'components';
import classnames from 'classnames';
import { prefixCls } from './index.scss';
import { datHelper, getDatTitle } from '@/utils/dataAnalytic';

export default class ShowInfo extends PureComponent {
  static displayName = 'ShowInfo';

  constructor(prop) {
    super(prop);
    /*
     * 此项值 是为了 simpleText 和 simpleMode
     * button的name以simpleText主，否则取默认值的name
     * cb以simpleMode中callback为主，否则取此处默认的方法
     */
    this.simpleModeBtns = {
      confirm: { name: '确定', btnType: 'primary', cb: this.onConfirm },
      cancel: { name: '取消', btnType: 'default', cb: this.onCancel },
    };
    // button点击的回调
    this.btnsHandles = {};
    this.state = {
      isShow: prop.isShow,
      buttons: this.setButtonStatus(prop),
    };
  }

  static defaultProps = {
    classname: '',
    type: CHECK,
    isShow: true,
    autoDestroy: true,
    title: '提示',
    buttons: [],
    closable: true,
    maskClosable: true,
    showIcon: true,
    simpleText: {}, // 自定义按钮文字
    isModal: false,
    autoHide: false,
    autoHideTime: 2000,
  };
  static propTypes = {
    classname: PropTypes.string,
    // 弹框中icon提示类型
    type: PropTypes.oneOf([WARN, ERROR, INFO, CHECK, NETERR]),
    // 是否显示icon
    showIcon: PropTypes.bool,
    // 是否默认显示
    isShow: PropTypes.bool,
    // 弹框提示正文
    content: PropTypes.any,
    // 弹框提示正文下面的说明
    contentNotice: PropTypes.any,
    // 是否自动销毁（for popup）
    autoDestroy: PropTypes.bool,
    // 弹框标题
    title: PropTypes.string,
    // 传入 confirm cancel 对应的callback
    simpleMode: PropTypes.object,
    // 默认文本
    simpleText: PropTypes.object,
    // 直接从外部制定buttons, 每个buttons需要有key
    buttons: PropTypes.array,
    // 是否有右上角查号
    closable: PropTypes.bool,
    // 是否点击遮罩关闭
    maskClosable: PropTypes.bool,
    // 关闭回调（for popup）
    close: PropTypes.func,
    closableCallback: PropTypes.func,
    isModal: PropTypes.bool,
    // 自动消失 自动销毁为true 则销毁
    autoHide: PropTypes.bool,
    // 自动消失时间
    autoHideTime: PropTypes.number,
    // 弹框中icon提示类型
    iconType: PropTypes.array,
  };

  componentDidMount() {
    const { autoHide, autoHideTime } = this.props;
    if (this.state.isShow && autoHide) {
      setTimeout(() => this.handleHide(), autoHideTime);
    }
    datHelper(() => {
      const { content, title: info, type } = this.props;
      if (type !== ERROR) return;
      const title = getDatTitle({ prefixSys: false });
      const name = typeof content === 'string' ? content : info;
      window._paq.push(['trackContentImpression', '打开提示弹窗', `【${title}】-${name}`, content]);
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isShow) {
      this.setState({
        isShow: nextProps.isShow,
      });
    }
  }

  // 兼容不同的参数生成最后弹框上的button
  setButtonStatus = prop => {
    let buttons = prop.buttons || [];
    // 从外部以 simpleMode 的形式传入 button 回调
    if (this.props.simpleMode) {
      buttons = [];
      Object.entries(this.props.simpleMode).map(([key, item]) => {
        item &&
          buttons.push({
            key,
            name: this.props.simpleText[key] || this.simpleModeBtns[key].name,
            btnType: this.props.simpleBtnType || this.simpleModeBtns[key].btnType,
            cb: item,
          });
        return true;
      });
    }
    // 不传button的情况下, 用默认值
    if (!Array.isArray(buttons) || buttons.length === 0) {
      buttons = [
        { key: 'confirm', name: '确定', btnType: 'primary', cb: this.onConfirm },
        { key: 'cancel', name: '取消', btnType: 'default', cb: this.onCancel },
      ];
    }
    // 将button的回调方法集体存储在 btnHandles中
    buttons.forEach((item, index) => {
      // 兼容以往不传key值的button
      buttons[index].key = buttons[index].key || buttons[index].name;
      this.btnsHandles[buttons[index].key] = item.cb && typeof item.cb === 'function' && item.cb;
    });
    return buttons;
  };
  // 暂未使用
  onConfirm = () => {};
  onCancel = () => {};
  /*
   * 修改button的状态
   * btnKey: 要修改的btnKey
   * flag: 是否为loading状态
   */
  changeBtnLoadingStatus = (btnKey, flag) => {
    const { buttons } = this.state;
    const newButtons = [];
    buttons.forEach(iitem => {
      const item = iitem;
      if (item.key === btnKey) {
        item.loading = flag;
      } else {
        item.disabled = flag;
      }
      newButtons.push(item);
    });
    this.setState({ buttons: newButtons });
  };
  handleHide = () => {
    this.infoDialog && this.infoDialog.handleHide();
  };
  handleShow = () => {
    this.infoDialog && this.infoDialog.handleShow();
  };
  // button的点击操作
  handleButtonClick = (key, ...arg) => {
    // pop关闭时判断是否有修改 提示弹框关闭时 事件会冒泡到已销毁的组件
    arg[0] && arg[0].stopPropagation && arg[0].stopPropagation();
    this.btnsHandles[key](this, key, ...arg);
  };
  // 生成底部button
  renderBottom = () => (
    <div>
      {this.state.buttons.map(item => (
        <Button
          type={item.btnType}
          key={item.name}
          loading={item.loading}
          disabled={item.disabled}
          onClick={(...arg) => this.handleButtonClick(item.key, ...arg)}
        >
          {item.name}
        </Button>
      ))}
    </div>
  );
  // 生成dialog content
  renderContent = (type, content, contentNotice, iconType) => {
    const maxHeight = (window.document.body.offsetHeight - 44) * 0.9 - 32 - 36; // (body - head)*0.9-title -footer
    const infoIcon = (
      <div className={`${prefixCls}__icon`}>
        <Icon iconType={`${iconType || type}`} />
      </div>
    );
    let noDetail = 'no_detail_1';
    if (typeof content === 'string') noDetail = 'no_detail';
    return (
      <div className={`${prefixCls}__content`} style={{ maxHeight }}>
        {this.props.showIcon ? infoIcon : null}
        <div className={`${prefixCls}__text ${noDetail}`}>{content}</div>
        <div className={`${prefixCls}__text_con_notice`}>{contentNotice}</div>
      </div>
    );
  };

  render() {
    const {
      isShow,
      type,
      classname,
      content,
      title,
      autoDestroy,
      closable,
      maskClosable,
      close,
      contentNotice,
      iconType,
    } = this.props;
    const classes = classnames({
      [prefixCls]: true,
      [classname]: classname,
      net_err: type === NETERR,
    });
    return (
      <ModalDialog
        isModal={this.props.isModal}
        classname={classes}
        ref={dialog => (this.infoDialog = dialog)}
        title={title}
        content={this.renderContent(type, content, contentNotice, iconType)}
        bottom={type === NETERR ? null : this.renderBottom()}
        autoDestroy={autoDestroy}
        isShow={isShow}
        closable={closable}
        close={close}
        closableCallback={() => (this.props.closableCallback ? this.props.closableCallback(this) : this.handleHide())}
        maskClosable={maskClosable}
        // style={{ width: 800 }}
      />
    );
  }
}
