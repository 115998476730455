/**
 
 * 全站列表设置、排序设置、筛选设置去掉 恢复默认
 * 列表设置页面中调用的设置有恢复默认
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { NewTypeSet, ModalDialog, Button } from 'components';
import emptyFunction from 'utils/core/emptyFunction';
import { prefixCls } from './index.scss';

export default class PopupTypeset extends PureComponent {
  static displayName = 'PopupTypeset';
  static propTypes = {
    typesetProps: PropTypes.object, // typesetProps
    title: PropTypes.any, // 弹出层title
    autoDestroy: PropTypes.bool, // 是否自动清除
    handleReset: PropTypes.func, // 是否reset
    handleSubmit: PropTypes.func, // 点击ok时触发
    handleCancel: PropTypes.func, // 点击取消的时候触犯
    handleHideCallback: PropTypes.func, // 隐藏执行过后的回调
    isShow: PropTypes.bool, // 是否显示
    hasReset: PropTypes.bool, // 是否有恢复默认选项
    headerTip: PropTypes.any, // 顶部tip
  };
  static defaultProps = {
    handleHideCallback: emptyFunction,
    hasReset: true,
  };
  handleReset = () => {
    this.props.handleReset && this.props.handleReset(this);
  };
  handleSubmit = () => {
    this.props.handleSubmit && this.props.handleSubmit(this);
  };
  handleCancel = () => {
    this.vModalDialog.handleHide(this.props.handleHideCallback);
    this.props.handleCancel && this.props.handleCancel(this);
  };
  resetBtnRefGetter = r => (this.resetBtnRef = r);
  submitBtnRefGetter = r => (this.submitBtnRef = r);
  cancelBtnRefGetter = r => (this.cancelBtnRef = r);
  renderBottom = () => (
    <div className="bottom-div">
      {this.props.hasReset && (
        <Button ref={this.resetBtnRefGetter} type="primary" onClick={this.handleReset}>
          恢复默认
        </Button>
      )}
      <Button ref={this.cancelBtnRefGetter} onClick={this.handleCancel}>
        取消
      </Button>
      <Button ref={this.submitBtnRefGetter} type="primary" onClick={this.handleSubmit}>
        确认
      </Button>
    </div>
  );
  refTypeSet = r => (this.typeSet = r);

  render() {
    const { typesetProps, title, autoDestroy, isShow = true, ...rest } = this.props;
    const typeset = <NewTypeSet ref={this.refTypeSet} {...typesetProps} />;
    const bottom = this.renderBottom();
    const typesetWrap = (
      <div>
        {this.props.headerTip && <div style={{ color: 'red', marginBottom: '4px' }}>{this.props.headerTip}</div>}
        {typeset}
      </div>
    );
    return (
      <ModalDialog
        isShow={isShow}
        title={title}
        autoDestroy={autoDestroy}
        content={typesetWrap}
        classname={prefixCls}
        ref={r => (this.vModalDialog = r)}
        bottom={bottom}
        style={{ width: 551 }}
        {...rest}
      />
    );
  }
}
