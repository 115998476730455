import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fetchApi as fetch, showInfo, validateFieldsOf, alert, buildTipMixin } from 'utils';
import { ERROR, NUMBER, CHECK, FLOAT_P_3, FLOAT_P_4 } from 'constants';
import { SlideDrager, Button, PureInput as Input, Radio } from 'components';
import { directToOrderDetail } from 'utils/business/order/direct';
import adaptor from '../adaptor';
import { getErrorMsg } from '../tool';
import saveOrder from '../public/saveOrder';
import calcCeeDist from '../public/calcCeeDist';
import calcCorDist from '../public/calcCorDist';
import { prefixCls } from './manual.scss';

const tip = buildTipMixin('该货物只剩1件，只能拆成1个运单');

class SplitReservationManual extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    close: PropTypes.func,
    onSaveSuccess: PropTypes.func,
    popContainer: PropTypes.object,
    togglePopWidth: PropTypes.func,
  };

  state = {};

  UNSAFE_componentWillMount() {
    this.getData();
  }

  getData = async () => {
    const url = 'Reservation/Reservation/edit';
    const res = await fetch(url, {
      method: 'POST',
      body: { req: { id: this.props.data.id, view: true, sys_version: '1' } },
    });
    if (res.errno !== 0) {
      showInfo(ERROR, getErrorMsg(res));
      this.slider.handleHide();
      return false;
    }
    const onlyOne =
      res.res.reservation_data.goods.reduce((pre, item) => pre + (item.num - item.num_split || 0), 0) === 1;
    const goods = res.res.reservation_data.goods.map(item => {
      const { num = 0, num_split = 0, weight = 0, weight_split = 0, volume = 0, volume_split = 0 } = item;
      const rn = num - num_split || 0;
      const rw = weight - weight_split || 0;
      const rv = volume - volume_split || 0;
      if (onlyOne && rn === 1) {
        return {
          ...item,
          rn,
          rw,
          rv,
          num_all: num,
          num: 1,
          num_split,
          weight_all: weight,
          weight: rw,
          weight_split,
          volume_all: volume,
          volume: rv,
          volume_split,
          disabled: 1,
        };
      }
      return {
        ...item,
        rn,
        rw,
        rv,
        num_all: num,
        num: 0,
        num_split,
        weight_all: weight,
        weight: 0,
        weight_split,
        volume_all: volume,
        volume: 0,
        volume_split,
      };
    });

    const splitFieldEnum = [
      { value: 'num', label: '按件数', disabled: goods.every(v => v.rn <= 0) },
      { value: 'weight', label: '按重量', disabled: goods.every(v => v.rw <= 0) },
      { value: 'volume', label: '按体积', disabled: goods.every(v => v.rv <= 0) },
    ];
    const notDisabled = splitFieldEnum.find(item => !item.disabled);
    const splitField = notDisabled && notDisabled.value;
    // eslint-disable-next-line react/no-unused-state
    this.setState({ splitField, goods, splitFieldEnum, onlyOne });
  };

  set = (i, key, e) => {
    const goods = [...this.state.goods];
    goods[i] = { ...goods[i], [key]: e.target.value };
    this.setState({ goods });
  };

  setSplitField = val => {
    const goods = this.state.goods.map(item => ({ ...item, num: '', weight: '', volume: '' }));
    this.setState({ goods, splitField: val });
  };

  allocation = i => {
    const { splitField } = this.state;
    const goods = [...this.state.goods];
    const split = goods[i][splitField];
    const rest = goods[i][`${splitField}_all`] - goods[i][`${splitField}_split`] || 0;
    const ratio = Math.min(split / rest, 1);
    goods[i] = {
      ...goods[i],
      num: goods[i].rn && +split ? Math.max((goods[i].rn * ratio).toFixed(), 1) : 0,
      weight: goods[i].rw && +split ? +Math.max(goods[i].rw * ratio, 0.001).toFixed(3) : 0,
      volume: goods[i].rv && +split ? +Math.max(goods[i].rv * ratio, 0.000001).toFixed(6) : 0,
    };
    this.setState({ goods });
  };

  fetchOrder = async goods => {
    const { splitField } = this.state;
    const req = {
      reservation_num: this.props.data.reservation_num,
      co_mode: 0,
      split_mode: 1,
      split_field: splitField,
      split_goods: goods,
    };
    const res = await fetch('Order/Order/co?raw=1', { method: 'POST', body: { req } });
    return res.errno === 0 ? { order: adaptor({ data: res, usedFor: 'create' }) } : { error: getErrorMsg(res) };
  };

  saveOneOrder = async (splitField, goods) => {
    const { order, error } = await this.fetchOrder(goods);
    if (error) {
      showInfo(ERROR, error);
      return false;
    }
    const [corPickDist, ceePickDist] = await Promise.all([calcCorDist(order), calcCeeDist(order)]);
    const req = { split_mode: 1, split_goods: goods, split_field: splitField };
    return await saveOrder({ ...order, corPickDist, ceePickDist }, 'split', req, true);
  };

  saveWrap = async btnType => {
    this.setState({ btnType, loading: true });
    await this.save(btnType);
    this.setState({ loading: false });
    this.props.onSaveSuccess(btnType);
  };

  save = async btnType => {
    if (!(await validateFieldsOf(this.wrap))) return false;
    const { splitField } = this.state;
    const goods = this.state.goods.map(item => ({ num: item.num, weight: item.weight, volume: item.volume }));
    const res = await this.saveOneOrder(splitField, goods);
    const isAutoSplitLast = this.state.goods.reduce((pre, item) => pre + (item.rn - item.num), 0) === 1;
    if (!res) return false;
    if (res.errno !== 0) {
      alert(ERROR, getErrorMsg(res));
      return false;
    }
    if (isAutoSplitLast) {
      const goods1 = this.state.goods.map(item => {
        if (item.rn - item.num === 1) {
          return {
            num: 1,
            weight: +(item.rw - item.weight || 0).toFixed(3),
            volume: +(item.rv - item.volume || 0).toFixed(6),
          };
        }
        return { num: 0, weight: 0, volume: 0 };
      });
      const res1 = await this.saveOneOrder(splitField, goods1);
      if (!res1) return false;
      if (res1.errno !== 0) {
        alert(ERROR, getErrorMsg(res1));
        return false;
      }
    }
    if (btnType === 'save') {
      const { order_num: orderNum, od_basic_id: odBasicId, od_link_id: odLinkId } = res.res.order_data;
      this.slider.handleHide();
      directToOrderDetail({ from: 'Reservation_default', orderNum, odBasicId, odLinkId });
    } else {
      showInfo(CHECK, '保存成功');
      isAutoSplitLast ? this.slider.handleHide() : await this.getData();
    }
  };

  renderSplitField(splitField, i, field, item) {
    if (splitField === field) {
      const pattern = { num: NUMBER, weight: FLOAT_P_3, volume: FLOAT_P_4 }[field];
      const disabled = item[`${field}_all`] - item[`${field}_split`] <= 0 || item.disabled;
      const num1Tip = item.disabled ? tip : {};
      return (
        <Input
          {...num1Tip}
          value={item[field]}
          pattern={pattern}
          disabled={disabled}
          onBlur={() => this.allocation(i)}
          onChange={e => this.set(i, field, e)}
        />
      );
    }
    return item[field];
  }

  render() {
    const { close, popContainer, togglePopWidth, data } = this.props;
    const { splitField, splitFieldEnum, goods = [], btnType, loading } = this.state;
    const content = (
      <div className={prefixCls} ref={r => (this.wrap = r)}>
        <div className="split-tip">* 请选择拆单方式，订单上的所有费用将按拆单方式分摊到每个运单上。</div>
        <label className="fn-label">拆单方式：</label>
        <Radio value={splitField} data={splitFieldEnum} onChange={this.setSplitField} />
        <div className="fn-table-wrap">
          <table className="fn-table-a">
            <thead>
              <tr>
                <th>货物名称</th>
                <th>包装</th>
                <th>订单件数</th>
                <th>已拆件数</th>
                <th>拆单件数</th>
                <th>订单重量</th>
                <th>已拆重量</th>
                <th>拆单重量</th>
                <th>订单体积</th>
                <th>已拆体积</th>
                <th>拆单体积</th>
              </tr>
            </thead>
            <tbody>
              {goods.map((item, i) => (
                <tr key={i}>
                  <td>{item.name}</td>
                  <td>{item.pkg}</td>
                  <td>{item.num_all}</td>
                  <td>{item.num_split}</td>
                  <td>{this.renderSplitField(splitField, i, 'num', item)}</td>
                  <td>{item.weight_all}</td>
                  <td>{item.weight_split}</td>
                  <td>{this.renderSplitField(splitField, i, 'weight', item)}</td>
                  <td>{item.volume_all}</td>
                  <td>{item.volume_split}</td>
                  <td>{this.renderSplitField(splitField, i, 'volume', item)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );

    const footer = (
      <div className={SlideDrager.footerClass}>
        <Button
          type="primary"
          disabled={!splitField || (loading && btnType !== 'save_new')}
          loading={loading && btnType === 'save_new'}
          onClick={() => this.saveWrap('save_new')}
        >
          保存并继续拆单
        </Button>
        <Button
          type="primary"
          disabled={!splitField || (loading && btnType !== 'save')}
          loading={loading && btnType === 'save'}
          onClick={() => this.saveWrap('save')}
        >
          保存并查看运单
        </Button>
        <Button type="default" disabled={loading} onClick={() => this.slider.handleHide()}>
          取消
        </Button>
      </div>
    );

    return (
      <SlideDrager
        ref={r => (this.slider = r)}
        HeaderTitle={`拆单转运单<订单号：${data.reservation_num}>`}
        isShow
        autoDestroy
        contentDiv={content}
        footerDiv={footer}
        close={close}
        togglePopWidth={togglePopWidth}
        dragerContainer={popContainer}
        slideWidth="820px"
      />
    );
  }
}

export default SplitReservationManual;
