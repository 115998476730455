import React from 'react';
import _ from 'lodash';
import { filterListColumnByValueList, formatHeader, mergeEnum, typeIs, fromJS, getComVal, showInfo } from 'utils';
import { PRICE_KEYS } from 'constants/priceCalc';
import { STATE_CLS_MAP, ERROR } from 'constants';
import { renderPayeeCol, renderPayeeTitle } from 'utils/sug/truck';
import { fuelCardSlide } from 'pages/CompanyController/FuelCard';
import g7Payee from 'pages/CarryController/listOp/g7Payee';
import { addFee } from '../../../../operation/listOperation/feeOp';
import { Icon, PopUp, ModalDialog, SelectDrop, Button } from 'components';

const config = require('../config');

function mergeHeader(oldHeader, header) {
  const newHeader = header;
  Object.keys(oldHeader).forEach(key => {
    if (!newHeader[key]) {
      newHeader[key] = { ...oldHeader[key], display: 'none' };
    }
  });
  return newHeader;
}
function addStartArr(key, table) {
  const newTable = table;
  if (newTable.header[key] && newTable.header[key].display === 'show') {
    const enumList = [];
    const enumKey = {};
    newTable.data.forEach(x => {
      x[key] && !enumKey[x[key]] && enumList.push({ key: x[key], name: x[key] }) && (enumKey[x[key]] = 1);
    });
    newTable.header[key].refEnum = key;
    newTable.header[key].filterType = 'SelectDrop';
    enumList.length <= 1 && (newTable.header[key].isMultiple = false);
    newTable.enumerations[key] = enumList;
  }
  return newTable;
}
function filterSugArr(key, table) {
  const newTable = table;
  if (newTable.header[key] && newTable.header[key].display === 'show') {
    const fetchApi = Object.assign({}, newTable.header[key].fetchApi);
    fetchApi.formateFunc = (res, fetchInfo) => {
      const newRes = res;
      const filterList = fetchInfo.filterList || [];
      const { uniqueKey } = fetchInfo;
      const resData = res.res.filter(x => filterList.includes(x[uniqueKey].toString()));
      return { reqData: resData };
    };
    const enumList = [];
    const enumKey = {};
    newTable.data.forEach(x => {
      x[key] && !enumKey[x[key]] && enumList.push(x[key]) && (enumKey[x[key]] = 1);
    });
    fetchApi.filterList = enumList;
    fetchApi.uniqueKey = newTable.header[key].uniqueKey;
    newTable.header[key].fetchApi = fetchApi;
  }
  return newTable;
}
// 过滤筛选值
export function dealTableEnum(enumFilter, tableList, oriTableList = {}, type = 'left,right', isDetail) {
  const newTableList = { ...tableList };
  const originLeft = oriTableList?.left || {};
  const originRight = oriTableList?.right || {};
  if (type.indexOf('left') === -1 && oriTableList) {
    newTableList.left = originLeft;
  }
  if (type.indexOf('right') === -1 && oriTableList) {
    newTableList.right = originRight;
  }
  if (enumFilter && tableList.left) {
    Object.keys(enumFilter).forEach(key => {
      const enumFilterList = enumFilter[key];
      const enumDict = tableList.left.enumerations[key];
      if (!enumDict) return;
      let newEnum = enumDict;
      if (enumFilterList.length) {
        newEnum = filterListColumnByValueList(enumDict, 'key', enumFilterList);
      }
      newTableList.left.enumerations[key] = newEnum;
      newTableList.right.enumerations[key] = newEnum;
    });
  }
  // 左右边的header merge到原来的数据中
  if (type.indexOf('left') !== -1 && tableList.left && oriTableList?.left) {
    newTableList.left.header = mergeHeader(oriTableList.left.header, newTableList.left.header);
    Object.assign(oriTableList.left.header, newTableList.left.header);
  }
  // 左右边的header merge到原来的数据中
  if (type.indexOf('right') !== -1 && tableList.right && oriTableList && oriTableList?.right) {
    newTableList.right.header = mergeHeader(oriTableList.right.header, newTableList.right.header);
    Object.assign(oriTableList.right.header, newTableList.right.header);
  }
  newTableList.left.header = formatHeader(
    newTableList.left.header || {},
    newTableList.left.enumerations || {},
    newTableList.left.enumapi || {},
  );
  newTableList.right.header = formatHeader(
    newTableList.right.header || {},
    newTableList.right.enumerations,
    newTableList.right.enumapi,
  );
  // 此处处理批次详情的发站和到站, 开单网点，出发网点，目的网点，收货点
  if (isDetail) {
    newTableList.right = addStartArr('start', newTableList.right);
    newTableList.right = addStartArr('arr', newTableList.right);
    newTableList.right = filterSugArr('arr_point', newTableList.right);
    newTableList.right = filterSugArr('start_point', newTableList.right);
    newTableList.right = filterSugArr('co_point', newTableList.right);
    newTableList.right = filterSugArr('rcv_stn', newTableList.right);
  }
  const { enumerations, enumapi } = mergeEnum(
    newTableList.left.enumerations || {},
    newTableList.right.enumerations || {},
    newTableList.left.enumapi || {},
    newTableList.right.enumapi || {},
    { ...(newTableList.left.header || {}), ...(newTableList.right.header || {}) },
  );
  newTableList.left.enumerations = newTableList.right.enumerations = enumerations;
  newTableList.left.enumapi = newTableList.right.enumapi = enumapi;
  return newTableList;
}

export function formateTableData(dataMap, item) {
  const newItem = item;
  if (!dataMap) return newItem;
  Object.keys(dataMap).forEach(key => {
    if (newItem[dataMap[key]] !== undefined) {
      newItem[key] = newItem[dataMap[key]];
    }
  });
  return newItem;
}
export function addPriceKey(originParam = {}) {
  const priceKey = [
    ...PRICE_KEYS,
    'tax',
    'pickup_f',
    'thermosphere',
    'value_add_service',
    'need_insured',
    'urgency',
    'insured',
    'route_mile',
  ];
  const priceParam = {};
  priceKey.forEach(key => (priceParam[key] = undefined));
  return { ...originParam, ...priceParam };
}

const addOilCard = (that, cb) => {
  fuelCardSlide(null, 'fc_add', null, cb);
};

export const addAdjustFee = (that, cb) => {
  addFee(null, null, { type: 17 }, res => {
    // const { adjustFeeTypeList = [] } = that?.state ?? {};
    // that?.setAdjustFeeTypeList?.(adjustFeeTypeList?.concat?.(res));
    cb?.(res);
  });
};

export const dealFeeDataForShow = (feeInfo, { dealSettle, dealOilCard, that }) => {
  const feeHeader = feeInfo.header;
  console.log(feeHeader);
  const addOilCardCallBack = (resData, item, index) => {
    const newFeeInfo = { ...feeInfo };

    if (newFeeInfo?.data?.[index]) {
      newFeeInfo.data[index] = {
        ...newFeeInfo?.data?.[index],
        oil_card_id: resData?.res?.id,
        oil_card: [
          {
            card_num: resData?.res?.card_num,
            key: resData?.res?.card_num,
            id: resData?.res?.id,
          },
        ],
      };
    }
    that?.setFeeInfo?.(newFeeInfo);
  };
  feeInfo?.data?.forEach?.((item, index) => {
    const row = item;
    const settleStatus = row.settle_status || {};
    const settled = settleStatus.settled || [];
    const partSettled = settleStatus.part_settled || [];
    row.otherProps = row.otherProps || {};
    row.otherProps.tooltips = row.otherProps.tooltips || {};
    row.otherProps.cellclass = row.otherProps.cellclass || {};

    dealOilCard &&
      (row.otherProps.oil_card = {
        ...row.otherProps.oil_card,
        inputWrapStyle: {
          width: 'calc(100% - 0px)',
        },
        style: {
          width: '100%',
        },
        after: () => (
          <Icon
            iconType="icon-add-rad"
            style={{ marginLeft: 4, marginRight: 6 }}
            onClick={e => {
              e?.stopPropagation?.();
              addOilCard(that, resData => {
                addOilCardCallBack(resData, item, index); // 新增油卡成功的回调
              });
            }}
          />
        ),
      });

    dealSettle &&
      partSettled.forEach(key => {
        if (feeHeader[key]) {
          row.otherProps.tooltips[key] = `<div>提示:<br/>${feeHeader[key].title || ''}已部分结算</div>`;
          row.otherProps.cellclass[key] = 'part-settled';
        }
      });
    dealSettle &&
      settled.forEach(key => {
        if (feeHeader[key]) {
          row.otherProps.tooltips[key] = `<div>提示:<br/>${feeHeader[key].title || ''}已结算</div>`;
          row.otherProps.cellclass[key] = 'settled';
        }
      });
  });
  return feeInfo;
};

function toArray(value) {
  if (typeIs(value, 'number')) {
    return [value];
  }
  if (typeIs(value, 'string')) {
    return value.split('，').map(v => +v);
  }
  if (typeIs(value, 'array')) {
    return value.map(v => +v);
  }
  return [];
}
function pricePramFilter(key, value) {
  switch (key) {
    // 多票重量体积
    case 'weight':
    case 'suit':
    case 'volume': // eslint-disable-line
      return toArray(value).reduce((a, b) => +a + +b, 0);
    default:
      return value;
  }
}
/**
 
 * @desc:    {[价格计算的接口]}
 * @param    {[type]}        item         [description]
 * @param    {[Function]}    paramFormate []
 * @return   {[type]}                     [description]
 */
export function buildPricePram(item, paramFormate, type) {
  const paramKeyList = addPriceKey(
    type === 'point_trans_pt' ? config.pointTransPt.priceParam : config.extTransPt.priceParam,
  );
  const priceDecodeList =
    type === 'point_trans_pt' ? config.pointTransPt.priceDecodeList : config.extTransPt.priceDecodeList;
  let newItem = {};
  Object.keys(paramKeyList).forEach(key => {
    const columKey = paramKeyList[key] || key;
    newItem[key] = item[columKey];
    if (priceDecodeList.indexOf(key) !== -1 && newItem[key] && newItem[key] !== '') {
      newItem[key] = JSON.parse(newItem[key]);
    }
    if (key === 'need_insured') {
      newItem[key] = !+newItem[key] ? '2' : '1';
    }
    if (key === 'insured') {
      console.log('insured', +newItem[key]);
      newItem[key] = !+newItem[key] ? '0' : '1';
      console.log(newItem, 'newItemnewItemnewItemnewItemnewItem');
    }
    // 网点中转多票
    if (type === 'point_trans_pt') {
      newItem[key] = pricePramFilter(key, newItem[key]); // 特殊处理入口
    }
  });
  if (type === 'ext_trans_pt') {
    newItem.trans_w_detail = Array.isArray(item.trans_w_detail) ? item.trans_w_detail : [item.trans_w_detail];
    if (_.get(window, 'company_setting.weight_unit.value') === 'T') {
      newItem.trans_w_detail = newItem.trans_w_detail.map(v => parseFloat(v * 1000));
    }
  }
  if (type === 'point_trans_pt') {
    newItem.point_trans_w_detail = Array.isArray(item.point_trans_w_detail)
      ? item.point_trans_w_detail
      : [item.point_trans_w_detail];
    if (_.get(window, 'company_setting.weight_unit.value') === 'T') {
      newItem.point_trans_w_detail = newItem.point_trans_w_detail.map(v => parseFloat(v * 1000));
    }
  }
  newItem.arr_point = item.arr_point;
  // 套数
  newItem.suit = newItem.goods ? newItem.goods.map(g => g.suit) : newItem.g_suit;
  newItem.suit = pricePramFilter('suit', newItem.suit);
  // 外部中转, 调用价格计算前，修正goods中的重量和体积为实际中转重量和体积
  if (type === 'ext_trans_pt') {
    // 单票时， 手动修改后，value值非array结构, weight，volume空值的情况
    newItem.weight = newItem.trans_w_detail;
    newItem.volume = item.trans_v_detail;
    typeIs(newItem.weight, 'string') && (newItem.weight = [newItem.weight]);
    typeIs(newItem.volume, 'string') && (newItem.volume = [newItem.volume]);
    newItem.weight &&
      newItem.weight.forEach((val, index) => newItem.goods[index] && (newItem.goods[index].weight = val));
    newItem.volume &&
      newItem.volume.forEach((val, index) => newItem.goods[index] && (newItem.goods[index].volume = val));
    newItem.weight && (newItem.weight = pricePramFilter('weight', newItem.weight));
    newItem.volume && (newItem.volume = pricePramFilter('volume', newItem.volume));
    newItem.num = newItem.num
      ? newItem.num
          .split('，')
          .map(v => +v)
          .reduce((m, n) => m + n)
      : newItem.num;
    newItem.trans_w = newItem.weight || 0;
    newItem.trans_v = newItem.volume || 0;
    newItem.trans_n = newItem.num || 0;
    newItem.insured = newItem.insured || null;
  }
  newItem.trans_goods = newItem.goods;
  newItem.product_key = newItem.product_key || 0;
  newItem.trans_arr_info = newItem.arr_info;
  // if (newItem.point_trans_start) {
  //   newItem.point_trans_start.district = ''
  //   newItem.point_trans_start.street = ''
  // }
  // // 网点中转
  // if (type === 'point_trans_pt' && newItem.goods && newItem.goods[0]) {
  //   newItem.goods[0].weight = item.point_trans_w
  //   newItem.goods[0].volume = item.point_trans_v
  // }
  // 网点中转 支持多货时价格计算
  if (type === 'point_trans_pt' && newItem.goods && newItem.goods[0]) {
    // newItem.goods[0].weight = newItem.weight
    // newItem.goods[0].volume = newItem.volume
    const weights = toArray(newItem.point_trans_w_detail);
    const volumes = toArray(item.point_trans_v_detail);
    weights.forEach((v, i) => {
      newItem.goods[i].weight = +weights[i];
      newItem.goods[i].volume = +volumes[i];
      if (!weights[i] || !volumes[i]) {
        newItem.goods[i].wv_ratio = 0;
      } else {
        const k = window.company_setting.weight_unit.value === 'KG' ? 1000 : 1;
        newItem.goods[i].wv_ratio = +weights[i] / +volumes[i] / k;
      }
    });
    newItem.weight = pricePramFilter('weight', weights);
    newItem.volume = pricePramFilter('volume', volumes);
    newItem.num = newItem.num
      ? newItem.num
          .split('，')
          .map(v => +v)
          .reduce((m, n) => m + n)
      : newItem.num;
    newItem.point_trans_w = newItem.weight || 0;
    newItem.point_trans_v = newItem.volume || 0;
    newItem.point_trans_n = newItem.num;
    newItem.point_trans_goods = newItem.goods;
  }
  // trans_cee_pick_dist_pre的优先级高
  newItem.trans_cee_pick_dist =
    !item.trans_cee_pick_dist_pre || item.trans_cee_pick_dist_pre === 0
      ? item.trans_cee_pick_dist
      : item.trans_cee_pick_dist_pre;
  // 此项值有误
  // newItem.trans_start_info = newItem.start_info
  if (paramFormate && typeof paramFormate === 'function') {
    newItem = paramFormate(newItem);
  }
  // newItem.weight = typeIs(newItem.weight, 'array') ? newItem.weight.map(v => +v).reduce((m, n) => m + n) : newItem.weight
  // newItem.volume = typeIs(newItem.volume, 'array') ? newItem.volume.map(v => +v).reduce((m, n) => m + n) : newItem.volume
  return newItem;
}

/*
 ** 将 cardForm 的data中下拉字段的tableHeade变为数组，并提供Select组件的render函数（colRender）
 */

export const handleCardFormDataForSelectRender = proxy => {
  const { key, value } = proxy;
  if (
    !value.display ||
    !value.otherProps ||
    !value.otherProps.tableHeader ||
    typeIs(value.otherProps.tableHeader, 'array')
  ) {
    return value;
  }
  const newValue = fromJS(value).toJS();
  const oriHeader = { ...newValue.otherProps.tableHeader };
  const newTableHeader = [];
  for (const headKey in oriHeader) {
    const objVal = { key: headKey, title: oriHeader[headKey] };
    if (headKey === 'payee') {
      objVal.render = renderPayeeCol;
      objVal.titleRender = renderPayeeTitle;
    } else if (headKey === 'tr_state_display') {
      objVal.render = item => {
        const state = STATE_CLS_MAP.batch.车辆状态;
        return <span className={state[item.tr_state_display]}>{item.tr_state_display}</span>;
      };
    } else if (headKey === 'batch_st_display') {
      objVal.render = item => {
        const state = STATE_CLS_MAP.batch.批次状态;
        return <span className={state[item.batch_st_display]}>{item.batch_st_display}</span>;
      };
    }
    newTableHeader.push(objVal);
  }
  newValue.otherProps.tableHeader = newTableHeader;
  return newValue;
};

// 车牌号/挂车号 应装重体计算函数
export const shouldWvFunc = (value, cardInfo, cardData) => {
  if (!value || !value.length) {
    return value;
  }
  const cardFormData = cardData || { tr_num: getComVal(cardInfo.tr_num), tr_num_ext: getComVal(cardInfo.tr_num_ext) };
  // 先判断应装重体字段是否显示 不显示则不计算, 按之前逻辑取值
  const hasWeightHeader = cardInfo.should_weight && cardInfo.should_weight.display;
  const hasVolumeHeader = cardInfo.should_volume && cardInfo.should_volume.display;
  // 判断车牌号或者挂车号是否已选
  const trNumSelected = cardFormData.tr_num && cardFormData.tr_num.length && cardFormData.tr_num[0].tr_num;
  const trNumExtSelected =
    cardFormData.tr_num_ext && cardFormData.tr_num_ext.length && cardFormData.tr_num_ext[0].tr_num;
  // 取值逻辑: 应装重量=车辆总质量（取挂车维护值，无挂车时取车头维护值）-车头整备质量-挂车整备质量
  const should_weight =
    hasWeightHeader &&
    // eslint-disable-next-line no-nested-ternary
    (trNumExtSelected
      ? trNumSelected
        ? cardFormData.tr_num_ext[0].full_mass -
          cardFormData.tr_num_ext[0].curb_weight -
          cardFormData.tr_num[0].curb_weight
        : cardFormData.tr_num_ext[0].full_mass - cardFormData.tr_num_ext[0].curb_weight
      : trNumSelected
      ? cardFormData.tr_num[0].full_mass - cardFormData.tr_num[0].curb_weight
      : '0');
  // 取值逻辑: 应装体积=挂车核定体积/无挂车时取车头核定体积
  const should_volume =
    hasVolumeHeader &&
    // eslint-disable-next-line no-nested-ternary
    (trNumExtSelected ? cardFormData.tr_num_ext[0].volume : trNumSelected ? cardFormData.tr_num[0].volume : '0');
  return [{ ...value[0], should_weight: should_weight || 0, should_volume: should_volume || 0 }];
};

// 客户开户人 调整为下拉选择
export const handleAccountHolderForSelect = (that, data) => {
  const field = 'account_holder';

  const fieldConfig = {
    ...data?.[field],
    otherProps: {
      ...data?.[field]?.otherProps,
      ...{
        width: 120,
        placeholder: '-请选择-',
        inputWrapStyle: {
          width: '118px',
        },
        after: () => (
          <Icon
            iconType="icon-add-rad"
            style={{ marginLeft: '6px', width: 16, height: 16, marginTop: 4 }}
            onClick={() => addTrPayeeName(that)}
          />
        ),
      },
    },
  };
  return fieldConfig;
};

// 添加收款人
export const addTrPayeeName = that => {
  new PopUp(g7Payee, {
    trPayeeCallBack: resData => {
      that?.cardForm?.setComVal?.('bank_num', [resData], 'SelectDrop');
      that?.cardForm?.setComVal?.('account_holder', [resData], 'SelectDrop');
      that?.cardForm?.setComVal?.('account_bank', [resData], 'SelectDrop');
      that?.cardForm?.setComVal?.('bank_name', resData.tr_payee_open_bank, 'Input');
    },
  }).show();
};

// 跨网点
export const crossCompany = (selectData, callBack) =>
  new Promise(function (resolve, reject) {
    let selectDotDialog;
    let checkedId = '';
    const onChange = val => {
      checkedId = val.id;
    };
    const dotDialogContent = () => {
      const selected = '';
      return (
        <div className="cross_dot__dialog">
          <p className="title">
            <i className="fn-icon fn-icon-info-o" />
            所选数据跨网点，请选择本次对账所属组织：
          </p>
          <SelectDrop
            uniquekey="key"
            filKey="name"
            data={selectData}
            defaultSelected={selected}
            selectOnly
            required
            placeholder="请选择"
            handleSelected={val => onChange(val)}
          />
          <div className="tip-area">
            <p>选择所属组织有什么用：</p>
            <p>1-本次对账使用本次选择的组织对应的系统设置；</p>
            <p>2-对账单所属组织为本次选择的组织，该组织及该组织以上的机构有查看操作权限。</p>
          </div>
        </div>
      );
    };
    const cancelDialogHide = () => {
      selectDotDialog.handleHide();
      reject();
    };
    // 确认键响应函数
    const submitDialogSave = () => {
      if (checkedId) {
        callBack?.(checkedId);
        resolve(checkedId);
        selectDotDialog.handleHide();
      } else {
        showInfo(ERROR, '请选择本次对账所属组织');
      }
    };
    const getSlideDragerFooter = () => (
      <div className="slide__drager__footer__content">
        <Button onClick={submitDialogSave} type="primary">
          确定
        </Button>
        <Button onClick={cancelDialogHide}>取消</Button>
      </div>
    );
    new PopUp(ModalDialog, {
      ref: dialog => (selectDotDialog = dialog),
      title: '操作提示',
      content: dotDialogContent(),
      bottom: getSlideDragerFooter(),
      isModal: true,
      popName: 'refuse',
      isShow: true,
      style: { width: '500px' },
    }).show();
  });
