import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Tips } from 'components';
import { ROW_BACK_COLOR } from 'constants';
import { typeIs, isset } from 'utils';
import classnames from 'classnames';
import * as TableCell from './../FixedDataTableCell';
import { getCellTips, getColumnType } from '../utils';
import _ from 'lodash';

export default class BodyCell extends PureComponent {
  constructor(props) {
    super(props);
    props.refGetter && props.refGetter('footer', props.columnKey, this);
  }

  static propTypes = {
    rowIndex: PropTypes.number,
    data: PropTypes.object,
    columnKey: PropTypes.string,
    prefixCls: PropTypes.string,
    operates: PropTypes.object,
    refGetter: PropTypes.func,
    isShowSelect: PropTypes.bool,
    isShowRowContextMenu: PropTypes.bool,
    columnProps: PropTypes.object,
    isAutoRowHeight: PropTypes.bool,
    gatherOperates: PropTypes.bool,
    // tableScrolling: PropTypes.bool,
    cellclassMap: PropTypes.object,
    cellEvents: PropTypes.object,
    tableKey: PropTypes.string,
    rowContextMenu: PropTypes.func,
    enumerations: PropTypes.object,
    style: PropTypes.object,
    tipsTrigger: PropTypes.string,
    cellStyleGetter: PropTypes.func,
    cellClassGetter: PropTypes.func,
    oriHeader: PropTypes.object,
    adaptiveCols: PropTypes.array,
    rowHeight: PropTypes.any,
  };
  getStyleProps = prop => {
    const { dataItem, style } = prop;
    let _style = {} || isset(dataItem, `otherProps.style.${ROW_BACK_COLOR}`, {});
    const otherStyle = (this.props.cellStyleGetter && this.props.cellStyleGetter(prop)) || {};
    _style = { ...style, ..._style, ...otherStyle };
    return _style;
  };
  getCellClass = prop => {
    const { dataItem, columnKey } = prop;
    const cellclass = isset(dataItem, `otherProps.cellclass.${ROW_BACK_COLOR}`, '');
    const otherCls = (this.props.cellClassGetter && this.props.cellClassGetter(prop)) || '';
    const colCls = `fn-table-col-${columnKey}`;
    return classnames({ [cellclass]: cellclass, [otherCls]: otherCls, [colCls]: colCls });
  };

  getContent = _p => {
    const {
      rowIndex,
      columnKey,
      height,
      columnProps,
      data,
      cellEvents = {},
      enumerations = {},
      isPopCopyOn,
      isPopTipsOn,
      tipsTrigger,
      oriHeader,
      isAutoRowHeight,
      adaptiveCols,
      rowHeight,
      rangeSelectable,
      onCellMouseDown,
      onCellMouseUp,
      onCellMouseOver,
      getRangeSelectCellClass,
    } = _p;
    let { width } = _p;
    const dataItem = data.getShowObjectAt(rowIndex);
    let columnType = getColumnType(_p.columnProps.type, dataItem, columnKey);
    // TODO 枚举值处理 尽量移到数据格式化中
    let enumData = columnProps.refEnum ? enumerations[columnProps.refEnum] : undefined;
    // 枚举值有table公共的枚举值， 如果当前行的枚举值和公共的枚举值不同，当前行的枚举值优先级更高
    const otherProps = dataItem.otherProps || {};
    const rowEnum = otherProps.enumerations || {};
    if (rowEnum[columnKey] !== undefined && typeIs(rowEnum[columnKey], 'array') && rowEnum.length !== 0) {
      enumData = rowEnum[columnKey];
    }
    columnProps.enumData = enumData;
    // columnProps.isAutoRowHeight = _p.isAutoRowHeight
    const cellStyle = this.getStyleProps({ dataItem, ..._p, enumerations, oriHeader }); // 列特殊枚举值暂不考虑
    const cellWrapClass = this.getCellClass({ dataItem, ..._p, enumerations, oriHeader });
    const curCellEvents = cellEvents[columnType];
    const cellProps = {
      rowIndex,
      columnKey,
      width,
      height,
      style: cellStyle,
      className: cellWrapClass,
    };
    const cellOtherProps = {
      ...curCellEvents,
      data,
      isPopCopyOn,
      isPopTipsOn,
      isAutoRowHeight,
      adaptiveCols,
      rowHeight,
      cellclassMap: _p.cellclassMap,
      colIndex: columnProps.colIndex,
      tableKey: _p.tableKey,
      tips: columnProps.tips,
      enableOperate: _p.enableOperate,
      cellContentGetter: _p.cellContentGetter,
    };
    if (_p.isPureText) {
      return (
        <TableCell.TextCell
          rowContextMenu={this.props.isShowRowContextMenu ? this.props.rowContextMenu : undefined}
          mapedIndex={_p.mapedIndex[rowIndex]}
          cellProps={cellProps}
          cellOtherProps={cellOtherProps}
          columnProps={columnProps}
          rowIndex={rowIndex}
          columnKey={columnKey}
          width={width}
          height={height}
        />
      );
    }
    // 勾选行 选中框勾选时 更新liststore后直接操作checkboxcell并置选中行样式
    switch (columnType) {
      case 'Operate':
        columnProps.gatherOperates = _p.gatherOperates;
        // columnProps.tableScrolling = _p.tableScrolling
        break;
      case 'Text':
        // 先只在text类型中加是否可以取合
        // 注意，原type被变更的情况 需要拎出去 单独if
        // props.summable = columnProps.summable
        // columnProps.isAutoRowHeight = _p.isAutoRowHeight
        break;
      // TODO 逐个字段的赋值可去除
      case 'CheckBox':
        // 勾选列点checkbox 事件冒泡上去 有行点击来相应勾选 // 默认true
        columnProps.stopPropagation = !(columnKey === 'checkbox' && _p.isRowClickSelect);
        columnProps.showEmptyCheckbox = true;
        columnProps.showDisableCheckbox = true;
        cellOtherProps.enableOperate = true; // TODO 去除是 需要保留 checkboxcell这块还有些单独的逻辑
        break;
      case 'CheckBoxText':
        columnType = 'CheckBox';
        break;
      case 'Subtract':
      case 'Substract':
        columnType = 'Subtract';
        width = 36;
        break;
      case 'Ccombiner':
        if (columnProps.data !== undefined) columnProps.headerData = columnProps.data;
        break;
      case 'LinkImg':
        columnType = 'Link';
        cellOtherProps.linkType = 'img';
        break;
      default:
        break;
    }
    // 最终形成清晰的 cellProps contentProps
    // 所有cell 需要支持的属性 onContextMenu
    // mapedIndex： 筛选时 index变化后，CollCell props没变化， 则不会更新，此值用于保证同步更新
    const ColCell = TableCell[`${columnType || 'Text'}Cell`] || TableCell.TextCell;
    const { colContent = {} } = columnProps;
    if (columnType === 'MultiContent' && !_.isEmpty(colContent)) {
      return (
        <div style={{ display: 'flex' }}>
          {Object.keys(colContent).map(item => (
            <div style={{ width: colContent[item] && colContent[item].width }}>
              {this.getContent({
                ...this.props,
                columnKey: item,
                columnProps: colContent[item],
                width: colContent[item] && colContent[item].width,
              })}
            </div>
          ))}
        </div>
      );
    }

    // 允许框选时，给 线条框 留出宽高空间
    if (rangeSelectable) {
      cellProps.width -= 3;
      cellProps.height -= 3;
    }

    let content = (
      <ColCell
        {...curCellEvents}
        rowContextMenu={this.props.isShowRowContextMenu ? this.props.rowContextMenu : undefined}
        cellUpdateFlag={
          _p.showingRows[rowIndex] && (_p.showingRows[rowIndex][columnKey] || _p.showingRows[rowIndex].allColumnKey)
        }
        mapedIndex={_p.mapedIndex[rowIndex]}
        cellProps={cellProps}
        cellOtherProps={cellOtherProps}
        columnProps={columnProps}
        rowIndex={rowIndex}
        columnKey={columnKey}
        width={width}
        height={height}
      />
    );

    const { colIndex } = columnProps;

    // 框选时，绘制线条框
    if (rangeSelectable) {
      content = (
        <span
          onMouseDown={e => onCellMouseDown?.(e, rowIndex, colIndex, cellProps, columnProps)}
          onMouseOver={e => onCellMouseOver?.(e, rowIndex, colIndex, cellProps, columnProps)}
          onMouseUp={e => onCellMouseUp?.(e, rowIndex, colIndex, cellProps, columnProps)}
          className={classnames('range-selection-cell', getRangeSelectCellClass(rowIndex, colIndex))}
          style={{ width: width - 1, height: height - 1 }}
        >
          {content}
        </span>
      );
    }

    const tips = getCellTips(otherProps, columnKey);
    return tips !== '' ? (
      <Tips title={tips} trigger={tipsTrigger} {...columnProps.tipProps}>
        {content}
      </Tips>
    ) : (
      content
    );
  };

  render() {
    return this.getContent(this.props);
  }
}
