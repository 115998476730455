import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { ButtonIcon, DragList, ListItem, LazyRender } from 'components';
import { throttle } from 'utils';
import { prefixCls } from './index.scss';
// import { WSAEINVALIDPROCTABLE } from 'constants'
const styles = { list: { position: 'fixed', maxWidth: 240, borderRadius: '4px' } };
export default class MenuBtn extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSubList: false, // 显示第三级菜单
      listSubList: [], // 三级菜单数据
      defActiveIndex: -1, // 三级菜单的上一级菜单的ID
      sublistStyle: {},
      subList: props.subList || [], // 二级菜单
    };
    this.sublistStyle = { ...styles.list, ...props.style, ...this.props.sublistStyle };
    this.isShow = false;
    this.resetbutton = false;
  }

  static defaultProps = {
    direction: 'left_down',
    sublistStyle: {},
    propsChangeAutoHide: true,
  };
  static propTypes = {
    classname: PropTypes.string,
    buttonProps: PropTypes.object,
    showKey: PropTypes.string,
    subList: PropTypes.array,
    uniqueKey: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    handleClick: PropTypes.func,
    display: PropTypes.bool,
    menuStyle: PropTypes.object,
    direction: PropTypes.string, // left right  left_up right_up left_down right_down left-middle right-middle
    tableHeader: PropTypes.object,
    sublistStyle: PropTypes.object,
    propsChangeAutoHide: PropTypes.bool, // Receive props 时 handlehide
    // 是否为动态按钮
    dynamic: PropTypes.string,
    // 动态按钮是否请求过数据
    resetbutton: PropTypes.bool,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.subList !== this.state.subList) {
      this.setState({ subList: nextProps.subList });
    }
    if (nextProps.resetbutton !== undefined) {
      this.resetbutton = nextProps.resetbutton;
    }
    this.props.propsChangeAutoHide && this.handleHide();
  }
  getSublistPosition = (e, n) => {
    const r = {};
    if (e && e.target && this.vBtnMenu && n) {
      let li = e.target;
      while (li.tagName !== 'LI') li = li.parentNode;
      const liRect = li.getBoundingClientRect();
      const { x, y, height, width } = liRect;
      const w = +(this.sublistStyle.width || this.sublistStyle.maxWidth);
      const h = height * (n - 1);
      // 右边放不开
      if (w + x + width > window.innerWidth) {
        r.left = x - w;
      } else {
        r.left = x + width;
      }
      // 下边放不开
      if (h + y > window.innerHeight) {
        r.top = liRect.y - h;
      } else {
        r.top = liRect.y;
      }
    }
    return r;
  };
  propsOnClick = (selected, e) => {
    this.props.onClick && this.props.onClick(selected, e, this.isShow);
  };
  handleClick = e => {
    this.props.handleClick && this.props.handleClick(e);
  };
  getIndex = (sublist, target) => {
    let index = -1;
    sublist.forEach((item, ind) => {
      if (item.key === target.key) {
        index = ind;
      }
    });
    return index;
  };
  handleOpationClick = (selected, e) => {
    // 判断是否含有下一级菜单，如果是， 显示下一级菜单， 否则不显示
    if (selected.children && selected.children.length) {
      this.setState({
        // defActiveIndex: this.props.subList.indexOf(selected), // 上一级菜单选中的项
        defActiveIndex: this.getIndex(this.state.subList, selected), // 上一级菜单选中的项
        showSubList: true,
        listSubList: selected.children,
        sublistStyle: { ...this.getSublistPosition(e, selected.children.length) },
      });
      return false;
    }
    if (!selected.dynamic) {
      this.handleHide();
      this.setState({
        defActiveIndex: -1,
        showSubList: false,
        listSubList: [],
      });
    }
    // } else {
    //   // 动态btn 不隐藏
    //   this.setState({
    //     defActiveIndex: this.getIndex(this.state.subList, selected),
    //   })
    // }
    this.propsOnClick(selected, e);
  };
  handleSubListOpationClick = (selected, e) => {
    const menuBtnItemParent = this.state.defActiveIndex === -1 ? {} : this.state.subList[this.state.defActiveIndex];
    this.handleOpationClick(
      { ...selected, isSublistClick: true, sublist: this.state.listSubList, menuBtnItemParent },
      e,
    );
  };
  handleBtnClick = e => {
    this.isShow = !this.isShow;
    this.propsOnClick(undefined, e, this.isShow);
    // 动态按钮并且已经请求过数据, 或者非动态按钮
    if (!this.props.dynamic || this.props.dynamic === 'false') {
      this.vLazyRender && this.vLazyRender.toggleDisplay();
    }
    if (this.props.dynamic && this.resetbutton) {
      this.vLazyRender && this.vLazyRender.toggleDisplay();
    }
  };
  resetSublist = (subList, e) => {
    this.resetbutton = true;
    if (e) {
      this.setState({
        showSubList: true,
        listSubList: subList,
        sublistStyle: { ...this.getSublistPosition(e, subList.length) },
      });
    } else {
      this.setState({ subList });
      this.vLazyRender && this.vLazyRender.toggleDisplay();
      if (this.getContentNode()) {
        this.getContentNode().style.height = 'auto';
      }
    }
  };

  resetTopLevelSublist = subList => this.setState({ subList });

  handleOpationMouseEnter = (selected, e) => {
    if (selected.children && selected.children.length) {
      this.handleOpationClick(selected, e);
      return false;
    }
    if (this.state.showSubList) {
      this.setState({
        defActiveIndex: -1,
        showSubList: false,
        listSubList: [],
      });
    }
  };
  handleHide = () => {
    if (this.isShow === false) return;
    this.isShow = false;
    this.vLazyRender && this.vLazyRender.handleHide();
  };
  handleShow = () => {
    if (this.isShow === true) return;
    this.isShow = true;
    this.vLazyRender && this.vLazyRender.handleShow();
  };
  hideSublist = throttle(() => {
    this.setState({ showSubList: false });
  }, 100);
  handleLazyRenderHideCallback = () => {
    this.isShow = false;
    this.hideSublist();
  };
  onDirectionChange = direction => {
    console.log(direction);
    // this.setState({ direction, ...this.getSublistPosition(e) })
  };
  getDomeNode = () => this && this.vButton && this.vButton.realDomContainer;
  getContentNode = () => this && this.vList() && this.vList().ul;
  vList = () => this._vList && this._vList.child;
  vList3 = () => this._vList3 && this._vList3.child;

  render() {
    const {
      classname,
      buttonProps,
      showKey,
      uniqueKey,
      /* subList, */ display,
      menuStyle,
      style,
      direction,
      tableHeader,
    } = this.props;
    const classes = classnames({ [prefixCls]: true, [classname]: classname, [`${prefixCls}__hide`]: !display });
    const { subList } = this.state;
    return (
      <div className={classes} onClick={this.handleClick} ref={r => (this.vBtnMenu = r)} style={menuStyle}>
        <ButtonIcon
          clickLimit={!(subList && subList.length)}
          {...buttonProps}
          ref={r => (this.vButton = r)}
          onClick={this.handleBtnClick}
        />
        {subList && subList.length ? (
          <LazyRender
            getDomNode={this.getDomeNode}
            direction={direction}
            ref={r => (this.vLazyRender = r)}
            getContentNode={this.getContentNode}
            onClose={this.handleLazyRenderHideCallback}
            onDirectionChange={this.onDirectionChange}
          >
            <DragList
              classname={`${classname}_droplist`}
              style={{ ...styles.list, ...style }}
              data={subList}
              showKey={showKey}
              uniqueKey={uniqueKey}
              direction={direction}
              tableHeader={tableHeader}
              showHeader={false}
              defActiveIndex={this.state.defActiveIndex}
              isShow
              ref={r => (this._vList = r)}
            >
              <ListItem
                onClick={this.handleOpationClick}
                onMouseEnter={this.handleOpationMouseEnter}
                onMouseLeave={this.onMouseLeave}
              />
            </DragList>
          </LazyRender>
        ) : null}
        {this.state.showSubList && this.state.listSubList.length
          ? createPortal(
              <DragList
                classname={`${classname}_dropSublist`}
                style={{ ...this.sublistStyle, ...this.state.sublistStyle }}
                data={this.state.listSubList}
                showKey={showKey}
                uniqueKey={uniqueKey}
                direction="left"
                tableHeader={tableHeader}
                showHeader={false}
                defActiveIndex={-1}
                isShow={this.state.showSubList}
                ref={r => (this._vList3 = r)}
              >
                <ListItem onClick={this.handleSubListOpationClick} />
              </DragList>,
              document.body,
            )
          : null}
      </div>
    );
  }
}
