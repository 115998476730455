import { getDistance } from '../tool';
const calcCorDist = state => {
  const companyPoi = state.companyInfo && state.companyInfo.address && state.companyInfo.address.poi;
  const rcvStnPoi = state.rcvStn ? state.rcvStn.poi : '';
  const p1 = state.corAddrInfo.poi;
  const p2 = state.rcvStnShow ? rcvStnPoi || companyPoi : companyPoi;
  const mileRatio = state.mileRatio;
  return getDistance(p1, p2, mileRatio);
};

export default calcCorDist;
