import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Icon } from 'components';
import CheckBox from '../CheckBox';
import './index.scss';

class CheckBoxGroup extends Component {
  static propTypes = {
    data: PropTypes.array,
    tips: PropTypes.object,
    className: PropTypes.string,
    checked: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    tips: {},
    className: '',
    onChange: () => {},
    disabled: false,
  };

  constructor(props) {
    super(props);
    const { checked = [] } = this.props;
    this.state = {
      checked,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProp) {
    if ('checked' in nextProp) {
      this.setState({
        checked: nextProp.checked || [],
      });
    }
  }

  handleGroupChange = (value, state) => {
    let checked;
    const option = this.props.data.find(item => item.value === value);
    if (state) {
      checked = [...this.state.checked, value];
      checked = checked.reduce((prev, item) => {
        const temp = prev.slice();
        if (!prev.includes(item)) {
          temp.push(item);
        }
        return temp;
      }, []);
      const { conflicts } = option;
      if (conflicts) {
        checked = checked.filter(x => !conflicts.includes(x));
      }
    } else {
      checked = this.state.checked.filter(x => x !== value);
    }
    this.setState({
      checked,
    });
    this.props.onChange(checked, value);
  };
  renderCheckout = () => {
    const tips = this.props.tips || {};
    const data = this.props.data || [];
    return data.map(item => {
      const { classname, value, name, label = name, disabled } = item;
      const isExist = this.state.checked.some(x => x == value); // eslint-disable-line
      const tip = item.tip || tips[label];
      const _html = (
        <CheckBox
          key={value}
          label={label}
          classname={classname}
          checked={isExist}
          onClick={this.handleGroupChange.bind(this, value)}
          direction="right"
          disabled={disabled || this.props.disabled}
        />
      );
      if (tip) {
        return (
          <div className="checkbox-tips">
            {_html}
            <Icon iconType="icon-help" tips={tip} />
          </div>
        );
      }
      return _html;
    });
  };

  render() {
    const { className, children, style } = this.props;
    return (
      <div className={className} style={style}>
        {this.renderCheckout()}
        {children}
      </div>
    );
  }
}
export default CheckBoxGroup;
