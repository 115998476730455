import PropTypes from 'prop-types';

import React, { createElement } from 'react';
import classnames from 'classnames';
import * as Components from 'components';
import { regulars } from 'constants';

const Generator = ({ name, props, getVirtualDom, classname, events = {}, getIndex, getPaths, style, tips }) => {
  // eslint-disable-line
  const renderEveryCardItem = () => {
    let everyComponent;
    let labelComponent;
    if (!props) {
      console.warn('Generator no props cannot generator component');
      return;
    }
    const { otherProps, labelProps, type, classname, eventNames = [], customValidity } = props, // eslint-disable-line
      key = name;
    console.log('CardFormType', type);
    if (type) {
      if ({}.hasOwnProperty.call(Components, type)) {
        let _getVirtualDom = getVirtualDom;
        if (typeof _getVirtualDom === 'function') {
          _getVirtualDom = virtualDom => getVirtualDom(key, type, virtualDom);
        }
        const _events = {};
        eventNames.forEach(eventName => {
          if (events === undefined) return false;
          const action = events[eventName];
          if (typeof action === 'function') {
            _events[eventName] = (...rest) => action(key, ...rest, getIndex, type, eventName);
          }
        });
        otherProps && delete otherProps[eventNames];
        if (otherProps && otherProps.pattern) {
          otherProps.pattern = regulars[otherProps.pattern] || otherProps.pattern;
        }
        // 不中转区域，中转区域不展示gis分区
        if (
          (props.labelProps && props.labelProps.children === '不中转区域:') ||
          (props.labelProps && props.labelProps.children === '中转区域:')
        ) {
          console.log('999999');
          otherProps.hiddenGis = false;
        }
        everyComponent = createElement(Components[type], {
          ...otherProps,
          ref: _getVirtualDom,
          ..._events,
          getPaths,
          customValidity,
        });
        if (tips) {
          everyComponent = (
            <Components.Tips
              style={{ lineHeight: `calc(100% - 2px)` }}
              classname={`${name}-tips`}
              title={tips}
              popoverClassName={`${name}-tips-popover`}
            >
              {everyComponent}
            </Components.Tips>
          ); // eslint-disable-line
        }
      }
    }

    if (labelProps && Object.keys(labelProps).length) {
      labelComponent = createElement(Components.Label, labelProps);
      if (labelProps.tips) {
        labelComponent = (
          <Components.Tips
            classname={`${name}-label-tips fn-card__form__label-tips`}
            title={labelProps.tips}
            popoverClassName={`${name}-label-tips-popover`}
          >
            {labelComponent}
          </Components.Tips>
        );
      }
    }

    const classes = classnames({
      [classname]: classname,
    });

    return (
      <div className={classes} key={key}>
        {labelComponent}
        {type === 'Table' ? <div className="fn-card__form__table-item">{everyComponent}</div> : everyComponent}
      </div>
    );
  };
  const classes = classnames({
    [classname]: classname,
    [name]: name,
    'in-bk': true,
    'has-suffix-icon': !!props.suffixType,
  });

  const renderSuffixIcon = () => {
    const { suffixType, suffixTips, suffixStyle = {} } = props;
    return (
      <Components.Icon
        classname="suffix-icon"
        iconType={suffixType}
        tips={suffixTips}
        style={suffixStyle}
        onClick={() => events.onSuffixClick?.(name)}
      />
    );
  };
  return (
    <div className={classes} style={style}>
      {(props.display === undefined || props.display) && renderEveryCardItem()}
      {props.suffixType && renderSuffixIcon()}
    </div>
  );
};

Generator.propTypes = {
  classname: PropTypes.string,
  name: PropTypes.string,
  props: PropTypes.object,
  tips: PropTypes.string,
  handleChange: PropTypes.func,
  handleSelected: PropTypes.func,
  handleIconClick: PropTypes.func,
  getVirtualDom: PropTypes.func,
  events: PropTypes.object,
  getIndex: PropTypes.func,
  getPaths: PropTypes.func,
  display: PropTypes.bool, // 此项是否显示
  suffixType: PropTypes.string,
  suffixTips: PropTypes.string,
  suffixStyle: PropTypes.object,
};

const render = (data = {}, opations) =>
  Object.entries(data).map(([key, props]) =>
    createElement(Generator, {
      ...opations,
      name: key,
      props,
      key,
      style: props.style,
    }),
  );

Generator.render = render;
export { render };
export default Generator;
