import PropTypes from 'prop-types';
import React from 'react';
import { Cell } from 'fixed-data-table-2';
import { Ccombiner } from 'components';
import classnames from 'classnames';
import { emptyFunction, setComVal, setComDisable, formatDeppProptotype } from 'utils';
import TableCell from './TableCell';
import { isInOtherPropsArray, getOtherPropsInfo } from './../utils';

export default class CcombinerCell extends TableCell {
  static propTypes = {
    mode: PropTypes.string, // 组合模式 right_in 后者在前者里面
    dataPathIndex: PropTypes.number, // 光标路径对应组件序号 0开始
    placeholder: PropTypes.string,
    handleEvents: PropTypes.func,
    onKeyDown: PropTypes.func,
    headerData: PropTypes.object,
    isSelectFooterAll: PropTypes.any,
  };
  static defaultProps = {
    placeholder: '',
    mode: 'right_in',
    dataPathIndex: 0,
  };
  handleEvents = (cValue, key, ...rest) => {
    // , value, form, data, type, event) => {
    const { rowIndex, handleEvents = emptyFunction, cellOtherProps } = this.props;
    const { data } = cellOtherProps;
    // 回传回去的值， 不包含header的信息
    // 注意： 此处如果有数据格式化，比如 divition multiple 并且是Input可修改的那种， 需要将回传的值进行反向格式化
    // 例子参考 inputCell
    if (rest[rest.length - 1] === 'onKeyDown') {
      this.props.onKeyDown(rest[0], this.props.rowIndex, this.props.cellOtherProps.colIndex);
      return;
    }
    const oldRow = data.getObjectAt(rowIndex);
    Object.keys(cValue).forEach(newKey => {
      handleEvents(rowIndex, newKey, cValue[newKey], key, oldRow, ...rest);
    });
  };
  getTips = (dataItem, columnKey, rowIndex) => {
    const extRender = getOtherPropsInfo(dataItem, columnKey, 'extRender');
    if (extRender) {
      const { cellOtherProps } = this.props;
      const { data } = cellOtherProps;
      const rowData = data.getAll()[rowIndex] || {};
      return extRender({ rowData, columnKey, rowIndex, type: 'InputCell' });
    }
    return null;
  };
  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data, colIndex } = cellOtherProps;
    const { rowIndex, columnKey, width, height, className } = cellProps;
    const { mode, dataPathIndex, headerData, colClass } = columnProps;
    // 使用header中的信息或者数据中的信息
    // let dataItem = data.getObjectAt(rowIndex)
    const dataItem = { ...data.getObjectAt(rowIndex), ...data.getShowObjectAt(rowIndex) };
    // 添加表格中col中的cellClass, 位置： otherProps.cellclass.columnKey
    const cellclass = getOtherPropsInfo(dataItem, columnKey, 'cellclass') || colClass || '';
    // 此处，table的行信息中不维护Ccombiner的类型信息, 在header中进行维护
    const otherProps = dataItem.otherProps || {};
    if (headerData) {
      Object.keys(headerData).forEach(key => {
        if (otherProps[key] && otherProps[key].type) {
          headerData[key].type = otherProps[key].type;
        }
        if (otherProps[key] && otherProps[key].otherProps) {
          headerData[key].otherProps = { ...headerData[key].otherProps, ...otherProps[key].otherProps };
        }
        // 在ccombiner中首先取的value，然后是 getComVal，如果getComVal获取的值和 value值不一致，会出错
        // 所以此处需要对value和setComVal均赋值
        // if (formatDeppProptotype(key, dataItem) !== undefined) {
        //   headerData[key].value = formatDeppProptotype(key, dataItem)
        // }
        headerData[key] = setComVal(headerData[key], formatDeppProptotype(key, dataItem)).toJS();
        const disabled = isInOtherPropsArray(dataItem, key, 'disable');
        headerData[key] = setComDisable(headerData[key], disabled).toJS();
      });
    }
    // const cellWrapClass = classnames({ [className]: className, [cellclass]: cellclass })
    const cellWrapClass = classnames({ [className]: className }); // TODO 为免已有的cellclass修改不全，此cell的cellclass还放内容上
    const combinerProps = {
      mode,
      // style: { width, height, lineHeight: `${height}px` },
      style: { width, height },
      data: Object.assign({}, headerData),
      dataPathIndex,
      handleEvents: this.handleEvents,
      classname: getOtherPropsInfo(dataItem, columnKey, 'classname'),
    };
    return (
      <Cell
        {...this.props.cellProps}
        className={cellWrapClass}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <Ccombiner {...combinerProps} classname={cellclass} dataPath={`${columnKey}_${rowIndex}_${colIndex}`} />
        {this.getTips(data.getObjectAt(rowIndex), columnKey, rowIndex)}
      </Cell>
    );
  }
}
