import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import './index.scss';

export class DivDrager extends PureComponent {
  static defaultProps = {
    classname: 'drag-div',
    style: { width: '8px' },
  };
  static propTypes = {
    classname: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
  };
  render() {
    const { classname, style, ...rest } = this.props;
    const classes = classnames(classname);
    return (
      <div {...rest} style={style} className={classes}>
        {this.props.children}
      </div>
    );
  }
}

export default { DivDrager };
