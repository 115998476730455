/**
 * name: voucherListOp.js
 
 
 * desc:
 */
// 凭证管理 列表页操作 -- 凭证列表/分录列表 - 填写、详情
import { PopUp, BatchOp } from 'components';
import Voucher from 'operation/listOperation/AccountingOperat/Voucher';
import UsualBox from 'operation/listOperation/AccountingOperat/UsualBox';
import AllocateBox from 'operation/listOperation/AccountingOperat/AllocateBox';
import fetchApi from 'utils/fetchApi';
import { WARN, INFO, DATE_TIME_UNLIMIT, ERROR, CHECK } from 'constants';
import { showDialog, createTip, getUuid, getPageQueryKey, isset, popSideListPage, opTips } from 'utils';
import { hasMenuPermission, hasPermission } from 'utils/utils';
// import { popSideListPage } from './reportsOp'
import { ASSIST_CONF } from 'operation/listOperation/AccountingOperat/AssistBox';
import _ from 'lodash';

export const handleRefreshTable = function (that, delay = 0) {
  return () => {
    setTimeout(() => {
      that.handleRefreshData.call(that);
    }, delay);
  };
};
/**
 * 数组去重
 */
const unique = data => {
  const arr = [];
  for (let i = 0; i < data.length; i++) {
    if (arr.indexOf(data[i]) === -1) arr.push(data[i]);
  }
  return arr;
};
/**
 * 获取 rule 详情
 */
const getRule = function () {
  const url = '/Accounts/Document/carryOver';
  const option = {
    method: 'POST',
    body: {
      req: {
        op_type: 'view',
      },
    },
  };
  return fetchApi(url, option);
};
/**
 * 获取 费用分摊 初始数据
 */
const getallocateExpense = function () {
  const url = '/Accounts/Document/allocateExpenseView';
  const option = {
    method: 'POST',
    body: null,
  };
  return fetchApi(url, option);
};
/**
 * 获取 doc 详情
 * @param docId
 */
const getDoc = function (docId) {
  const url = '/Accounts/Document/getDoc';
  const option = {
    method: 'POST',
    body: {
      req: {
        doc_id: docId,
      },
    },
  };
  return fetchApi(url, option);
};
/**
 * 使用opTips显示信息的弹框
 * @param url 需要向后端请求的url
 * @param req 需要向后端发送请求的req
 * @param list 表格的ListPage
 * @param para 其他参数
 */
export const opTipsDialog = async (url, req, list, para) => {
  // sucMsg: 成功之后的返回信息; cb: 继续按钮的callback; dialogCb: 前面有提示弹框的callBack;
  // opType、tips和orderType是opTips的入参; idKey: 继续按钮需要回传的id的键值;
  // continueCallback是在opTips的继续按钮的回调，主要是为了后面批量操作的处理。
  const { sucMsg, cb, dialogCb, opType, tips, orderType, btnKey, idKey, continueCallback, sucCallback } = para;
  const key = idKey || 'period_ids';
  // 继续按钮的回调函数
  const continueCB = (ids, callback) => {
    if (continueCallback) {
      continueCallback(ids, callback);
      return;
    }
    const continueReq = { ...req, [key]: ids.join(','), force: '1' };
    const continueParam = { cb: callback, opType, tips, orderType, btnKey };
    opTipsDialog(url, continueReq, list, continueParam);
  };
  const conf = {
    method: 'POST',
    body: { req },
  };
  btnKey && list.disableMenuButton(btnKey);
  const res = await fetchApi(url, conf);
  btnKey && list.enableMenuButton(btnKey);
  dialogCb && dialogCb();
  cb && cb();
  const param = {
    opType,
    tips,
    resData: res,
    orderType,
    sucCallback: () => {
      if (sucCallback) {
        sucCallback();
        return;
      }
      const delay = sucMsg ? 2000 : 0;
      sucMsg && createTip(sucMsg, CHECK).show();
      handleRefreshTable(list, delay)();
    },
    continueCallback: continueCB,
    showSucTips: !sucMsg,
    ext: {
      failedCallback: () => btnKey && list.enableMenuButton(btnKey),
    },
  };
  opTips(param);
};
// 多步操作返回数据的回调
const batchPaceCallback = (resData, indexs, tableData) => {
  const { failed_detail } = resData.res;
  const success_id = Object.values(resData.res.success_id);
  const rtnData = {};
  indexs.forEach(index => {
    const data = Object.assign({}, { ...resData, res: {} });
    if (success_id.includes(+tableData[index].doc_id)) {
      data.res.failed_detail = [];
      data.res.success_id = [+tableData[index].doc_id];
    } else {
      data.res.failed_detail = failed_detail.filter(item => item.number === tableData[index].no);
      data.res.success_id = [];
      data.errmsg = '';
      data.errno = -304;
    }
    rtnData[index] = data;
  });
  return rtnData;
};
/**
 * 凭证列表的批量弹框
 */
export const docBatchOp = (callback, param) => {
  const { batchData, reqUrl, batchTips, list, op_type, pace, maxInterval = 60 * 1000 } = param;
  callback && callback();
  const batchHeader = {
    no: { title: '凭证号', type: 'Text', display: 'show', width: 120, align: 'center' },
    create_by_name: { title: '制单人', type: 'Text', display: 'show', width: 90, align: 'center' },
    doc_date: { title: '凭证日期', type: 'Text', display: 'show', width: 160, align: 'center' },
  };
  const batchParam = {
    requestUrl: reqUrl,
    header: batchHeader,
    data: batchData,
    tips: batchTips,
    orderType: '凭证',
    prefixalTips: '',
    pace: pace || 1,
    maxInterval,
    getPara: dataList => {
      const selectRowIds = dataList.map(item => item.doc_id).join(',');
      const reqParam = { op_type, doc_ids: selectRowIds, force: '1' };
      return reqParam;
    },
    finishCallback: handleRefreshTable(list),
    pacesCallback: batchPaceCallback, // 一次传送10条记录时的回调函数
    escBind: false,
  };
  new PopUp(BatchOp, batchParam).show();
};
// /**
//  * 获取一个新的凭证序列号
//  */
// const getDocNo = function () {
//   const url = '/Accounts/Document/getDocNo'
//   return fetchApi(url)
// }
/**
 * 获取凭证设置
 */
const getDocConfig = function () {
  const url = '/Accounts/Document/getDocDetailConf';
  const option = {
    method: 'POST',
    body: {
      req: {
        key: 'ac_doc_detail',
      },
    },
  };
  return fetchApi(url, option);
};
const getSugOpenPeriod = function (ledgerPid) {
  const url = '/Accounts/Period/sugOpenPeriod';
  const option = {
    method: 'POST',
    body: {
      req: {
        ledger_pid: ledgerPid,
      },
    },
  };
  return fetchApi(url, option);
};
/**
 * 过账
 */
export const docAccount = function (that, btnKey) {
  const selectedIndex = that.vTable.getStateSelectes();
  const selectRowIds = unique(that.getSelectesKey('doc_id'));
  const data = {};
  that.getStateSelecteRows().forEach(item => (data[item.doc_id] = item));
  const selectedData = selectRowIds.map(id => data[id]);
  if (selectedIndex.length < 1) {
    createTip('至少选中一行', WARN).show();
    return;
  }
  const url = '/Accounts/Document/opDoc';
  const successMsg = '过账成功';
  const req = {
    // 0-delete 1-audit 2-audit_cancel 3-account 4-account_cancel
    op_type: 3,
    doc_ids: selectRowIds.join(','),
    is_check: selectRowIds.length > 1 ? 1 : 0, // 是否进行校验
  };
  /**
   * 批量操作弹框
   * ids 是点击【继续】按钮后返回的成功的id数组, callback是点击【继续】按钮后返回的回调函数
   */
  const docBatch = (ids, callback) => {
    const batchData = ids ? selectedData.filter(item => ids.includes(+item.doc_id)) : selectedData;
    const param = {
      reqUrl: url,
      batchData,
      batchTips: '过账',
      list: that,
      op_type: 3,
      pace: 30, // 步长
      maxInterval: 200,
      minInterval: 0,
    };
    docBatchOp(callback, param);
  };
  const callback = selectRowIds.length > 1 ? docBatch : undefined;
  const param = {
    sucMsg: successMsg,
    opType: '过账',
    tips: '过账',
    orderType: '凭证',
    btnKey,
    idKey: 'doc_ids',
    continueCallback: callback,
    sucCallback: callback,
  };
  opTipsDialog(url, req, that, param);
  // showDialog({
  //   type: INFO,
  //   content: '您是否确认过账，过账后凭证不可再进行修改及删除的操作',
  //   confirm: doAction
  // }).show()
};
/**
 * 审核
 */
export const docAudit = function (that, btnKey) {
  const selectedIndex = that.vTable.getStateSelectes();
  const selectRowIds = unique(that.getSelectesKey('doc_id'));
  const data = {};
  that.getStateSelecteRows().forEach(item => (data[item.doc_id] = item));
  const selectedData = selectRowIds.map(id => data[id]);
  if (selectedIndex.length < 1) {
    createTip('至少选中一行', WARN).show();
    return;
  }
  const url = '/Accounts/Document/opDoc';
  const successMsg = '审核成功';
  const req = {
    // 0-delete 1-audit 2-audit_cancel 3-account 4-account_cancel
    op_type: 1,
    doc_ids: selectRowIds.join(','),
    is_check: selectRowIds.length > 1 ? 1 : 0, // 是否进行校验
  };
  /**
   * 批量操作弹框
   * ids 是点击【继续】按钮后返回的成功的id数组, callback是点击【继续】按钮后返回的回调函数
   */
  const docBatch = (ids, callback) => {
    const batchData = ids ? selectedData.filter(item => ids.includes(+item.doc_id)) : selectedData;
    const param = {
      reqUrl: url,
      batchData,
      batchTips: '审核',
      list: that,
      op_type: 1,
      pace: 30, // 步长
      maxInterval: 200,
      minInterval: 0,
    };
    docBatchOp(callback, param);
  };
  const callback = selectRowIds.length > 1 ? docBatch : undefined;
  const param = {
    sucMsg: successMsg,
    opType: '审核',
    tips: '审核',
    orderType: '凭证',
    btnKey,
    idKey: 'doc_ids',
    continueCallback: callback,
    sucCallback: callback,
  };
  opTipsDialog(url, req, that, param);
};
/**
 * 取消审核
 */
export const docAuditCancel = function (that, btnKey) {
  const selectedIndex = that.vTable.getStateSelectes();
  const selectRowIds = unique(that.getSelectesKey('doc_id'));
  const data = {};
  that.getStateSelecteRows().forEach(item => (data[item.doc_id] = item));
  const selectedData = selectRowIds.map(id => data[id]);
  if (selectedIndex.length < 1) {
    createTip('至少选中一行', WARN).show();
    return;
  }
  const url = '/Accounts/Document/opDoc';
  const successMsg = '取消审核成功';
  const req = {
    // 0-delete 1-audit 2-audit_cancel 3-account 4-account_cancel
    op_type: 2,
    doc_ids: selectRowIds.join(','),
    is_check: selectRowIds.length > 1 ? 1 : 0, // 是否进行校验
  };
  /**
   * 批量操作弹框
   * ids 是点击【继续】按钮后返回的成功的id数组, callback是点击【继续】按钮后返回的回调函数
   */
  const docBatch = (ids, callback) => {
    const batchData = ids ? selectedData.filter(item => ids.includes(+item.doc_id)) : selectedData;
    const param = {
      reqUrl: url,
      batchData,
      batchTips: '取消审核',
      list: that,
      op_type: 2,
      pace: 30, // 步长
      maxInterval: 200,
      minInterval: 0,
    };
    docBatchOp(callback, param);
  };
  const callback = selectRowIds.length > 1 ? docBatch : undefined;
  const param = {
    sucMsg: successMsg,
    opType: '取消审核',
    tips: '取消审核',
    orderType: '凭证',
    btnKey,
    idKey: 'doc_ids',
    continueCallback: callback,
    sucCallback: callback,
  };
  opTipsDialog(url, req, that, param);
};
/**
 * 填写凭证
 */
// 辅助核算数据结构
let defaultAssistData = Object.values(ASSIST_CONF).reduce((dist, currItem) => {
  const newDist = dist;
  newDist[currItem.key] = {};
  return newDist;
}, {});
defaultAssistData = { ...defaultAssistData, accounts_id: {} };
export const docCreate = function (that, btnKey) {
  const pageKey = getPageQueryKey();
  const isCreate = true;
  // let docNoPromise = getDocNo()
  const sugOpenPeriod = getSugOpenPeriod();
  const docConfigPromise = getDocConfig();
  that.disableMenuButton(btnKey);
  Promise.all([sugOpenPeriod, docConfigPromise]).then(result => {
    that.enableMenuButton(btnKey);
    // const docNoRes = result[0]
    const openPeriodRes = result[0];
    const docConfigRes = result[1];
    let docNo;
    // if (docNoRes.errno !== 0) {
    //   createTip('凭证序列号生成失败，请重试', WARN).show()
    //   return
    // }
    if (openPeriodRes.errno !== 0) {
      createTip('会计期间获取失败，请重试', WARN).show();
      return;
    }
    if (docConfigRes.errno !== 0) {
      createTip('凭证列表设置获取失败，请重试', WARN).show();
      return;
    }
    // docNo = docNoRes.res
    const openPeriod = openPeriodRes.res;
    const docConfig = docConfigRes.res;
    let openPeriodArray = [];
    const openPeriodLen = openPeriod.length;
    if (openPeriodLen <= 0) {
      createTip('没有会计期间，创建会计期间后才能填写凭证', WARN).show();
      return;
    }
    openPeriodArray = openPeriod.map(x => x.snap_time);
    const { header } = docConfig;
    const manualEnable = docConfig.voucher_num && docConfig.voucher_num.manual_enable;
    const cashFlow = docConfig.cash_flow || [];
    const cashFlowSubject = docConfig.cash_flow_subject || [];
    const assistLink = docConfig.assist_conf.sort || [];
    const assistJoin = docConfig.assist_conf.link || '-';
    const data = { openPeriodArray };
    const canEdit = true;
    const config = { title: '填制凭证', show: true, action: 'add' };
    const active = {
      doc_data: [
        {
          doc_detail_id: -1,
          account_detail_id: -1,
          account_direct: -1,
          assist: {},
          assistConfig: [],
          assistData: defaultAssistData,
          money: 0,
          abstract: '',
          account_code: '',
          account_name: '',
          debit_money: 0,
          credit_money: 0,
          debitState: false,
          creditState: false,
        },
      ],
      no: docNo,
      num: 1,
      date: '',
      create_by_name: '',
      ledgerPid: '',
    };
    const { buttons } = that.state;
    const dialog = new PopUp(Voucher, {
      popName: `zgzVoucher_${pageKey}`,
      config,
      data,
      active,
      buttons,
      isCreate,
      header,
      manualEnable,
      cashFlow,
      cashFlowSubject,
      assistLink,
      assistJoin,
      canEdit,
      isArtificial: true,
      opCallback: handleRefreshTable(that, 3000),
    });
    dialog.show();
  });
};
/**
 * 修改凭证
 */
export const docUpdate = function (that, btnKey) {
  const pageKey = getPageQueryKey();
  const selectRowData = that.getSelectesKey(['doc_id', 'ledger_id', 'state', 'second_class', 'first_class']);
  const selectedData = selectRowData[0];
  const tableRef = that;
  if (selectRowData.length < 1) {
    createTip('至少选中一行', WARN).show();
    return;
  }
  if (selectRowData.length > 1) {
    createTip('修改操作不允许多选，只能选中一条操作', WARN).show();
    return;
  }
  if (+selectedData.state !== 1) {
    createTip('只有待审核的凭证才可以修改！', ERROR).show();
    return;
  }
  // if (selectedData.state === '3') {
  //   createTip('凭证已过账，无法修改！', WARN).show()
  //   return
  // }
  const docReq = getDoc(selectedData.doc_id);
  const sugOpenPeriod = getSugOpenPeriod(selectedData.ledger_id);
  const docConfigPromise = getDocConfig();
  that.disableMenuButton(btnKey);
  Promise.all([docReq, sugOpenPeriod, docConfigPromise]).then(result => {
    that.enableMenuButton(btnKey);
    const docRes = result[0];
    const openPeriodRes = result[1];
    const docConfigRes = result[2];
    if (docRes.errno !== 0) {
      createTip('获取详情失败，请重试', WARN).show();
      return;
    }
    if (openPeriodRes.errno !== 0) {
      createTip('会计期间获取失败，请重试', WARN).show();
      return;
    }
    if (docConfigRes.errno !== 0) {
      createTip('凭证列表设置获取失败，请重试', WARN).show();
      return;
    }
    const doc = docRes.res;
    const openPeriod = openPeriodRes.res;
    const docConfig = docConfigRes.res;
    const openPeriodLen = openPeriod.length;
    let openPeriodArray;
    if (openPeriodLen <= 0) {
      createTip('没有会计期间，请创建', WARN).show();
    } else {
      openPeriodArray = openPeriod.map(x => x.snap_time); // 期间sug数据
    }
    const docData = doc.list.map(item => {
      const debitMoney = parseFloat(item.debit_money, 10);
      const creditMoney = parseFloat(item.credit_money, 10);
      return {
        doc_detail_id: item.doc_detail_id, // 凭证ID
        account_detail_id: item.account_detail_id, // 科目ID
        account_direct: debitMoney ? 1 : 2,
        assist: item.assist || {},
        assistData: item.assist_data || defaultAssistData,
        assistConfig: item.assist_can_edit || [], // 辅助核算显示项
        money: debitMoney || creditMoney,
        abstract: item.abstract,
        account_code: item.account_code,
        account_name: item.account_name,
        debit_money: parseFloat(item.debit_money, 10),
        credit_money: parseFloat(item.credit_money, 10),
        debitState: false,
        creditState: false,
        cash_flow_code: item.cash_flow_code,
        files: item.files,
      };
    });
    const { header } = docConfig;
    const assistLink = docConfig.assist_conf.sort || [];
    const assistJoin = docConfig.assist_conf.link || '-';
    const data = { openPeriodArray };
    const canEdit = parseInt(selectedData.state, 10) !== 2 && parseInt(selectedData.state, 10) !== 3;
    const docExt = doc.ext;
    const manualEnable = docConfig.voucher_num && docConfig.voucher_num.manual_enable;
    const unableEdit = docExt.auto_doc_unable_edit;
    const isArtificial = unableEdit ? selectedData.first_class === '人工' : true; // 一级类别是否是【人工】
    const config = hasPermission('ac_doc_update')
      ? { title: '填制凭证', show: true, action: 'update' }
      : { title: '填制凭证', show: true, action: 'view' };
    const active = {
      doc_data: docData,
      account_time: doc.account_time, // 存在过账时间 则显示已过账
      audit_time: doc.audit_time, // 存在审核时间 则显示已已审核
      no: doc.no,
      num: doc.num, // 附单据张数
      date: doc.date,
      create_by_name: doc.create_by_name,
      id: doc.id,
      docSource: doc.doc_source,
      first_class: doc.first_class,
      second_class: doc.second_class,
      connect_ids: doc.connect_ids,
      ledger_id: selectedData.ledger_id,
      files: doc.files,
    };
    const cashFlow = docConfig.cash_flow || [];
    const cashFlowSubject = docConfig.cash_flow_subject || [];
    const { buttons } = that.state;
    const dialog = new PopUp(Voucher, {
      popName: `zgzVoucher_${pageKey}`,
      config,
      data,
      active,
      buttons,
      tableRef,
      header,
      manualEnable,
      assistLink,
      assistJoin,
      canEdit,
      isArtificial,
      ext: doc.ext,
      cashFlow,
      cashFlowSubject,
      opCallback: handleRefreshTable(that, 3000),
    });
    dialog.show();
  });
};
/**
 * 删除
 */
export const docDelete = function (that, btnKey) {
  const selectedIndex = that.vTable.getStateSelectes();
  const selectRowIds = unique(that.getSelectesKey('doc_id'));
  const data = {};
  that.getStateSelecteRows().forEach(item => (data[item.doc_id] = item));
  const selectedData = selectRowIds.map(id => data[id]);
  const url = '/Accounts/Document/opDoc';
  if (selectedIndex.length < 1) {
    createTip('至少选中一行', WARN).show();
    return;
  }
  /**
   * 批量操作弹框
   * ids 是点击【继续】按钮后返回的成功的id数组, callback是点击【继续】按钮后返回的回调函数
   */
  const docBatch = (ids, callback) => {
    const batchData = ids ? selectedData.filter(item => ids.includes(+item.doc_id)) : selectedData;
    const param = {
      reqUrl: url,
      batchData,
      batchTips: '删除',
      list: that,
      op_type: 0,
    };
    docBatchOp(callback, param);
  };
  const callback = selectedIndex.length > 1 ? docBatch : undefined;
  const handleConfirm = confirmCb => {
    const successMsg = '删除成功';
    const req = {
      // 0-delete 1-audit 2-audit_cancel 3-account 4-account_cancel
      op_type: 0,
      doc_ids: selectRowIds,
      is_check: selectedIndex.length > 1 ? 1 : 0, // 是否进行校验
    };
    const param = {
      sucMsg: successMsg,
      opType: '删除',
      tips: '删除',
      orderType: '凭证',
      btnKey,
      dialogCb: confirmCb,
      continueCallback: callback,
      sucCallback: callback,
      idKey: 'doc_ids',
    };
    opTipsDialog(url, req, that, param);
  };
  const showDialogHasLoading = selectedIndex.length === 1;
  showDialog(
    {
      type: WARN,
      content: '您是否确认删除此凭证，删除后的凭证号将不可重复使用',
      // confirm: selectedIndex.length > 1 ? docDeleteBatch : doAction
      confirm: handleConfirm,
    },
    undefined,
    showDialogHasLoading,
  ).show();
};
export const docDetail = function (that, index) {
  const data = that.getData();
  const initFilterHeader = that.getHeader() || {};
  const selectData = data[index];
  // 跳转至 分录列表
  const pack = {
    designateParam: {
      no: selectData.no,
      ignore_default: true,
    },
    designateFilter: {
      no: selectData.no,
      doc_date: DATE_TIME_UNLIMIT,
    },
    initFilterHeader,
  };
  const urlData = {
    controller: 'Accounting',
    action: 'joulList',
    injectProps: pack,
  };
  window.$app.direct(urlData);
};
export const docSourceShow = function (that, index, column, data, pageKey) {
  // 有非列表调用 index column 可能为空
  const docSource = data.doc_source;
  const listData = that.getData();
  const secondClass = data.secondClass ? data.secondClass : listData[index].second_class;
  // doc_source为on_account_order_trade时,category:Order,tab:doc_detail  //运单挂账和应收应付挂账
  // doc_source为on_account_batch时,category:Batch,tab:doc_batch_detail    //发车批次挂账
  // doc_source为on_account_shuttle时,category:Batch,tab:doc_shuttle_detail  //短驳批次挂账
  // doc_source为on_account_delivery时,category:Batch,tab:doc_delivery_detail    //送货批次挂账
  // query:  no//凭证号,doc_source//凭证来源
  // 补充：doc_source为settle时，跳转资金流水明细；    //结算、取消结算
  // doc_source为manual时，为人工创建，无明细； //人工创建
  let category;
  let tab;
  let url = 'Table/Search/docSourceList';
  let designateFilter = {
    doc_id: data.doc_id,
    doc_source: docSource,
  };
  if (docSource === 'on_account_order_trade') {
    category = 'Order';
    tab = 'doc_detail';
  } else if (docSource === 'on_account_batch') {
    category = 'Batch';
    tab = 'doc_batch_detail';
  } else if (docSource === 'on_account_shuttle') {
    category = 'Batch';
    tab = 'doc_shuttle_detail';
  } else if (docSource === 'on_account_delivery') {
    category = 'Batch';
    tab = 'doc_delivery_detail';
  } else if (docSource === 'recharge_mgr') {
    category = 'Recharge';
    tab = 'doc_detail';
  } else if (docSource === 'withdraw_mgr') {
    category = 'Withdraw';
    tab = 'doc_detail';
  } else if (docSource === 'on_account_batch_task') {
    category = 'Batch';
    tab = 'doc_batch_task_detail';
  } else if (docSource === 'settle') {
    const hasSettleLogPermission = hasMenuPermission('money_flow_total');
    const hasSettleLogDetailPermission = hasMenuPermission('money_flow_detail');
    if (hasSettleLogPermission || hasSettleLogDetailPermission) {
      const urlData = {
        controller: 'Finance',
        action: hasSettleLogDetailPermission ? 'settleLogDetail' : 'settleLog',
        injectProps: {
          designateFilter: {
            cert_no: data.no,
            create_time: DATE_TIME_UNLIMIT,
          },
        },
      };
      window.$app.direct(urlData);
    } else {
      createTip('您需要资金流水管理权限才可查看', WARN).show();
    }
    return;
  } else if (docSource === 'order_modify') {
    const urlData = {
      controller: 'Order',
      action: 'changeOrderManager',
      injectProps: {
        designateFilter: {
          id: data.connect_ids,
        },
      },
    };
    window.$app.direct(urlData);
    return;
  } else if (docSource === 'on_account_daily') {
    category = 'Settle';
    tab = 'balance_ledger_doc';
    url = 'Table/Search/settleList';
    designateFilter = {
      ledger_cert_ids: [data.doc_id],
    };
  } else if (docSource === 'manual') {
    createTip('人工创建的凭证无业务明细', WARN).show();
    return;
  }
  const sideTitle = `${secondClass}业务明细-凭证编码：${data.no}`;
  const pageConfig = {
    listPage: true,
    url,
    tableInfo: {
      category,
      tab,
      body: {
        fetch_mode: 'all',
      },
    },
    key: getUuid(),
  };
  // const curTable = that.getTableInfo ? that.getTableInfo() : that.getData()
  const curTable = that.getTableInfo();
  const listPageConfig = { pageConfig, key: pageConfig.key };
  const getPageInfo = () =>
    Object.assign(curTable, {
      menuName: `${secondClass}业务明细`,
      pageConfig,
    });
  popSideListPage(
    sideTitle,
    Object.assign(listPageConfig, { parentPageKey: pageKey || that.getPageKey() }),
    { designateFilter },
    getPageInfo,
  );
};

// export function docListNo(that, index, rowDataMap, certNoId) {
export function docListNo(that, index, rowDataMap) {
  if (!hasPermission(['ac_doc_view', 'ac_doc_detail_view'])) {
    createTip('您没有查看凭证的权限！', WARN).show();
    return;
  }
  const { noId, ledgerId, curCertNoId } = rowDataMap;
  const pageKey = getPageQueryKey();
  const tableData = that.getData();
  const tableRef = that;
  const selectedData = tableData[index];
  // const reqId = certNoId || selectedData[noId] || selectedData.doc_id || selectedData.cert_no_id
  const reqId =
    curCertNoId ||
    selectedData[noId] ||
    selectedData.doc_id ||
    selectedData.cert_no_id ||
    selectedData['Settle|cert_no_id'];
  const reqPeriodId = selectedData[ledgerId] || selectedData.ledger_id;
  const docReq = getDoc(reqId);
  const sugOpenPeriod = getSugOpenPeriod(reqPeriodId);
  const docConfigPromise = getDocConfig();
  Promise.all([docReq, sugOpenPeriod, docConfigPromise]).then(result => {
    const docRes = result[0];
    const openPeriodRes = result[1];
    const docConfigRes = result[2];
    if (docRes.errno !== 0) {
      createTip(docRes.errmsg, WARN).show();
      return;
    }
    if (openPeriodRes.errno !== 0) {
      createTip('会计期间获取失败，请重试', WARN).show();
      return;
    }
    if (docConfigRes.errno !== 0) {
      createTip('凭证列表设置获取失败，请重试', WARN).show();
      return;
    }
    const doc = docRes.res;
    const openPeriod = openPeriodRes.res;
    const docConfig = docConfigRes.res;
    const openPeriodLen = openPeriod.length;
    let openPeriodArray;
    if (openPeriodLen <= 0) {
      createTip('没有会计期间，请创建', WARN).show();
    } else {
      openPeriodArray = openPeriod.map(x => x.snap_time);
    }
    const docData = doc.list.map(item => {
      const debitMoney = parseFloat(item.debit_money, 10);
      const creditMoney = parseFloat(item.credit_money, 10);
      return {
        doc_detail_id: item.doc_detail_id,
        account_detail_id: item.account_detail_id,
        account_direct: debitMoney ? 1 : 2,
        assist: item.assist || {},
        assistData: item.assist_data || defaultAssistData,
        assistConfig: item.assist_can_edit || [],
        money: debitMoney || creditMoney,
        abstract: item.abstract,
        account_code: item.account_code,
        account_name: item.account_name,
        debit_money: parseFloat(item.debit_money, 10),
        credit_money: parseFloat(item.credit_money, 10),
        debitState: false,
        creditState: false,
        cash_flow_code: item.cash_flow_code,
      };
    });
    const { header } = docConfig;
    const assistLink = docConfig.assist_conf.sort || [];
    const assistJoin = docConfig.assist_conf.link || '-';
    const data = { openPeriodArray };
    const canEdit = parseInt(doc.state, 10) === 1 && !_.get(doc, 'ext.delete');
    const docExt = doc.ext;
    const unableEdit = docExt.auto_doc_unable_edit;
    const manualEnable = docConfig.voucher_num && docConfig.voucher_num.manual_enable;
    const isArtificial = unableEdit ? selectedData.first_class === '人工' : true; // 一级类别是否是【人工】
    // let editPermission = that.getExtData().edit_permission || 0, config
    // if (editPermission && selectedData.state !== '3') {
    //   config = { title: '填制凭证', show: true, action: 'update' }
    // } else {
    //   config = { title: '填制凭证', show: true, action: 'view' }
    // }
    const config = hasPermission('ac_doc_update')
      ? { title: '填制凭证', show: true, action: 'update' }
      : { title: '填制凭证', show: true, action: 'view' };
    const active = {
      doc_data: docData,
      account_time: doc.account_time,
      audit_time: doc.audit_time,
      no: doc.no,
      num: doc.num,
      date: doc.date,
      create_by_name: doc.create_by_name,
      id: doc.id,
      docSource: doc.doc_source,
      connect_ids: doc.connect_ids,
      first_class: doc.first_class,
      second_class: doc.second_class,
      ledger_id: reqPeriodId,
      indexInfo: index,
      files: doc.files,
    };
    const buttons = isset(that, 'state.buttons', {});
    const dataArr = isset(that, 'state.data', {});
    const cashFlow = docConfig.cash_flow || [];
    const cashFlowSubject = docConfig.cash_flow_subject || [];
    const dialog = new PopUp(Voucher, {
      popName: `zgzVoucher_${pageKey}`,
      config,
      data,
      active,
      buttons,
      dataArr,
      tableRef,
      index,
      header,
      assistLink,
      assistJoin,
      canEdit,
      isArtificial,
      manualEnable,
      cashFlow,
      cashFlowSubject,
      ext: doc.ext || {},
      opCallback: handleRefreshTable(that, 3000),
    });
    dialog.show();
  });
}

export function allocateExpense(that, btnKey) {
  that.disableMenuButton(btnKey);
  getallocateExpense().then(res => {
    that.enableMenuButton(btnKey);
    if (res.errno === 0) {
      const initData = res.res.default;
      const enumList = res.res.enum;
      const dialog = new PopUp(AllocateBox, { initData, enumList, that });
      dialog.show();
    } else {
      createTip(res.errmsg, ERROR).show();
    }
  });
}

export function carryOver(that, btnKey) {
  that.disableMenuButton(btnKey);
  getRule().then(res => {
    that.enableMenuButton(btnKey);
    if (res.errno === 0) {
      const initData = res.res.default;
      const enumList = res.res.enum;
      const dialog = new PopUp(UsualBox, { initData, enumList, that });
      dialog.show();
    } else {
      createTip(res.errmsg, ERROR).show();
    }
  });
}
/**
 * 取消过账
 * @param that
 */
export function docAccountCancel(that, btnKey) {
  const selectedIndex = that.vTable.getStateSelectes();
  const selectRowIds = unique(that.getSelectesKey('doc_id'));
  const data = {};
  that.getStateSelecteRows().forEach(item => (data[item.doc_id] = item));
  const selectedData = selectRowIds.map(id => data[id]);
  if (selectedIndex.length < 1) {
    createTip('至少选中一行', WARN).show();
    return;
  }
  const url = '/Accounts/Document/opDoc';
  /**
   * 批量操作弹框
   * ids 是点击【继续】按钮后返回的成功的id数组, callback是点击【继续】按钮后返回的回调函数
   */
  const docBatch = (ids, callback) => {
    const batchData = ids ? selectedData.filter(item => ids.includes(+item.doc_id)) : selectedData;
    const param = {
      reqUrl: url,
      batchData,
      batchTips: '取消过账',
      list: that,
      op_type: 4,
      pace: 30, // 步长
      maxInterval: 200,
      minInterval: 0,
    };
    docBatchOp(callback, param);
  };
  const callback = selectRowIds.length > 1 ? docBatch : undefined;
  const handleConfirm = confirmCb => {
    const successMsg = '取消过账成功';
    const req = {
      // 0-delete 1-audit 2-audit_cancel 3-account 4-account_cancel
      op_type: 4,
      doc_ids: selectRowIds.join(','),
      is_check: selectRowIds.length > 1 ? 1 : 0, // 是否进行校验
      // force: 1
    };
    const param = {
      sucMsg: successMsg,
      opType: '取消过账',
      tips: '取消过账',
      orderType: '凭证',
      dialogCb: confirmCb,
      btnKey,
      idKey: 'doc_ids',
      continueCallback: callback,
      sucCallback: callback,
    };
    opTipsDialog(url, req, that, param);
  };
  showDialog(
    {
      type: INFO,
      content: '您是否确认取消过账？',
      confirm: handleConfirm,
    },
    undefined,
    true,
  ).show();
}

export function init(emt) {
  emt.on('docAccount', docAccount);
  emt.on('docAccountCancel', docAccountCancel);
  emt.on('docAudit', docAudit);
  emt.on('docAuditCancel', docAuditCancel);
  emt.on('docCreate', docCreate);
  emt.on('docDelete', docDelete);
  emt.on('docUpdate', docUpdate);
  // emt.on('docListDocDetail', docDetail)
  // emt.on('docListNo', docListNo)
  emt.on('accountsDocDetail', docDetail);
  emt.on('accountsDocSource', docSourceShow);
  emt.on('accountsNo', docListNo);
  emt.on('carryOver', carryOver);
  emt.on('allocateExpense', allocateExpense);
  emt.on('settleLedgerCertNo', (that, index) => docListNo(that, index, { noId: 'ledger_cert_id' }));
  emt.on('settleRecordNo', docListNo);
}
