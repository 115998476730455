import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  CardForm,
  Table,
  ScanSug,
  AdjustableDiv,
  Icon,
  Tips,
  MsgTips,
  CheckBox,
  PopUp,
  ColorTips,
  Page,
  PureInput,
  ModalDialog,
  SelectDrop,
  Button,
} from 'components';
import OrderButtons from 'components/commoncomponents/OrderButtons';
import {
  typeIs,
  emitter,
  formatCamelCase,
  createTip,
  formatDeppProptotype,
  throttle,
  fetchApi as callApi,
  isEmptyObj,
  formateSplitInfo,
  filterValidate,
  alert,
  getErrorDetail,
  mergeEnum,
  cellClassGetter,
  showMaxPageLimitDialog,
  proxyListPage,
  opTips,
  showInfo,
} from 'utils';
import {
  ERROR,
  INFO,
  PICKORDER_DEFAULT_PAGE,
  PICKORDER_DEFAULT_TABLE_WIDTH,
  DOWN_TAB,
  MAX_PICK_ORDER,
  FIRST_SORT_ID,
  FIRST_SORT_TIME,
  exportUrlConf,
  MAX_TABLE_ACC_ROW,
  FLOAT_P,
} from 'constants';
import { handleGetModule } from 'operation';
import _ from 'lodash';
import classnames from 'classnames';
import actions from 'actions';
import splitOrder from '../SplitOrder';
import { getSumVal } from '../../../utilcomponents/tableV2/helper/getSumVal';
import { getCustomize } from '../../../../pages/ListPage/customize';
import { prefixCls } from './index.scss';
import { i18n } from '@/utils/i18n/index.js';
import { POPUP_CONFIRM_WINDOW } from '@/utils/i18n/constants.js';

import { hasOverCompanyAuth } from './overCompanyAuth';

const pickMock = require('./mockData');

export default class BiPickOrder extends Component {
  static defaultProps = {
    type: 'show',
    defaultPage: PICKORDER_DEFAULT_PAGE,
    adjustWidth: PICKORDER_DEFAULT_TABLE_WIDTH,
    leftShow: true,
    rightShow: true,
    defaultDataFormate: true,
    isPack: false,
    showRate: false,
  };
  static propTypes = {
    // type: add, edit 和 show ( 外部只修改type值 )
    type: PropTypes.string,
    openPageBtnKey: PropTypes.string,
    openPageTab: PropTypes.string,
    // 批次信息
    batchInfo: PropTypes.object,
    classname: PropTypes.string,
    // 挑单全局信息
    batchTableInfo: PropTypes.object,
    // 左、右侧表格数据
    leftList: PropTypes.object,
    rightList: PropTypes.object,
    // 左右屏的宽度比例
    adjustWidth: PropTypes.object,
    // 左右挑单完成后的回调
    handleRightToLeftCallback: PropTypes.func,
    handleLeftToRightCallback: PropTypes.func,
    handleRightTodoAdd: PropTypes.func,
    handleRightTodoSubtract: PropTypes.func,
    handleRightOperateClick: PropTypes.func,
    // 左屏数据格式化(挑单前)
    leftDataFormate: PropTypes.func,
    // 默认页码
    defaultPage: PropTypes.string,
    // 页码变化回调
    changeLeftPageNumCallback: PropTypes.func,
    handleLeftPaging: PropTypes.func,
    // 行筛选回调
    handleFilterChange: PropTypes.func,
    // button点击回调
    handleBtnClick: PropTypes.func,
    // 配载率
    wRateValue: PropTypes.any,
    vRateValue: PropTypes.any,
    // 表格中输入框change事件
    handleTableChange: PropTypes.func,
    // 表格设置（表头/筛选/切换模板）
    tableSet: PropTypes.func,
    //
    handleTableFormClick: PropTypes.func,
    // 表格头部排序
    handleHeaderSortable: PropTypes.func,
    rightDataFormate: PropTypes.func,
    // 对原始在右屏的数据是否进行格式化
    defaultDataFormate: PropTypes.bool,
    // 是否显示左、右屏 (由type值来初始化)
    leftShow: PropTypes.bool,
    rightShow: PropTypes.bool,
    refreshData: PropTypes.func,
    orderNum: PropTypes.any,
    packNum: PropTypes.any,
    packList: PropTypes.array,
    orderArrList: PropTypes.array,
    // 对账比例
    onRateChange: PropTypes.array,
    reconciliationRate: PropTypes.string,
    showRate: PropTypes.bool,
    prohibitTableMove: PropTypes.func,
  };

  constructor(prop) {
    super(prop);

    const rightList = this.getInitRightList(prop);
    this.state = {
      // tableEnum: this.mergeEnum((rightList === undefined) ? {} : rightList.enumerations, (this.props.leftList === undefined) ? {} : this.props.leftList.enumerations),
      rightList: rightList === undefined ? [] : rightList.data,
      rightEnum: rightList === undefined ? [] : rightList.enumerations,
      rightButtons: rightList === undefined ? {} : this.dealButtons(rightList.buttons, 'right'),
      rightSort: rightList === undefined ? [] : rightList.sort,
      leftList: this.props.leftList === undefined ? [] : this.props.leftList.data,
      leftEnum: this.props.leftList === undefined ? [] : this.props.leftList.enumerations,
      leftButtons: this.props.leftList === undefined ? {} : this.dealButtons(this.props.leftList.buttons, 'left'),
      leftSort: this.props.leftList === undefined ? [] : this.props.leftList.sort,
      type: this.props.type,
      batchTableInfo: this.props.batchTableInfo,
      templateList: [],
      wRateValue: this.props.wRateValue,
      vRateValue: this.props.vRateValue,
      leftShow: this.props.leftShow && this.props.type !== 'show', // 此处修改是为了兼容挑单夹
      rightShow: this.props.rightShow,
      insuranceShow: rightList.ext_data ? rightList.ext_data.is_perm_for_insur : 0, // 整车投保单选框是否显示
      isChecked: false,
      skinSet: window.psn_setting.skin_set,
      leftPageDetailShow: false,
    };
  }

  UNSAFE_componentWillMount = () => {
    this.emitter = emitter.getSpance('batch');
  };
  // 运单号的sug是否能显示的下 否则改样式
  componentDidMount = () => {
    this.vBtns && this.vBtns.handleIsShowMoreBtn();
    // window.emt && window.emt.on('psnSettingUpdated', this.onPsnSettingUpdate)
    this.handleChangeAmount(); // 触发合计计算
  };
  componentWillUnmount = () => {
    // window.emt && window.emt.off('psnSettingUpdated', this.onPsnSettingUpdate)
  };
  componentDidUpdate = () => {
    this.handleChangeAmount(); // 触发合计计算
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.leftList === undefined || nextProps.rightList === undefined) return false;
    let changeFlag = false;
    // 左侧数据是否发生变化
    if (nextProps.leftList && nextProps.leftList.data !== this.state.leftList) {
      changeFlag = true;
      this.setState({
        leftList: nextProps.leftList.data,
        leftSort: nextProps.leftList.sort,
        leftEnum: nextProps.leftList.enumerations,
        leftButtons: this.dealButtons(nextProps.leftList.buttons, 'left'),
      });
    }
    // 右屏数据是否发生变化, 右屏数据不进行数据格式化
    if (nextProps.rightList && nextProps.rightList.data !== this.state.rightList) {
      changeFlag = true;
      const rightList = this.getInitRightList(nextProps);
      this.setState({
        rightList: rightList.data,
        rightSort: rightList.sort,
        rightEnum: rightList.enumerations,
        rightButtons: this.dealButtons(rightList.buttons, 'right'),
      });
    }
    // 至少有一侧数据更新时， 更新左屏显示状态
    if (nextProps.leftShow !== this.state.leftShow && changeFlag) {
      this.setState({ leftShow: nextProps.type !== 'show' });
    }
    // type的变化 影响左屏是否显示
    if (nextProps.type !== this.state.type) {
      this.setState({ type: nextProps.type });
    }
    // 挑单全局信息是否发生变化
    if (nextProps.batchTableInfo !== this.state.batchTableInfo) {
      this.setState({ batchTableInfo: nextProps.batchTableInfo });
    }
  };
  onPsnSettingUpdate = () => {
    this.setState({ skinSet: window.psn_setting.skin_set });
  };
  getInitRightList = props => {
    if (!props.leftList || !props.rightList) return {};
    const rightList = props.rightList || {};
    if (typeof this.props.handleLeftToRightCallback === 'function') {
      // defaultDataFormate 用来判断第一次就在右屏的数据是否进行格式化
      if (
        this.props.defaultDataFormate &&
        this.props.leftDataFormate &&
        typeof this.props.leftDataFormate === 'function'
      ) {
        // 左屏数据格式化
        rightList.data = this.props.leftDataFormate(rightList.data || [], false, true);
        rightList.data = rightList.data.filter(x => x !== false);
        // 此处第一次带到右侧的数据，如果有默认值，需要同时更新total信息
        rightList.total = this.sumTotal(
          rightList.data,
          Object.keys(rightList.header).filter(x => rightList.header[x].summable),
        );
        console.log(rightList.total);
      }
      // 比如，从列表也带入的运单计算配载率、计算价格等
      setTimeout(() => {
        this.props.handleLeftToRightCallback(rightList.data, props.leftList.data, rightList.data);
      }, 50);
    }
    Object.keys(rightList.header).forEach(x => {
      if (pickMock.reduceKey.indexOf(x) !== -1 && this.props.type !== 'show') {
        rightList.header[x] && (rightList.header[x].filterSelectAll = true);
      } else {
        rightList.header[x] && (rightList.header[x].filterable = 'front_only');
      }
    });
    return rightList;
  };
  // 非批次详情的情况， 列表设置的icon显示在表格底部, 批次详情显示为button右侧icon
  dealButtons = (buttons, type) => {
    if (!buttons) return {};
    const newButtons = buttons;
    if (buttons.config) {
      this[`${type}Config`] = { ...buttons.config };
      this[`${type}Config`].children = '设置';
      newButtons.config = this[`${type}Config`];
      // 非批次详情的情况， 不显示列表设置icon
      if (this.props.type !== 'show') {
        delete newButtons.config;
      }
    }
    return newButtons;
  };
  // 设置左右宽度
  getAdjustWidth = () => {
    const tab = this.getTab();
    let adjustWidth = localStorage[`${tab}_width`] ? JSON.parse(localStorage[`${tab}_width`]) : undefined;
    adjustWidth = adjustWidth || this.props.adjustWidth;
    adjustWidth = { ...adjustWidth, dragWidth: 6 };
    // eslint-disable-next-line no-nested-ternary
    const value = !this.state.leftShow
      ? { rightWidth: '100%', leftWidth: '0%', dragWidth: 6 }
      : !this.state.rightShow
      ? { rightWidth: '0%', leftWidth: '100%', dragWidth: 6 }
      : adjustWidth;
    return value;
  };

  // 重新设置配载率
  setRateVal = data => {
    this.setState({
      wRateValue: data.wRateValue || 0,
      vRateValue: data.vRateValue || 0,
    });
  };
  // 获取右侧button
  getRightButtonList = () => this.state.rightButtons || [];
  getLeftButtonList = () => this.state.leftButtons || [];
  // 更新右侧button
  changeRightButtonList = buttonList => {
    this.setState({ rightButtons: buttonList });
  };
  changeLeftButtonList = buttonList => {
    this.setState({ leftButtons: buttonList });
  };
  // 重新计算表格底部的合计信息
  calcTotal = (from, target, item) => {
    const header = this.summableHeader;
    const fromItem = from;
    const targetItem = target;
    const headerInfo = (this.props.rightList || this.props.leftList || {}).header || {};
    header.forEach(key => {
      // 此处精度有问题， 应该取header中的精度
      const colHeader = headerInfo[key] || {};
      let value = item[key] || formatDeppProptotype(key, item);
      if (colHeader.type === 'CheckBoxText' && typeIs(value, 'object')) {
        value = value.text || value;
      }
      targetItem[key] = parseFloat(
        parseFloat((parseFloat(targetItem[key]) || 0) + (parseFloat(value) || 0)).toFixed(2),
      );
      fromItem[key] = parseFloat(parseFloat((parseFloat(fromItem[key]) || 0) - (parseFloat(value) || 0)).toFixed(2));
    });
    return { fromItem, targetItem };
  };
  // 计算多条数据的 合计信息
  sumTotal = (itemList, header) => {
    const res = {};
    const headerInfo = (this.props.rightList || this.props.leftList || {}).header || {};
    header.forEach(key => {
      const colHeader = headerInfo[key] || {};
      res[key] = getSumVal(itemList, key, colHeader.type === 'CheckBoxText');
    });
    return res;
  };
  // 表格数据更新  direction : 1: 从左向右， 0 从右向左
  getChangedTable = (fTable, tTable, newChangeList, direction, changeList) => {
    let fromTable = fTable;
    let toTable = tTable;
    const newCList = [];
    const cList = [];
    newChangeList.forEach((item, index) => {
      if (item) {
        newCList.push(item);
        cList.push(changeList[index]);
      }
    });
    if (
      window.company_setting.pick_order_sort &&
      window.company_setting.pick_order_sort.value &&
      window.company_setting.pick_order_sort.value === 'desc'
    ) {
      toTable = [...toTable, ...newCList];
    } else {
      toTable = [...newCList, ...toTable];
    }
    fromTable = fromTable.filter(x => cList.indexOf(x) === -1);
    // 此处不再进行排序， 更新表格数据后，调用表格数据排序
    // const sortRule = direction ? this.state.rightSort : this.state.leftSort
    // const table = direction ? this.rightTable : this.leftTable
    // if (typeIs(sortRule, 'object') && Object.keys(sortRule).length !== 0) {
    //   toTable = this.handleSortTable(sortRule, toTable, table)
    // }
    return { fromTable, toTable };
  };
  // sortFrontendGetValue = (index, col) => this.state.listStore.getObjectAt(index)[col]
  // 支持排序
  // handleSortTable = (sortRule, data = [], table) => {
  //   const sortFrontendGetValue = (index, col) => data[index] ? data[index][col] : undefined
  //   const sortIndexes = range(data.length),
  //     newData = []
  //   sortIndexes.sort((indexA, indexB) => table.sortFrontendComFunc(sortRule, indexA, indexB, sortFrontendGetValue))
  //   sortIndexes.forEach((item) => {
  //     newData.push(data[item])
  //   })
  //   return newData
  // }
  // 重置table 的合计信息
  resetTotalData = (type, total, resetTotal = false) => {
    resetTotal && this[`${type}Table`] && this[`${type}Table`].resetTotalData(total, true);
    this[`${type}TableTotal`] = total;
  };
  resetPageData = (type, pageSize, pageNum) => {
    // eslint-disable-next-line no-unused-expressions
    this[`${type}TableTotal`];
  };
  resetTableData = (type, data, enumerations, resetTotal = true) =>
    this[`${type}Table`] && this[`${type}Table`].resetData(data, enumerations, resetTotal);
  // 现在只有修改的时候在用
  setTab = tab => {
    tab && this.setState({ tab });
    const batchTableInfo = this.state.batchTableInfo || {};
    const fetchApi = batchTableInfo.fetchApi || {};
    fetchApi.para.tab && (fetchApi.para.tab = `${tab}_left`);
  };
  // 获取当前页面的tab值
  getTab = () => {
    if (this.state.tab) return this.state.tab;
    const batchTableInfo = this.props.batchTableInfo || {};
    const tableFilter = batchTableInfo.tableFilter || {};
    return tableFilter.tab || '';
  };
  // 获取当前页面的type值
  getType = () => {
    const batchTableInfo = this.props.batchTableInfo || {};
    const tableFilter = batchTableInfo.tableFilter || {};
    return tableFilter.type || '';
  };
  // 获取当前页面的category值
  getCategory = () => {
    const batchTableInfo = this.props.batchTableInfo || {};
    const tableFilter = batchTableInfo.tableFilter || {};
    return tableFilter.category || '';
  };
  // 获取表格数据
  getTableData = (type, ori = false) => {
    const _table = this[`${type}Table`];
    // eslint-disable-next-line no-nested-ternary
    return _table ? (ori ? _table.getOriData() : _table.getStateCache(true)) : [];
  };
  getTableShowData = type => {
    const _table = this[`${type}Table`];
    return _table.getStateCache();
  };
  changeIndexToOrgIndex = (type, select) => {
    const _table = this[`${type}Table`];
    return _table.state.listStore.getOriSelected(select);
  };
  // 获取表格合计数据
  getTotalData = type => (this[`${type}Table`] ? this[`${type}Table`].getTotalData() : {});
  // get Filter
  getSearchFilter = type => (this[`${type}Table`] ? this[`${type}Table`].getSearchFilter() : {});
  // set Filter
  setSearchFilter = (type, filter) => (this[`${type}Table`] ? this[`${type}Table`].setSearchFilter(filter) : {});
  //  更新左右表格的total信息
  reSetTotalState = (lTotal, rTotal, leftList, rightList) => {
    const leftTotal = lTotal || {};
    const rightTotal = rTotal || {};
    leftTotal.count = leftList.length || 0;
    rightTotal.count = rightList.length || 0;
    this.resetTotalData('left', leftTotal, true);
    this.resetTotalData('right', rightTotal);
  };
  // 进行挑单权限判定，是否在考核费待审核/审核中
  getAuthority = (
    changeList,
    continueCallbackFunc,
    okCallbackFunc,
    needSuccessIdEmpty = false,
    typeFont = '创建对账单',
  ) => {
    const { company_id, com_id } = changeList[0];
    const b_basic_id = [];
    const real_company_id = company_id || com_id;
    const uuid = [];
    changeList.forEach(item => {
      uuid.push(item.uuid);
      b_basic_id.push(item.b_basic_id || item.od_basic_id);
    });
    let type = this.getType();
    if (type === 'receipt_bill_detail') {
      type = 'receipt';
    }
    const tab = this.getTab();
    const param = {
      company_id: real_company_id,
      type,
      tab,
      uuid,
      b_basic_id,
    };
    const paramReq = {
      method: 'POST',
      body: { req: param },
    };

    callApi('Finance/ReviewBill/fnPickCheck', paramReq).then(checkRes => {
      if (checkRes.errno !== 0) {
        createTip(checkRes.errmsg || '获取数据失败', 'warn').show();
      }
      // 不显示继续按钮
      if (needSuccessIdEmpty && checkRes.res) {
        checkRes.res.success_id = '';
      }
      if (!_.isEqual(_.get(checkRes, 'res.failed_detail', []), [])) {
        opTips({
          opType: typeFont,
          orderType: '数据',
          resData: checkRes,
          continueCallback: (successIds, cb) => {
            continueCallbackFunc && continueCallbackFunc(successIds);
            cb();
          },
          sucCallback: () => {
            console.log('sucCallback');
          }, // 成功的回调
          failedCallback: () => {
            console.log('failedCallback');
          },
          ext: {
            cancelBtnName: '确定',
            customErrRule: '',
          },
        });
      } else {
        okCallbackFunc && okCallbackFunc();
      }
    });
  };
  judgeType = type => {
    // 满足以下菜单来的对账单才进行权限判定
    const typeArr = [
      'customer_batch_client', // 客户运单对账
      'customer_batch_project', // 客户项目对账
      'customer_batch_team', // 承运商对账
      'customer_batch_driver', // 司机对账
      'customer_batch_car', // 车辆对账
      'settle_batch_task', // 运单结算
      'receipt', // 客户订单对账收款按钮打开的挑单页
      'receipt_bill_detail', // 收款单 订单对账单 编辑按钮打开
    ];
    if (type && typeArr.indexOf(type) > -1) {
      return true;
    }
    return false;
  };
  // 左到右挑单，双击挑单，保存挑单时需要进行数据的权限判定
  doAuthCheck = (list, continueCallbackFunc, okCallbackFunc, needSuccessIdEmpty = false) => {
    const typeTab = this.getType();
    const okType = this.judgeType(typeTab);
    const hasAuth = this.getAuthority;
    let typeFont = typeTab === 'settle_batch_task' ? '结算' : '创建对账单';
    if (typeTab === 'receipt' || typeTab === 'receipt_bill_detail') {
      typeFont = '收款';
    }
    if (hasAuth && typeof hasAuth === 'function' && okType) {
      hasAuth(
        list,
        // 继续的执行函数
        continueCallbackFunc,
        // 没有错误的执行函数
        okCallbackFunc,
        needSuccessIdEmpty,
        typeFont,
      );
    } else {
      okCallbackFunc();
    }
  };
  doNewList = (idList, dataList) => {
    const newChangeList = [];
    idList.forEach(id => {
      const data = dataList.filter(item => item.b_basic_id === id || item.od_basic_id === id);
      if (data) {
        newChangeList.push(data[0]);
      }
    });
    console.log(newChangeList);
    return newChangeList;
  };
  // 左右移动表格数据时的处理函数, direction : 1: 从左向右， 0 从右向左
  changeTable = (changeList, direction) => {
    if (direction === 1) {
      // 错误弹窗点击继续的执行函数
      const continueCallbackFunc = callbackList => {
        const newChangeList = this.doNewList(callbackList, changeList);
        this.realChangeTable(newChangeList, direction);
      };
      // 没有错误的执行函数
      const okCallbackFunc = () => {
        this.realChangeTable(changeList, direction);
      };
      this.doAuthCheck(changeList, continueCallbackFunc, okCallbackFunc);
    } else {
      this.realChangeTable(changeList, direction);
    }
  };
  realChangeTable = (changeList, direction) => {
    let newChangeList = changeList;
    let rightList = this.getTableData('right');
    let leftList = this.getTableData('left');
    if (typeof this.props.prohibitTableMove === 'function') {
      const prohibitMove = this.props.prohibitTableMove();
      if (prohibitMove) return;
    }
    if (rightList.length > MAX_PICK_ORDER || (direction && newChangeList.length + rightList.length > MAX_PICK_ORDER)) {
      createTip(`最大挑单数量不能超过${MAX_PICK_ORDER}条！`, ERROR).show();
      return false;
    }
    if (direction) {
      // 挑单前的左屏数据处理
      if (this.props.leftDataFormate && typeof this.props.leftDataFormate === 'function') {
        newChangeList = this.props.leftDataFormate(newChangeList);
      }
      const resTable = this.getChangedTable(leftList, rightList, newChangeList, direction, changeList);
      leftList = resTable.fromTable;
      rightList = resTable.toTable;
    } else {
      // 挑单前的右屏数据处理
      if (this.props.rightDataFormate && typeof this.props.rightDataFormate === 'function') {
        newChangeList = this.props.rightDataFormate(newChangeList);
      }
      const resTable = this.getChangedTable(rightList, leftList, newChangeList, direction, changeList);
      rightList = resTable.fromTable;
      leftList = resTable.toTable;
    }
    this.resetTableData('left', leftList);
    this.resetTableData('right', rightList);
    let callback = this.props.handleRightToLeftCallback;
    if (direction) callback = this.props.handleLeftToRightCallback;
    if (callback && typeof callback === 'function') {
      callback(newChangeList, leftList, rightList);
    }
  };
  // 双击左右移动
  handleRowDbClick = (type, idIndexList) => {
    // 只展示的情况下， 右屏幕不触发双击操作
    if (this.state.type === 'show' && type === 'right') return;
    const newRightList = this.rightTable ? this.rightTable.getStateCache() : [];
    const newLeftList = this.leftTable ? this.leftTable.getStateCache() : [];
    const selectItem = type === 'left' ? newLeftList[idIndexList] : newRightList[idIndexList];
    const direction = type === 'left' ? 1 : 0;
    this.changeTable([selectItem], direction);
  };
  // 数据左右移动的时候做防抖处理
  //  批量数据从右边到左边(type = right) || 从左边移动到右边 （type = left）
  handleBatchChange = throttle(type => {
    const table = this[`${type}Table`];
    const direction = type === 'left' ? 1 : 0;
    const changeList = table.getFilterStateCache();
    this.changeTable(changeList, direction);
  }, 300);
  // 选中数据从左边移动到右边(type = 'left')|| 从右边移动到左边(type = 'right')
  handleSingleChange = throttle(type => {
    const direction = type === 'left' ? 1 : 0;
    const opsiteType = type === 'left' ? 'right' : 'left';
    const table = this[`${type}Table`];
    const opsiteTable = this[`${opsiteType}Table`];
    const selectRows = table.getStateSelecteRows(); // eslint-disable-line
    if (!selectRows.length) return;
    this.changeTable(selectRows, direction);
    opsiteTable.resetStateSelectes();
  }, 300);
  // adjust 拖动宽度后 更新左右表格的宽度
  changeWidthCallback = (para, isDrag) => {
    if (isDrag) {
      const tab = this.getTab();
      localStorage[`${tab}_width`] = JSON.stringify(para);
    }
    setTimeout(() => {
      this.leftTable && this.leftTable.handleResizeTable();
      this.rightTable && this.rightTable.handleResizeTable();
    }, 30);
  };
  // 恢复双屏显示
  resume = () => this.setState({ leftShow: true, rightShow: true });
  // 关闭左侧显示
  handleCloseLeft = () => this.setState({ leftShow: false });
  // 关闭右侧显示
  handleCloseRight = () => this.setState({ rightShow: false });
  // 表格中信息发生变化时的回调
  handleTableChange = (rowIndex, col, value, oldData, key, ...rest) => {
    const resData = {
      select: this.rightTable.getStateSelectes(),
      list: this.rightTable.getStateCache(true),
    };
    this.handleChangeAmount();
    if (typeof this.props.handleTableChange === 'function') {
      this.props.handleTableChange(rowIndex, col, value, resData, key, ...rest);
    }
  };
  // 表格中信息发生变化时的回调
  handleUpdate = async prop => {
    const { rowIndex, col, value, rowData, that, eventType, key } = prop;
    const newIndex = 0;
    // 现在用的日期类型的没有blur事件
    if (eventType === 'onChange' && !(col === 'trans_t' || col === 'point_trans_t' || col === 'inner_trans_t'))
      return false;
    if (eventType === 'onCcombinerEvent' && col !== key) return;
    if (eventType === 'onCheckBoxChange') {
      rowData[`${col}_manual_change`] = true; // 记录手动更改
      rowData[`${col}_paid`] = +value; // 记录勾选状态
      const oldValue = typeIs(rowData[col], 'object') ? rowData[col] : { text: rowData[col] };
      rowData[col] = {
        ...oldValue,
        checked: +value,
      };
      // 任务指派右屏含税
      if (col === 'b_trans_tax_inc') {
        rowData[col] = +value;
      } else {
        return that.updateCacheRowWrap(rowIndex, rowData);
      }
    }
    if (typeof this.props.handleTableChange === 'function') {
      const newRowData = await this.props.handleTableChange(newIndex, col, value, [rowData], key);
      if (newRowData) {
        that.updateCacheRowWrap(rowIndex, newRowData);
        that._updateTotalRow();
      }
    }
  };
  // 表格的列操作
  handleLinkClick = (type, rowIndex, column, data, obj) => {
    const category = this.getCategory();
    const tab = this.getTab();
    const newKey = `${category}_${column}`;
    const isDown = DOWN_TAB.indexOf(this.getTab().toLocaleLowerCase()) !== -1;
    const tableData = this.getTableShowData(type);

    const { header } = this.props[`${type}List`];
    // 标识下页面信息
    const listPage = proxyListPage(tableData, {
      category,
      tab,
      type: 'pickOrder',
      refresh: this.props.refreshData,
      filterQuery: this[`${type}Table`]._filter,
      header,
    });
    listPage.batchInfo = { ...this.props.batchInfo };
    handleGetModule(newKey, 'batch', this.emitter, () => {
      this.emitter.emit(formatCamelCase(newKey), listPage, rowIndex, column, data, obj, isDown, resData => {
        ['trans_w_detail', 'trans_v_detail', 'point_trans_w_detail', 'point_trans_v_detail'].indexOf(column) !== -1 &&
          this.handleUpdate({
            rowIndex,
            col: column,
            value: resData[rowIndex][column],
            rowData: resData[rowIndex],
            that: this[`${type}Table`],
            eventType: 'onBlur',
          });
      });
    });
  };
  // 表格头部header排序
  handleHeaderSortable = (col, sort, fetch = true) => {
    let rule = {};
    if (JSON.stringify(sort) === '{}') {
      const propSort = Object.assign({}, this.props.leftList.sort);
      delete propSort[col];
      rule = propSort;
    } else {
      rule = sort;
    }
    this.setState({ leftSort: rule });
    if (typeof this.props.handleHeaderSortable === 'function' && fetch) {
      const checkRes = this.checkFilter('left', this.leftTable._filter || {});
      !checkRes && this.props.handleHeaderSortable(col, rule, this.leftTable._filter || {});
    }
  };
  findCompanyIdObj = (data, key) => {
    const arr = data?.filter?.(item => item.id === key) ?? '';
    return arr;
  };
  // 调账，编辑费用
  handleChangeAmount = () => {
    const list = this.rightTable?.getStateCache?.(true) ?? [];
    const { header } = this.props.rightList ?? {};
    this.props.handleChangeAmount?.({ list, header });
    return { list, header };
  };
  // 外部触发按钮点击（当自定义按钮显示位置，可以复用按钮点击逻辑）
  onBtnClick = (...rest) => {
    const isCheck = this.state.isChecked;
    this.handleBtnClick(isCheck, ...rest);
  };
  // 右侧批量删除
  handleRightBatchSubtract = async () => {
    const table = this.rightTable;
    const list = table?.getStateCache?.(true) ?? [];
    const selectRows = table?.getStateSelecteRows?.() ?? []; // eslint-disable-line
    if (!selectRows.length) {
      showInfo(ERROR, '请先选择需移除的数据');
      return;
    }
    if (list.length === selectRows.length) {
      showInfo(ERROR, '不可移除全部数据');
      return;
    }

    showInfo(INFO, '您确定需要将选择的在途费用取消报销吗？', true, {
      confirm: () => {
        this.changeTable(selectRows, 0);
      },
    });
  };
  // button点击的回调
  handleBtnClick = (isCheck, type, selected, key, subList = [], ...res) => {
    let newKey = key;
    if (key === 'payment_batch_onway_del') {
      type === 'right' && this.handleRightBatchSubtract();
      return;
    }
    if (subList && subList.length && !selected) return false;
    if (subList && subList.length) newKey = selected.key;
    if (typeof this.props.handleBtnClick === 'function') {
      // 校验表格中的数据
      const list = this[`${type}Table`].getStateCache(true);
      const { header } = this.props[`${type}List`];
      // 前端判断员工全部交账使用,另外一侧的列表数据
      let opListCount;
      if (type === 'left') {
        opListCount = this.rightTable ? this.rightTable.getStateCache(true).length || 0 : 0;
      } else {
        opListCount = this.leftTable ? this.leftTable.getStateCache(true).length || 0 : 0;
      }
      const select = this[`${type}Table`].getStateSelectes(true);
      const resData = { list, select, header, opListCount, isCheck };
      // 子button信息
      if (subList.length && selected) resData.selectSubKey = selected;
      // 通知收发货人短信信息
      if (this[`${type}TableForm`]) {
        resData.form = this[`${type}TableForm`].getStateDataForm();
      }
      // 增加了左侧列表数据，在点左侧刷新时用来判断是否清除右侧运单的依据
      const { company_id_perm, company_id } = this.state.rightEnum;
      const rightList = this.rightTable.getStateCache(true);
      const companyIdObj = {};
      rightList.forEach(item => {
        const id = item.company_id || item.com_id;
        companyIdObj[id] = {
          id,
          key: id,
          name: this.findCompanyIdObj(company_id_perm || company_id, id)?.[0]?.short_name,
        };
      });
      const rCompanyId = Object.keys(companyIdObj);
      // 把当前系统网点加上
      const comInfo = window.company_info || {};
      companyIdObj[comInfo.id] = {
        id: comInfo.id,
        key: comInfo.id,
        name: comInfo.company_name,
      };
      const { openPageBtnKey, openPageTab } = this.props;
      const okType = hasOverCompanyAuth(openPageBtnKey, openPageTab);
      if (okType && rCompanyId.length > 1 && key === 'save' && type === 'right') {
        const comSet = window.company_setting || {};
        const cus_ac_mix_point = comSet.cus_ac_mix_point || {};
        if (cus_ac_mix_point.checked) {
          this.selectDot(_.values(companyIdObj), companyId => {
            this.props.handleBtnClick(newKey, resData, selected, rightList, type, companyId, ...res);
          });
        } else {
          showInfo(ERROR, '所选数据跨网点，您没有跨网点对账权限，请先去开通或检查勾选项！');
        }
      } else {
        this.props.handleBtnClick(newKey, resData, selected, rightList, type, '', ...res);
      }
    }
  };
  selectDot = (selectData, callBack) => {
    let selectDotDialog;
    let checkedId = '';
    const onChange = val => {
      checkedId = val.id;
    };
    const dotDialogContent = () => {
      const selected = '';
      return (
        <div className="cross_dot__dialog">
          <p className="title">
            <i className="fn-icon fn-icon-info-o" />
            所选数据跨网点，请选择本次对账所属组织：
          </p>
          <SelectDrop
            uniquekey="key"
            filKey="name"
            data={selectData}
            defaultSelected={selected}
            selectOnly
            required
            placeholder="请选择"
            handleSelected={val => onChange(val)}
          />
          <div className="tip-area">
            <p>选择所属组织有什么用：</p>
            <p>1-本次对账使用本次选择的组织对应的系统设置；</p>
            <p>2-对账单所属组织为本次选择的组织，该组织及该组织以上的机构有查看操作权限。</p>
          </div>
        </div>
      );
    };
    const cancelDialogHide = () => {
      selectDotDialog.handleHide();
    };
    // 确认键响应函数
    const submitDialogSave = () => {
      if (checkedId) {
        callBack(checkedId);
        selectDotDialog.handleHide();
      } else {
        showInfo(ERROR, '请选择本次对账所属组织');
      }
    };
    const getSlideDragerFooter = () => (
      <div className="slide__drager__footer__content">
        <Button onClick={submitDialogSave} type="primary">
          确定
        </Button>
        <Button onClick={cancelDialogHide}>取消</Button>
      </div>
    );
    new PopUp(ModalDialog, {
      ref: dialog => (selectDotDialog = dialog),
      title: '操作提示',
      content: dotDialogContent(),
      bottom: getSlideDragerFooter(),
      isModal: true,
      popName: 'refuse',
      isShow: true,
      style: { width: '500px' },
    }).show();
  };
  changeLeftPageNum = () => {
    const newPage = this.leftChangePageObj.value || this.props.defaultPage;
    if (typeof this.props.changeLeftPageNumCallback === 'function') {
      this.props.changeLeftPageNumCallback(newPage);
    }
  };
  // 整车投保单选框
  handleChecked = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };
  mergeSugEnum = (sugEnum, type) => {
    const { enumerations } = mergeEnum(
      this.state[`${type}Enum`] || {},
      sugEnum || {},
      this.props[`${type}List`].enumapi || {},
      {},
      this.props[`${type}List`].header,
    );
    return enumerations;
  };
  formateEnum = (enumDict, header) => {
    const newEnumDict = enumDict;
    const dict2array = (dt, headerCol) =>
      Object.keys(dt).reduce((newArr, key) => {
        newArr.push({
          [headerCol.uniqueKey || 'key']: key,
          [headerCol.showKey || 'display']: dt[key].display,
          ...dt[key],
        });
        return newArr;
      }, []);
    Object.keys(enumDict).forEach(key => {
      const headerKey = Object.keys(header).filter(innerKey => header[innerKey].refEnum === key) || [];
      const headerCol = header[headerKey[0]] || {};
      if (enumDict[key]) {
        newEnumDict[key] = dict2array(enumDict[key], headerCol);
      }
    });
    return newEnumDict;
  };
  // 挑单选中后的调用
  handleOrderSug = (SelectDateObj, otherParam) => {
    let tips = otherParam.filterTips;
    if (typeof this.props.prohibitTableMove === 'function') {
      const prohibitMove = this.props.prohibitTableMove();
      if (prohibitMove) return;
    }
    // 挑单的枚举值
    if (SelectDateObj === '') return;
    let selectList = [SelectDateObj];
    if (typeIs(selectList, 'array') && selectList[0] === '') return false;
    if (this.props.leftDataFormate && typeof this.props.leftDataFormate === 'function') {
      const res = this.props.leftDataFormate(selectList);
      if (selectList[0] && !res[0]) return false;
      selectList = res;
    }
    // 空值的blur事件
    const item = selectList[0] || {};
    // 查询结果为空的情况
    if (item === undefined || Object.keys(item).length === 0) {
      tips = tips.replace('请输入', '请输入存在的');
      createTip(tips, ERROR).show();
      return;
    }
    let newRightList = this.getTableData('right');
    let newLeftList = this.getTableData('left');
    // 删除左侧table中的该条数据
    const { batchTableInfo } = this.props;
    // 员工交账数据格式不同，所以另外加了一个sugUniqueKey
    let uniqueKey = batchTableInfo.sugUniqueKey || batchTableInfo.uniqueKey || 'od_link_id';
    if (this.props.isPack) {
      uniqueKey = 'b_link_id';
    }
    let tmpList = [];
    let flag = false; // 判断右侧表格中是否存在当前条数据
    let addItem = false;
    if (
      window.company_setting.pick_order_sort &&
      window.company_setting.pick_order_sort.value &&
      window.company_setting.pick_order_sort.value === 'asc'
    ) {
      tmpList.push(item);
      addItem = true;
    }
    // 员工交账的key 为[od_link_id, expense]
    if (Array.isArray(uniqueKey)) {
      if (!uniqueKey.length) return;
      const checkKeyList = uniqueKey.reduce(
        (dist, uniqueItem) => ({ ...dist, [uniqueItem]: item[uniqueItem] || -1 }),
        {},
      );
      // key值有 -1 的 数据出错， 不再处理
      if (Object.values(checkKeyList).some(checkKeyListItem => checkKeyListItem === -1)) return;
      newRightList.forEach(data => {
        const mainKeyDiff = data[uniqueKey[0]].toString() !== checkKeyList[uniqueKey[0]].toString();
        const otherKeyDiff = uniqueKey
          .slice(1)
          .some(uniqueItem => data[uniqueItem].toString() !== checkKeyList[uniqueItem].toString());
        if (mainKeyDiff || (!mainKeyDiff && otherKeyDiff)) tmpList.push(data);
        else flag = true;
      });
      if (
        !addItem ||
        (window.company_setting.pick_order_sort &&
          window.company_setting.pick_order_sort.value &&
          window.company_setting.pick_order_sort.value === 'desc')
      ) {
        tmpList.push(item);
      }
      newRightList = tmpList;
      tmpList = []; // 清空， 再处理左侧表格
      newLeftList.forEach(data => {
        const mainKeyDiff = data[uniqueKey[0]].toString() !== checkKeyList[uniqueKey[0]].toString();
        const otherKeyDiff = uniqueKey
          .slice(1)
          .some(uniqueItem => data[uniqueItem].toString() !== checkKeyList[uniqueItem].toString());
        if (mainKeyDiff || (!mainKeyDiff && otherKeyDiff)) tmpList.push(data);
      });
      newLeftList = tmpList;
    } else {
      const id = item[uniqueKey] || -1;
      if (id === -1) return false;
      // 新挑单的数据放到顶部
      newRightList.forEach(data => {
        if (+data[uniqueKey] !== +id) tmpList.push(data);
        else flag = true;
      });
      if (
        !addItem ||
        (window.company_setting.pick_order_sort &&
          window.company_setting.pick_order_sort.value &&
          window.company_setting.pick_order_sort.value === 'desc')
      ) {
        tmpList.push(item);
      }
      newRightList = tmpList;
      if (id !== -1) {
        tmpList = [];
        newLeftList.forEach(data => {
          if (data[uniqueKey].toString() !== id.toString()) tmpList.push(data);
        });
        newLeftList = tmpList;
      }
    }
    const enumDict = this.formateEnum(otherParam.enumDict || {}, this.props.rightList.header);
    const rightEnum = this.mergeSugEnum(enumDict, 'right');
    const leftEnum = this.mergeSugEnum(enumDict, 'left');
    console.log(newLeftList, leftEnum, 'newLeftList, leftEnum');
    this.resetTableData('left', newLeftList, leftEnum);
    this.resetTableData('right', newRightList, rightEnum);
    // 首行添加选中背景
    this.rightTable && this.rightTable.handleRowClassNameGetter(0);
    // if (flag) {
    //   createTip('挑单信息已经置顶', INFO).show()
    // }
    // 只有右侧不存在该条数据的时候才需要调用 删除左侧表格中的该条数据
    if (!flag && typeof this.props.handleLeftToRightCallback === 'function') {
      this.props.handleLeftToRightCallback([item], this.getTableData('left'), newRightList);
    }
  };
  // 判断是设置表头还是排序,还是切换模板
  tableOP = (obj, type) => {
    if (obj === undefined) return;
    this.props.tableSet && this.props.tableSet(obj, type);
  };
  checkFilter = (type, filter) =>
    Object.keys(filter).some(filterKey => {
      if (filter[filterKey] === '') return false;
      const validRes = filterValidate(filterKey, filter[filterKey], this.props[`${type}List`].header);
      if (validRes) {
        // const headerTitle = this.props[`${type}List`].header[filterKey].title
        createTip(validRes, ERROR).show();
        return true;
      }
      return false;
    });
  // 表格行筛选回调
  handleSearchFilter = (type, form = {}, isDate, key, value, fetch = true) => {
    let filter = {};
    this[`${type}Table`] && (filter = this[`${type}Table`]._filter || {});
    // 执行减速
    if (type === 'right' && pickMock.reduceKey.indexOf(key) !== -1) {
      // 加延迟为了防止， 回车执行时还未进行完筛选状态更新
      throttle(() => {
        // 此处只取显示的
        const changeList = this.rightTable.getFilterStateCache();
        const direction = 0;
        if (changeList.length === 0 || !value || value === '') return;
        // 左移显示运单
        this.changeTable(changeList, direction);
        throttle(() => {
          this.rightTable.setFilterField(key, '', true);
        }, 100)();
      }, 100)();
      return;
    }
    // 筛选值校验， 比如数字类型、日期类型
    if (fetch && typeof this.props.handleFilterChange === 'function') {
      const checkRes = this.checkFilter(type, filter);
      !checkRes && this.props.handleFilterChange(type, filter);
    }
  };

  handleQuickFilter = (col, rowIndex, key, filter) => {
    const type = 'left';
    const newFilter = { ...(this[`${type}Table`] && this[`${type}Table`]._filter), ...filter };
    if (typeof this.props.handleFilterChange === 'function') {
      const checkRes = this.checkFilter(type, newFilter);
      !checkRes && this.props.handleFilterChange(type, newFilter);
    }
  };

  // 保存表格样式
  handleSaveTableStyle = (type, body) => {
    const batchTableInfo = this.props.batchTableInfo || {};
    console.log('typetypetypetypetype', type, body, batchTableInfo);
    const tableFilter = batchTableInfo.tableFilter || {};
    const table = this[`${type}Table`];
    const tab = this.getTab();
    if (typeIs(body, 'string')) {
      table.handleSaveTableStyle();
      return;
    }
    const tabVal = tableFilter.is_detail === 1 ? tab : `${tab}_${type}`;
    actions.listpageServer.setTable(this.getCategory(), tabVal, body, this[`${type}Table`]);
  };
  // 操作button行
  renderButtonList = (buttons = {}, type) => {
    if ((!buttons || !Object.keys(buttons).length) && type === 'right') return null;
    const tmpBtn = { ...buttons };
    const buttonKey = Object.keys(buttons);
    const lastBtnKey = buttonKey[buttonKey.length - 1];
    let colorRule;
    // 批次详情的情况下，最后一个button 样式强调
    if (this.state.type !== 'show' && type === 'right') {
      tmpBtn[lastBtnKey].btnType = 'primary';
    }
    if (this.state.type === 'show' && type === 'right') {
      // 批次详情的情况下， 颜色设置显示在右侧button的第一个，其他的情况显示在左移和右移的icon后面
      colorRule = this.props[`${type}List`].colorSetting;
    }
    const isCheck = this.state.isChecked;
    let tab = this.getTab();
    if (!Object.keys(exportUrlConf).includes(tab)) {
      tab = `${tab}_${type}`;
    }

    // 自定义区域 自定义无数据tip
    const buttonAreaInfo = this.props.buttonAreaInfo
      ? this.props.buttonAreaInfo
      : getCustomize({
          category: this.getCategory(),
          tab: this.getTab(),
          type: 'buttonAreaInfo',
        });
    const customProps = {
      that: this,
      // total,
      state: this.state,
      tableInfo: this.tableInfo,
    };

    // 过滤掉自定义展示的按钮
    buttonKey?.forEach?.(key => {
      if (tmpBtn[key]?.postion === 'custom') delete tmpBtn[key];
    });

    return (
      <div className={`${prefixCls}__btn`}>
        <OrderButtons
          data={{ ...tmpBtn }}
          ref={r => (this.vBtns = r)}
          handleClick={(...args) => this.handleBtnClick(isCheck, type, ...args)}
          handleMoreBtnClick={() => {
            this[`${type}Table`] && this[`${type}Table`].handleResizeTable();
          }}
          defaultRightList={['list_config', 'export', 'tr_loading_print', 'print_preview', 'printer']}
          colorRule={colorRule}
          tab={tab}
          flat={false}
          category={this.getCategory()}
          buttonAreaInfo={typeIs(buttonAreaInfo, 'function') ? buttonAreaInfo(customProps) : buttonAreaInfo}
        />
      </div>
    );
  };
  handleSaveToTpl = (type, data) => {
    const tplId = data.key;
    const _category = this.getCategory();
    const _tab = `${this.getTab()}_${type}`;
    const saveStyle = this[`${type}Table`].getTableStyle();
    const _saveStyle = JSON.stringify(saveStyle);
    const query = {
      tpl_id: tplId,
      field_info: _saveStyle,
      category: _category,
      tab: _tab,
    };
    const req = callApi('Basic/TplList/tplInfoOp', {
      method: 'POST',
      body: { req: query },
    });
    req.then(res => {
      if (res.errno === 0) {
        createTip('保存到模板成功！', INFO).show();
        return;
      }
      createTip(res.errmsg || '保存到模板失败', ERROR).show();
    });
  };
  isUpdate = (item, mapKey) => {
    let isUpdate = 0;
    Object.keys(mapKey).forEach(key => {
      isUpdate =
        isUpdate || (item[mapKey[key]] !== undefined && +item[mapKey[key]] !== 0 && +item[mapKey[key]] !== +item[key]);
    });
    return isUpdate ? 1 : 0;
  };
  resetRightPayValue = (data, pageType) => {
    const rowData = data;
    const isUpdate = this.isUpdate(rowData, pickMock.checkIsSplitMap[pageType]);
    const keyMap = pickMock.splitDisableKeyMap[pageType];
    const { header } = this.props.rightList || {};
    rowData.otherProps = rowData.otherProps || {};
    rowData.otherProps.disable = rowData.otherProps.disable || [];
    rowData.otherProps.tooltips = rowData.otherProps.tooltips || {};
    if (isUpdate) {
      // 拆单状态
      rowData.otherProps.disable = [...rowData.otherProps.disable, keyMap.key];
      rowData.otherProps.tooltips[keyMap.key] = keyMap.tips;
      rowData[keyMap.key] = { ...rowData[keyMap.key], checked: false };
    } else {
      // 非拆单状态, 且 已付列不显示， 不勾选
      let checked = true;
      header[keyMap.key].display !== 'show' && (checked = false);
      rowData.otherProps.disable = rowData.otherProps.disable.filter(x => x !== keyMap.key);
      rowData[keyMap.key] = { ...rowData[keyMap.key], checked };
      delete rowData.otherProps.tooltips[keyMap.key];
    }
    return rowData;
  };
  renderSplitOrder = async (orderInfo, splitInfo, setting, rowIndex, extPara) => {
    const i18nDict = await i18n(POPUP_CONFIRM_WINDOW);
    const batchDict = i18nDict.get('batch', '批次');
    const orderDict = i18nDict.get('order', '运单');
    const _this = this;
    // rightList = this.rightTable.getFilterStateCache() || []
    const rightList = this.rightTable.getStateCache(true) || [];
    const tab = this.getTab(0);
    const category = this.getCategory();
    const showSplitTab = pickMock.showSplitTab[category];
    const orderNumKey = (showSplitTab[tab] || {}).orderNumKey || 'order_num';
    const odKey = (showSplitTab[tab] || {}).od_key || 'od_link_id';
    new PopUp(splitOrder, {
      ...extPara,
      tab,
      category,
      orderNum: orderInfo[orderNumKey], // 运单号
      orderTips: (showSplitTab[tab] || {}).orderTips || orderDict,
      orderId: orderInfo[odKey], // 运单id
      batchId: this.props.batchInfo ? this.props.batchInfo.b_basic_id : orderInfo[extPara.batchKey], // 当前的批次号，只在修改的时候使用
      currSplitInfo: orderInfo.od_link_split_info,
      disableActualPrice: (showSplitTab[tab] || {}).disableActualPrice || false,
      splitInfo,
      setting,
      orderInfo, // 选中的运单信息
      i18nDict,
      // 确认拆单的弹框
      confirmSplitCallBack: ({ currSplitInfo, aveType, splitType }) => {
        rightList[rowIndex] = {
          ...orderInfo,
          ...{
            od_link_split_info: { ...currSplitInfo, sub_sp_type: aveType, sp_type: splitType },
            splitInfo,
            splitSetting: setting,
          },
        };
        // const showSplitTab = pickMock.showSplitTab[this.getCategory()] || {}
        const { resetKeyDict } = showSplitTab[this.getTab()] || {};
        Object.keys(resetKeyDict).forEach(resetKey => {
          let showValue = currSplitInfo[resetKeyDict[resetKey]];
          if (
            window.company_setting.weight_unit &&
            window.company_setting.weight_unit.value === 'T' &&
            resetKeyDict[resetKey] === 'weight' &&
            category !== 'Reservation'
          ) {
            showValue *= 1000;
          }
          showValue !== undefined && (rightList[rowIndex][resetKey] = showValue);
        });
        if (pickMock.resetPayList.indexOf(tab) !== -1) {
          // 更新右侧表格中的checkbox状态
          rightList[rowIndex] = this.resetRightPayValue(rightList[rowIndex], tab);
        }
        _this.resetTableData('right', rightList, false);
        throttle(() => {
          this.handleTableChange(rowIndex, 'weight', rightList[rowIndex].weight);
        }, 50)();
      },
    }).show();
  };
  /**
   
   * @desc:    {[调用拆单弹框]}
   * @return   {[type]}        [description]
   */
  handleSplitOrder = async (...args) => {
    const i18nDict = await i18n(POPUP_CONFIRM_WINDOW);
    const batchDict = i18nDict.get('batch', '批次');
    const orderDict = i18nDict.get('order', '运单');
    const tab = this.getTab();
    const category = this.getCategory();
    const extPara = {};
    const showSplitTab = pickMock.showSplitTab[category] || {};
    const urlPath = (showSplitTab[tab] || {}).path || 'Truck';
    const tipText = (showSplitTab[tab] || {}).tipText || '库存';
    const isFromSign = (showSplitTab[tab] || {}).isFromSign || false;
    const batchKey = (showSplitTab[tab] || {}).batchKey || 'b_tr_basic_id,';
    const od_key = (showSplitTab[tab] || {}).od_key || 'od_link_id';
    const url = `${urlPath}/getSplitInfo`;
    const rowIndex = this.rightTable.showIndexToOri([...args][1]);
    extPara.tipText = tipText;
    extPara.isFromSign = isFromSign;
    extPara.batchKey = batchKey;
    if (rowIndex === -1) {
      createTip('操作行错误', ERROR).show();
      return;
    }
    // const rightList = this.rightTable.getFilterStateCache() || [],
    const rightList = this.rightTable.getStateCache(true) || [];
    const orderInfo = rightList[rowIndex];
    if (!orderInfo) {
      createTip('操作行错误', ERROR).show();
      return;
    }
    // 已经执行过的， 不再请求后端拆单信息
    if (!orderInfo.od_link_split_info) {
      const param = {
        method: 'POST',
        body: { req: { [od_key]: orderInfo[od_key], company_id: orderInfo.com_id } },
      };
      callApi(url, param)
        .then(res => {
          if (res.errno === -131) {
            const detail = getErrorDetail(
              res.errmsg ||
                `当前${orderDict}已经全部拆单完成并且进行了账户扣款，无法修改拆单！请先进行相应的逆向操作取消账户扣款后再修改拆单信息。`,
            );
            alert(ERROR, '当前运单无法拆单！', undefined, detail);
            return;
          }
          if (res.errno !== 0) {
            createTip(res.errmsg || '获取拆单信息失败', ERROR).show();
            return;
          }
          let splitInfo = res.res || {};
          // 此处actual_price_total是运单全部的运费 actuan_price是剩余的运费
          splitInfo.od_info.goods.g_actual_price = [(splitInfo.od_info || {}).actual_price_total];
          splitInfo.od_info.stock_goods.g_actual_price = [(splitInfo.od_info || {}).actual_price];
          // 此处对splitInfo中的goods、stock_goods、curr_info中的重量值根据单位进行处理
          splitInfo = formateSplitInfo(splitInfo, category);
          if ((splitInfo.od_info || {}).curr_info) {
            ((splitInfo.od_info || {}).curr_info || []).forEach(item => (item.g_actual_price = [item.actual_price])); // eslint-disable-line
          }
          this.renderSplitOrder(
            orderInfo,
            splitInfo.od_info,
            { ...splitInfo.setting, has_settle: splitInfo.has_settle },
            rowIndex,
            extPara,
          );
        })
        .catch(err => console.log(err));
      return;
    }
    const { splitInfo } = orderInfo;
    const { splitSetting } = orderInfo;
    this.renderSplitOrder(orderInfo, splitInfo, splitSetting, rowIndex, extPara);
  };
  onRateChange = key => e => {
    const list = this.rightTable ? this.rightTable.getStateCache(true) : [];
    if (typeof this.props.onRateChange === 'function') {
      this.props.onRateChange(list, key, e);
    }
  };
  headerRightMenu = type => {
    const rightMenu = [];
    let tplList =
      this[`${type}Config`] && this[`${type}Config`].sublist
        ? this[`${type}Config`].sublist.filter(x => x.key === 'switch_tpl')[0]
        : {};
    if (!tplList) return rightMenu;
    tplList = tplList.children;
    if (window.menuPermission && window.menuPermission.toString().indexOf('tpl_list_header') !== -1) {
      rightMenu.push({
        key: 'handleSaveToTpl',
        name: { icon: 'icon-save', title: '存入列表模板' },
        subList: tplList,
        handler: (...args) => this.handleSaveToTpl(type, ...args),
      });
    }
    return rightMenu;
  };
  rowRightMenu = type => {
    const menu = [];
    const tab = this.getTab();
    const category = this.getCategory();
    const showSplitTab = pickMock.showSplitTab[category] || {};
    // 只在开通的页面的，右侧表格以及可编辑的情况下出现拆单,打包维度不可出现拆单
    if (
      type === 'right' &&
      this.state.type !== 'show' &&
      Object.keys(showSplitTab).indexOf(tab) !== -1 &&
      window.permission.indexOf(showSplitTab[tab].permission) !== -1 &&
      !this.props.isPack
    ) {
      // 拆单中的预装车暂时拿掉拆单右键
      const { batchTableInfo } = this.state;
      if (tab === 'truck_pick' && batchTableInfo.isPreLoad) {
        return menu;
      }
      menu.push({
        key: 'handleSplitOrder',
        name: { icon: 'icon-copy', title: '拆单' },
        handler: this.handleSplitOrder,
      });
    }
    return menu;
  };
  // 调用扫码模块
  renderOrderSug = () => {
    if (window.curr_sys === 3) {
      return null;
    }
    const batchTableInfo = this.state.batchTableInfo || {};
    const fetchApi = batchTableInfo.fetchApi || {};
    const { filterTips } = batchTableInfo;
    const uniqueKey = batchTableInfo.uniqueKey || 'od_link_id';
    const iconType = fetchApi.para && fetchApi.para.category === 'Batch' ? '' : 'icon-qrcode';
    const checkScan = batchTableInfo.checkScan === undefined ? true : batchTableInfo.checkScan; // 是否开启扫码校验
    return (
      <ScanSug
        uniqueKey={uniqueKey}
        filterTips={filterTips}
        fetchApi={fetchApi}
        isNotScanSug={!checkScan}
        isScan={false}
        inputIconType={iconType}
        orderSugInfo={(SelectDateObj, otherParam) => this.handleOrderSug(SelectDateObj, otherParam)}
      />
    );
  };
  // 可以合并到otherform中
  renderRate = (rateValue, type, title) => {
    let classname = `${prefixCls}__rate`;
    if (rateValue > 100) classname = `${prefixCls}__rate_red`;
    return (
      <Tips title={title}>
        <span className={classname}>
          <Icon iconType={type} />
          {rateValue}%
        </span>
      </Tips>
    );
  };
  // 快速挑单icon List
  renderIcon = (type, addition) => {
    let iconList = pickMock[`${type}IconList`];
    const isShow = type === 'left' ? this.state.rightShow : this.state.leftShow;
    if (!isShow) iconList = pickMock[`${type}HideIconList`];
    return (
      <div className="in-bk">
        {type === 'left' && addition}
        {Object.entries(iconList).map(([key, item]) => (
          <Icon key={key} iconType={item.iconType} onClick={() => this[item.clickFunc](type)} tips={item.tips} />
        ))}
        {type === 'right' && addition}
      </div>
    );
  };
  /**
   
   * @desc:    {[颜色设置icon]}
   * @return   {[type]}        [description]
   */
  renderColorRule = type => {
    const { colorSetting } = this.props[`${type}List`];
    return !isEmptyObj(colorSetting) ? <ColorTips colorRule={colorSetting} /> : undefined;
  };
  renderIconListRight = (type, inputShow) => {
    if (type === 'left') return;
    let rate;
    const { wRateValue } = this.state;
    const { vRateValue } = this.state;
    if ((wRateValue !== undefined || vRateValue !== undefined) && pickMock.showWVRate.includes(this.getTab())) {
      rate = (
        <div className="wvRate">
          {this.renderRate(wRateValue, 'icon-chengzhong', '重量装载率')}
          {this.renderRate(vRateValue, 'icon-area', '体积装载率')}
        </div>
      );
    }
    const orderNum = (
      <div className="orderNum">
        <div>打包单：{this.props.packNum}</div>
        <div>运单： {this.props.orderNum}</div>
      </div>
    );
    // 线上发放列表，点在线发放安钮，左右挑单要出现下面提示
    const { cd_to_private, cd_to_company } = this.props.batchInfo;
    //
    // const cd_to_private_el = +cd_to_private > 0 ? `发放到私户手续费${cd_to_private}元/笔` : '发放到私户免手续费'
    // const cd_to_company_el = +cd_to_company > 0 ? `发放到公户手续费${cd_to_company}元/笔` : '发放到公户免手续费'
    // const fee_description_tips = (+cd_to_private === 0 && +cd_to_company === 0) ? '<span>免手续费</span>' : `<span>${cd_to_private_el}，${cd_to_company_el}，将从钱包余额中额外扣除</span>`
    const fee_description_tips =
      +cd_to_private === 0 && +cd_to_company === 0
        ? '<span>免手续费</span>'
        : '<span>钱包付款账户发放到对私账户免费，钱包收款账户发放到对私账户与钱包收款<br >账户提现手续费率及扣款账户一致。暂不支持对公账户发放。</span>';
    const feeDescription = (
      <Tips title={fee_description_tips} isDom>
        <span className="fee-description">
          <Icon iconType={type} />
          ￥手续费说明
        </span>
      </Tips>
    );
    const reconciliationRate = (
      <div className="right-table_rate">
        <span className="text-warning">本次对账任务总比例:</span>
        <PureInput
          onChange={this.onRateChange('rate')}
          value={this.props.reconciliationRate}
          pattern={FLOAT_P}
          suffix="%"
        />
      </div>
    );
    const showNum = this.state.leftButtons && !!this.state.leftButtons.pack_order;

    return (
      <div className="in-bk">
        {this.state.type !== 'show' && this.renderIcon(type, this.renderColorRule('right'))}
        {inputShow && this.state.type !== 'show' && this.renderOrderSug()}
        {showNum && orderNum}
        {this.state.type !== 'show' && !this.props.isPack && rate}
        {this.state.type !== 'show' && this.props.batchInfo.buttonKey === 'chPayOnline' && feeDescription}
        {this.props.showRate && this.state.type !== 'show' && reconciliationRate}
      </div>
    );
  };
  renderOtherForm = (type, otherForm) => {
    const handleEvents = {};
    if (this.props.handleTableFormClick) {
      handleEvents.onClick = this.props.handleTableFormClick;
      handleEvents.onChange = this.props.handleTableFormClick;
    }
    const pageType = this.props.batchTableInfo.tableFilter.tab;
    let msgTypeS = '';
    // templateList短信模板数据，方便短信校验取短信模板id
    if (
      type === 'right' &&
      (pageType === 'sign_pick' || pageType === 'pickup_sign_pick' || pageType === 'delivery_sign_pick')
    ) {
      // 签收-发货人 9 BUS_SIGN_SEND
      // msgTypeS = (<MsgTips msgTypeS="BUS_SIGN_SEND" position="top-right" onChange={val => this.setState({ templateList: val })} />)
    } else if (type === 'right' && pageType === 'trans_pick') {
      // 中转 查看短信模版 7，8  发货人&收货人 BUS_TRANSFER_SEND,BUS_TRANSFER_RECEIVE
      msgTypeS = (
        <MsgTips
          msgTypeS="BUS_TRANSFER_SEND,BUS_TRANSFER_RECEIVE"
          onChange={val => this.setState({ templateList: val })}
        />
      );
    } else if (type === 'right' && (pageType === 'co_delivery_paid' || pageType === 'co_delivery_online')) {
      // 货款发放和导出打款单 发货人 BUS_COD_SEND
      msgTypeS = <MsgTips msgTypeS="BUS_COD_SEND" onChange={val => this.setState({ templateList: val })} />;
    }
    let form = otherForm; // 左右挑单页的打印运单图标
    if (otherForm.printOrder) {
      form.printOrder.otherProps.iconType = 'icon-print-out';
    }
    if (otherForm.sendmsg) {
      form.sendmsg.otherProps.iconType = 'icon-message';
    }
    if (this.props.isPack) {
      // 打包单不展示收货人和打印运单按钮
      let newForm = {};
      for (const item in newForm) {
        if (item !== 'consignor' || item !== 'printOrder') {
          newForm = form[item];
        }
      }
      form = newForm;
    }
    return (
      <div className={`${prefixCls}__table_other_form`}>
        {msgTypeS}
        <CardForm
          isHeader={false}
          classname="in-bk"
          handleEvents={handleEvents}
          data={form}
          ref={r => (this[`${type}TableForm`] = r)}
        />
      </div>
    );
  };
  carInsuredRadio = () => {
    if (this.getTab() === 'truck_pick') {
      return (
        <CheckBox
          classname="car-insured"
          direction="right"
          label="整车投保"
          checked={this.state.isChecked}
          display={this.state.insuranceShow === 1}
          onClick={this.handleChecked}
        />
      );
    }
  };
  handlePaging = (type, pageSize, status, pageNum) => {
    const newPageSize = pageSize;
    const total = this[`${type}TableTotal`] || {};
    let newPageNum = pageNum || +total.pageNum || 1;
    switch (status) {
      case -1:
        newPageNum -= 1;
        break;
      case 1:
        newPageNum += 1;
        break;
      case 'jump':
        newPageNum = pageNum;
        break;
      default:
        newPageNum = 1;
    }
    newPageNum < 1 && (newPageNum = 1);
    if (newPageSize * newPageNum > MAX_TABLE_ACC_ROW) {
      showMaxPageLimitDialog();
    } else {
      if (!this[`${type}TableTotal`]) {
        this[`${type}TableTotal`] = {};
      }
      this[`${type}TableTotal`].pageSize = pageSize;
      this[`${type}TableTotal`].pageNum = newPageNum;
      this.props?.handlePaging?.(type, newPageSize, newPageNum);
    }
  };

  renderTablePage = type => {
    // 左侧分页器
    if (type === 'left') return this.renderPage('left');
    if (type !== 'right') return;
    // 右侧分页器
    return this.renderPage('right');
  };
  renderPage = type => {
    const total = this[`${type}TableTotal`] || {};
    const listTotal = this.props?.[`${type}List`]?.total ?? {};
    const pageSize = total.pageSize || this.props?.[`${type}List`]?.page_size || PICKORDER_DEFAULT_PAGE;
    total.pageTotal = parseInt(total.count || listTotal.count || 0, 10);
    total.pageSize = parseInt(pageSize || 0, 10);
    const { pageInfo } = this.props.batchTableInfo;
    if (!pageInfo) return false;
    const pages = Object.values(pageInfo).map(val => ({ key: val, name: val }));

    return (
      <Page
        ref={r => {
          // 兼容老逻辑，只有left page
          if (type === 'left') {
            this.page = r;
          }
          this[`${type}Page`] = r;
        }}
        prevPage={(...para) => this.handlePaging(type, ...para)}
        nextPage={(...para) => this.handlePaging(type, ...para)}
        pageSize={total.pageSize}
        pageTotal={+total.pageTotal}
        pageNum={total.pageNum || 1}
        pages={pages}
        toggle
        className={`${type}-page`}
      />
    );
  };
  renderActionList = (data, postion, otherForm, inputShow) => {
    if (data === undefined) return;
    const frClass = classnames({
      fr: true,
      detailFr: this.state.type === 'show',
    });
    const tableInfo = this.props.batchTableInfo ?? {};
    const controlBtnListHide = _.get(tableInfo, 'controlBtnListHide', false);
    return (
      <div className={`${prefixCls}__table_button table_button_${postion} clearfix`}>
        {!controlBtnListHide && (
          <div className="fl right-query">{postion === 'right' && this.renderIconListRight(postion, inputShow)}</div>
        )}
        <div className={`${frClass} right-button`}>
          {Object.keys(otherForm).length > 0 && this.renderOtherForm(postion, otherForm)}
          {this.renderButtonList(data, postion)}
          {postion === 'left' && this.renderIcon(postion, this.renderColorRule(postion))}
        </div>
        {this.carInsuredRadio()}
      </div>
    );
  };
  // 挑单左侧的页码信息
  renderPageNum = () => {
    const { leftPageDetailShow } = this.state;
    if (this.props.leftList === undefined) return false;
    const { pageInfo } = this.props.batchTableInfo;
    const defaultPage = (this.props.leftList || {}).page_num || PICKORDER_DEFAULT_PAGE;
    if (pageInfo === undefined || defaultPage === undefined) return false;
    return (
      <div style={{ display: 'inline-block' }}>
        <select
          className={`${prefixCls}__table_page`}
          defaultValue={defaultPage}
          onChange={this.changeLeftPageNum}
          ref={leftChangePageObj => (this.leftChangePageObj = leftChangePageObj)}
        >
          {Object.entries(pageInfo).map(([key, num]) => (
            <option key={key} value={num}>
              {num}
            </option>
          ))}
        </select>
        <Icon iconType={leftPageDetailShow ? 'icon-zhankai' : 'icon-shouqi'} />
      </div>
    );
  };
  // 页码和列表设置
  renderDivFooter = type => {
    const showRightPage = this.state.type === 'show' && this.props.isOverload && type === 'right'; // 详情-右侧，超过1000条展示分页
    // 20230216，原逻辑是：查看详情时，不展示【页码和列表设置】，无论左右列表均不展示。
    // 现在修改为：当 isOverload 查看详情时，需要展示【右侧页码】，但是不展示【列表设置】
    if (this.state.type === 'show' && !showRightPage) return;

    return (
      <div className={`${prefixCls}__table_bottom_${type}`}>
        {type === 'left' || showRightPage ? this.renderTablePage(type) : null}
        {showRightPage ? null : (
          <OrderButtons
            flat={false}
            data={{ config: this[`${type}Config`] || pickMock.subBtnList }}
            ref={r => (this[`${type}_config`] = r)}
            handleClick={obj => this.tableOP(obj, type)}
            isShowPage={false}
            tab={`${this.getTab()}_${type}`}
            category={this.getCategory()}
          />
        )}
      </div>
    );
  };
  renderTable = (data, type) => {
    if (this.props[`${type}List`] === undefined) return false;
    const { colorSetting } = this.props[`${type}List`];
    const { header } = this.props[`${type}List`];
    const buttons = this.state[`${type}Buttons`];
    const orderList = this.state[`${type}List`];
    const key = `${type}TableWrap`;
    const tableInfo = this.props.batchTableInfo[type] || {};
    const tableForm = tableInfo.otherForm || {};
    const inputShow = _.get(tableInfo, 'inputShow', true);
    this.props[`${type}List`]._table.isRowDbClick = this.props[`${type}List`]?._table?.isRowDbClick ?? true;
    // 可编辑的右屏幕不进行行的窗口优化
    const onlyViewportColumn = !(this.state.type !== 'show' && type === 'right');
    type === 'left' && (this.props[`${type}List`]._table.handleHeaderSortable = this.handleHeaderSortable);
    if (header === undefined || orderList === undefined) return false;
    // 左屏的 网点筛选 封上，不支持筛选
    // type === 'left' && header.com_id && (header.com_id.filterable = 'false')
    // 右变表格启用行的右键操作
    this.props.rightList._table = { ...this.props.rightList._table, isShowRowContextMenu: true };
    const tab = this.getTab();
    const category = this.getCategory();
    let tableChangeFunc = {
      handleSelectDropSelect: this.handleTableChange,
      handleBlur: this.handleTableChange,
      handleChange: this.handleTableChange,
    };
    const updaterTabs = [
      'trans_pick',
      'trans_inner_pick',
      'trans_point_pick',
      'dispatch',
      'update_dispatch',
      'delivery_dispatch',
      'pickup_dispatch',
      'transport_task_dispatch_driver',
      'update_transport_task_dispatch_driver',
      'dispatch_carrier',
      'pickup_batch_dispatch',
      'shuttle_up_dispatch',
      'tr_up_dispatch',
      'delivery_batch_dispatch',
      'pickup_dispatch_carrier',
      'update_pickup_dispatch_carrier',
      'delivery_dispatch_carrier',
      'update_delivery_dispatch_carrier',
    ];
    if (updaterTabs.includes(tab)) {
      tableChangeFunc = { dataUpdater: this.handleUpdate };
    }
    this.tableConfig = getCustomize({ category, tab, type: 'tableConfig' });
    // 此用户的装车清单在特殊的月份下需要显示为前端排序状态
    let firstSort = false;
    if (
      FIRST_SORT_ID.includes(window.group_id) &&
      this.props.getTruckTime &&
      typeof this.props.getTruckTime === 'function'
    ) {
      const truckTime = this.props.getTruckTime();
      firstSort = FIRST_SORT_TIME(truckTime);
    }
    // const { skinSet } = this.state
    return (
      <div className={`${prefixCls}__table_${type} clearfix`} style={{ width: '100%' }} ref={r => (this[key] = r)}>
        {this.renderActionList(buttons, type, tableForm, inputShow)}
        <div className={`${prefixCls}__table_wraper`}>
          <div className={`${prefixCls}__table_wraper2`}>
            <Table
              isAutoSize
              entryKeyBatchSearch
              enableQuickFilter={type === 'left'}
              firstSort={firstSort}
              onlyViewportColumn={onlyViewportColumn}
              classname={`${prefixCls}__table`}
              data={orderList}
              header={header}
              enableOperate={!(type === 'left')}
              markFrontOnly={type === 'left'}
              sortIner={type === 'right'}
              preLoadStore={type !== 'right'}
              ignoreDefault={type === 'right'}
              style={{ flex: 1, height: 'initial' }}
              handleSearchFilter={(...res) => this.handleSearchFilter(type, ...res)}
              handleQuickFilter={this.handleQuickFilter}
              total={this.props[`${type}List`].total}
              sort={this.state[`${type}Sort`]}
              enumerations={this.state[`${type}Enum`]}
              headerRightMenu={this.headerRightMenu(type)}
              rowRightMenu={this.rowRightMenu(type)}
              handleTodoAdd={type === 'right' ? this.props.handleRightTodoAdd : undefined}
              handleTodoSubtract={type === 'right' ? this.props.handleRightTodoSubtract : undefined}
              handleLinkClick={(...res) => this.handleLinkClick(type, ...res)}
              handleOperateClick={type === 'right' ? this.props.handleRightOperateClick : undefined}
              handleRowDbClick={(...res) => this.handleRowDbClick(type, ...res)}
              handleSaveTableStyle={(...res) => this.handleSaveTableStyle(type, ...res)}
              {...tableChangeFunc}
              colorRule={colorSetting}
              refGetter={table => (this[`${type}Table`] = table)}
              cellClassGetter={prop => cellClassGetter(prop, { category, tab })}
              {...this.props[`${type}List`]._table}
              {...this.tableConfig}
              initUpdateTotal={type === 'left'}
            />
          </div>
        </div>
        {this.renderDivFooter(type)}
      </div>
    );
  };

  render() {
    const { classname, batchTableInfo } = this.props;
    const dragger = (
      <div className={`${prefixCls}__dragger table-slider`}>
        <div className="dragger_content" />
      </div>
    );
    return (
      <div className={classnames(classname, `${prefixCls}__div`)}>
        <AdjustableDiv
          left={this.props.type !== 'show' && this.renderTable(batchTableInfo.left, 'left')}
          right={this.renderTable(batchTableInfo.right, 'right')}
          drager={dragger}
          additionStyle={this.getAdjustWidth()}
          changeCallback={(para, isDrag) => this.changeWidthCallback(para, isDrag)}
          ref={adjust => (this.adjust = adjust)}
        />
      </div>
    );
  }
}
