import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import KeyCode from 'rc-util/lib/KeyCode';
import classnames from 'classnames';
import Animate from 'rc-animate';
import { emptyFunction as noop } from 'utils';
import {
  getPropValue,
  getValuePropValue /* isCombobox, */,
  isMultipleOrTags,
  isMultipleOrTagsOrCombobox,
  isSingleMode,
  toArray,
  UNSELECTABLE_ATTRIBUTE,
  UNSELECTABLE_STYLE,
  preventDefaultEvent,
  getTreeNodesStates,
  flatToHierarchy,
  filterParentPosition,
  isInclude,
  labelCompatible,
  loopAllChildren,
  filterAllCheckedData,
  processSimpleTreeData,
} from './../util';
import SelectTrigger from './SelectTrigger';
import TreeNode from '../TreeNode';

function filterFn(input, child) {
  return String(getPropValue(child, labelCompatible(this.props.treeNodeFilterProp))).indexOf(input) > -1;
}

const SHOW_ALL = 'SHOW_ALL';
const SHOW_PARENT = 'SHOW_PARENT';
const SHOW_CHILD = 'SHOW_CHILD';
export default class Select extends Component {
  constructor(props) {
    super(props);
    let value = [];
    if ('value' in props) {
      value = toArray(props.value);
    } else {
      value = toArray(props.defaultValue);
    }
    // save parsed treeData, for performance (treeData may be very big)
    this.renderedTreeData = this.renderTreeData();
    value = this.addLabelToValue(props, value);
    value = this.getValue(props, value, props.inputValue ? '__strict' : true);
    const inputValue = props.inputValue || '';
    this.state = {
      value,
      inputValue,
      open: props.open || props.defaultOpen,
      focused: false,
    };
  }

  static SHOW_ALL = SHOW_ALL;
  static SHOW_PARENT = SHOW_PARENT;
  static SHOW_CHILD = SHOW_CHILD;
  static propTypes = {
    defaultExpandedKeys: PropTypes.array,
    children: PropTypes.any,
    className: PropTypes.string,
    prefixCls: PropTypes.string,
    multiple: PropTypes.bool,
    filterTreeNode: PropTypes.any,
    showSearch: PropTypes.bool,
    disabled: PropTypes.bool,
    showArrow: PropTypes.bool,
    allowClear: PropTypes.bool,
    // tags: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    open: PropTypes.bool, // 下拉是否展开
    transitionName: PropTypes.string, // 下拉动画名
    animation: PropTypes.string,
    choiceTransitionName: PropTypes.string, // 多选样式名
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    onSearch: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    placeholder: PropTypes.any,
    inputValue: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
    label: PropTypes.any,
    defaultLabel: PropTypes.any,
    labelInValue: PropTypes.bool,
    dropdownStyle: PropTypes.object,
    drodownPopupAlign: PropTypes.object,
    onDropdownVisibleChange: PropTypes.func,
    maxTagTextLength: PropTypes.number,
    showCheckedStrategy: PropTypes.oneOf([SHOW_ALL, SHOW_PARENT, SHOW_CHILD]),
    // skipHandleInitValue: PropTypes.bool, // Deprecated (use treeCheckStrictly)
    treeCheckStrictly: PropTypes.bool,
    treeIcon: PropTypes.bool,
    treeLine: PropTypes.bool,
    treeDefaultExpandAll: PropTypes.bool,
    treeCheckable: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    treeNodeLabelProp: PropTypes.string, // 下拉选中项在输入框中lable的值对应的字段
    treeNodeFilterProp: PropTypes.string,
    uniqueKey: PropTypes.string.isRequired,
    showKey: PropTypes.string.isRequired,
    childrenKey: PropTypes.string,
    treeData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    treeDataSimpleMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    loadData: PropTypes.func,
    fieldName: PropTypes.string, // 字段名
    threeState: PropTypes.bool, //  三态勾选
    dropdownMatchSelectWidth: PropTypes.bool,
    fireOnExpand: PropTypes.bool,
  };
  static defaultProps = {
    prefixCls: 'rc-tree-select',
    uniqueKey: 'value',
    showKey: 'label',
    childrenKey: 'children',
    filterTreeNode: filterFn,
    showSearch: true,
    allowClear: false,
    placeholder: '',
    searchPlaceholder: '',
    labelInValue: false,
    defaultValue: [],
    inputValue: '',
    onClick: noop,
    onChange: noop,
    onSelect: noop,
    onDeselect: noop,
    onSearch: noop,
    showArrow: true,
    dropdownMatchSelectWidth: true,
    dropdownStyle: {},
    onDropdownVisibleChange: () => true,
    notFoundContent: '无相关数据', // 41570
    showCheckedStrategy: SHOW_CHILD,
    // skipHandleInitValue: false, // Deprecated (use treeCheckStrictly)
    treeCheckStrictly: false,
    treeIcon: false,
    treeLine: false,
    treeDataSimpleMode: false,
    treeDefaultExpandAll: false,
    treeCheckable: false,
    treeNodeFilterProp: 'value',
    treeNodeLabelProp: 'title', // 下拉选中项在输入框中lable的值对应的字段
    fieldName: '', // 数据字段名
    threeState: false,
  };

  componentDidMount() {
    if (this.state.inputValue) {
      const inputNode = this.getInputDOMNode();
      if (inputNode && inputNode.value) {
        inputNode.style.width = `${inputNode.scrollWidth}px`;
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // save parsed treeData, for performance (treeData may be very big)
    this.renderedTreeData = this.renderTreeData(nextProps);
    if ('value' in nextProps) {
      if (this._cacheTreeNodesStates !== 'no' && this._savedValue && nextProps.value === this._savedValue) {
        // Detecting whether the object of `onChange`'s argument  is old ref.
        // Better to do a deep equal later.
        this._cacheTreeNodesStates = true;
      } else {
        this._cacheTreeNodesStates = false;
      }
      let value = toArray(nextProps.value);
      value = this.addLabelToValue(nextProps, value);
      value = this.getValue(nextProps, value);
      this.setState({
        value,
      });
    }
    if (nextProps.inputValue !== this.props.inputValue) {
      this.setState({
        inputValue: nextProps.inputValue,
      });
    }
    if ('open' in nextProps) {
      this.setState({
        open: nextProps.open,
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      this._savedValue &&
      nextProps.value &&
      nextProps.value !== this._savedValue &&
      nextProps.value === this.props.value
    ) {
      this._cacheTreeNodesStates = false;
      this.getValue(nextProps, this.addLabelToValue(nextProps, toArray(nextProps.value)));
    }
  }

  componentDidUpdate() {
    const { state } = this;
    const { props } = this;
    if (state.open && isMultipleOrTags(props)) {
      const inputNode = this.getInputDOMNode();
      if (inputNode.value) {
        inputNode.style.width = `${inputNode.scrollWidth}px`;
      } else {
        inputNode.style.width = '';
      }
    }
  }

  componentWillUnmount() {
    this.clearDelayTimer();
    if (this.dropdownContainer) {
      ReactDOM.unmountComponentAtNode(this.dropdownContainer);
      document.body.removeChild(this.dropdownContainer);
      this.dropdownContainer = null;
    }
  }

  onInputChange = event => {
    const val = event.target.value;
    const { props } = this;
    this.setState({ inputValue: val, open: true });
    if (props.treeCheckable && !val) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.setState({ value: this.getValue(props, [...this.state.value], false) });
    }
    // if (isCombobox(props)) {
    //   this.fireChange([{
    //     value: val,
    //   }])
    // }
    props.onSearch && props.onSearch(val);
  };
  onDropdownVisibleChange = open => {
    // selection inside combobox cause click
    if (!open && document.activeElement === this.getInputDOMNode()) {
      // return
    }
    // this.setOpenState(open)
    // setTimeout, then have animation. why?
    setTimeout(() => {
      this.setOpenState(open, undefined, !open);
      this.props.onDropdownVisibleChange && this.props.onDropdownVisibleChange(open);
    }, 10);
  };
  // combobox ignore
  onKeyDown = event => {
    const { props } = this;
    if (props.disabled) {
      return;
    }
    const { keyCode } = event;
    if (this.state.open && !this.getInputDOMNode()) {
      this.onInputKeyDown(event);
    } else if (keyCode === KeyCode.ENTER || keyCode === KeyCode.DOWN) {
      this.setOpenState(true);
      event.preventDefault();
    }
  };

  onInputBlur() {
    // if (isMultipleOrTagsOrCombobox(this.props)) {
    //   return
    // }
    // this.clearDelayTimer()
    // this.delayTimer = setTimeout(() => {
    //   this.setOpenState(false)
    // }, 150)
  }

  onInputKeyDown = event => {
    const { props } = this;
    if (props.disabled) return;
    const { state } = this;
    const { keyCode } = event;
    if (isMultipleOrTags(props) && !event.target.value && keyCode === KeyCode.BACKSPACE) {
      const value = state.value.concat();
      if (value.length) {
        const popValue = value.pop();
        props.onDeselect && props.onDeselect(this.isLabelInValue() ? popValue : popValue.key);
        this.fireChange(value);
      }
      return;
    }
    if (keyCode === KeyCode.DOWN) {
      if (!state.open) {
        this.openIfHasChildren();
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    } else if (keyCode === KeyCode.ESC) {
      if (state.open) {
        this.setOpenState(false);
        event.preventDefault();
        event.stopPropagation();
      }
      return;
    }
    if (state.open) {
      // const menu = this.trigger.getPopupEleRefs()
      // if (menu && menu.onKeyDown(event)) {
      //   event.preventDefault()
      //   event.stopPropagation()
      // }
    }
  };
  onSelect = (selectedKeys, info) => {
    if (info.selected === false) {
      this.onDeselect(info);
      return;
    }
    const item = info.node;
    let { value } = this.state;
    const { props } = this;
    const selectedValue = getValuePropValue(item);
    const selectedLabel = this.getLabelFromNode(item);
    let event = selectedValue;
    if (this.isLabelInValue()) {
      event = {
        value: event,
        label: selectedLabel,
      };
    }
    props.onSelect && props.onSelect(event, item, info);
    const checkEvt = info.event === 'check';
    if (isMultipleOrTags(props)) {
      if (checkEvt) {
        value = this.getCheckedNodes(info, props).map(n => ({
          value: getValuePropValue(n),
          label: this.getLabelFromNode(n),
        }));
      } else {
        if (value.some(i => i.value === selectedValue)) {
          return;
        }
        value = value.concat([
          {
            value: selectedValue,
            label: selectedLabel,
          },
        ]);
      }
      // if (!checkEvt && value.indexOf(selectedValue) !== -1) {
      // it has issues on set `multiple`
      // return
      // }
    } else {
      if (value.length && value[0].value === selectedValue) {
        // this.setOpenState(false, true)
        this.setOpenState(false);
        return;
      }
      value = [
        {
          value: selectedValue,
          label: selectedLabel,
        },
      ];
      // this.setOpenState(false, true)
      this.setOpenState(false);
    }
    const extraInfo = {
      triggerValue: selectedValue,
      triggerNode: item,
    };
    if (checkEvt) {
      extraInfo.checked = info.checked;
      // if inputValue existing, tree is checkStrictly
      extraInfo.allCheckedNodes =
        props.treeCheckStrictly || this.state.inputValue
          ? info.checkedNodes
          : flatToHierarchy(info.checkedNodesPositions);
      this._checkedNodes = info.checkedNodesPositions;
      const _tree = this.trigger.popupEle;
      this._treeNodesStates = _tree.checkKeys;
    } else {
      extraInfo.selected = info.selected;
    }
    this.fireChange(value, extraInfo);
    if (props.inputValue === null) {
      this.setState({
        inputValue: '',
      });
    }
    // if (isCombobox(props)) {
    //   this.setState({
    //     inputValue: getPropValue(item, props.treeNodeLabelProp),
    //   })
    // }
  };
  onDeselect = info => {
    this.removeSelected(getValuePropValue(info.node));
    if (!isMultipleOrTags(this.props)) {
      this.setOpenState(false);
    }
    if (this.props.inputValue === null) {
      this.setState({
        inputValue: '',
      });
    }
  };
  onPlaceholderClick = () => {
    this.getInputDOMNode().focus();
  };
  onOuterFocus = () => {
    // It stops open/close animation, and note `onDropdownVisibleChange`'s `setTimeout`
    // this.setState({
    //   focused: true,
    // })
  };
  onOuterBlur = () => {
    // It stops open/close animation, and note `onDropdownVisibleChange`'s `setTimeout`
    // this.setState({
    //   focused: false,
    // })
  };
  onClearSelection = event => {
    const { props } = this;
    const { state } = this;
    if (props.disabled) {
      return;
    }
    event.stopPropagation();
    if (state.inputValue || state.value.length) {
      this.fireChange([]);
      this.setOpenState(false);
      if (props.inputValue === null) {
        this.setState({
          inputValue: '',
        });
      }
    }
  };
  getLabelFromNode = child => getPropValue(child, this.props.treeNodeLabelProp);

  getLabelFromProps(props, value) {
    if (value === undefined) {
      return null;
    }
    let label = null;
    loopAllChildren(this.renderedTreeData || props.children, item => {
      if (getValuePropValue(item) === value) {
        label = this.getLabelFromNode(item);
      }
    });
    if (label === null) {
      return value;
    }
    return label;
  }

  getDropdownContainer = () => {
    if (!this.dropdownContainer) {
      this.dropdownContainer = document.createElement('div');
      document.body.appendChild(this.dropdownContainer);
    }
    return this.dropdownContainer;
  };
  getSearchPlaceholderElement = hidden => {
    const { props } = this;
    let placeholder;
    if (isMultipleOrTagsOrCombobox(props)) {
      placeholder = props.placeholder || props.searchPlaceholder;
    } else {
      placeholder = props.searchPlaceholder;
    }
    if (placeholder) {
      return (
        <span
          style={{ display: hidden ? 'none' : 'block' }}
          onClick={this.onPlaceholderClick}
          className={`${props.prefixCls}-search__field__placeholder`}
        >
          {placeholder}
        </span>
      );
    }
    return null;
  };
  getInputElement = () => {
    const { props } = this;
    return (
      <span className={`${props.prefixCls}-search__field__wrap`}>
        <input
          ref={this.saveInputRef}
          onBlur={this.onInputBlur}
          onChange={this.onInputChange}
          onKeyDown={this.onInputKeyDown}
          value={this.state.inputValue}
          disabled={props.disabled}
          className={`${props.prefixCls}-search__field`}
          role="textbox"
        />
        {isMultipleOrTags(props) ? null : this.getSearchPlaceholderElement(!!this.state.inputValue)}
      </span>
    );
  };
  saveInputRef = r => (this.inputInstance = r);
  getInputDOMNode = () => this.inputInstance;
  getPopupDOMNode = () => this.trigger.getPopupDOMNode();
  getPopupComponentRefs = () => this.trigger.getPopupEleRefs();
  getValue = (_props, val, init = true) => {
    let value = val;
    // if inputValue existing, tree is checkStrictly
    // &&&&&&&&&&&&&&&&&&&&&&&&&&&
    const _strict =
      init === ('__strict' || init) &&
      ((this.state && this.state.inputValue) || this.props.inputValue !== _props.inputValue);
    if (_props.treeCheckable && (_props.treeCheckStrictly || _strict)) {
      this.halfCheckedValues = [];
      value = [];
      val.forEach(i => {
        if (!i.halfChecked) {
          value.push(i);
        } else {
          this.halfCheckedValues.push(i);
        }
      });
    }
    // if (!(_props.treeCheckable && !_props.treeCheckStrictly)) {
    // &&&&&&&&&&&&&&&&&&&&&&&&&&&
    if (!_props.treeCheckable || (_props.treeCheckable && (_props.treeCheckStrictly || _strict))) {
      return value;
    }
    let checkedTreeNodes;
    if (
      this._cachetreeData &&
      this._cacheTreeNodesStates &&
      this._checkedNodes &&
      this.state &&
      !this.state.inputValue
    ) {
      this.checkedTreeNodes = checkedTreeNodes = this._checkedNodes;
    } else {
      /**
       * Note: `this._treeNodesStates`'s treeNodesStates must correspond to nodes of the
       * final tree (`processTreeNode` function from SelectTrigger.jsx produce the final tree).
       *
       * And, `this._treeNodesStates` from `onSelect` is previous value,
       * so it perhaps only have a few nodes, but the newly filtered tree can have many nodes,
       * thus, you cannot use previous _treeNodesStates.
       */
      // getTreeNodesStates is not effective.
      this._treeNodesStates = getTreeNodesStates(
        this.renderedTreeData || _props.children,
        value.map(item => item.value),
        _props.threeState,
      );
      this.checkedTreeNodes = checkedTreeNodes = this._treeNodesStates.checkedNodes;
    }
    const mapLabVal = arr =>
      arr.map(itemObj => ({
        value: getValuePropValue(itemObj.node || itemObj),
        label: getPropValue(itemObj.node || itemObj, _props.treeNodeLabelProp),
      }));
    const { props } = this;
    let checkedValues = [];
    if (props.showCheckedStrategy === SHOW_ALL) {
      checkedValues = mapLabVal(checkedTreeNodes);
    } else if (props.showCheckedStrategy === SHOW_PARENT) {
      const posArr = filterParentPosition(checkedTreeNodes.map(itemObj => itemObj.pos));
      checkedValues = mapLabVal(checkedTreeNodes.filter(itemObj => posArr.indexOf(itemObj.pos) !== -1));
    } else {
      // checkedValues = mapLabVal(checkedTreeNodes.filter(itemObj => {
      //   console.log(itemObj)
      //   return (itemObj.node && !itemObj.node.props.children) || (itemObj.props && !itemObj.props.children)
      // }))
      checkedValues = mapLabVal(
        checkedTreeNodes.filter(
          itemObj => (itemObj.node && !itemObj.node.props.children) || (itemObj.props && !itemObj.props.children),
        ),
      );
    }
    return checkedValues;
  };
  getCheckedNodes = (info, props) => {
    // TODO treeCheckable does not support tags/dynamic
    let { checkedNodes } = info;
    // if inputValue existing, tree is checkStrictly
    if (props.treeCheckStrictly || this.state.inputValue) {
      return checkedNodes;
    }
    const { checkedNodesPositions } = info;
    if (props.showCheckedStrategy === SHOW_ALL) {
      // checkedNodes = checkedNodes
    } else if (props.showCheckedStrategy === SHOW_PARENT) {
      const posArr = filterParentPosition(checkedNodesPositions.map(itemObj => itemObj.pos));
      checkedNodes = checkedNodesPositions
        .filter(itemObj => posArr.indexOf(itemObj.pos) !== -1)
        .map(itemObj => itemObj.node);
    } else {
      checkedNodes = checkedNodes.filter(n => !n.props.children);
    }
    return checkedNodes;
  };
  getDeselectedValue = selectedValue => {
    const { checkedTreeNodes } = this;
    let unCheckPos;
    checkedTreeNodes.forEach(itemObj => {
      if (itemObj.node.props.value === selectedValue) {
        unCheckPos = itemObj.pos;
      }
    });
    const nArr = unCheckPos.split('-');
    const newVals = [];
    const newCkTns = [];
    checkedTreeNodes.forEach(itemObj => {
      const iArr = itemObj.pos.split('-');
      // &&&&&&&&&&&&&&&&&&&&&&&&&&&
      if (
        itemObj.pos === unCheckPos ||
        (nArr.length > iArr.length && isInclude(iArr, nArr)) ||
        (nArr.length < iArr.length && isInclude(nArr, iArr))
      ) {
        // Filter ancestral and children nodes when uncheck a node.
        return;
      }
      newCkTns.push(itemObj);
      newVals.push(itemObj.node.props.value);
    });
    this.checkedTreeNodes = this._checkedNodes = newCkTns;
    const nv = this.state.value.filter(val => newVals.indexOf(val.value) !== -1);
    this.fireChange(nv, { triggerValue: selectedValue, clear: true });
  };
  setOpenState = (open, needFocus, documentClickClose = false) => {
    this.clearDelayTimer();
    const { props } = this;
    if (!this.props.onDropdownVisibleChange(open, { documentClickClose })) {
      return;
    }
    this.setState(
      {
        open,
      },
      () => {
        if (needFocus || open) {
          if (open || isMultipleOrTagsOrCombobox(props)) {
            const input = this.getInputDOMNode();
            if (input && document.activeElement !== input) {
              input.focus();
            }
          } else if (this.selection) {
            this.selection.focus();
          }
        }
      },
    );
  };
  addLabelToValue = (props, value_) => {
    let value = value_;
    if (this.isLabelInValue()) {
      value.forEach((iv, i) => {
        const v = iv;
        if (Object.prototype.toString.call(value[i]) !== '[object Object]') {
          value[i] = {
            value: '',
            label: '',
          };
          return;
        }
        v.label = v.label || this.getLabelFromProps(props, v.value);
      });
    } else {
      value = value.map(v => ({
        value: v,
        label: this.getLabelFromProps(props, v),
      }));
    }
    return value;
  };
  clearDelayTimer = () => {
    if (this.delayTimer) {
      clearTimeout(this.delayTimer);
      this.delayTimer = null;
    }
  };
  removeSelected = selectedVal => {
    const { props } = this;
    if (props.disabled) {
      return;
    }
    this._cacheTreeNodesStates = 'no';
    if (
      props.treeCheckable &&
      (props.showCheckedStrategy === SHOW_ALL || props.showCheckedStrategy === SHOW_PARENT) &&
      !(props.treeCheckStrictly || this.state.inputValue)
    ) {
      this.getDeselectedValue(selectedVal);
      return;
    }
    // click the node's `x`(in select box), likely trigger the TreeNode's `unCheck` event,
    // cautiously, they are completely different, think about it, the tree may not render at first,
    // but the nodes in select box are ready.
    let label;
    const value = this.state.value.filter(singleValue => {
      if (singleValue.value === selectedVal) {
        label = singleValue.label;
      }
      return singleValue.value !== selectedVal;
    });
    const canMultiple = isMultipleOrTags(props);
    if (canMultiple) {
      let event = selectedVal;
      if (this.isLabelInValue()) {
        event = {
          value: selectedVal,
          label,
        };
      }
      props.onDeselect && props.onDeselect(event);
    }
    if (props.treeCheckable) {
      if (this.checkedTreeNodes && this.checkedTreeNodes.length) {
        const getValue = item => (item.node ? item.node.props.value : item.props.value);
        this.checkedTreeNodes = this._checkedNodes = this.checkedTreeNodes.filter(item =>
          value.some(i => i.value === getValue(item)),
        );
      }
    }
    this.fireChange(value, { triggerValue: selectedVal, clear: true });
  };
  openIfHasChildren = () => {
    const { props } = this;
    if (React.Children.count(props.children) || isSingleMode(props)) {
      this.setOpenState(true);
    }
  };
  fireChange = (value, extraInfo) => {
    const { props } = this;
    const vals = value.map(i => i.value);
    const sv = this.state.value.map(i => i.value);
    if (vals.length !== sv.length || !vals.every((val, index) => sv[index] === val)) {
      let ex = { preValue: [...this.state.value] };
      if (extraInfo) {
        ex = Object.assign(ex, extraInfo);
      }
      let labs = null;
      let vls = value;
      if (!this.isLabelInValue()) {
        labs = value.map(i => i.label);
        vls = vls.map(v => v.value);
      } else if (this.halfCheckedValues && this.halfCheckedValues.length) {
        this.halfCheckedValues.forEach(i => !vls.some(v => v.value === i.value) && vls.push(i));
      }
      if (props.treeCheckable && ex.clear) {
        const treeData = this.renderedTreeData || props.children;
        ex.allCheckedNodes = flatToHierarchy(filterAllCheckedData(vals, treeData));
      }
      if (props.treeCheckable && this.state.inputValue) {
        const _vls = [...this.state.value];
        if (ex.checked) {
          value.forEach(i => {
            if (_vls.every(ii => ii.value !== i.value)) {
              _vls.push({ ...i });
            }
          });
        } else {
          let index;
          const includeVal = _vls.some((i, ind) => {
            if (i.value === ex.triggerValue) {
              index = ind;
              return true;
            }
            return false;
          });
          if (includeVal) {
            _vls.splice(index, 1);
          }
        }
        vls = _vls;
        if (!this.isLabelInValue()) {
          labs = _vls.map(v => v.label);
          vls = _vls.map(v => v.value);
        }
      }
      const newValue = this.getValue(
        props,
        vls.map((v, i) => ({ value: v, label: labs[i] })),
      );
      this._savedValue = isMultipleOrTags(props) ? newValue.map(item => item.value) : newValue[0].value;
      const newLabels = newValue.map(item => item.label);
      props.onChange && props.onChange(this._savedValue, newLabels, ex, props.fieldName);
      if (!('value' in props)) {
        this._cacheTreeNodesStates = false;
        this.setState({
          value: newValue,
        });
      }
    }
  };
  isLabelInValue = () => {
    const { treeCheckable, treeCheckStrictly, labelInValue } = this.props;
    if (treeCheckable && treeCheckStrictly) {
      return true;
    }
    return labelInValue || false;
  };
  renderTopControlNode = () => {
    const { value } = this.state;
    const { props } = this;
    const { choiceTransitionName, prefixCls, maxTagTextLength } = props;
    // single and not combobox, input is inside dropdown
    if (isSingleMode(props)) {
      let innerNode = (
        <span key="placeholder" className={`${prefixCls}-selection__placeholder`}>
          {props.placeholder}
        </span>
      );
      if (value.length) {
        innerNode = <span key="value">{value[0].label}</span>;
      }
      return <span className={`${prefixCls}-selection__rendered`}>{innerNode}</span>;
    }
    let selectedValueNodes = [];
    if (isMultipleOrTags(props)) {
      selectedValueNodes = value.map(singleValue => {
        let content = singleValue.label;
        const title = content;
        if (maxTagTextLength && typeof content === 'string' && content.length > maxTagTextLength) {
          content = `${content.slice(0, maxTagTextLength)}...`;
        }
        return (
          <li
            style={UNSELECTABLE_STYLE}
            {...UNSELECTABLE_ATTRIBUTE}
            onMouseDown={preventDefaultEvent}
            className={`${prefixCls}-selection__choice`}
            key={`${singleValue.value}${Math.random()}`}
            title={title}
          >
            <span
              className={`${prefixCls}-selection__choice__remove`}
              onClick={this.removeSelected.bind(this, singleValue.value)}
            />
            <span className={`${prefixCls}-selection__choice__content`}>{content}</span>
          </li>
        );
      });
    }
    selectedValueNodes.push(
      <li
        className={`${prefixCls}-search ${prefixCls}-search--inline ${
          !this.state.open ? 'rc-tree-select-tree-hide' : ''
        }`}
        key="__input"
      >
        {this.getInputElement()}
      </li>,
    );
    const className = `${prefixCls}-selection__rendered`;
    if (isMultipleOrTags(props) && choiceTransitionName) {
      return (
        <Animate className={className} component="ul" transitionName={choiceTransitionName}>
          {selectedValueNodes}
        </Animate>
      );
    }
    return <ul className={className}>{selectedValueNodes}</ul>;
  };
  loopTreeData0 = (data, level = 0) =>
    data.map((item, index) => {
      const pos = `${level}-${index}`;
      const title = item[this.props.showKey];
      const value = item[this.props.uniqueKey];
      const props = {
        // title: item.label,
        // value: item.value,
        // value: item.value || String(item.key || item.label), // cause onChange callback error /* eslint-disable */
        title,
        value,
        key: item.key || value || pos,
        disabled: item.disabled || false,
        // selectable: item.hasOwnProperty('selectable') ? item.selectable : true,
        selectable: item.selectable ? item.selectable : true,
      };
      let ret;
      if (item.children && item.children.length) {
        ret = <TreeNode {...props}>{this.loopTreeData(item.children, pos)}</TreeNode>;
      } else {
        ret = <TreeNode {...props} isLeaf={item.isLeaf} />;
      }
      return ret;
    });
  loopTreeData = (data, level = 0) =>
    Object.entries(data).map(([index, item]) => {
      const pos = `${level}-${index}`;
      const title = item[this.props.showKey];
      const value = item[this.props.uniqueKey];
      const children = item[this.props.childrenKey];
      const props = {
        title,
        value,
        key: item.key || value || pos,
        disabled: item.disabled || false,
        selectable: item.selectable ? item.selectable : true,
      };
      let ret;
      if (children && Object.entries(children).length > 0) {
        ret = <TreeNode {...props}>{this.loopTreeData(children, pos)}</TreeNode>;
      } else {
        ret = <TreeNode {...props} isLeaf={item.isLeaf} />;
      }
      return ret;
    });
  renderTreeData = props => {
    const validProps = props || this.props;
    if (validProps.treeData) {
      if (props && props.treeData === this.props.treeData && this.renderedTreeData) {
        // cache and use pre data.
        this._cachetreeData = true;
        return this.renderedTreeData;
      }
      this._cachetreeData = false;
      let treeData = Array.isArray(validProps) ? [...validProps.treeData] : validProps.treeData;
      // process treeDataSimpleMode
      if (validProps.treeDataSimpleMode) {
        const simpleFormat = {
          id: 'id',
          pId: 'pId',
          rootPId: null,
        };
        if (Object.prototype.toString.call(validProps.treeDataSimpleMode) === '[object Object]') {
          Object.assign(simpleFormat, validProps.treeDataSimpleMode);
        }
        treeData = processSimpleTreeData(treeData, simpleFormat);
      }
      return this.loopTreeData(treeData);
    }
  };

  render() {
    const { props } = this;
    const multiple = isMultipleOrTags(props);
    const { state } = this;
    const { className, disabled, allowClear, prefixCls } = props;
    const ctrlNode = this.renderTopControlNode();
    let extraSelectionProps = {};
    if (!isMultipleOrTagsOrCombobox(props)) {
      extraSelectionProps = {
        onKeyDown: this.onKeyDown,
        tabIndex: 0,
      };
    }
    const rootCls = {
      [className]: !!className,
      [prefixCls]: 1,
      [`${prefixCls}-open`]: state.open,
      [`${prefixCls}-focused`]: state.open || state.focused,
      // [`${prefixCls}-combobox`]: isCombobox(props),
      [`${prefixCls}-disabled`]: disabled,
      [`${prefixCls}-enabled`]: !disabled,
    };
    return (
      <SelectTrigger
        {...props}
        treeNodes={props.children}
        treeData={this.renderedTreeData}
        _cachetreeData={this._cachetreeData}
        _treeNodesStates={this._treeNodesStates}
        halfCheckedValues={this.halfCheckedValues}
        multiple={multiple}
        disabled={disabled}
        visible={state.open}
        inputValue={state.inputValue}
        inputElement={this.getInputElement()}
        value={state.value}
        onDropdownVisibleChange={this.onDropdownVisibleChange}
        getPopupContainer={props.getPopupContainer}
        onSelect={this.onSelect}
        ref={t => (this.trigger = t)}
      >
        <span
          style={props.style}
          onClick={props.onClick}
          onBlur={this.onOuterBlur}
          onFocus={this.onOuterFocus}
          className={classnames(rootCls)}
        >
          <span
            ref={s => (this.selection = s)}
            key="selection"
            className={`${prefixCls}-selection
            ${prefixCls}-selection--${multiple ? 'multiple' : 'single'}`}
            role="combobox"
            aria-autocomplete="list"
            aria-haspopup="true"
            aria-expanded={state.open}
            {...extraSelectionProps}
          >
            {ctrlNode}
            {allowClear && !multiple && this.state.value.length && this.state.value[0].value ? (
              <span key="clear" className={`${prefixCls}-selection__clear`} onClick={this.onClearSelection} />
            ) : null}
            {multiple || !props.showArrow ? null : (
              <span key="arrow" className={`${prefixCls}-arrow`} style={{ outline: 'none' }}>
                <b />
              </span>
            )}
            {multiple ? this.getSearchPlaceholderElement(!!this.state.inputValue || this.state.value.length) : null}
          </span>
        </span>
      </SelectTrigger>
    );
  }
}
