import React from 'react';
import FeeOperat from '../FeeReportOp';
import { postJAVA, showInfo } from 'utils';
import { ERROR } from 'constants';
import MaterialApply from 'operation/listOperation/material/apply';

const PASS = 1;
const REBUT = 4;
export const componentWrapArray = ['fee_report', 'material'];
export const auditConfigs = {
  // 在途费用审核(组件内嵌)
  fee_report: {
    getTitle: item => item?.on_way_fee_number || '审核',
    renderContent: (that, item) => {
      const { id, fee_type } = item;
      return (
        <FeeOperat
          id={id}
          feeType={fee_type}
          isAudit
          onlyShowContent
          setLoading={val => that.auditSet('loading', val)}
          opType="view"
        />
      );
    },
    getParams: (item, result, remark) => ({
      audit: result ? PASS : REBUT,
      opinion: remark,
      data: [
        {
          number: `${item.car_batch}，${item.fee_name}`,
          apply_id: item.id,
          operation: 'fee_report_apply',
        },
      ],
      check_source: 6,
    }),
  },
  // 物料发放审核（组件内嵌）
  material: {
    getData: async (that, item) => {
      try {
        that.setState({ loading: false });
        const infoRes = await postJAVA('/cyt-vehicle-biz-v1/material/apply/init', { mab_id: item.id });
        that.setState({ loading: false });
        if (+infoRes.errno !== 0) {
          return showInfo(ERROR, infoRes.errmsg);
        }
        that.setState({
          materialData: infoRes.res,
        });
      } catch (e) {
        console.log(e);
      }
    },
    getTitle: item => `物料申请【${item?.apply_code || ''}】`,
    renderContent: that => {
      const { materialData } = that.state;
      return (
        <MaterialApply
          key={materialData?.mab_id}
          infoData={materialData}
          btnKey="audit_MaterialApplyBill_release_bill"
          onlyShowContent
        />
      );
    },
    getParams: (item, result, remark) => ({
      audit: result ? PASS : REBUT,
      opinion: remark,
      data: [
        {
          number: item.apply_code,
          apply_id: item.id,
          operation: 'material_apply',
        },
      ],
    }),
  },
  // 改单审核（服务端渲染）
  update_batch: {
    auditUrl: 'japi/',
    auditParams: {},
    detailParams: {},
  },
};
