import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classnames from 'classnames';
import { prefixCls } from './index.scss';

export default class ButtonGroup extends Component {
  static propTypes = {
    classname: PropTypes.string,
  };
  render() {
    const { classname, ...rest } = this.props;
    const classes = classnames({
      [classname]: classname,
      [prefixCls]: true,
    });
    return <div {...rest} className={classes} />;
  }
}
