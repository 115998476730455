import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Amap from 'components/utilcomponents/mapAddon/Amap';
import { ModalDialog, Button, Input } from 'components';
import { isEmptyObj } from 'utils';
// eslint-disable-next-line import/no-cycle
import AddrSug from './index';
import { prefixCls } from './mapDialog.scss';

const { MapShow } = Amap;
const { MapInfoWin } = Amap;
// 坐标统一用逗号分隔的字符串
export default class MapDialog extends Component {
  static displayName = 'MapDialog';
  static propTypes = {
    style: PropTypes.object, // 样式
    placeholder: PropTypes.string, // 提示
    mapCenter: PropTypes.array, // 地图中心点
    selectedPoiData: PropTypes.object, // 选中值
    valiType: PropTypes.string, // 数据校验类型
    valueToJson: PropTypes.bool, // 返回值转为json字符串
    disabled: PropTypes.bool, // 禁用 只读
    onClose: PropTypes.func,
    onMapLoaded: PropTypes.func,
    getMap: PropTypes.func,
    mode: PropTypes.string, // 查询模式  district , address
    close: PropTypes.func,
    handleMapSelect: PropTypes.func,
    handleMapIconClick: PropTypes.func,
    handleMapConfirm: PropTypes.func,
  };
  static defaultProps = {
    mapCenter: [116.397, 39.908],
    valueToJson: false,
  };

  constructor(prop) {
    super(prop);
    const selectedPoiData = prop.selectedPoiData || {};
    const maplnglat = selectedPoiData.poi || '';
    this.state = {
      maplnglat,
      selectedPoiData, // 当前选中的完整poi数据
    };
    this._currMap = null;
    this._mapShow = null;
  }

  handleResetState = () => {
    const reset = {
      maplnglat: '',
      selectedPoiData: '',
    };
    this.setState(reset);
  };

  componentDidMount() {
    if (this.props.mode === 'address' || !!this.state.maplnglat) {
      this.handleLanlat(this.state.maplnglat || '116.407394,39.904211');
    }
  }

  componentWillUnmount() {
    this.markerClickEvent && window.AMap.event.removeListener(this.markerClickEvent);
    this.markerDragEvent && window.AMap.event.removeListener(this.markerDragEvent);
    this.mapClickEvent && window.AMap.event.removeListener(this.mapClickEvent);
    this.mapRightClickEvent && window.AMap.event.removeListener(this.mapRightClickEvent);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPoiData !== this.props.selectedPoiData) {
      this.setState({ selectedPoiData: nextProps.selectedPoiData });
    }
  }

  getSelectedDetail = () => this.state.selectedPoiData;
  getMapDialog = () => this._currMap || this._mapShow.getMap();
  onMapLoaded = cMap => {
    this._currMap = cMap;
    if (this.props.mode === 'address') {
      this.mapClickEvent = window.AMap.event.addListener(cMap, 'click', e => {
        this._currMap && this.regeocoder([e.lnglat.getLng(), e.lnglat.getLat()], false, true, true);
      });
      this.mapRightClickEvent = window.AMap.event.addListener(cMap, 'rightclick', e => {
        this._currMap && this.regeocoder([e.lnglat.getLng(), e.lnglat.getLat()], false, true, true);
      });
    }
  };
  handleLanlat = lnglat => {
    const ll = lnglat && lnglat.split(',');
    this._currMap && this.regeocoder(ll);
  };
  // 地址  -> 坐标
  regeocoder = (lnglat, setFitView, autoOpen, updateSelet = false) => {
    const cMap = this._currMap;
    const geocoder = new window.AMap.Geocoder({
      radius: 1000,
      city: '全国',
      extensions: 'all',
    });
    geocoder.getAddress(lnglat, (status, r) => {
      if (status === 'complete' && r.info === 'OK') {
        if (!cMap) return r.regeocode;
        const info = { address: r.regeocode.formattedAddress };
        if (updateSelet) {
          const { addressComponent } = r.regeocode;
          // 直辖市 city = []
          const city = !addressComponent.city.length ? addressComponent.province : addressComponent.city;
          const selectedPoiData = {
            province: addressComponent.province,
            city,
            district: addressComponent.district,
            adcode: addressComponent.adcode,
            poi: lnglat.join(','),
            street: addressComponent.township,
            show_val: r.regeocode.formattedAddress,
          };
          this.setState({ selectedPoiData, maplnglat: lnglat });
        }
        // 地图画点
        this.setMarker(lnglat, info, setFitView, autoOpen);
      }
    });
  };
  setMarker = (lnglat, info = {}, setFitView = true, autoOpen = false) => {
    const draggable = this.props.mode === 'address';
    const cMap = this._currMap;
    cMap.clearMap();
    const marker = new window.AMap.Marker({
      map: cMap,
      position: lnglat,
      draggable,
      icon: new window.AMap.Icon({
        image: require('images/map/marker/dragMarker.png'),
        size: new window.AMap.Size(20, 27),
        imageSize: new window.AMap.Size(20, 27),
      }),
      offset: new window.AMap.Pixel(-10, -25),
    });
    const infoWindow = MapInfoWin('详细信息', `<div>${info.address}</div>`);
    this.markerClickEvent = window.AMap.event.addListener(marker, 'click', () => {
      infoWindow.open(cMap, marker.getPosition());
    });
    if (draggable) {
      this.markerDragEvent = window.AMap.event.addListener(marker, 'dragend', e => {
        this._currMap && this.regeocoder([e.lnglat.getLng(), e.lnglat.getLat()], false, true, true);
      });
    }
    autoOpen && infoWindow.open(cMap, marker.getPosition());
    setFitView && cMap.setFitView();
  };

  render() {
    // 地图框 map
    const { mode, disabled, close, onClose } = this.props;
    const { selectedPoiData } = this.state;
    return (
      <ModalDialog
        title="地址选择"
        close={close}
        ref={dialog => (this._mapDialog = dialog)}
        isShow
        closable
        isModal
        maskClosable={false}
        onClose={onClose}
        contentStyle={{ maxWidth: 'none' }}
        content={
          <div>
            <div className={prefixCls}>
              <div className="select-location">
                <span>所在地址</span>
                <AddrSug
                  mapIcon={false}
                  mode={mode}
                  disabled={disabled}
                  defaultValue={selectedPoiData}
                  handleSelected={this.handleMapSelect}
                />
                <span>经纬度</span>
                <Input readOnly="readonly" placeholder="(经度，纬度)" defaultValue={this.state.maplnglat} />
                <Button type="primary" onClick={this.handleMapConfirm}>
                  确定
                </Button>
              </div>
              <MapShow ref={m => (this._mapShow = m)} onMapLoaded={this.onMapLoaded} />
            </div>
          </div>
        }
      />
    );
  }

  handleMapSelect = item => {
    if (isEmptyObj(item)) return;
    const { valueToJson } = this.props;
    const maplnglat = item.poi;
    this.setState({ selectedPoiData: item, maplnglat });
    this.handleLanlat(maplnglat);
    const selectedPoiData = valueToJson ? JSON.stringify(item) : item;
    this.props.handleMapSelect && this.props.handleMapSelect(selectedPoiData);
  };
  // 地图内 确定按钮
  handleMapConfirm = () => {
    this._mapDialog.handleHide();
    this.props.handleMapConfirm && this.props.handleMapConfirm(this.state.selectedPoiData);
  };
}
