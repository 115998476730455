import PropTypes from 'prop-types';
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import TableCell from './TableCell';

export default class RadioCell extends TableCell {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    disabled: PropTypes.bool,
  };
  onChange = e => {
    const { columnKey, rowIndex } = this.props;
    this.props.onChange && this.props.onChange(rowIndex, columnKey, e.target.checked);
  };

  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data, tableKey, colIndex } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const { name = columnKey, disabled = false } = columnProps;
    const defaultValue = data.getShowObjectAt(rowIndex)[columnKey];
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <input
          data-path={`${tableKey}_${columnKey}_${rowIndex}_${colIndex}`}
          type="radio"
          name={name}
          disabled={disabled}
          value={defaultValue}
          defaultChecked={defaultValue}
          onChange={this.onChange}
        />
      </Cell>
    );
  }
}
