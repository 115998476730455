/**
 
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Spin } from '@gui/web-react';
import { DataList } from 'components';
import { createTip, post, throttle } from 'utils';
import { POISelector } from 'components/utilcomponents/mapAddon/Amap';
import { DEFAULT_ADDR_MAP_ICON, ERROR } from 'constants';
import { formatLevel4Address, getShowVal } from './util';
import { prefixCls } from './index.scss';
import Icon from '../../icon/Icon';

class G7AddrSug extends Component {
  constructor(prop) {
    super(prop);
    let { defaultValue } = prop;
    if (defaultValue === 'string') {
      defaultValue = JSON.parse(prop.defaultValue || '{}');
    }
    // select组件Value设置默认值
    const selectDropValue = defaultValue?.show_val || defaultValue?.address || defaultValue?.address || '';
    const selectedPoiData = prop?.valueToJson ? JSON.stringify(defaultValue) : defaultValue;
    this.state = {
      selectDropData: [], // DataList组件枚举值
      poiDatasRet: [], // 当前关键词对应的全部查询结果的返回集
      selectDropValue, // DataList组件Value值
      selectedPoiData, // 当前选中的完整poi数据
      addressLibrary: window.company_setting?.address_library?.values, // G7地址库系统设置
      moreLoading: false, // 加载更多状态
      pageNum: 1, // 接口请求页码
      pageSize: 10, // 接口请求条数
      pageNumTotal: 5, // 页数阈值
    };
  }

  static propTypes = {
    classname: PropTypes.string, // 地址框样式
    style: PropTypes.object, // 样式
    fieldName: PropTypes.string,
    placeholder: PropTypes.string, // 提示
    defaultValue: PropTypes.oneOfType([
      // 默认值
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
    ]),
    mapIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // 地图弹框响应图标 不要图标需手动置false
    valiType: PropTypes.string, // 数据校验类型
    valueToJson: PropTypes.bool, // 返回值转为json字符串
    blurCheck: PropTypes.bool, // blur数据检查,
    customValidity: PropTypes.func,
    onBlur: PropTypes.func, // blur 事件
    onFocus: PropTypes.func,
    onChange: PropTypes.func, // 输入框点击
    onKeyDown: PropTypes.func, // 输入框
    handleSelected: PropTypes.func, // 下拉选择处理
    handleAdrIconClick: PropTypes.func,
    disabled: PropTypes.bool, // 禁用 只读
    dataPath: PropTypes.string, // 光标路径
    required: PropTypes.bool,
    showTitleTips: PropTypes.bool,
    scrollLoad: PropTypes.bool,
  };
  static defaultProps = {
    defaultValue: '',
    valueToJson: false,
    blurCheck: false,
    scrollLoad: true,
    mapIcon: DEFAULT_ADDR_MAP_ICON,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldDefaultValue = this.props.defaultValue || {};
    let newDefaultValue = nextProps.defaultValue || {};
    if (newDefaultValue.show_val !== oldDefaultValue.show_val) {
      if (typeof defaultValue === 'string') {
        newDefaultValue = JSON.parse(newDefaultValue || '{}');
      }
      // lnglat = defaultValue.poi || '';
      const selectDropValue = newDefaultValue.show_val || newDefaultValue.address || newDefaultValue.address || '';
      const selectedPoiData = this.props.valueToJson ? JSON.stringify(newDefaultValue) : newDefaultValue;
      // eslint-disable-next-line react/no-unused-state
      this.setState({ selectDropValue, selectedPoiData });
    }
  }
  componentDidMount() {}

  focusInput = () => {
    this.input && this.input.focus();
  };
  refInput = r => {
    this.input = r;
  };
  refCustomMenuWrap = customMenuWrap => {
    this.customMenuWrap = customMenuWrap;
  };

  // 格式化数据为DataList所用
  handleFormatSelectData(data) {
    const { addressLibrary } = this.state;
    const nextSelectDropData = data.map((item, i) => {
      const province = item.province || '';
      const city = item.city || '';
      const name = item.name || '';
      const district = item.district || item.adname || '';
      const aliasName = item.aliasName || '';
      const source = Number(item.source) ?? 2;

      let label = name;
      let value = getShowVal({ province, city, district, name });
      let info = `${province}${city}${district}`;
      if (addressLibrary === 'alias') {
        label = aliasName || label;
        value = aliasName || value;
        info = aliasName ? getShowVal({ province, city, district, name }) : info;
      } else if (addressLibrary === 'aliasDetail') {
        label = aliasName ? `${aliasName}[${label}]` : label;
        value = aliasName ? `${aliasName}[${value}]` : value;
      }
      return {
        info,
        listVal: label,
        value,
        source,
      };
    });
    return nextSelectDropData;
  }

  // 格式化数据为上层组件所用
  handleFormatPoiData(data) {
    const { addressLibrary } = this.state;
    const poiDatasRet = data.map((item, i) => {
      const adcode = item.adcode || '';
      const province = item.province || '';
      const city = item.city || '';
      const name = item.name || '';
      const district = item.district || item.adname || '';
      const aliasName = item.aliasName || '';
      const location = item.location || '';

      let value = getShowVal({ province, city, district, name });

      if (addressLibrary === 'alias') {
        value = aliasName || value;
      } else if (addressLibrary === 'aliasDetail') {
        value = aliasName ? `${aliasName}[${value}]` : value;
      }

      return {
        adcode,
        poi: location,
        show_val: value,
        ...formatLevel4Address(item),
      };
    });
    return poiDatasRet;
  }
  // 获取G7地址库数据
  getSearchDiffPoi = async req => {
    try {
      const res = await post('/Basic/Amap/searchDiffPoi', req);
      // 优先级: 租户数据- > 平台数据 -> 高德数据
      const data = res?.res || [];
      if (res.errno === 0 && _.isArray(data)) {
        const selectDropData = this.handleFormatSelectData(data);
        const poiDatasRet = this.handleFormatPoiData(data);
        return {
          selectDropData,
          poiDatasRet,
        };
      }
      return {
        selectDropData: [],
        poiDatasRet: [],
      };
    } catch (e) {
      return {
        selectDropData: [],
        poiDatasRet: [],
      };
    }
  };

  filter = async searchStr => {
    const { selectDropValue, pageSize } = this.state;
    const req = { keyword: searchStr || selectDropValue || '', pageNum: 1, pageSize };
    const { selectDropData, poiDatasRet } = await this.getSearchDiffPoi(req);
    this.setState({
      selectDropData,
      poiDatasRet,
      pageNum: 1,
    });
  };
  onBlur = e => {
    const { selectedPoiData } = this.state;
    if (this.props.blurCheck && selectedPoiData && selectedPoiData.show_val && !selectedPoiData.province) {
      createTip('请从下拉中选择数据', ERROR, undefined, undefined, undefined, true).show();
    }
    this.props.onBlur && this.props.onBlur(selectedPoiData, this.props.fieldName, e);
  };
  onKeyDown = e => {
    if (e.keyCode === 13 && !e.target.dataset.isSelect) {
      const { selectedPoiData } = this.state;
      this.props.onKeyDown && this.props.onKeyDown(selectedPoiData, this.props.fieldName);
    }
  };
  // DataList组件Change事件
  handleOnChange = searchStr => {
    const poiDatasRet = {
      province: '',
      city: '',
      district: '',
      adcode: '',
      poi: '',
      street: searchStr,
      show_val: searchStr,
    };
    this.setState({ selectDropValue: searchStr, selectedPoiData: poiDatasRet });
    this.props.onChange && this.props.onChange(poiDatasRet);
  };

  // DataList组件Select事件
  handleOnSelected = item => {
    if (_.isEmpty(item)) {
      this.setState({ selectDropValue: '' });
      this.props.handleSelected && this.props.handleSelected({});
      return;
    }
    const { valueToJson } = this.props;
    const { poiDatasRet } = this.state;
    const selectedIndex = item.index;
    if ((selectedIndex === '-1' || selectedIndex === '') && item.value === '') return false;
    let selectedPoiData = poiDatasRet[selectedIndex] || {
      show_val: item.value,
      province: '',
      city: '',
      district: '',
      adcode: '',
      poi: '',
    };
    this.setState({ selectedPoiData, selectDropValue: item?.value });
    selectedPoiData = valueToJson ? JSON.stringify(selectedPoiData) : selectedPoiData;
    this.props.handleSelected && this.props.handleSelected(selectedPoiData);
  };
  // 图标点击地图选择地址
  handleAdrIconClick = async (e, item, i) => {
    const { valueToJson, defaultValue } = this.props;
    const { poiDatasRet, addressLibrary } = this.state;
    const nodeAddress = poiDatasRet?.[i] || defaultValue || undefined;

    // 打开地址库弹窗
    let selectedPoiData = await POISelector({
      title: '地址选择',
      appName: 'cyt',
      groupId: window.group_id,
      companyId: window.company_id,
      defaultValue: nodeAddress,
    });
    // 获取到地址库弹窗后进行系统设置处理
    if (selectedPoiData) {
      const { aliasName } = selectedPoiData;
      let value = getShowVal({ ...selectedPoiData });
      if (addressLibrary === 'alias') {
        value = aliasName || value;
      } else if (addressLibrary === 'aliasDetail') {
        value = aliasName ? `${aliasName}[${value}]` : value;
      }
      selectedPoiData = { ...selectedPoiData, show_val: value, ...formatLevel4Address(selectedPoiData) };
      this.setState({ selectedPoiData, selectDropValue: value });
      selectedPoiData = valueToJson ? JSON.stringify(selectedPoiData) : selectedPoiData;
      this.props.handleSelected && this.props.handleSelected(selectedPoiData);
    }
    this.props.handleAdrIconClick && this.props.handleAdrIconClick(this.state.selectedPoiData);
    e.stopPropagation();
    e.preventDefault();
  };
  // 键盘上下选择
  handleOnCursor = cursorIndex => {
    const curFocus = this.customMenuWrap && this.customMenuWrap.querySelector('.node.focus');
    const newFocus = this.customMenuWrap && this.customMenuWrap.querySelector(`.node:nth-child(${cursorIndex + 1})`);
    curFocus && curFocus.classList.remove('focus');
    newFocus && newFocus.classList.add('focus');
  };
  // 滚动加载
  handleOnMenuScroll = throttle(async e => {
    if (!e || !this.customMenuWrap || !this.props.scrollLoad) return;
    const dom = this.customMenuWrap;
    // 文档内容实际高度（包括超出视窗的溢出部分）
    const scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
    // 滚动条滚动距离
    const { scrollTop } = e.target;
    // 窗口可视范围高度
    const clientHeight = dom.innerHeight || Math.min(dom.clientHeight, dom.clientHeight);
    if (clientHeight + scrollTop >= scrollHeight) {
      const { selectDropData, poiDatasRet, selectDropValue, pageSize, pageNum, pageNumTotal, moreLoading } = this.state;

      if (pageNum >= pageNumTotal || moreLoading) return;
      this.setState({ moreLoading: true });
      const req = { keyword: selectDropValue || '', pageNum: pageNum + 1, pageSize };
      const { selectDropData: nextSelectDropData, poiDatasRet: nextPoiDatasRet } = await this.getSearchDiffPoi(req);
      if (selectDropData.length > 0 && poiDatasRet.length > 0) {
        this.setState({
          pageNum: pageNum + 1,
          selectDropData: [...selectDropData, ...nextSelectDropData],
          poiDatasRet: [...poiDatasRet, ...nextPoiDatasRet],
          moreLoading: false,
        });
      } else {
        this.setState({ moreLoading: false });
      }
    }
  }, 100);
  // 渲染menu部分
  customRenderMenu = params => {
    const { realData, onSelect, loading } = params;
    const { moreLoading } = this.state;
    return (
      <div className="g7map-dropdown-menu" ref={this.refCustomMenuWrap} onScroll={this.handleOnMenuScroll}>
        <div className="g7map-dropdown-menu__header">
          点击
          <i className={`input-icon fn-icon fn-${DEFAULT_ADDR_MAP_ICON}`} />
          快捷加入地址库
          {realData.length > 0 && (
            <span>
              鼠标
              <Icon iconType="icon-arrow-mup" />
              <Icon iconType="icon-arrow-mdown" />
              滑动快捷选择
            </span>
          )}
        </div>
        {loading ? (
          <Spin />
        ) : (
          <div className="g7map-dropdown-menu__content" ref={this.refCustomMenuContent}>
            {realData.map((item, i) => (
              <div className="node" key={i} onMouseDown={e => onSelect(item, e)}>
                <div className="l" onMouseDown={e => this.handleAdrIconClick(e, item, i)}>
                  <i className={`input-icon fn-icon fn-${DEFAULT_ADDR_MAP_ICON}`} />
                  <div>{+item?.source === 2 ? '高德' : '地址库'}</div>
                </div>
                <div className="r">
                  <p className="value">{item.listVal}</p>
                  <p className="info">{item.info}</p>
                </div>
              </div>
            ))}
            {moreLoading && <Spin />}
            {realData.length < 1 && <div className="empty">无相关数据</div>}
          </div>
        )}
      </div>
    );
  };
  render() {
    const {
      classname,
      style,
      placeholder,
      disabled,
      valiType,
      mapIcon,
      dataPath,
      customValidity,
      required,
      showTitleTips,
    } = this.props;
    const classes = classnames({ [prefixCls]: prefixCls, [classname]: classname, [`fn-field--${valiType}`]: valiType });
    const imapIcon = mapIcon === true ? DEFAULT_ADDR_MAP_ICON : mapIcon;
    const selectDropData = this.state.selectDropData.map((item, index) => ({
      ...item,
      index,
    }));
    return (
      <DataList
        ref={this.refInput}
        style={style}
        required={required}
        className={classes}
        placeholder={placeholder}
        menuClassName="fn-address__menu"
        disabled={disabled}
        data={selectDropData}
        value={this.state.selectDropValue}
        data-path={dataPath}
        customValidity={customValidity}
        highlight="listVal"
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        onFocus={this.props.onFocus}
        filter={this.filter}
        onChange={this.handleOnChange}
        onSelect={this.handleOnSelected}
        onClose={this.handleHideCallback}
        showTitleTips={showTitleTips}
        customRenderMenu={this.customRenderMenu}
        onCursor={this.handleOnCursor}
      >
        {imapIcon && <i className={`input-icon fn-icon fn-${imapIcon}`} onClick={this.handleAdrIconClick} />}
      </DataList>
    );
  }
}
export default G7AddrSug;
