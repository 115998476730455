import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { animateClass, deleteProps, typeIs, isset } from 'utils';
import { Tips, Icon } from 'components';
import { prefixCls } from './index.scss';

export default class Button extends PureComponent {
  constructor(props) {
    super(props);
    this.clicks = 0;
    // this.loadingText = '加载中...'
    this.state = {
      loading: props.loading,
      disabled: props.disabled,
    };
  }

  static defaultProps = {
    type: 'default',
    display: true,
    useDefaultCls: true,
    clickLimit: true,
    showLoading: true,
    showDisableMask: true,
  };
  static propTypes = {
    style: PropTypes.object,
    disabled: PropTypes.bool,
    showDisableMask: PropTypes.bool,
    onClick: PropTypes.func,
    classname: PropTypes.string,
    display: PropTypes.bool,
    children: PropTypes.any,
    isAnimate: PropTypes.bool,
    loading: PropTypes.bool,
    type: PropTypes.oneOf(['primary', 'default', 'disabled', 'positive', 'positive-r', 'text']),
    useDefaultCls: PropTypes.bool,
    tips: PropTypes.string, // Button 上是否有 tips
    clickLimit: PropTypes.bool, // 点击响应无限制
    autoStopLoading: PropTypes.bool, // 长时间loading 自动解除
    showLoading: PropTypes.bool, // 自动加载遮罩
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!!nextProps.loading !== !!this.props.loading) {
      this.setState({ loading: nextProps.loading });
      this.setBtnLoadingTag(nextProps.loading);
      // 禁用遮罩
      nextProps.loading ? this.openForbidMask() : this.closeForbidMask();
    }
    if (nextProps.disabled !== this.props.disabled) {
      this.setState({ disabled: nextProps.disabled });
    }
  }

  setBtnLoadingTag = state => {
    // console.log('set btnLoadingon', state)
    window.btnLoadingOn = state;
  };

  componentDidMount() {
    // 如非 必要 请忽略这个生命周期
    if (this.props.isAnimate) {
      animateClass(this.vBtn, 'zoom-enter zoom-enter-active');
    }
  }

  componentWillUnmount() {
    this.closeForbidMask();
  }

  // 10、15s 自动去除loading状态
  longTimeAutoStop = () => {
    this.clearAutoStop();
    this.timer = setTimeout(() => {
      if (this.props.autoStopLoading) this.loadingOff();
      // recordLog({ message: `按钮loading 超时：${Date()}` })
    }, 10000);
  };
  clearAutoStop = () => this.timer && clearTimeout(this.timer);
  // 打开禁用遮罩
  openForbidMask = () => {
    if (!this.props.showDisableMask) return;
    this.longTimeAutoStop();
    const forbidNode = document.createElement('div');

    const tab = document.querySelector('.r_indexpage_main > .tabs-active-content');
    const modalPage = document.querySelector('.modal-page--active');
    const parentNode = modalPage || tab || document?.body;

    const oldForbidNode = parentNode.querySelector('.forbid-btn-mask');
    forbidNode.setAttribute('class', 'forbid-btn-mask');
    this.parentNode = parentNode;
    if (oldForbidNode) this.closeForbidMask();
    parentNode.appendChild(forbidNode);
  };
  // 关闭禁用遮罩
  closeForbidMask = () => {
    this.clearAutoStop();
    const parentNodeCancel = this.parentNode;
    const forbidNodeCancel = parentNodeCancel && parentNodeCancel.querySelector('.forbid-btn-mask');
    if (parentNodeCancel && forbidNodeCancel) parentNodeCancel.removeChild(forbidNodeCancel);
  };
  loadingOn = () => {
    this.openForbidMask();
    this.setState({ loading: true });
    this.setBtnLoadingTag(true);
  };
  loadingOff = () => {
    this.closeForbidMask();
    this.setState({ loading: false });
    this.setBtnLoadingTag(undefined);
  };
  disabledOn = () => {
    this.openForbidMask();
    this.setState({ disabled: true });
  };
  disabledOff = () => {
    this.closeForbidMask();
    this.setState({ disabled: false });
  };
  onClick = (...rest) => {
    this.setBtnLoadingTag(false);
    window.btnClick = true;
    if (!this.props.clickLimit) {
      this.props.onClick && this.props.onClick(...rest, this);
      return;
    }
    const cTime = Date.now();
    const interval = 1000; // 1500 // 防短时多次点击
    if (cTime - this.clicks < interval) {
      console.log('submit too fast');
      return;
    }
    this.props.onClick && this.props.onClick(...rest, this);
    this.clicks = cTime;
  };
  getLoadingText = () => {
    // if (typeIs(this.props.children, 'string')) return this.loadingText
    // just from icon & icon position is left
    // if (typeIs(this.props.children, 'array') && typeIs(this.props.children[1], 'string')) return [this.props.children[0], this.loadingText]
    // return this.props.children
    // 显示loading图标
    const loadingIcon = <Icon key="icon_spinner" iconType="icon-spinner" />;
    if (typeIs(this.props.children, 'string')) return [loadingIcon, this.props.children];
    return [loadingIcon, this.props.children[1]];
  };

  render() {
    const { classname, style, display, type, useDefaultCls, showLoading, ...rest } = this.props;
    const { loading, disabled } = this.state;
    const classes = classnames({
      [classname]: classname,
      [prefixCls]: true,
      [`${prefixCls}--disabled`]: disabled,
      [`${prefixCls}--loading`]: loading,
      [`${prefixCls}--${type}`]: !disabled,
      [`${prefixCls}__hide`]: isset(display, 'string') ? display !== 'true' : !display,
    });
    const props = deleteProps(
      rest,
      'isAnimate',
      'getPaths',
      'valiType',
      'onClick',
      'clickLimit',
      'disabled',
      'useDefaultCls',
      'tips',
      'loading',
      'autoStopLoading',
      'showDisableMask',
      'customValidity',
    );
    const dealStyle = style;
    let { children } = this.props;
    if (loading && showLoading) {
      children = this.getLoadingText();
      // 改为 loading 状态时， 防止宽度发生变化
      // dealStyle = Object.assign({}, style, { width: (this.vBtn) ? (this.vBtn.offsetWidth > 95 ? this.vBtn.offsetWidth : 95) : 'initial' })
    }
    let content = (
      <button
        {...props}
        disabled={disabled || loading}
        className={useDefaultCls ? classes : classname}
        style={dealStyle}
        onClick={this.onClick}
        ref={r => (this.vBtn = r)}
      >
        {children}
      </button>
    );
    if (this.props.tips !== undefined && this.props.tips !== '') {
      content = <Tips title={this.props.tips}>{content}</Tips>;
    }
    return content;
  }
}
