import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { emptyFunction } from 'utils';
import { SelectDrop, DatePicker } from 'components';
import './index.scss';

const rangeList = (() => {
  const list = [];
  for (let i = 0; i < 24; i++) {
    const num = i < 10 ? `0${i}` : i;
    const num1 = i + 1 < 10 ? `0${i + 1}` : i + 1;
    const rangeText = `${num}:00-${num1}:00`;
    list.push({ name: rangeText, key: rangeText });
  }
  return list;
})();
const rangeRegex = /\d{2}:00-\d{2}:00/;

export default class TimeRangePicker extends Component {
  constructor(props) {
    super(props);
    const { defaultValue } = props;
    let dateValue;
    const result = rangeRegex.exec(defaultValue);
    let timeValue;
    if (result) {
      dateValue = defaultValue.replace(rangeRegex, '');
      dateValue = dateValue.trim();
      timeValue = result[0];
    } else if (defaultValue) {
      dateValue = defaultValue;
    }
    dateValue = dateValue ? moment(dateValue) : undefined;
    const defaultTimeValue = [];
    if (timeValue) {
      const targetItem = rangeList.find(item => item.name === timeValue);
      defaultTimeValue.push(targetItem);
    }
    this.state = {
      dateValue,
      timeValue,
      defaultTimeValue,
    };
  }

  static propTypes = {
    defaultValue: PropTypes.string,
    classname: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    onChange: PropTypes.func,
    allowClear: PropTypes.bool, // 是否显示日历后面的小叉号
    autoFocus: PropTypes.objectOf([false, 'date', 'time']),
  };
  static defaultProps = {
    allowClear: false,
    onChange: emptyFunction,
    autoFocus: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      let dateValue;
      const result = rangeRegex.exec(nextProps.defaultValue);
      let timeValue;
      if (result) {
        dateValue = nextProps.defaultValue.replace(rangeRegex, '');
        dateValue = dateValue.trim();
        timeValue = result[0];
      } else if (nextProps.defaultValue) {
        dateValue = nextProps.defaultValue;
      }
      dateValue = dateValue ? moment(dateValue) : undefined;
      const defaultTimeValue = [];
      if (timeValue) {
        const targetItem = rangeList.find(item => item.name === timeValue);
        defaultTimeValue.push(targetItem);
      }
      this.setState({
        dateValue,
        timeValue,
        defaultTimeValue,
      });
    }
  }

  handleDatePickerChange = moments => {
    const dateValue = moments.format('YYYY-MM-DD HH:mm:ss');
    this.setState({ dateValue });
    const concatDateValue = moments.format('YYYY-MM-DD');
    const submitValue = this.state.timeValue ? `${concatDateValue} ${this.state.timeValue}` : concatDateValue;
    console.log('handleDatePickerChange', submitValue);
    this.props.onChange(submitValue);
  };
  handleSelected = selected => {
    const concatDateValue = this.state.dateValue ? moment(this.state.dateValue).format('YYYY-MM-DD') : '';
    const submitValue = `${concatDateValue} ${selected && selected.key ? selected.key : ''}`;
    const timeValue = selected.key;
    this.setState({ timeValue });
    console.log('handleSelected', submitValue);
    this.props.onChange(submitValue);
  };

  render() {
    const { classname, disabled, autoFocus } = this.props;
    const { dateValue, defaultTimeValue } = this.state;
    const timeRangePicker = true;
    const classes = classnames({
      [classname]: true,
      timeRangePicker,
    });
    const dateVal = dateValue ? moment(dateValue) : undefined;
    return (
      <div className={classes}>
        <DatePicker
          style={{ width: '100px !important' }}
          format="YYYY-MM-DD"
          onChange={this.handleDatePickerChange}
          value={dateVal}
          disabled={disabled}
          autoFocus={autoFocus === 'date'}
          allowClear={this.props.allowClear}
        />
        <SelectDrop
          handleSelected={this.handleSelected}
          listClass="timeRangePickerList"
          showKey="name"
          uniqueKey="key"
          readOnly
          isFocus={autoFocus === 'time'}
          formKey="key"
          filterKey="key"
          data={rangeList}
          disabled={disabled}
          defaultSelected={defaultTimeValue}
        />
      </div>
    );
  }
}
