/**
 
 * 逐个审核组件
 */

import React from 'react';
import { PropTypes } from 'prop-types';
import { SlideDrager, Button, CardBox, CheckBox, TextArea, PureInput, UploadFile } from 'components';
import { bem, post, showInfo, sleep } from 'utils';
import { ERROR, CHECK } from 'constants';
import { auditConfigs, componentWrapArray } from './config.js';
import { prefixCls } from './index.scss';
import _ from 'lodash';

const { FormItem } = CardBox;
const cls = bem(prefixCls);

class AuditOneByOne extends React.Component {
  static propTypes = {
    onSuccess: PropTypes.func,
    close: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    popContainer: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    category: PropTypes.string,
    tab: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    filter: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    selectRows: PropTypes.array,
    type: PropTypes.string, // 审批类型
  };
  static defaultProps = {
    selectRows: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      auditList: [],
      pageSize: 10,
      formData: {
        data: [],
        header: {},
      },
      tableData: {
        data: [],
        header: {},
      },
      nextChecked: localStorage.getItem(`next_checked.${window.user_id}.${props.type}`) === 'true', // 记忆用户之前的选择
      isComponent: componentWrapArray.includes(props.type), // 是否组件内嵌
      auditConfig: auditConfigs[props.type],
      remark: '',
      complete: false, // 请求到最后一页
      update: false, // 是否有过审批操作
      loading: false,
    };
  }
  componentDidMount() {
    this.getAuditList(true);
  }
  // 获取待审核列表,第一次只查询选中的数据
  getAuditList = async isFirst => {
    const { category, tab, selectRows, query = {}, filter = {} } = this.props;
    const { pageSize } = this.state;
    const req = {
      page_size: pageSize,
      category,
      tab,
      is_first: isFirst === true,
      query: { ...query, id: isFirst ? selectRows.map(i => i.id) : [] },
      filter,
    };
    try {
      this.setState({ loading: true });
      const res = await post('/Table/Search/getDataList', req);
      this.setState({ loading: false });
      if (res.errno === 0) {
        const { header, enumerations } = res?.res;
        let { data } = res?.res;
        if (!data.length) {
          showInfo(ERROR, '已无待审核数据！');
          setTimeout(() => {
            this.close();
          }, 1000);
          return;
        }
        // 不是第一次请求或者是第一次请求但没有已选中数据，返回的数据长度小于pagesize,说明到了最后一页
        if ((!isFirst || (isFirst && !selectRows.length)) && data.length < pageSize) {
          this.setState({
            complete: true,
          });
        }
        // 数据适配
        if (header?.fee_id) {
          data = data?.map(item => ({
            ...item,
            fee_name: enumerations?.fee_id_all?.find(i => +i.key === +item.fee_id)?.display,
          }));
        }
        this.setState(
          {
            auditList: data,
          },
          () => this.updataDetail(data[0]),
        );
      } else {
        showInfo(ERROR, res.errmsg);
      }
    } catch (error) {
      showInfo(ERROR, '网络错误');
    }
  };
  // 更新审核内容
  updataDetail = item => {
    const { isComponent } = this.state;
    if (isComponent) {
      this.state?.auditConfig?.getData?.(this, item);
    } else {
      this.getDetail();
    }
  };
  // 获取审核内容
  getDetail = async () => {
    const { auditList } = this.state;
    const req = {
      apply_id: auditList[0].id,
      operation: auditList[0].operation,
    };
    try {
      this.setState({ loading: true });
      const res = await post('/Basic/Approval/getApprovalDetail', req);
      this.setState({ loading: false });
      // -1 该数据已审核
      if (res.errno === -1) {
        this.toNext();
      }
      if (res.errno === 0) {
        this.setState({
          formData: res.res.formData,
          tableData: res.res.tableData,
        });
      } else {
        showInfo(ERROR, res.errmsg);
      }
    } catch (error) {
      showInfo(ERROR, '网络错误');
    }
  };
  // 赋值
  auditSet = (key, val) => {
    this.setState({ [key]: val });
    if (key === 'nextChecked') {
      localStorage.setItem(`next_checked.${window.user_id}.${this.props.type}`, val); // 保存用户关联往返勾选情况
    }
  };
  // 下一单
  toNext = () => {
    const { auditList, complete, nextChecked } = this.state;
    // 未勾选继续下一单
    if (!nextChecked) {
      return this.close();
    }
    // 没有待审核数据则关闭
    if (complete && auditList.length === 1) {
      showInfo(ERROR, '已无待审核数据！');
      setTimeout(() => {
        this.close();
      }, 1000);
      return;
    }
    // 清空审核备注
    this.setState({
      remark: '',
    });
    // 当前列表审核完成，请求下一组数据
    if (!complete && auditList.length === 1) {
      this.getAuditList();
    } else {
      auditList.shift();
      this.setState(
        {
          auditList,
        },
        () => this.updataDetail(auditList[0]),
      );
    }
  };
  onSubmit = async result => {
    const { auditList, remark, auditConfig, complete } = this.state;

    const req = auditConfig.getParams(auditList[0], result, remark);
    const url = auditConfig.auditUrl || '/Basic/Approval/audit';
    try {
      // 标记是否有审批
      this.setState({
        update: true,
        loading: true,
      });
      const res = await post(url, req);
      this.setState({ loading: false });
      if (res.errno === 0) {
        showInfo(CHECK, '审核成功');
        // 没有待审核数据增加延迟
        if (complete && auditList.length === 1) {
          await sleep(1000);
        }
        this.toNext();
        return;
      }
      // CYTRD-16500 防止重复提交
      if (+res?.errno === 941) {
        // do nothing.
        return;
      }
      const errmsg = res.errmsg || _.get(res.res, 'failed_detail.0.msg');
      showInfo(ERROR, errmsg);
    } catch (error) {
      showInfo(ERROR, '网络错误');
    }
  };
  close = () => {
    this.props.close();
    if (this.state.update) {
      this.props?.onSuccess?.();
    }
  };
  renderForm() {
    const { formData } = this.state;
    const { header, data } = formData;
    return (
      <CardBox title="基本信息">
        {Object.keys(header).map(key => (
          <FormItem label={header[key].title} key={key}>
            {header[key].type === 'Input' && <PureInput value={data[key]} data-path={key} disabled />}
            {header[key].type === 'UploadImgText' && (
              <UploadFile
                fileList={data[key]}
                disabled
                fileType="feeReport"
                maxNum={10}
                showType="img"
                accept="image/*"
              />
            )}
          </FormItem>
        ))}
      </CardBox>
    );
  }
  renderTable() {
    const { tableData } = this.state;
    const { header, data } = tableData;
    const headerList = Object.keys(header);
    return (
      <table>
        <thead>
          <tr>
            {headerList.map(i => (
              <th>{i.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr>
              {headerList.map(key => (
                <th>{item[key]}</th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  renderAuditContent() {
    return (
      <div>
        {this.renderForm()}
        {this.renderTable()}
      </div>
    );
  }
  renderContent() {
    const { auditList, isComponent, auditConfig, remark } = this.state;
    return (
      <div>
        {isComponent ? auditConfig.renderContent(this, auditList[0]) : this.renderAuditContent()}
        <div className={cls('remark')}>
          <label className="fn-label">审批备注：</label>
          <TextArea
            name="audit_remark"
            maxLength="100"
            classname="remark"
            placeholder=""
            defaultValue={remark}
            onChange={e => this.auditSet('remark', e.target.value)}
          />
        </div>
      </div>
    );
  }
  renderFooter() {
    const { nextChecked, loading } = this.state;
    return (
      <div className="slide__drager__footer__content">
        <Button onClick={() => this.onSubmit(true)} loading={loading} type="primary">
          通过
        </Button>
        <Button onClick={() => this.onSubmit(false)} loading={loading} type="default">
          驳回
        </Button>
        <Button onClick={this.toNext} loading={loading} type="default">
          暂不审核
        </Button>
        <CheckBox
          label="继续下一单"
          direction="right"
          checked={nextChecked}
          onChange={val => this.auditSet('nextChecked', val)}
        />
      </div>
    );
  }
  render() {
    const { auditConfig, auditList } = this.state;
    return (
      <div className={cls('')}>
        {auditList.length > 0 && (
          <SlideDrager
            popName="SlideDrager"
            isShow
            dragerContainer={this.props.popContainer}
            slideWidth={750}
            // eslint-disable-next-line no-return-assign
            ref={ref => (this._slideDrager = ref)}
            close={this.close}
            HeaderTitle={auditConfig.getTitle(auditList[0])}
            contentDiv={this.renderContent()}
            footerDiv={this.renderFooter()}
          />
        )}
      </div>
    );
  }
}
export default AuditOneByOne;
