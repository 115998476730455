import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { ListItem } from 'components';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { TYPESET_CARDS } from 'constants';

let _dragStartIndex = 0;
const cardSource = {
  beginDrag(props) {
    _dragStartIndex = props.index;
    let selectedList = props.selectedList();
    const { uniqueKey } = props;
    let isSelected = true;
    let newDragedItems = [];
    if (!selectedList.length || !selectedList.find(item => item[uniqueKey] === props.id)) {
      // 无选中项 或 当前拖拽项 不在选中项里
      selectedList = [props.data];
      isSelected = false; // 多个移动时都是选中的， 单个移动时单独传是否勾选
    }
    !newDragedItems.length && (newDragedItems = selectedList.filter(item => !item.color));
    // props.onDragStart && props.onDragStart(selectedList)
    return {
      id: props.id,
      index: props.index,
      uniqueKey,
      selectedList,
      newDragedItems,
      dragItemProps: { ...props, isSelected },
    };
  },
  endDrag(props, monitor) {
    if (!monitor.didDrop()) return;
    const dragItem = monitor.getItem();
    const dropIndex = dragItem.index;
    const { selectedList } = dragItem;
    // const dropIndex = monitor.getDropResult().index
    if (_dragStartIndex === dropIndex) return;
    props.onDrop(_dragStartIndex, dropIndex, selectedList, dragItem.newDragedItems);
  },
  isDragging(props, monitor) {
    const dragItem = monitor.getItem();
    const { selectedList } = dragItem;
    return !!selectedList.find(item => item[dragItem.uniqueKey] === props.id);
    // return monitor.getItem().index === props.index
  },
  canDrag(props, monitor) {
    const result = props?.handleItemDragAble(props);
    return result;
  },
};
const cardTarget = {
  hover(props, monitor, component) {
    const dragItem = monitor.getItem();
    const dragIndex = dragItem.index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) return;
    // 尺寸
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect(); // eslint-disable-line
    // 竖直中部
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    // 鼠标位置
    const clientOffset = monitor.getClientOffset();
    // 距上高度
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    // 下
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
    // 上
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
    props.onMove(dragIndex, hoverIndex, dragItem.selectedList);
    dragItem.index = hoverIndex; // eslint-disable-line
  },
};
@DropTarget(TYPESET_CARDS, cardTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource(TYPESET_CARDS, cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  // You can ask the monitor about the current drag preview
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export class dragableBaseOpation extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.any,
    onMove: PropTypes.func,
    onDrop: PropTypes.func,
    onDragStart: PropTypes.func,
    getRef: PropTypes.func,
    connectDragPreview: PropTypes.func,
    selectedList: PropTypes.func,
  };

  componentDidMount() {
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    this.props.connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true,
    });
  }

  render() {
    const { connectDragSource, connectDropTarget, isDragging, connectDragPreview, ...rest } = this.props;
    const style = isDragging ? { border: '1px dashed #0fb2ef', height: 26 } : {};
    return connectDragSource(
      connectDropTarget(
        <div className={`drag-wraper${isDragging ? '-draging' : ''}`} style={{ ...style, cursor: 'move' }}>
          <ListItem {...rest} getRef={r => this.props.getRef && this.props.getRef(r)} />
        </div>,
      ),
    );
  }
}
