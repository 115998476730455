import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import classnames from 'classnames';
import { PopUp, ModalDialog, Label, Input, Button, LodopFunc, ShowInfo } from 'components';
import actions from 'actions';
import { ERROR, CHECK, CO_EMPTY_CHECK_TIPS, WARN } from 'constants';
import {
  fetchApi,
  typeIs,
  initPath as utilInitPath,
  showCheckFeeTips,
  formatObjectToArray,
  showInfo,
  opTips,
  ot,
  proxyListPage as proxyListPageUtil,
  filterEmoji,
  getPrintComId,
  formatCamelCase,
} from 'utils';
import printSlnDialog from 'components/commoncomponents/printSlnDialog';
import { corKeys, ceeKeys, memberKeys, goodsKeys, OUTER_TRANS, POINT_TRANS, INNER_TRANS, tableKeys } from './constant';
import round from './public/round';
import calcField from './public/calcField';
import calcFreight from './public/calcFreight';
import calcMaxFreight from './public/calcMaxFreight';

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const goodsPath = goodsKeys.reduce((allSet, key) => ({ ...allSet, [`${key}_1`]: 1 }));

export const showOfSetting = setting => key => setting[key] && setting[key].show;
export const requiredOfSetting = setting => key => setting[key] && setting[key].required;

export const required = bool => (bool ? ' r' : '');

export const getErrorMsg = res => {
  let msg = `${res.errmsg}`;
  const data = res.res;
  if (data && data.failed_detail && data.failed_detail[0] && data.failed_detail[0].msg) {
    msg = `${msg}，${data.failed_detail[0].msg}`;
  }
  return msg;
};

export { round, calcField, calcFreight, calcMaxFreight };

// 此函数内部 注释前面有+++的请勿删除， 单价转换备用
export const convertWeight = (goods, reverse = false) =>
  goods.map(item => {
    const { weight } = item;
    const practicalW = item.practical_w;
    const unitPVal = item.unit_p;
    const unitP = item.unit_p_unit;
    const weightPerNum = item.weight_per_num;
    const weightUnitPrice = item.weight_unit_price;
    const newRow = { ...item };
    if (+weight) newRow.weight = reverse ? +(weight * 1000).toFixed(3) : +(weight / 1000).toFixed(6);
    if (+practicalW) newRow.practical_w = reverse ? +(practicalW * 1000).toFixed(3) : +(practicalW / 1000).toFixed(6);
    if (+weightPerNum)
      newRow.weight_per_num = reverse ? +(weightPerNum * 1000).toFixed(3) : +(weightPerNum / 1000).toFixed(6);
    if (unitP === 'per_w' && +unitPVal)
      newRow.unit_p = reverse ? +(+unitPVal / 1000).toFixed(9) : +(+unitPVal * 1000).toFixed(6);
    if (+weightUnitPrice)
      newRow.weight_unit_price = reverse
        ? +(+weightUnitPrice / 1000).toFixed(9)
        : +(+weightUnitPrice * 1000).toFixed(6);
    return newRow;
  });

export const switchWeight = (weight = '', reverse = false) => {
  const newVal = reverse ? +(weight * 1000).toFixed(3) : +(weight / 1000).toFixed(6);
  return newVal || '';
};

export const weightKG = (weight, weightUnit) => (weightUnit === '吨' ? switchWeight(weight, true) : weight);

export function getDistance(p1, p2, mileRatio = 1) {
  return new Promise(resolve =>
    window.calDistance(p1, p2).then(res => resolve(+((res.distance * mileRatio) / 1000).toFixed(3) || 0)),
  );
}

export function getDistanceByKeyword(p1, p2, city, mileRatio = 1) {
  return new Promise(resolve =>
    window.calDistance(p1, p2, city).then(res => resolve(+((res.distance * mileRatio) / 1000).toFixed(3) || 0)),
  );
}

export const initPath = (rootElement, pathSetting = [], needSkipGoods) => {
  const corCeeKeys = { ...corKeys, ...memberKeys, ...ceeKeys };
  return utilInitPath({ rootElement, pathSetting, needSkipGoods, corCeeKeys, goodsPath });
};

export const userInputPrintCount = (type, defaultCount, printTagSerial) =>
  new Promise(resovle => {
    let count = defaultCount || '';
    let serial = 1;
    let position = 1;
    let modal;
    const isMultiTag = type === 'multiple_tag';
    const save = () => {
      if (+serial > +count) {
        showInfo(ERROR, `“初始${ot('货物流水号')}” 不能大于 “标签打印个数”！`);
        return false;
      }
      modal.handleHide();
      resovle({ count, serial, position });
    };
    const cancel = () => {
      modal.handleHide();
      resovle(false);
    };
    const content = (
      <div className="co_print_tag_conent">
        <div className="tags_count">
          <Label>标签打印个数: </Label>
          <Input defaultValue={count} onChange={e => (count = e.target.value)} />
        </div>
        {!isMultiTag && printTagSerial && (
          <div className="goods_serial">
            <Label>初始货物流水号: </Label>
            <Input defaultValue={serial} onChange={e => (serial = e.target.value)} />
          </div>
        )}
        {isMultiTag && (
          <div className="goods_serial">
            <Label>初始打印位置: </Label>
            <Input defaultValue={position} onChange={e => (position = e.target.value)} />
          </div>
        )}
      </div>
    );
    const bottom = (
      <div className="co_print_tag_btm">
        <Button type="primary" onClick={save}>
          保存
        </Button>
        <Button onClick={cancel}>取消</Button>
      </div>
    );

    new PopUp(ModalDialog, {
      content,
      bottom,
      isShow: true,
      title: isMultiTag ? '打印多列标签' : '打印标签',
      ref: r => (modal = r),
      popName: 'coPrintDialog',
      contentStyle: { width: '360px', height: '180px', overflow: 'auto' },
      closableCallback: cancel,
    }).show();
  });

export const getPrintInfo = (printerHostId, printerHostName, basePrintItem, odLinkId, printFn, groupId, comId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async resolve => {
    const printItem = { ...basePrintItem };
    if (printItem.key === 'factory_tag') {
      // 管货宝的运单打印需要这样处理下
      const factoryTagTpl = await fetchApi('/Basic/Print/getFactoryTagTpl', {
        method: 'POST',
        body: {
          req: {
            group_id: groupId,
          },
        },
      });
      if (factoryTagTpl.errno === 0) {
        printItem.tpl_key = factoryTagTpl.res.tpl_id;
      } else {
        showInfo(ERROR, factoryTagTpl.errmsg);
        return false;
      }
    }
    actions.orderServer
      .getPrintInfo({
        cid: printerHostId,
        tpl_id: printItem.key,
        fields_type: printItem.type,
        ids: [odLinkId],
        group_id: groupId,
        company_id: getPrintComId(comId),
      })
      .then(res => {
        const { data } = res;
        if (!data || data.errno < 0 || _.get(data, 'res.e_data.failed_detail', []).length) {
          const errmsg = _.get(data, 'res.e_data.failed_detail[0].msg', data.errmsg);
          errmsg && showInfo(ERROR, errmsg);
          resolve(false);
          return;
        }
        const tplSetting = data.res.tpl_setting;
        if (+tplSetting.length === 0) {
          showInfo(ERROR, '请创建运单打印模板');
          resolve(false);
          return;
        }
        if (tplSetting.has_sln !== 1) {
          // 是否有打印方案
          actions.orderServer
            .getPrintSln({
              cid: printerHostId,
              tpl_id: printItem.key,
              tpl_type: printItem.type,
              group_id: groupId,
              company_id: comId,
            })
            .then(res1 => {
              const resResult = res1.data.res;
              if (resResult) {
                // resResult.data.print_tpl_name.labelProps.children = printerHostName
                resResult.data.printer.otherProps.data = LodopFunc.getAllPrinters(LodopFunc.getLodop());
              }
              new PopUp(printSlnDialog, {
                dialogKey: 'printSln',
                dialogInfo: resResult || {},
                cid: printerHostId,
                tplId: printItem.key,
                tplType: printItem.type,
                groupId,
                companyId: comId,
                onClose: () => resolve(false),
                closePrintSln: (where, testSlnSet) => {
                  if (where === 'confirm') {
                    if (printFn && testSlnSet) {
                      data.res.tpl_setting = { ...data.res.tpl_setting, ...testSlnSet };
                      printFn(printItem.type, data.res);
                      return;
                    }
                    getPrintInfo(printerHostId, printerHostName, printItem, odLinkId, undefined, groupId, comId).then(
                      result => resolve(result),
                    );
                  } else resolve(false);
                },
              }).show();
            });
        } else {
          resolve(data.res);
        }
      });
  });

export const getPreDate = oBillDate => {
  const curTime = oBillDate.slice(-9);
  const _billDate = localStorage.billDate;
  return _billDate ? `${_billDate}${curTime}` : oBillDate;
};

export const postponeDate = (dateStr, checkSaturday, checkSunday, time, overTime) => {
  const date = typeIs(dateStr, 'string') && dateStr ? moment(dateStr) : dateStr;
  if (!checkSaturday && !checkSunday && !overTime) return date.format('YYYY-MM-DD HH:mm:ss');
  if (overTime) date.add(1, 'day');
  if (checkSaturday && date.day() === 6) date.add(1, 'day');
  if (checkSunday && date.day() === 0) date.add(1, 'day');
  return `${date.format('YYYY-MM-DD')} ${time}`;
};

export const ary2Obj = transDt => {
  const resDt = [];
  transDt.map(item => resDt.push({ key: item, name: item }));
  return resDt;
};

export const checkOrderExisted = val => {
  const conf = { method: 'POST', body: { req: { order_num: val } } };
  return fetchApi('/Order/Order/checkOrderExisted', conf).then(res => {
    if (Object.keys(res.res.order_data).length) {
      return `运单号${val}已存在`;
    }
    return '';
  });
};

export const oNumCheck = (val, inRule, repeatOp, oriVal, usedFor) => {
  const isPass = [];
  const ary = ['char', 'capital', 'lower', 'spec'];
  const unRules = ary.filter(cur => !inRule.includes(cur));

  unRules.forEach(item => {
    if (item === 'char' && new RegExp('[\\u4E00-\\u9FFF]+', 'g').test(val)) {
      isPass.push('汉字');
    }
    if (item === 'capital' && new RegExp('[A-Z]', 'g').test(val)) {
      isPass.push('大写字母');
    }
    if (item === 'lower' && new RegExp('[a-z]', 'g').test(val)) {
      isPass.push('小写字母');
    }
    if (item === 'spec' && new RegExp('[@\'"#$+=\\-%&^*?><;,.`「」【】~!{}\\[\\]()\\uff00-\\uffff]+', 'g').test(val)) {
      isPass.push('空格、逗号、加号、全角字符和不可见字符');
    }
  });

  // 去掉判断条件 oriVal !== val 是因为bug 30192
  // 引入usedFor 是因为bug 31381
  // 去掉pointTrans 是因为bug31979
  if (repeatOp === 'err_tip' && !isPass.length && usedFor !== 'pointTrans') {
    if (['modify', 'updatePre'].includes(usedFor)) {
      if (oriVal !== val) {
        return checkOrderExisted(val);
      }
    } else {
      return checkOrderExisted(val);
    }
  }
  return isPass.length ? `${ot('运单号')}不允许输入${isPass.join('、')}` : '';
};

export const lockShow = value => value.map(item => item.value || item).join(',');

export const proxyListPage = async odLinkId => {
  const url = 'Order/Order/getOrderByOdLinkId';
  const req = { od_link_id: odLinkId };
  const res = await fetchApi(url, { method: 'POST', body: { req } });
  const { enumapi: enumApi, ...rest } = res.res;
  const data = [rest];
  const refEnum = { com_id: 'company_id' };
  return function (page, key) {
    const { comId, buttons, enums } = page.store.getState();
    const refresh = () =>
      page.props?.onSaveSuccess?.(['modify_pre', 'order_delete'].includes(key) ? 'close' : 'refresh');
    const disableParent = (btnKey, disable) => {
      const parent = Object.keys(buttons).find(k => buttons[k].children && buttons[k].children[btnKey]);
      parent && page[disable ? 'beginSave' : 'endSave'](parent, parent);
    };
    if (res.errno !== 0) return null;
    const tab = key === 'Order_sign_single' ? 'pickup_sign' : '';
    const disableMenuButton = btnKey =>
      buttons[btnKey] ? page.beginSave(btnKey, btnKey) : disableParent(btnKey, true);
    const enableMenuButton = btnKey => (buttons[btnKey] ? page.endSave(btnKey, btnKey) : disableParent(btnKey, false));
    return proxyListPageUtil(data, {
      category: 'Order',
      tab,
      refEnum,
      fromPage: page.props.fromPage,
      filterQuery: { com_id: [comId] },
      refresh,
      disableMenuButton,
      enableMenuButton,
      enums,
      enumApi,
    });
  };
};

export const sendMsg = async (typeList, odLinkId) => {
  const url = '/Basic/ShortMessage/send';
  const orderIds = odLinkId ? { orderIds: [odLinkId] } : {};
  const req = { ...orderIds, typeList };
  const conf = { method: 'POST', body: { req } };
  const res = await fetchApi(url, conf);
  if (res.errno === 0) {
    showInfo(CHECK, '成功！');
  } else {
    showInfo(ERROR, res.errmsg);
  }
  return res;
};

export const calcMaintainByUnitPrice = (maintainUnitPrice, weight, volume) => {
  if (!maintainUnitPrice) return 0;
  const [weightUnitPrice, volumeUnitPrice, numUnitPrice] = maintainUnitPrice;
  return +Math.max(+numUnitPrice || 0, weightUnitPrice * weight || 0, volumeUnitPrice * volume || 0).toFixed(2);
};

export const calcMaintainByFormula = (state, formulaKeys, formulaKeyMap, formulaRadioKey) => {
  const formulaRadio = state[formulaRadioKey];
  if (!formulaRadio) {
    return 0;
  }
  const { keyMap } = state;
  const res = Object.keys(formulaKeys).reduce((total, key) => {
    const ratio = formulaKeys[key];
    let value = +state[key] || 0;
    if (!keyMap[key]) {
      const newKey = formulaKeyMap[key];
      // todo
      tableKeys.forEach(tableKey => {
        const tableData = state[tableKey];
        tableData &&
          tableData.forEach(row => {
            if (row[newKey]) value = +row[newKey] || 0;
          });
      });
    }
    return total + ratio * value;
  }, 0);
  return +(res * formulaRadio).toFixed(2);
};

export const invalidTransInfo = (transInfo = [], isModify, state) =>
  transInfo.some(item => {
    const transType = +item.trans_type;
    if (!transType) {
      showInfo(ERROR, `${ot('中转类型')}不得为空`);
      return true;
    }

    if (!item.trans_pay_mode || item.trans_pay_mode === 'pay_none') {
      showInfo(ERROR, `${ot('中转付款方式')}不得为空`);
      return true;
    }
    const { transDeliveryMust, deliveryMode, transFieldSetting } = state;
    if (
      +transType === OUTER_TRANS &&
      transDeliveryMust.includes(deliveryMode) &&
      (item.trans_delivery_f === undefined || item.trans_delivery_f === '' || +item.trans_delivery_f === 0)
    ) {
      showCheckFeeTips('trans_delivery', CO_EMPTY_CHECK_TIPS, `需送货时，[${ot('中转送货费')}]必填！`);
      return true;
    }
    if (!isModify && transType === POINT_TRANS && !item.dn_com_id) {
      showInfo(ERROR, `网点中转${ot('承运网点')}不能为空`);
      return true;
    }
    if (!isModify && transType === INNER_TRANS && !item.dn_mgr_id) {
      showInfo(ERROR, `员工中转${ot('承运经办人')}不能为空`);
      return true;
    }
    if (!isModify && transType === OUTER_TRANS && !item.dn_com_id) {
      showInfo(ERROR, `外部中转${ot('承运公司')}不能为空`);
      return true;
    }
    // 根据中转设置必填做校验
    const fieldSetting = transFieldSetting[transType];
    const transFieldSetMap = {
      remark: 'order_trans_remark',
      arrival: 'address',
    };
    // 网点中转的分摊方式设置在开单中转中不存在
    return formatObjectToArray(fieldSetting, 'key')
      .filter(x => x.required && x.key !== 'fee_ave_type')
      .some(x => {
        let checkKey =
          x.key === 'point_trans_freight_f' || x.key === 'inner_trans_freight_f' ? 'trans_freight_f' : x.key;
        transFieldSetMap[x.key] && (checkKey = transFieldSetMap[x.key]);
        // 中转付款方式为免费时非必填
        if (checkKey === 'trans_freight_f' && item.trans_pay_mode === 'pay_free') {
          return false;
        }
        // 去除中转前缀
        if (x.key.startsWith('point_')) {
          checkKey = x.key.replace('point_', '');
        } else if (x.key.startsWith('inner_')) {
          checkKey = x.key.replace('inner_', '');
        }
        if (!item[checkKey]) {
          showCheckFeeTips(x.key, CO_EMPTY_CHECK_TIPS, undefined, x.label);
          return true;
        }
        return false;
      });
  });

export const buildPrintItem = (allPrintItems, printData, sort) => {
  const sortNew = sort.map(key => key.toString());
  const printItemsShow = Object.entries(allPrintItems).filter(([key, item]) => item.show && printData[key]);
  const printItemsSorted = printItemsShow.sort((a, b) => {
    const indexA = sortNew.indexOf(a[0]);
    const indexB = sortNew.indexOf(b[0]);
    if (indexA < 0 && indexB < 0) {
      return 0;
    } else if (indexA < 0) {
      return 1;
    } else if (indexB < 0) {
      return -1;
    }
    return indexA < indexB ? -1 : 1;
  });

  return printItemsSorted.map(([key, item]) => ({
    ...printData[key],
    checked: item.required,
    title: printData[key].name,
  }));
};

export const isCollectPrint = state => {
  const { isCreate, pointCostInfo, collectPrintFees, keyMap, collectPrintFeesIncludePickupF } = state;
  if (!isCreate) return false;
  if (collectPrintFeesIncludePickupF && pointCostInfo && pointCostInfo[0] && +pointCostInfo[0].pickup_f) return true;
  return collectPrintFees.some(key => +state[keyMap[key]]);
};

export const consignorAlarm = async (state, res) => {
  const { corName, corMobile } = state;
  const data = res.res.order_data;
  const listFilter = (key, val) =>
    val === ''
      ? {
          0: { 0: { id: '_EXIST_FIELD_', [key]: '_EXIST_FIELD_' }, _logic: 'not' },
          [key]: ['', '_NULL_VALUE_'],
          logic: 'or',
        }
      : val;

  const query = {
    [corName === '' ? 0 : 'cor_name']: listFilter('cor_name', corName),
    [corMobile === '' ? +(corName === '') : 'cor_mobile']: listFilter('cor_mobile', corMobile),
  };
  const directData = {
    urlData: {
      controller: 'Finance',
      action: 'consignorRel',
    },
    designateFilter: query,
  };
  const directToDetail = () => {
    const pack = {
      designateFilter: directData.designateFilter,
    };
    const { urlData } = directData;
    urlData.injectProps = pack;
    window.$app.direct(urlData);
  };

  const className = 'consignor-alarm-dialog';

  if (res.errno === 160) {
    // 达到锁机状态
    const content = (
      <div>
        <h4>发货人未结合计超过锁机金额，无法开单！</h4>
        <p>
          历史运单未结算总金额:<span className="strong">{data.account_settle_todo}</span>元
        </p>
        <p>
          本次开单待结算总金额:<span className="strong">{data.diff_sum}</span>元
        </p>
        <p>
          锁机金额:<span className="strong">{data.lock_value}</span>元
        </p>
        {data.update_lock_time && (
          <p>
            锁机值最近修改时间:<span className="strong">{data.update_lock_time}</span>
          </p>
        )}
      </div>
    );
    return await new Promise(resolve =>
      new PopUp(ShowInfo, {
        classname: className,
        type: ERROR,
        content,
        autoDestroy: true,
        title: '锁机提醒',
        popName: 'customer-key',
        onClose: () => resolve(false),
        buttons: [
          {
            name: '关闭',
            btnType: 'primary',
            cb: thisDialog => {
              thisDialog.handleHide();
              resolve(false);
            },
          },
          { name: '查看欠款', btnType: 'default', cb: directToDetail },
        ],
        closable: true,
        maskClosable: true,
      }).show(),
    );
  }

  if (res.errno === 169) {
    // 超过欠款期限
    const content = (
      <div>
        <h4>发货人未结天数超过欠款期限，无法开单！</h4>
        <p>
          未结金额:<span className="strong">{data.account_settle_todo}</span>元
        </p>
        <p>
          未结天数:<span className="strong">{data.account_settle_todo_days}</span>天
        </p>
        <p>
          欠款期限:<span className="strong">{data.todo_days}</span>天
        </p>
        {data.todo_days_up_time && (
          <p>
            欠款期限最近修改时间:<span className="strong">{data.todo_days_up_time}</span>
          </p>
        )}
      </div>
    );
    return await new Promise(resolve =>
      new PopUp(ShowInfo, {
        classname: className,
        type: WARN,
        content,
        autoDestroy: true,
        onClose: () => resolve(false),
        title: '未结超期提醒',
        popName: 'customer-key',
        buttons: [
          {
            name: '关闭',
            btnType: 'primary',
            cb: thisDialog => {
              thisDialog.handleHide();
              resolve(false);
            },
          },
          { name: '查看欠款', btnType: 'default', cb: directToDetail },
        ],
        closable: true,
        maskClosable: true,
      }).show(),
    );
  }

  if (res.errno === 178) {
    // 达到预警状态
    const content = (
      <div>
        <h4>发货人未结合计已超过预警金额！如果达到锁机金额将无法继续开单！请尽快结算!</h4>
        <p>
          历史运单未结算总金额:<span className="strong">{data.account_settle_todo}</span>元
        </p>
        <p>
          本次开单待结算总金额:<span className="strong">{data.diff_sum}</span>元
        </p>
        <p>
          预警金额:<span className="strong">{data.alarm_value}</span>元
        </p>
        <p>
          锁机金额:<span className="strong">{data.lock_value}</span>元
        </p>
        {data.update_lock_time && (
          <p>
            锁机值最近修改时间:<span className="strong">{data.update_lock_time}</span>
          </p>
        )}
      </div>
    );
    return await new Promise(resolve =>
      new PopUp(ShowInfo, {
        classname: className,
        type: WARN,
        content,
        autoDestroy: true,
        onClose: () => resolve(false),
        title: '发货人未结提醒',
        popName: 'customer-key',
        buttons: [
          {
            name: '保存运单',
            btnType: 'primary',
            cb: thisDialog => {
              thisDialog.handleHide();
              resolve(true);
            },
          },
          { name: '查看欠款', btnType: 'default', cb: directToDetail },
        ],
        closable: true,
        maskClosable: true,
      }).show(),
    );
  }

  return true;
};

export const getCeeRelateArrPoint = data =>
  +data.re_arr_point
    ? {
        company_id: data.re_arr_point,
        name: data.rap_name,
        short_name: data.rap_short_name,
        addr: data.rap_addr,
        company_type: data.rap_company_type,
      }
    : '';

export const numSectionCheck = res => {
  const specialCause = res.res.failed_detail;
  delete res.res.failed_detail;
  opTips({
    orderType: '运单',
    showSucTips: false,
    resData: { ...res },
    ext: {
      specialCause,
      specialTips: `${ot('运单号')}或${ot('回单号')}不在分配的号段内，无法保存运单！`,
      failedCallback: () => console.log('号段 failedCallback isCheck='),
    },
  });
};

export const setPerson = data => {
  actions.settingServer.setOrderSet(data, undefined, undefined, 'psn', true);
  Object.assign(window.psn_setting, data);
};

export const AutoWrapLabel = ({ text, className, ...rest }) => {
  if (text.length >= 5) {
    const len = Math.ceil(text.length / 2);
    return (
      <label className={classnames({ [className]: className, 'label--auto-wrap': true })} {...rest}>
        <span>{text.slice(0, len)}</span>
        <span>{text.slice(len)}</span>
      </label>
    );
  }
  return (
    <label className={className} {...rest}>
      {text}
    </label>
  );
};

export const transferPOI = address =>
  address &&
  address.poi &&
  Array.isArray(address.poi) &&
  (address.poi[0] ? (address.poi = address.poi.join(',')) : (address.poi = '')); // eslint-disable-line

export const postString = (value, trim) => {
  if (typeof value !== 'string') return value;
  let newStr = value;
  if (trim) {
    newStr = newStr.trim();
  }
  return filterEmoji(newStr);
};
export const getEnumText = (val, enumData, valKey = 'key', showKey = 'name') =>
  (enumData.find(item => item[valKey] === val) || {})[showKey];

export const isLongKunBlackCor = (black, corName, corMobile) => black[`${corName || ''}___${corMobile || ''}`];

export const formatFormula = formula => {
  const formulaKeys = {};
  const formulaKeyMap = {};
  formula.forEach(f => {
    const ratio = (+f.ratio || 0) * (f.operator === 'minus' ? -1 : 1);
    f.itemList.forEach(k => {
      const CamelKey = formatCamelCase(k);
      const preRatio = formulaKeys[CamelKey] || 0;
      formulaKeyMap[CamelKey] = k;
      formulaKeyMap[k] = CamelKey;
      formulaKeys[CamelKey] = preRatio + ratio;
    });
  });
  return { formulaKeys, formulaKeyMap };
};

export const matchProfitFormula = (formulaSetting = {}, priceMode, isCoPointOrParent, isOpenPayment) => {
  const { formulaDt, tableData } = formulaSetting;
  if (tableData) {
    const [def, ...rest] = tableData;
    if (isOpenPayment) {
      const match = rest.find(
        item =>
          (!item.is_order_point ||
            (+item.is_order_point === 1 && isCoPointOrParent) ||
            (+item.is_order_point === 2 && !isCoPointOrParent)) &&
          (!item.price_mode || !item.price_mode.length || item.price_mode.some(id => +id === +priceMode)),
      );
      if (match) {
        return match.formulaDt;
      }
    }
    return def.formulaDt;
  }
  return formulaDt || [];
};

export const validateReceiptN = val => (+val > 0 ? '' : '您已设置回单数必填，只能填写大于0的整数');
