/**
 * 使用： TMS 车辆配载
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { prefixCls } from './index.scss';
import { SelectAddr, Icon, PopUp, PopTip } from 'components';
import { filterListNotEquKey, typeIs } from 'utils';
import { ERROR } from 'constants';

export default class Route extends Component {
  static defaultProps = {
    uniqueKey: 'id',
    showKey: 'value',
    needBorder: true,
    disabled: false,
    value: [],
    defaultSelect: [],
    activeTabPane: ['6'], // 6组织、7高德地址、8四级地址
    canOpForOther: false, // 提其他网点操作的权限，控制第一个节点
  };
  static propTypes = {
    disabled: PropTypes.bool,
    needBorder: PropTypes.bool,
    classname: PropTypes.string,
    defaultSelect: PropTypes.any,
    value: PropTypes.array,
    children: PropTypes.any,
    pointInfo: PropTypes.array,
    showKey: PropTypes.string,
    uniqueKey: PropTypes.string,
    onChange: PropTypes.func,
    showColon: PropTypes.bool,
    activeTabPane: PropTypes.array,
  };

  constructor(prop) {
    super(prop);
    this.state = {
      value: [],
      selectItem: '-1',
    };
  }

  handleResetState = () => {
    this.setState({
      value: [],
      selectItem: '-1',
    });
  };
  dealData = (data = []) => {
    const newData = [];
    const { showKey } = this.props;
    data.forEach(item => {
      const newItem = Object.assign({}, item);
      newItem[`${showKey}_show`] = item[showKey];
      newData.push(newItem);
    });
    return newData;
  };

  UNSAFE_componentWillMount() {
    this.setState({
      value: [...this.props.value, { company_id: '-1' }],
      pointInfo: this.dealData(this.props.pointInfo),
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    // 当前选中的第几个发生变化，不进行重新渲染
    if (this.state.selectItem !== nextState.selectItem) return false;
    return true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      const value = [...nextProps.value];
      const flag = value.some(t => +t.company_id === -1);
      if (+this.props.readOnly !== 1 && !flag) {
        value.push({ company_id: '-1' });
      }
      this.setState({ value });
      if (nextProps.value && nextProps.value.length === 1) {
        this.setState({
          pointInfo: this.dealData(nextProps.pointInfo),
        });
      }
    }
  }

  // 添加一个路由节点 只生成一个待输入的路由
  addRoadItem = () => {
    console.log('addRoadItem');
    // 防止上一个路由的blur动作处理未完成
    setTimeout(() => {
      const newItem = {};
      const key = this.props.uniqueKey;
      const { showKey } = this.props;
      const lastIndex = this.state.value.length - 1;
      if (this.state.value[lastIndex][key] === '-1') {
        this.setState({
          selectItem: '-1',
        });
        return false;
      }
      let defaultSelectItem = this.props.defaultSelect ? this.props.defaultSelect : -1;
      // eslint-disable-next-line react/no-access-state-in-setstate
      let newRoadItem = this.state.value;
      newRoadItem = newRoadItem.filter(x => x !== undefined);
      // 如果当前元素的默认值已经加入了，则下一个默认-1
      if (defaultSelectItem > 0) {
        for (const props of this.state.value) {
          if (props[key] === `${defaultSelectItem}`) {
            defaultSelectItem = -1;
          }
        }
      }
      newItem[key] = `${defaultSelectItem}`;
      if (defaultSelectItem > 0) {
        const { pointInfo } = this.state;
        for (const pi of pointInfo) {
          if (pi[key] === defaultSelectItem) {
            newItem[showKey] = pi[showKey];
          }
        }
      }
      newRoadItem.push(newItem);
      this.setState({
        value: newRoadItem,
        selectItem: `${defaultSelectItem}`,
      });
      // 此处不加 callback  因为 值未真正发生变化
      if (defaultSelectItem > 0) {
        const callBack = this.props.onChange;
        if (typeof callBack === 'function') {
          callBack(newRoadItem);
        }
      }
      const { setCalcMile } = this.props;
      if (typeof setCalcMile !== 'function') return;
      if (newRoadItem.length > 1) {
        this.calcDistance(newRoadItem);
      }
    }, 100);
  };
  /**
   
   * @desc:    {[description]}
   * @param    {[type]}        newValue  [description]
   * @param    {[type]}        valueList [description]
   * @param    {[type]}        key       [当前修改的路由值]
   * @return   {[type]}                  [description]
   */
  checkDuplicateRoute = (newValue, valueList, key) => {
    if (newValue === '' || +newValue.node_type === 7 || +newValue.node_type === 8) {
      return false;
    }
    const { uniqueKey } = this.props;
    const id = newValue[uniqueKey];
    let flag = false;
    valueList.forEach((item, index) => {
      if (index !== Number(key) && id === item[uniqueKey]) flag = true;
    });
    return flag;
  };
  isNode = (item, index) => {
    if (item.node_address && item.node_address.poi) {
      return { poi: item.node_address.poi, index };
    }
    if (item.nodeAddress && item.nodeAddress.poi) {
      return { poi: item.nodeAddress.poi, index };
    }
    return false;
  };
  getDistance = async (val, curPath) => {
    let _line = val;
    return await window.calDistance(curPath).then(({ distance, duration }) => (_line += Math.round(distance / 1000)));
  };
  calcDistance = async (nodes, force) => {
    const _self = this;
    const path = nodes.map((item, index) => _self.isNode(item, index)).filter(item => item && item.poi);
    let _line = 0;
    if (path.length > 1) {
      for (let i = 0; i < path.length; i++) {
        const nextItem = path[i + 1];
        if (nextItem) {
          const curPath = [path[i].poi, nextItem.poi];
          _line = await this.getDistance(_line, curPath);
        }
      }
    }
    this.props.setCalcMile(_line);
    return _line;
  };

  /* 输入发生变化时，改变数据 */
  handleSelected = (key, newValue) => {
    const { uniqueKey } = this.props;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newRoadItem = this.state.value;
    if (
      newValue === false ||
      (typeIs(newValue, 'array') && newValue[0][uniqueKey] === '-1') ||
      (typeIs(newValue, 'object') && newValue[uniqueKey] === '-1')
    ) {
      this.deleteRoadItem(key);
      return false;
    }
    // eslint-disable-next-line react/no-access-state-in-setstate
    const pointInfo = filterListNotEquKey(this.state.pointInfo, uniqueKey, [newValue[uniqueKey]]);
    // 因为在可选的下拉中已经剔除掉已选择的网点， 所以基本不存在已选的
    if (this.checkDuplicateRoute(newValue, newRoadItem, key)) {
      const popup = new PopUp(PopTip, {
        type: ERROR,
        content: '当前路由已经存在，请重新选择',
      });
      popup.show();
      if (this[`select_${key}`].select) {
        this[`select_${key}`].select.input.value = '';
        this[`select_${key}`].select.input.focus();
      }
      return false;
    }
    // 如果返回值的uniqueKey为空，不处理
    if (typeIs(newValue, 'string')) return;
    newRoadItem[key] = Object.assign({}, newValue);
    this.setState({
      value: newRoadItem,
      pointInfo,
    });
    const callBack = this.props.onChange;
    if (typeof callBack === 'function') {
      callBack(newRoadItem);
    }
    this.addRoadItem();
    const { setCalcMile } = this.props;
    if (typeof setCalcMile !== 'function') return;
    if (newRoadItem.length > 1) {
      this.calcDistance(newRoadItem);
    }
  };
  /*
   * 删除一个节点
   */
  deleteRoadItem = key => {
    console.log('deleteRoadItem');
    // eslint-disable-next-line react/no-access-state-in-setstate
    let newRoadItem = this.state.value;
    const { pointInfo } = this.state;
    // 将删除的网点重新添加到可选网点中
    const newPoint = Object.assign({}, newRoadItem[key]);
    const { uniqueKey } = this.props;
    // 此为拼的空的网点
    if (newPoint[uniqueKey] !== '-1' && newPoint[uniqueKey] !== '') {
      let inPointList = false;
      for (const point of pointInfo) {
        // 没有在列表里面的才继续加回去
        if (point[uniqueKey] === newPoint[uniqueKey]) {
          inPointList = true;
          break;
        }
      }
      if (!inPointList) {
        pointInfo.push(newPoint);
      }
    }

    newRoadItem[key] = undefined;
    newRoadItem = newRoadItem.filter(x => x !== undefined);
    newRoadItem = newRoadItem.filter(x => +x.company_id !== -1);
    if (newRoadItem.length === 0) {
      newRoadItem[0] = { company_id: -1 };
    }
    console.log(newRoadItem);
    this.setState({
      value: newRoadItem,
      pointInfo,
    });
    const callBack = this.props.onChange;
    if (typeof callBack === 'function') {
      callBack(newRoadItem);
    }
    const { setCalcMile } = this.props;
    if (typeof setCalcMile !== 'function') return;
    if (newRoadItem.length > 1) {
      this.calcDistance(newRoadItem);
    } else {
      setCalcMile('');
    }
  };
  handleInputClick = key => {
    this.inputFocus(key);
    if (key !== this.state.selectItem) {
      this.setState({
        selectItem: key,
      });
    }
  };
  onChange = (key, newValue) => {
    console.log('onChange');
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newRoadItem = this.state.value;
    const { uniqueKey } = this.props;
    const { showKey } = this.props;
    newRoadItem[key] = Object.assign({}, { [uniqueKey]: -1, [showKey]: newValue });
    this.setState({
      value: newRoadItem,
    });
  };
  inputFocus = key => {
    const roadItem = this.state.value || [];
    // eslint-disable-next-line react/no-access-state-in-setstate
    let pointInfo = this.state.pointInfo || [];
    const { uniqueKey } = this.props;
    if (roadItem[key] && +roadItem[key][uniqueKey] !== -1) {
      pointInfo = pointInfo.filter(x => x[uniqueKey] !== roadItem[key][uniqueKey]);
      pointInfo = [roadItem[key], ...pointInfo];
    }
    this.setState({ pointInfo });
  };
  deleIcon = (key, props, i) => {
    if (this.props.firstNodeDisabled && !i) return null;
    if (+props.company_id === -1) return null;
    return (
      <Icon
        key={`dele_icon_${key}`}
        iconType="icon-error-o"
        classname={`${prefixCls}__dele_icon`}
        onClick={(...param) => this.deleteRoadItem(key, ...param)}
      />
    );
  };
  renderItem = () => {
    const itemClass = `${prefixCls}__item`;
    const iconStr = <Icon iconType="icon-arrow-mright" />;
    const new_value = this.state.value.filter(t => t && (t.node_type || t.company_id));
    const len = new_value.length;
    const { firstNodeDisabled, canOpForOther, activeTabPane } = this.props;
    return Object.entries(new_value).map(([key, props], i) => (
      <div className={itemClass} key={`div${key}`}>
        <SelectAddr
          data-path={+props.company_id === -1 ? 'route' : undefined}
          value={props}
          index={i}
          disabled={firstNodeDisabled && !i}
          opForOther={canOpForOther}
          selectNode={new_value}
          activeTabPane={activeTabPane}
          onChange={val => this.handleSelected(key, val)}
          ref={r => (this[`select_${key}`] = r)}
        />
        {this.deleIcon(key, props, i)}
        {Number(key) + 1 !== len && iconStr}
      </div>
    ));
  };

  render() {
    const { needBorder, disabled } = this.props;
    let baseClass = prefixCls;
    if (needBorder) {
      baseClass = `${prefixCls} ${prefixCls}__border`;
    }
    const classes = classnames(baseClass, this.props.classname);
    return (
      <div className={classes} ref={roadWrap => (this.roadWrap = roadWrap)}>
        <div className={`${prefixCls} ${prefixCls}__item_wrap`}>
          <div className={`${prefixCls}__item_con`}>{this.renderItem()}</div>
        </div>
      </div>
    );
  }
}
