/**
 * description: 车线管理的工具文件
 
 
 */

import { hasPermission, formatCamelCase, formatFenceData } from 'utils';
import _ from 'lodash';

// 打开帮助中心
export const redirectToHelpCenter = (controller, menuId) => {
  window.$app.direct({
    controller: 'CustomService',
    action: 'helpCenter',
    query: {
      tabName: '帮助中心',
    },
    injectProps: {
      controller,
      searchFilter: {
        menuId,
      },
    },
  });
};

/**
 * 每个卡片折叠展开的状态处理
 * @param cardState 卡片的状态
 * @param cardName 卡片名称
 * @returns  class string
 */
export const getCardHeaderClass = (cardState, cardName) => {
  const stateClass = `card-head ${cardState ? 'turn' : ''}`;
  const nameClass = `${cardName || ''}`;
  return `${stateClass} ${nameClass}`;
};

/**
 * 每个卡片折叠展开的状态处理
 * @param cardState 卡片的状态
 * @param cardName 卡片名称
 * @returns  class string
 */
export const getCardBodyClass = (cardState, cardName) =>
  `card-body ${cardState ? 'card-body-fold' : ''} card-body-${cardName}`;

export const orgFetchApiFormate = res => {
  let arr = (res.res && res.res) || [];
  if (arr.length) {
    arr = arr.map(item => {
      const iitem = item;
      iitem.address_show_val = (iitem.address && iitem.address.show_val) || '';
      iitem.address_poi = (iitem.address && iitem.address.poi) || '';
      const fence = formatFenceData(item.fence || []);
      iitem.fence_shape = fence.shape || '';
      iitem.fence_radius = fence.radius || '';
      iitem.fence_extra = fence.extra || '';
      return iitem;
    });
  }
  return {
    reqData: arr,
  };
};

const getShowRequiredFunc = setting => {
  const show = {};
  const required = {};
  const className = {};
  Object.keys(setting).forEach(key => {
    const item = setting[key];
    show[formatCamelCase(`${key}_show`)] = item.show;
    required[formatCamelCase(`${key}_required`)] = item.required;
    className[formatCamelCase(`${key}_class`)] = item.required ? `${key} red` : key;
  });

  return { show, required, className };
};

// 字段显示必填处理逻辑
export const handleFieldSet = () => {
  const lineFieldSet = _.get(window.company_setting, 'line_add_field.line_add_field', {});
  const formatSet = getShowRequiredFunc(lineFieldSet);

  const { show, required, className } = formatSet;
  const { fenceRadiusShow, fenceExtraShow } = show;

  return {
    className,
    required,
    show: {
      ...show,
      fenceRadiusShow: fenceRadiusShow && hasPermission(['fenceset']), // 围栏半径
      fenceExtraShow: fenceExtraShow && hasPermission(['fenceset']), // 外延长度
    },
  };
};
