/* eslint-disable */

/**
 * 结算记录
 */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import Amap from 'components/utilcomponents/mapAddon/Amap';
import { isEmptyObj, showInfo, fetchApi as fetch } from 'utils';
import { ERROR } from 'constants';
import actions from 'actions';
import { PopUp, ModalDialog, Button } from 'components';
import './index.scss';
// 收获地址、目的网点匹配的错误码
const CEE_ERRNO = {
  // 匹配
  MATCH: 0,
  // 不匹配
  NOT_MATCH: 93,
  // 不能匹配
  CAN_NOT_MATCH: 92,
};
// 收获地址、目的网点匹配的提示信息
const CEE_TIPS = {
  // 系统无法识别
  CAN_NOT_RECOGNIZE: '收货地址不存在或不够精确，系统无法识别，请更新收货地址，从下拉里选择可定位地址',
  // 不匹配
  NOT_MATCH: '当前收货地址不在目的网点派送范围内，是否更换目的网点？',
  // 不能匹配
  CAN_NOT_MATCH: '收货地址不在任何网点的服务范围内，请更换收货地址或手动选择目的网点',
};

export const operateType = {
  add_cee_addr: 0,
  add_more_arr_point: 2,
};

export default class Map extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    cityName: PropTypes.string,
    style: PropTypes.object,
    showArrPoint: PropTypes.bool,
    arrPoint: PropTypes.any,
    ceeAddr: PropTypes.any,
    ceeMatchArr: PropTypes.bool,
    // 匹配目的网点自定义事件对象
    onMatch: PropTypes.func,
    // 公司id
    companyId: PropTypes.number,
  };
  static defaultProps = {
    width: 398,
    height: 300,
    cityName: '北京市',
    showArrPoint: true,
    ceeMatchArr: true,
  };
  constructor(prop) {
    super(prop);
    // 当前实例地图
    this.aMap = null;
    // 收货地址 参数保存
    this.ceeAddr = null;
    // 目的网点 参数保存
    this.arrPoint = {};
    // 状态保存
    this.state = {
      // 目的网点描述信息
      arrPointDesp: {},
    };
    // 目的网点marker数组
    this.pointMarkerArray = [];
    // 收货地址marker
    this.ceeAddrMarker = null;
    // 从哪个输入框触发的改变   arrPoint (目的网点) 或 ceeAddr (收货地址) 或 program (程序，无切换限制)
    this.changeFrom = '';
    // 在地图中点击的哪个目的网点，防止匹配进入死循环
    this.pointClickFromMap = {};
    // 模拟请求abort机制
    this.requestKey = +new Date();
    // 当前是否处于显示弹框遮罩状态，这种状态下，不要重复匹配了，有可能造成页面错乱
    this.popUpIsShow = false;

    prop.arrPoint && this.saveParams('arrPoint', prop.arrPoint);
    prop.ceeAddr && this.saveParams('ceeAddr', prop.ceeAddr);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let needMatch = false;
    if (nextProps.arrPoint !== this.props.arrPoint) {
      this.saveParams('arrPoint', nextProps.arrPoint);
      this.renderArrPoint();
      needMatch = true;
    }
    if (nextProps.ceeAddr !== this.props.ceeAddr) {
      this.saveParams('ceeAddr', nextProps.ceeAddr);
      needMatch = true;
    }
    needMatch && this.checkCeeMatchArr();
  }
  render() {
    let { style } = this.props;
    if (!this.props.showArrPoint && !this.props.ceeMatchArr) {
      return null;
    }
    return (
      <div className={'arr-point-map'} style={style}>
        <div className={'map'}>
          <Amap.MapShow width={this.props.width} height={this.props.height} onMapLoaded={this.declareMap} />
        </div>
        {this.renderMapPointInfo()}
      </div>
    );
  }

  joinDispatchZone = zones => {
    // 网点派送区域
    if (!zones || zones.length === 0) {
      return '';
    }
    let zonesStr = zones
      .map(item => {
        let retStr = (item && item.show_val) || '';
        return retStr;
      })
      .join(' ');
    return zonesStr;
  };
  declareMap = aMap => {
    // 声明地图
    if (aMap) {
      this.aMap = aMap;
      // 禁用地图
      this.aMap.setStatus({
        animateEnable: false,
      });
      this.props.arrPoint && this.renderArrPoint();
    }
  };

  // 渲染目的网点
  renderArrPoint = () => {
    if (!this.aMap) {
      return;
    }
    let companyId = this.arrPoint.company_id;
    if (companyId) {
      this.getCompanyInfo(companyId).then(data => {
        let dispatchZone = data ? data.dispatch_zone : [];
        if (!Array.isArray(dispatchZone)) {
          dispatchZone = [];
        }
        dispatchZone = dispatchZone.map(item => item.show_val).join(' ');
        let arrPointDesp = {
          shortName: data.short_name,
          dispatchZone,
          address: data.address && data.address.show_val,
          address_remark: data.address_remark,
          phone: data.phone,
        };
        this.setState({ arrPointDesp });
        this.drawDistrictArea(data.dispatch_zone);
        if (!this.arrPoint.addr && data.address) {
          this.arrPoint = { ...this.arrPoint, addr: data.address };
          this.addOneArrMarker();
        }
      });
    } else {
      let arrPointDesp = {};
      this.setState({ arrPointDesp });
    }
    this.arrPoint.addr && this.addOneArrMarker();
  };
  // 获取组织结构信息
  getCompanyInfo = comId => {
    let promise = new Promise(resolve => {
      let param = {
        req: {
          company_id: comId,
          type: 'Company',
          readonly: true,
        },
        raw: true,
      };
      actions.companyServer.getOrgInfo(param).then(res => {
        let resData = res.data || {};
        if (res.type === 'ORG_INFO_SUCCESS' && resData.errno === 0) {
          resolve(resData.res && resData.res.org_info);
        } else {
          showInfo(ERROR, res.message || resData.errmsg || '网络错误');
        }
      });
    });
    return promise;
  };
  renderMapPointInfo = () => {
    // 地图下面的网点信息
    let mapPointInfo = this.state.arrPointDesp;
    // if (isEmptyObj(mapPointInfo)) {
    //   return null
    // }
    return (
      <div className="point_map_info">
        <h2>
          <em>目的网点：</em>
          {mapPointInfo.shortName}
        </h2>
        <div>
          <p className="single">
            <i className="fn-icon fn-icon-location" />
            <em>地址：</em>
            <span>{mapPointInfo.address}</span>
          </p>
          <p className="single">
            <i className="fn-icon fn-icon-bianji2" />
            <em>地址备注：</em>
            <span>{mapPointInfo.address_remark}</span>
          </p>
          <p className="single">
            <i className="fn-icon fn-icon-phone-line" />
            <em>电话：</em>
            <span>{mapPointInfo.phone}</span>
          </p>
          <h5>派送区域：</h5>
          <p>
            <span>{mapPointInfo.dispatchZone}</span>
          </p>
        </div>
      </div>
    );
  };
  addMarker = (info, opts) => {
    // 添加标记
    if (!this.aMap) {
      return null;
    }
    let defaultOpt = {
      color: 'red',
      index: 1,
      type: 'arr_point',
    };
    let options = Object.assign(defaultOpt, opts);
    if (!info || !info.poi || info.poi.indexOf(',') < 1) {
      return null;
    }
    let content = `<div class="amap-marker">
                      <div class="amap-marker-content" style="opacity: 1;">
                          <div class="amap-simple-marker amap-simple-marker-def-style amap-simple-marker-style-${
                            options.color
                          }">
                              <div class="amap-simple-marker-icon"></div>
                              <div class="amap-simple-marker-label" style="color: #fff;">${options.index}</div>
                          </div>
                      </div>
                      <div class="amap-marker-label marker_label_${options.color}" style="bottom: 30px; left: 0;">${
      info.short_name || info.company_name || info.show_val
    }</div>
                  </div>`;
    let lnglat = info.poi.split(',');
    let marker = new window.AMap.Marker({
      content,
      offset: new window.AMap.Pixel(-12, -30),
      position: new window.AMap.LngLat(lnglat[0], lnglat[1]),
    });
    marker.setMap(this.aMap);
    return marker;
  };
  // 单个目的网点绘制
  addOneArrMarker = () => {
    if (!this.aMap) {
      setTimeout(this.addOneArrMarker, 2000);
      return;
    }
    if (isEmptyObj(this.arrPoint) && isEmptyObj(this.ceeAddr)) {
      this.aMap.setCity(this.props.cityName);
      return;
    }
    if (isEmptyObj(this.arrPoint)) {
      return;
    }
    let markerInfo = {
      poi: this.arrPoint.addr.poi,
      short_name: this.arrPoint.short_name,
    };
    let marker = this.addMarker(markerInfo, { color: 'red' });
    this.pointMarkerArray.push(marker);
    this.aMap.setFitView();
  };
  clickRecommendArrMarker = arrPoint => {
    this.changeFrom = 'program';
    this.clearMap();
    this.addCeeAddrMarker();
    this.getRouteAndChangeArrPoint(arrPoint);
    this.pointClickFromMap = arrPoint;
    this.aMap.setFitView();
  };
  addRecommendArrMarker = arrPoint => {
    if (isEmptyObj(arrPoint)) {
      return;
    }
    let markerInfo = {
      poi: arrPoint.poi,
      short_name: arrPoint.company_name,
    };
    let marker = this.addMarker(markerInfo, { color: 'red', index: 2 });
    if (marker) {
      marker.on('click', this.clickRecommendArrMarker.bind(this, arrPoint));
    }
    this.pointMarkerArray.push(marker);
    this.aMap.setFitView();
    this.drawDistrictArea(arrPoint.dispatch_zone);
  };
  // 收货地址绘制
  addCeeAddrMarker = () => {
    this.ceeAddrMarker = this.addMarker(this.ceeAddr, { color: 'blue', type: 'cee_addr' });
    this.aMap.setFitView();
  };
  // 多个目的网点绘制
  batchAddPointMarker = List => {
    let list = this.formatBatchPointMarkers(List);
    list.forEach((item, i) => {
      let marker = this.addMarker(item, { color: 'red', index: i + 1 });
      if (marker) {
        marker.on('click', this.pointClick.bind(this, item, marker));
      }
      this.pointMarkerArray.push(marker);
    });
    this.aMap.setFitView();
    list.forEach(item => {
      this.drawDistrictArea(item.dispatch_zone);
    });
    this.setState({
      arrPointDesp: {},
    });
  };
  clearMap = () => {
    this.aMap.clearMap();
    this.pointMarkerArray = [];
    this.ceeAddrMarker = null;
  };
  pointClick = (item, marker) => {
    if (!item) {
      return;
    }
    this.pointMarkerArray.forEach(one => {
      if (one !== marker) {
        one.setMap(null);
      }
    });
    this.pointMarkerArray = [marker];
    this.changeFrom = 'program';
    this.aMap.setFitView();
    this.getRouteAndChangeArrPoint(item);
    this.pointClickFromMap = item;
  };
  saveParams = (paramKey, paramValue) => {
    if (paramKey === 'ceeAddr' || paramKey === 'arrPoint') {
      this[paramKey] = paramValue;
      this.changeFrom = paramKey;
    }
    // 如果改变的不是目的网点，并且目的网点的的company_id与地图中点击的company_id不等，则重置 pointClickFromMap
    if (
      !(
        paramKey === 'arrPoint' &&
        !isEmptyObj(paramValue) &&
        !isEmptyObj(this.pointClickFromMap) &&
        paramValue.company_id === this.pointClickFromMap.company_id
      )
    ) {
      this.pointClickFromMap = {};
    }
  };
  // 获取路由信息,并更换目的网点
  getRouteAndChangeArrPoint = arrInfo => {
    if (this.changeFrom === 'arrPoint') {
      return;
    }
    let { companyId } = this.props;
    let req = {
      type: 'end',
      start_pid: companyId,
      slice_num: 1000,
    };
    const url = '/Basic/Sug/pointSug';
    const conf = { method: 'POST', body: { req } };
    fetch(url, conf).then(res => {
      let routes = res.res;
      if (!Array.isArray(routes)) {
        routes = [];
      }
      // 从路由列表中获取company_id与arrInfo的company_id相同的
      routes = routes.filter(item => item.company_id === arrInfo.company_id);
      if (routes.length === 1) {
        this.props.onMatch && this.props.onMatch(routes[0]);
        // 保存arrPoint
        this.arrPoint = routes[0];
        // 获取组织结构信息，显示电话，派送区域
        this.renderArrPoint();
      } else if (routes.length > 1) {
        // 路由大于1个的时候，打开目的网点
        this.props.onMatch && this.props.onMatch(routes, arrInfo);
      }
    });
  };
  // 绘制派送区域
  drawDistrictArea = dispatchZone => {
    if (!this.props.ceeMatchArr) {
      return;
    }
    // 实例化DistrictSearch
    let drawZoneList = dispatchZone;
    if (!Array.isArray(drawZoneList)) {
      drawZoneList = [];
    }
    drawZoneList.forEach(district => {
      if (district && district.adcode) {
        let level = 'district';
        let keyword = district.adcode;
        if (!district.province) {
          level = 'country';
          keyword = '中国';
        } else if (!district.city) {
          level = 'province';
          keyword = district.province;
        } else if (!district.district) {
          level = 'city';
          keyword = district.city;
        }
        // 行政区划高德地图service
        if (!this.districtService) {
          let AMap = window.AMap;
          let opts = {
            subdistrict: 1, // 返回下一级行政区
            extensions: 'all', // 返回行政区边界坐标组等具体信息
            level: 'city', // 查询行政级别为 市
            showbiz: false,
          };
          let district = new AMap.DistrictSearch(opts);
          this.districtService = district;
        }
        this.districtService.setLevel(level);
        this.districtService.search(keyword, (status, result) => {
          if (status === 'complete') {
            let bounds = result.districtList[0].boundaries;
            if (bounds) {
              let AMap = window.AMap;
              for (let i = 0, l = bounds.length; i < l; i++) {
                new AMap.Polygon({
                  map: this.aMap,
                  strokeWeight: 1,
                  strokeColor: '#fdc303',
                  fillColor: '#fce69e',
                  fillOpacity: 0.5,
                  path: bounds[i],
                });
              }
            }
          }
        });
      }
    });
  };
  // 显示弹框
  showPopup = (content, sureTxt) => {
    // 如果有输入框，当前处于获得焦点状态，使其失去焦点
    let activeElement = document.activeElement;
    if (activeElement && activeElement.tagName === 'INPUT') {
      try {
        activeElement.blur();
      } catch (e) {
        // eslint-disable-next-line
      }
    }
    let p = new Promise((resolve, reject) => {
      let dialogRef;
      let sure = () => {
          this.popUpIsShow = false;
          dialogRef.handleHide();
          resolve();
        },
        cancel = () => {
          this.popUpIsShow = false;
          dialogRef.handleHide();
          reject();
        };
      let popUp = new PopUp(ModalDialog, {
        content: (
          <div className="coinfomap_popup">
            <div className="fn-show_info__icon">
              <i className="fn-icon fn-icon-info-o" />
            </div>
            {content}
          </div>
        ),
        bottom: (
          <div>
            <Button type="primary" onClick={sure}>
              {sureTxt || '更换'}
            </Button>
            <Button onClick={cancel}>取消</Button>
          </div>
        ),
        isShow: true,
        ref: r => (dialogRef = r),
        title: '提醒',
      });
      popUp.show();
      this.popUpIsShow = true;
    });
    return p;
  };
  // 处理不匹配
  handleNotMatch = result => {
    let arrInfo = this.formatBatchPointMarkers(result.res.arr_info);
    if (!arrInfo[0]) {
      return;
    }
    arrInfo = arrInfo[0];
    if (!isEmptyObj(this.arrPoint)) {
      // 渲染目的网点信息
      this.renderArrPoint();
      if (arrInfo.company_id !== this.arrPoint.company_id) {
        this.showPopup(CEE_TIPS.NOT_MATCH).then(
          () => {
            let clickRecommendArrMarker = this.clickRecommendArrMarker.bind(this, arrInfo);
            clickRecommendArrMarker();
          },
          () => {},
        );
        // 显示推荐目的网点和派送区域
        this.addRecommendArrMarker(arrInfo);
      }
    } else {
      this.getRouteAndChangeArrPoint(arrInfo);
    }
  };
  formatBatchPointMarkers = list => {
    let l;
    if (!Array.isArray(list)) {
      if (typeof list === 'object') {
        l = [list];
      } else {
        l = [];
      }
    } else {
      l = list.slice();
    }
    let ret = [];
    l.forEach(obj => {
      let newObj = Object.assign({}, obj);
      try {
        newObj.address = JSON.parse(newObj.address);
      } catch (e) {
        console.log(`parse error: ${newObj.address}`);
      }
      try {
        newObj.dispatch_zone = JSON.parse(newObj.dispatch_zone);
      } catch (e) {
        console.log(`parse error: ${newObj.dispatch_zone}`);
      }
      ret.push(newObj);
    });
    return ret;
  };
  // 针对服务器返回的结果进行处理
  handleCeeMatchArr = result => {
    switch (result.errno) {
      case CEE_ERRNO.MATCH:
      case CEE_ERRNO.NOT_MATCH:
        this.handleNotMatch(result);
        break;
      case CEE_ERRNO.CAN_NOT_MATCH:
        if (!Array.isArray(result.res) || result.res.length === 0) {
          return;
        }
        // 显示提示 不在范围内
        this.showPopup(CEE_TIPS.CAN_NOT_MATCH).then(
          () => {
            let onMatch = this.props.onMatch;
            // 清空收货地址
            if (onMatch) {
              // 自定义事件触发，让CoInfoPerson清空
              onMatch('CAN_NOT_MATCH');
              // 保存的ceeAddr清空
              this.ceeAddr = {};
              // 触发检查，恢复默认状态
              this.checkCeeMatchArr();
            }
          },
          () => {
            // 如果目的网点为空，批量显示最近的3个推荐网点
            if (isEmptyObj(this.arrPoint)) {
              // 批量显示网点
              this.batchAddPointMarker(result.res);
            }
          },
        );
        break;
      default:
        break;
    }
  };
  checkCeeMatchArr = () => {
    if (!this.aMap) {
      return;
    }
    if ((!this.props.showArrPoint && !this.props.ceeMatchArr) || this.popUpIsShow) {
      return;
    }
    this.clearMap();

    // 如果没有收获地址，什么都不做
    if (!this.props.ceeMatchArr || isEmptyObj(this.ceeAddr)) {
      // 如果开启了地图，且目的网点为空，显示中心点，定位到北京
      if (!this.props.ceeMatchArr && isEmptyObj(this.arrPoint)) {
        this.aMap.setCity(this.props.cityName);
      }
      return;
    }
    if (!this.ceeAddr.show_val) {
      return;
    }
    // 如果存在收获地址，但是无法定位
    if (!this.ceeAddr.adcode) {
      // this.showPopup(CEE_TIPS.CAN_NOT_RECOGNIZE, '更新').then(() => {
      //   let onMatch = this.props.onMatch
      //   // 清空收货地址
      //   if (onMatch) {
      //     // 自定义事件触发，让CoInfoPerson清空收货地址
      //     onMatch('CAN_NOT_RECOGNIZE')
      //     // 保存的ceeAddr清空
      //     this.ceeAddr = {}
      //     // 触发检查，恢复默认状态
      //     this.checkCeeMatchArr()
      //   }
      // }, () => {})
      return;
    }
    if (
      !isEmptyObj(this.arrPoint) &&
      !isEmptyObj(this.pointClickFromMap) &&
      this.arrPoint.company_id === this.pointClickFromMap.company_id
    ) {
      // 显示收货地址
      if (this.props.ceeMatchArr) {
        this.addCeeAddrMarker();
      }
      return;
    }
    this.pointMarkerArray = [];
    let req = {
      arr_info: this.arrPoint,
      cee_addr_info: this.ceeAddr,
      unique_key: +new Date(),
    };
    // abort机制
    this.requestKey = req.unique_key;
    // 发送请求

    const url = '/Order/Order/match';
    const conf = { method: 'POST', body: { req } };
    fetch(url, conf).then(res => {
      let resData = res || {};
      if (resData.req.unique_key !== this.requestKey) {
        return;
      }
      if (this.props.ceeMatchArr) {
        this.addCeeAddrMarker();
      }
      this.handleCeeMatchArr(resData);
    });
  };
}
