import PropTypes from 'prop-types';
import React from 'react';
// TODO 继承Component 的原因
// import React, { Component, PropTypes } from 'react'
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { formatDeppProptotype, showInfo } from 'utils';
import { Input } from 'components';
import { ERROR, validateTips } from 'constants';
import TableCell from './TableCell';
import { isInOtherPropsArray, getOtherPropsInfo } from './../utils';
// import { dataFormater, reverseFormate, multiFormate } from '../helper/dataFormater'
export default class InputCell extends TableCell {
  // constructor(prop) {
  //   super(prop)
  //   this.isInit = true
  // }
  static propTypes = {
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func,
    format: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };
  handleKeyDown = e => {
    this.props.onKeyDown(e, this.props.rowIndex, this.props.cellOtherProps.colIndex);
  };
  // input的获取焦点
  onFocus = e => {
    this.props.onFocus(e, this.props.rowIndex, this.props.cellOtherProps.colIndex);
  };

  changeData = (rowIndex, columnKey, val, func, options) => {
    const { type } = options || {};
    const { pattern, trigger = '' } = this.props.columnProps;
    let value = val;

    const handlers = [
      {
        trigger: 'blur',
        condition: type === trigger && trigger === 'blur',
        handler: () => {
          if (pattern && validateTips[pattern] && value) {
            const isOk = new RegExp(pattern).test(value);
            if (!isOk) {
              showInfo(ERROR, validateTips[pattern]);
              value = '';
              this.props.onChange && this.props.onChange(rowIndex, columnKey, value);
            }
          }
        },
      },
    ];

    // 如果定义了触发类型，则只有触发类型是，才走校验逻辑
    if (trigger) {
      const current = handlers.find(item => item.trigger === type && item.condition);
      current?.handler?.();
    } else if (pattern && validateTips[pattern] && value) {
      const isOk = new RegExp(pattern).test(value);
      if (!isOk) {
        showInfo(ERROR, validateTips[pattern]);
        value = '';
      }
    }

    // this.isInit = false
    // const { format } = this.props.columnProps || {}
    // let formateValue = value
    // formateValue = reverseFormate(formateValue, format)
    func(rowIndex, columnKey, value);
  };
  getTips = (dataItem, columnKey, rowIndex) => {
    const extRender = getOtherPropsInfo(dataItem, columnKey, 'extRender');
    if (extRender) {
      const { cellOtherProps } = this.props;
      const { data } = cellOtherProps;
      const rowData = data.getAll()[rowIndex] || {};
      return extRender({ rowData, columnKey, rowIndex, type: 'InputCell' });
    }
    return null;
  };
  render() {
    const { cellProps, cellOtherProps, columnProps, onChange, onBlur } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data, colIndex } = cellOtherProps;
    const { rowIndex, columnKey, width, height } = cellProps;
    // let defaultValue = data.getShowObjectAt(rowIndex)[columnKey]
    const dataItem = data.getObjectAt(rowIndex);
    const rowProps = dataItem.otherProps && dataItem.otherProps[columnKey];
    const defaultValue = formatDeppProptotype(columnKey, dataItem);
    const style = { width: width - 3, height: height - 2, padding: '0 1px' };

    const tips = getOtherPropsInfo(dataItem, columnKey, 'tips');
    // defaultValue = (format.division || format.multiples) ? multiFormate(defaultValue || '', format) || '' : defaultValue
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <Input
          style={style}
          pattern={columnProps.pattern}
          data-path={`${columnKey}_${rowIndex}_${colIndex}`}
          // defaultValue={this.isInit ? dataFormater(defaultValue || '', format) || '' : defaultValue}
          defaultValue={defaultValue}
          onChange={e => this.changeData(rowIndex, columnKey, e.target.value, onChange, { type: 'change' })}
          onBlur={e => this.changeData(rowIndex, columnKey, e.target.value, onBlur, { type: 'blur' })}
          disabled={isInOtherPropsArray(dataItem, columnKey, 'disable')}
          onClick={e => e.stopPropagation()}
          onKeyDown={this.handleKeyDown}
          onFocus={this.onFocus}
          placeholder={columnProps.placeholder || ''}
          tips={tips}
          {...rowProps}
        />
        {this.getTips(dataItem, columnKey, rowIndex)}
      </Cell>
    );
  }
}
