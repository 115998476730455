import _ from 'lodash';
import { throttle, deepEquals, sequentiallyPost, sequentiallyPostJAVA } from 'utils';
// import getJAVAPriceSettingParams from 'utils/business/price/getJAVAPriceSettingParams';
import {
  SET_ORDER_DATA,
  OUTER_TRANS,
  POINT_TRANS,
  FETCH_ORDER_DATA,
  RE_CALC_OUTER_PRICE,
  CALC_OUTER_PRICE_IMMEDIATELY,
} from '../constant';
import { initCostInfo, initStdCost, outerTransCalcPriceFee, tableCalcPriceFee, calcPriceFee } from './constant';
import {
  getInnerPriceParams,
  getOuterPriceParams,
  feeRelate,
  calcFeeTotal,
  needGetInnerPrice,
  needGetOuterPrice,
  getPointTransCompanyID,
} from './tool';

const priceMiddleware = page => store => {
  let lastPriceMode = '';
  let lastOuterPriceParams = {};
  let lastInnerPriceParams = {};
  let innerFetch;
  let outerFetch;
  let outerFetch1;
  let outerPriceCalcInQueue;
  let pointTransPriceResultMerged;

  const { edited } = page;

  const r2oFill = key => {
    const state = store.getState();
    const { isReservation, r2oFillPriceWhenEmpty } = state;
    return (
      !isReservation || !r2oFillPriceWhenEmpty || !(state.origin.order_data[key] && state.origin.order_data[key].value)
    );
  };

  const originGetOuterPrice = () => {
    const state = store.getState();
    const {
      sendFlag,
      frontSendFlag,
      transFlag,
      budgetFlag,
      pickupFlag,
      pointFlag,
      useJAVAPrice,
      transInfo,
      noCalPrice,
      feeSource,
    } = state;
    const order = getOuterPriceParams(state);
    const type = [];

    order.fee_source = feeSource.getSource();

    const url = `${useJAVAPrice ? '/cmm-pricesys/' : ''}Basic/PriceSystemV2/getOrderOuterPrice?no_explain_detail=0`;
    const req = { type, orders: [order], company_id: state.cid };

    outerPriceCalcInQueue = false;

    if (frontSendFlag) return;
    if (noCalPrice) return;
    if (sendFlag) type.push('cor_pt');
    if (budgetFlag) type.push('budget_pt');
    if (transFlag && (order.carrier_id || order.trans_arr_info)) type.push('ext_trans_pt');
    if (pickupFlag) type.push('pickup_pt');
    if (pointFlag && !useJAVAPrice) type.push('point_trans_pt');
    // if (useJAVAPrice) req.settings = getJAVAPriceSettingParams(type);
    if (
      order.goods &&
      order.goods.some(row => Number.isNaN(+row.num) || Number.isNaN(+row.weight) || Number.isNaN(+row.volume))
    )
      return;
    // if (order.goods && order.goods.some(row => Number.isNaN(+row.num) || Number.isNaN(+row.weight))) return

    // CYTRD-4821 价格计算优先级，暂时去掉参数相同判断
    // const isParamsEqual = deepEquals(req, lastOuterPriceParams);
    // if (
    //   isParamsEqual &&
    //   (!pointFlag ||
    //     pointTransPriceResultMerged ||
    //     !transInfo ||
    //     transInfo.every(row => +row.trans_type !== POINT_TRANS))
    // )
    //   return;

    if (!type.length && !(pointFlag && useJAVAPrice)) return;
    let calcTimes = 0;
    let calcDone = 0;
    lastOuterPriceParams = req;
    page.merge('outerPriceCalcInProcess', true);
    // 网点中转只能是旧价格，当开启java价格要单独发请求计算 所以这里逻辑会写两遍
    if (
      pointFlag &&
      !pointTransPriceResultMerged &&
      transInfo?.some(row => +row.trans_type === POINT_TRANS) &&
      useJAVAPrice
    ) {
      calcTimes++;

      outerFetch1('Basic/PriceSystemV2/getOrderOuterPrice?no_explain_detail=0', { ...req, type: ['point_trans_pt'] })
        .catch(e => {
          calcDone++;

          if (calcDone === calcTimes) {
            page.merge('outerPriceCalcInProcess', false);
          }
          return Promise.reject(e);
        })
        .then(res => {
          const curState = store.getState();
          const result = res.res && res.res.price[0];

          calcDone++;

          if (calcDone === calcTimes) {
            page.merge('outerPriceCalcInProcess', false);
          }

          if (result?.point_trans_pt && curState.transInfo) {
            curState.transInfo.forEach((row, index) => {
              const transType = +row.trans_type;
              if (!transType || transType === POINT_TRANS) {
                const isUp = +order.point_trans_up_com_id === +state.cid;
                const newVal = {};
                const needFill = transType === POINT_TRANS || (isUp && !transType);
                if (needFill) {
                  pointTransPriceResultMerged = true;
                }
                Object.entries(result.point_trans_pt).forEach(([k, v]) => {
                  const newKey = k.replace('point_', '').replace('_act_', '_');
                  if (!edited(newKey, 'trans_info', index)) {
                    newVal[newKey] = needFill && v ? +(+v).toFixed(2) : 0;
                  }
                });
                if (transType === POINT_TRANS) {
                  const tempRow = { ...row, ...newVal };
                  newVal.trans_std_f = +(
                    (+tempRow.trans_std_freight_f || 0) +
                    (+tempRow.trans_std_handling_f || 0) +
                    (+tempRow.trans_std_pickup_f || 0) +
                    (+tempRow.trans_std_delivery_f || 0)
                  ).toFixed(2);
                  const transFee = calcFeeTotal(curState, tempRow);
                  const relate = feeRelate(curState, tempRow, transFee);
                  Object.assign(newVal, relate);
                }
                page.setTable('transInfo', index, newVal, 'price');
              }
              if (transType === POINT_TRANS && pointFlag && state.isModify) {
                page.merge('pointTransChange', true);
              }
            });
          }
          if (result?.point_trans_pt) {
            const pointPriceResult = {};
            Object.entries(result.point_trans_pt).forEach(([k, v]) => {
              const newKey = k.replace('point_', '');
              pointPriceResult[newKey] = v ? +(+v).toFixed(2) : 0;
            });
            page.merge('pointPriceResult', pointPriceResult);

            if (+_.get(curState, 'route.route[0].company_id') === +order.point_trans_up_com_id) {
              const stdF =
                +(pointPriceResult.trans_std_freight_f + pointPriceResult.trans_std_delivery_f).toFixed(2) || 0;
              page.setTable('stdCost', 0, { std_f: stdF });
            }
          }
        });
    }
    if (!type.length) return;

    calcTimes++;

    outerFetch(url, req)
      .catch(e => {
        calcDone++;

        if (calcDone === calcTimes) {
          page.merge('outerPriceCalcInProcess', false);
        }
        return Promise.reject(e);
      })
      .then(res => {
        const curState = store.getState();
        const result = res.res && res.res.price[0];
        const detailPath = useJAVAPrice
          ? 'res.process_snapshot[0].cor_pt.co_freight_f'
          : 'res.price_ex[0].cor_pt.co_freight_f.detail[0].explain';
        const explain = _.get(res, detailPath, []);
        const { keyMap, weightUnit } = curState;
        const priceSnapshot = {};

        calcDone++;

        if (calcDone === calcTimes) {
          page.merge('outerPriceCalcInProcess', false);
        }

        if (sendFlag && result?.cor_pt) {
          const lastFreightSrcIsGoods = curState.lastFreightSrc === 'goods';
          // 取系统设置->价格设置->订单价格->计算方式 1 按货计价 2 按单计价 3 按和计价 与按单一样
          const corPtCalcType = +window?.ps_setting.price_types?.find(item => item?.price_type === 'cor_pt')?.calc_type;
          const co_freight_f = res.res?.process_snapshot?.[0]?.cor_pt?.co_freight_f;
          // unitObj的key指根据哪个单位做的价格计算 value对应的是单价单位
          const unitObj = {
            件: 'per_num',
            方: 'per_v',
            公斤: 'per_w',
            吨: 'per_w',
            套: 'per_s',
          };
          const newGoods = curState.goods.map((row, index) => {
            // 如果按货计价 则取co_freight_f的index对应的单位 按单按和计算取第0个数组的单位则为此次计算的单位
            // 有used_detail字段 则代表用此单位计算
            const unit = co_freight_f?.[index]?.price_cal?.range_price_detail?.filter(v => v.used_detail)?.[0]
              ?.real_values?.[0]?.unit;
            // 如果手动编辑过单价单位 则不对单价单位重新赋值
            const unit_p_unit =
              (page.edited(`unit_p_unit_${index + 1}`) && row?.unit_p_unit) || unitObj[unit] || 'per_num';
            const { unit_price, unit_price_new } = explain[index] ?? {};
            const detail = { ...unit_price, ...unit_price_new };
            const weightUnitPrice = (weightUnit === '吨' ? detail.吨 : detail.公斤) || '';
            const volumeUnitPrice = +detail.方 || '';
            // 工单8023 经与产品确认 续价只取每件价格 不取按件价格
            const numUnitPrice = +detail.每件价格 || '';
            const suitUnitPrice = +detail.每套价格 || '';
            const unitPriceMap = {
              per_num: numUnitPrice, // 件数单价
              per_v: volumeUnitPrice, // 体积单价
              per_w: weightUnitPrice, // 重量单价
              per_s: suitUnitPrice, // 重量单价
            };
            const changes = {
              weight_unit_price: weightUnitPrice,
              volume_unit_price: volumeUnitPrice,
              num_unit_price: numUnitPrice,
              suit_unit_price: suitUnitPrice,
            };
            // 按货计价 对应的货物有数值
            // CYTRD-4821 价格计算优先级 source不等于1就填充
            if (
              order.fee_source?.goods_source?.[index]?.unit_source !== 1 &&
              row.unit_p !== unitPriceMap[unit_p_unit] &&
              unit
            ) {
              changes.unit_p = unitPriceMap[unit_p_unit]; // 单价
              changes.unit_p_unit = unit_p_unit; // 单价单位
            }

            if (order.fee_source?.goods_source?.[index]?.subtotal_source !== 1) {
              // CYTRD-4821 使用价格计算返回的运费小计
              const subtotal_price = res.res?.goods?.[index].subtotal_price;

              changes.subtotal_price = subtotal_price ?? '';
            }

            lastFreightSrcIsGoods && page.setTable('goods', index, changes);
            return { ...row, ...changes };
          });
          !lastFreightSrcIsGoods && page.merge('goods', newGoods);
          page.merge('corPt', { ...result.cor_pt });
          curState.preFreight && delete result.cor_pt.co_freight_f && page.merge('freightChange', true);
          // 选择货物 type = 2 后， 触发orderEditor/fee/freight/middleware.js 文件 page.merge('lastFreightSrc', 'goods');
          // lastFreightSrcIsGoods判断了是否等于goods后删除了价格计算后的运费
          // 目前场景不再需要判断类型，所以暂时注释 --2022-06-13
          // lastFreightSrcIsGoods && delete result.cor_pt.co_freight_f;
          curState.commissionSetting.checked !== 'price' && delete result.cor_pt.co_delivery_fee;
          // const { priceSnapshot: prePriceSnapshot, priceSnapshotDelete: prePriceSnapshotDelete } = curState;
          Object.entries(result.cor_pt).forEach(([k, v]) => {
            if (r2oFill(k)) {
              // 暂时注释根据价格计算icon来做赋值逻辑 --2022.10.14
              // const preValue = curState[keyMap[k]];
              // // 输入框值是否为价格计算 = 值不为空 && 是否开启价格计算 && 价格计算快照存在
              // const isCalcPriceValue = Boolean(
              //   preValue && useJAVAPrice && (prePriceSnapshot[k] || !prePriceSnapshotDelete[k]),
              // );
              // // 价格计算返回为null后，并且输入框内值不来源于价格计算，则不进行赋值
              // if (!isCalcPriceValue && (v === null || v === undefined)) {
              //   return;
              // }

              if (order.fee_source[k] !== 1) {
                const camelKey = keyMap[k];

                if ((v !== null && +curState[camelKey] !== +v) || (v === null && curState[camelKey] !== '')) {
                  // 优化：当前页面数据跟接口返回不一致才赋值
                  page.merge(camelKey, v ?? '', 'price');
                }

                priceSnapshot[k] = res.res.process_snapshot[0].cor_pt[k];
              }
            }
          });
        }
        if (((transFlag && result?.ext_trans_pt) || (pointFlag && result?.point_trans_pt)) && curState?.transInfo) {
          page.set('ext_trans_pt', result.ext_trans_pt);
          const isUp = +order.point_trans_up_com_id === +state.cid;
          curState.transInfo.forEach((row, index) => {
            const transType = +row.trans_type;
            if (state.isModify) {
              if (transType === POINT_TRANS && pointFlag) {
                page.merge('pointTransChange', true);
              }
              return;
            }
            if ((transType === OUTER_TRANS && transFlag) || (result?.point_trans_pt && pointFlag)) {
              const newVal = {};
              const shouldUpd = key => {
                let flag = !edited(key, 'trans_info', index);
                if (flag && key === 'trans_freight_f') {
                  // 如果中转费用是通过单价计算得出的，则不再覆盖
                  flag = row.trans_freight_f_src !== 'goods';
                }
                return flag;
              };
              result.ext_trans_pt &&
                transType === OUTER_TRANS &&
                Object.entries(result.ext_trans_pt).forEach(([k, v]) => {
                  if (shouldUpd(k)) {
                    newVal[k] = v ? +(+v).toFixed(2) : 0;
                    if (useJAVAPrice) {
                      priceSnapshot[k] = res.res.process_snapshot[0].ext_trans_pt[k];
                    }
                  }
                });
              if (!transType || transType === POINT_TRANS) {
                const needFill = transType === POINT_TRANS || isUp;
                if (needFill) {
                  pointTransPriceResultMerged = true;
                }
                result.point_trans_pt &&
                  Object.entries(result.point_trans_pt).forEach(([k, v]) => {
                    const newKey = k.replace('point_', '').replace('_act_', '_');
                    if (!edited(newKey, 'trans_info', index)) {
                      newVal[newKey] = needFill && v ? +(+v).toFixed(2) : 0;
                      if (useJAVAPrice && needFill) {
                        priceSnapshot[k] = res.res.process_snapshot[0].point_trans_pt[k];
                      }
                    }
                  });
              }
              const tempRow = { ...row, ...newVal };
              newVal.trans_std_f = +(
                (+tempRow.trans_std_freight_f || 0) +
                (+tempRow.trans_std_handling_f || 0) +
                (+tempRow.trans_std_pickup_f || 0) +
                (+tempRow.trans_std_delivery_f || 0)
              ).toFixed(2);
              if (transType) {
                const transFee = calcFeeTotal(curState, tempRow);
                const relate = feeRelate(curState, tempRow, transFee);
                Object.assign(newVal, relate);
              }
              page.setTable('transInfo', index, newVal, 'price');
            }
          });
        }
        if (pointFlag && result?.point_trans_pt) {
          const pointPriceResult = {};
          Object.entries(result.point_trans_pt).forEach(([k, v]) => {
            const newKey = k.replace('point_', '');
            pointPriceResult[newKey] = v ? +(+v).toFixed(2) : 0;
          });
          page.merge('pointPriceResult', pointPriceResult);
          if (+_.get(curState, 'route.route[0].company_id') === +order.point_trans_up_com_id) {
            const stdF =
              +(pointPriceResult.trans_std_freight_f + pointPriceResult.trans_std_delivery_f).toFixed(2) || 0;
            page.setTable('stdCost', 0, { std_f: stdF });
          }
        }
        if (budgetFlag && result.budget_pt && curState.budgetInfo) {
          curState.budgetInfo.forEach((row, index) => {
            const newVal = {};
            Object.entries(result.budget_pt).forEach(([k, v]) => {
              if (!edited(k, 'budget_info', index)) {
                newVal[k] = v ? +(+v).toFixed(2) : 0;
                if (useJAVAPrice) {
                  priceSnapshot[k] = res.res.process_snapshot[0].budget_pt[k];
                }
              }
            });
            page.setTable('budgetInfo', index, newVal, 'price');
          });
        }
        if (
          pickupFlag &&
          result.pickup_pt &&
          curState.pointCostInfo &&
          curState.pointCostInfo[0] &&
          !edited('point_cost_info_pickup_f_0')
        ) {
          page.setTable('pointCostInfo', 0, { pickup_f: +(+result.pickup_pt.pickup_f || 0).toFixed(2) }, 'price');
          if (useJAVAPrice) {
            priceSnapshot.pickup_f = res.res.process_snapshot[0].pickup_pt.pickup_f;
          }
        }
        if (useJAVAPrice) {
          page.merge('priceSnapshot', priceSnapshot);
          page.merge('priceSnapshotOrigin', { price: [result], process_snapshot: res.res.process_snapshot });
        }
      });
  };

  const throttledGetOuterPrice = throttle(originGetOuterPrice, 500);

  const getOuterPrice = () => {
    outerPriceCalcInQueue = true;
    throttledGetOuterPrice();
  };

  const getInnerPrice = throttle(() => {
    const state = store.getState();
    const { priceMode } = state;

    // 上次价格模式和当前价格模式都为空什么都不执行
    if (!priceMode && !lastPriceMode) return;
    lastPriceMode = priceMode;

    if (+priceMode > 2) {
      // 价格模式大于2时发请求
      const req = getInnerPriceParams(state);
      const url = 'Basic/PriceSystemV2/getOrderPrice';
      // if (req.goods && req.goods.some(row => Number.isNaN(+row.num) || Number.isNaN(+row.weight) || Number.isNaN(+row.volume))) return
      if (req.goods && req.goods.some(row => Number.isNaN(+row.num) || Number.isNaN(+row.weight))) return;
      if (deepEquals(req, lastInnerPriceParams)) return;

      lastInnerPriceParams = req;

      page.merge('innerPriceList', undefined);

      innerFetch(url, req).then(res => {
        const result = res.res;
        const curState = store.getState();
        if (result.std_cost && curState.stdCost) {
          const [up] = getPointTransCompanyID(state.route);
          delete result.std_cost.std_mgr_id;
          delete result.std_cost.std_uld_grp;
          delete result.std_cost.std_mn_f;
          delete result.std_cost.std_uld_f;
          if (+_.get(curState, 'route.route[0].company_id') === +up) {
            delete result.std_cost.std_f;
          }
          const stdCost = {};
          Object.entries(result.std_cost).forEach(
            ([k, v]) => !edited(k, 'std_cost') && (stdCost[k] = v ? +v.toFixed(2) : 0),
          );
          state.stdCost && page.setTable('stdCost', 0, stdCost);
        }
        if (result.cost_info && curState.costInfo) {
          const costInfo = {};
          Object.entries(result.cost_info).forEach(
            ([k, v]) => !edited(k, 'cost_info') && (costInfo[k] = v ? +v.toFixed(2) : 0),
          );
          state.costInfo && page.setTable('costInfo', 0, costInfo);
        }
        page.merge('innerPriceList', result.ext ? result.ext.inner_price_list : undefined);
        page.merge('innerPriceError', result.reasons && result.reasons.length ? result.reasons : null);
      });
    } else {
      // 否则重置价格信息
      state.stdCost && page.setTable('stdCost', 0, initStdCost);
      state.costInfo && page.setTable('costInfo', 0, initCostInfo);
      page.merge('innerPriceList', undefined);
      page.merge('innerPriceError', null);
    }
  }, 800);

  return next => action => {
    switch (action.type) {
      case SET_ORDER_DATA: {
        const state = store.getState();
        const { key } = action.payload;
        const { calcOuterPrice, calcInnerPrice, useJAVAPrice, keyMap } = state;

        // 价格发送请求
        calcInnerPrice && needGetInnerPrice(key, action) && getInnerPrice();
        calcOuterPrice && needGetOuterPrice(key, action, state) && getOuterPrice();
        if (useJAVAPrice && calcPriceFee[keyMap[key]] && state[key] !== action.payload.val && action.src !== 'price') {
          page.merge('priceSnapshotDelete', { ...state.priceSnapshotDelete, [keyMap[key]]: 1 });
          if (state.priceSnapshotOrigin) {
            const priceSnapshot = { ...state.priceSnapshot };
            delete priceSnapshot[keyMap[key]];
            page.merge('priceSnapshot', priceSnapshot);
          }
        }
        if (
          useJAVAPrice &&
          (key === 'budgetInfo' || key === 'pointCostInfo' || key === 'transInfo') &&
          action.src !== 'price'
        ) {
          const ptMap = { budgetInfo: 'budget_pt', pointCostInfo: 'pickup_pt' };
          let pt = ptMap[key];
          let changes;
          let hasDelete;
          if (key === 'transInfo') {
            const originRow = (state.transInfo && state.transInfo[action.index]) || {};
            const transType = +originRow.trans_type;
            changes = action.changes;
            if (transType === POINT_TRANS) {
              pt = 'point_trans_pt';
            }
            if (transType === OUTER_TRANS) {
              pt = 'ext_trans_pt';
            }
            if (!changes || changes.trans_type) {
              if (transType === OUTER_TRANS) {
                changes = outerTransCalcPriceFee;
              }
            }
          } else {
            changes = action.changes;
          }
          if (state.priceSnapshotOrigin) {
            const priceSnapshot = { ...state.priceSnapshot };
            changes &&
              Object.keys(changes).forEach(k => {
                const priceKey = pt === 'point_trans_pt' ? `point_${k}` : k;
                if (priceSnapshot[priceKey]) {
                  hasDelete = true;
                  delete priceSnapshot[priceKey];
                }
              });
            if (hasDelete) {
              page.merge('priceSnapshot', priceSnapshot);
            }
          }
          const priceSnapshotDelete = { ...state.priceSnapshotDelete };
          changes &&
            Object.keys(changes).forEach(k => {
              if (tableCalcPriceFee[k]) {
                priceSnapshotDelete[pt === 'point_trans_pt' ? `point_${k}` : k] = 1;
              }
            });
          page.merge('priceSnapshotDelete', priceSnapshotDelete);
        }
        break;
      }
      case CALC_OUTER_PRICE_IMMEDIATELY: {
        if (outerPriceCalcInQueue) {
          lastOuterPriceParams = {};
          originGetOuterPrice();
        }
        break;
      }
      case RE_CALC_OUTER_PRICE: {
        lastOuterPriceParams = {};
        break;
      }
      case FETCH_ORDER_DATA: {
        const data = action.payload.data.res;
        const useJAVAPrice = data.basic_setting.use_java_price && data.basic_setting.use_java_price.checked;
        lastOuterPriceParams = {};
        lastInnerPriceParams = {};
        outerPriceCalcInQueue = false;
        pointTransPriceResultMerged = false;
        innerFetch = sequentiallyPost();
        outerFetch = useJAVAPrice ? sequentiallyPostJAVA() : sequentiallyPost();
        outerFetch1 = sequentiallyPost();
        setTimeout(() => {
          const { isReservation, calcOuterPrice } = store.getState();
          isReservation && calcOuterPrice && getOuterPrice();
        });
        break;
      }
      default:
        break;
    }
    next(action);
  };
};

export default priceMiddleware;
