import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { Icon } from 'components';
import TableCell from './TableCell';
import { isInOtherPropsArray } from './../utils';

export default class SubtractCell extends TableCell {
  render() {
    const { onClick } = this.props;
    const { cellProps, cellOtherProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const disabled = isInOtherPropsArray(data.getObjectAt(rowIndex), columnKey, 'disable');
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <p style={{ textAlign: 'center' }}>
          {!disabled && (
            <Icon
              iconType="icon-minus-rad"
              disabled={disabled}
              onClick={disabled ? undefined : e => onClick(e, rowIndex, columnKey)}
            />
          )}
        </p>
      </Cell>
    );
  }
}
