import { getDistance, getDistanceByKeyword } from '../tool';
const calcCeeDist = state => {
  const { arrPoint, transMode, ceeAddrInfo, arrInfo } = state;
  if (arrPoint && (transMode === 2 || transMode === 1)) {
    const p1 = ceeAddrInfo.poi;
    const p2 = arrPoint && arrPoint.addr && arrPoint.addr.poi;
    const mileRatio = state.mileRatio;
    return getDistance(p1, p2, mileRatio);
  }
  const startPoi = ceeAddrInfo && ceeAddrInfo.poi ? ceeAddrInfo.show_val : '';
  const startCity = ceeAddrInfo && ceeAddrInfo.poi ? ceeAddrInfo.city || ceeAddrInfo.province : '';
  const arrInfoAddress = arrInfo && arrInfo.addr ? arrInfo.addr : arrInfo;
  const endPoi =
    arrInfoAddress && arrInfoAddress.poi ? `${arrInfoAddress.district || arrInfoAddress.city}人民政府` : '';
  const endCity = arrInfoAddress && arrInfoAddress.poi ? arrInfoAddress.city || arrInfoAddress.province : '';
  const mileRatio = state.mileRatio;
  return getDistanceByKeyword(startPoi, endPoi, [startCity, endCity], mileRatio);
};

export default calcCeeDist;
