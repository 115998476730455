import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { deleteProps } from 'utils';
import Button from '../Button';
import Icon from '../../icon/Icon';
import { prefixCls } from './index.scss';

export default class ButtonIcon extends PureComponent {
  static defaultProps = {
    direction: 'left',
    display: true,
  };
  static propTypes = {
    buttonClass: PropTypes.string,
    iconClass: PropTypes.string,
    iconLeftClass: PropTypes.string,
    iconRightClass: PropTypes.string,
    iconType: PropTypes.string,
    iconLeftType: PropTypes.string,
    iconRightType: PropTypes.string,
    classname: PropTypes.string,
    direction: PropTypes.oneOf(['left', 'right', 'both']),
    children: PropTypes.any,
    disabled: PropTypes.bool,
    display: PropTypes.bool,
    type: PropTypes.string,
    useIconForPoi: PropTypes.bool,
  };

  renderIcon() {
    const {
      iconClass,
      iconType,
      direction,
      children,
      iconRightClass,
      iconRightType,
      iconLeftClass,
      iconLeftType,
      disabled,
      useIconForPoi,
    } = this.props;
    const _children = [];
    const ref = r => useIconForPoi && (this.realDomContainer = ReactDOM.findDOMNode(r)); // eslint-disable-line
    switch (direction) {
      case 'right':
        _children.push(
          children,
          <Icon
            ref={ref}
            iconType={iconRightType || iconType}
            disabled={disabled}
            classname={iconRightClass || iconClass}
            key={0}
          />,
        );
        break;
      case 'both':
        _children.push(
          <Icon
            ref={ref}
            iconType={iconLeftType || iconType}
            disabled={disabled}
            classname={iconLeftClass || iconClass}
            key={0}
          />,
          children,
          <Icon ref={ref} iconType={iconRightType || iconType} classname={iconRightClass || iconClass} key={1} />,
        );
        break;
      default:
        _children.push(
          <Icon ref={ref} iconType={iconLeftType || iconType} classname={iconRightClass || iconClass} key={0} />,
          children,
        );
    }
    return _children;
  }

  classForType() {
    let typeClass;
    switch (this.props.direction) {
      case 'right':
        typeClass = `${prefixCls}__right`;
        break;
      case 'both':
        typeClass = `${prefixCls}__both`;
        break;
      default:
        typeClass = `${prefixCls}__left`;
    }
    return typeClass;
  }

  render() {
    const { buttonClass, classname, disabled, display, useIconForPoi, ...rest } = this.props;
    const classes = classnames(this.classForType(), {
      [prefixCls]: true,
      [classname]: classname,
      [`${prefixCls}--disabled`]: disabled,
      [`${prefixCls}__hide`]: !display,
    });
    const _props = deleteProps(
      rest,
      'children',
      'iconLeftClass',
      'iconRightClass',
      'iconType',
      'iconClass',
      'direction',
      'iconLeftType',
      'iconRightType',
      'className',
      'display',
    );
    return (
      <div className={classes} ref={r => !useIconForPoi && (this.realDomContainer = r)}>
        <Button disabled={disabled} classname={buttonClass} {..._props}>
          {this.renderIcon()}
        </Button>
      </div>
    );
  }
}
