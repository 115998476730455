import React, { Component } from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import Animate from 'rc-animate';
import { browser } from '../util';
import { emptyFunction as noop } from 'utils';

const browserUa = typeof window !== 'undefined' ? browser(window.navigator) : '';
const ieOrEdge = /.*(IE|Edge).+/.test(browserUa);
// const uaArray = browserUa.split(' ')
// const gtIE8 = uaArray.length !== 2 || uaArray[0].indexOf('IE') === -1 || Number(uaArray[1]) > 8
const defaultTitle = '---';
class TreeNode extends Component {
  constructor(props) {
    super(props);
    const events = [
      'onExpand',
      'onSelect',
      'onCheck',
      'onClick',
      'onContextMenu',
      'onMouseEnter',
      'onMouseLeave',
      'onDragStart',
      'onDragEnter',
      'onDragOver',
      'onDragLeave',
      'onDrop',
      'onDragEnd',
    ];
    events.forEach(m => {
      this[m] = this[m].bind(this);
    });
    this.state = {
      dataLoading: false,
      dragNodeHighlight: false,
    };
  }

  static displayName = 'TreeNode';

  componentDidMount() {
    if (!this.props.root._treeNodeInstances) {
      this.props.root._treeNodeInstances = [];
    }
    this.props.root._treeNodeInstances.push(this);
  }

  // shouldComponentUpdate(nextProps) {
  //   if (!nextProps.expanded) {
  //     return false
  //   }
  //   return true
  // }
  onCheck() {
    this.props.root.onCheck(this);
  }

  onSelect() {
    this.props.root.onSelect(this);
  }

  onClick() {
    this.props.root.onClick(this);
  }

  onMouseEnter(e) {
    e.preventDefault();
    this.props.root.onMouseEnter(e, this);
  }

  onMouseLeave(e) {
    e.preventDefault();
    this.props.root.onMouseLeave(e, this);
  }

  onContextMenu(e) {
    e.preventDefault();
    this.props.root.onContextMenu(e, this);
  }

  onDragStart(e) {
    // console.log('dragstart', this.props.eventKey, e)
    // e.preventDefault()
    e.stopPropagation();
    this.setState({
      dragNodeHighlight: true,
    });
    this.props.root.onDragStart(e, this);
    try {
      // ie throw error
      // firefox-need-it
      e.dataTransfer.setData('text/plain', '');
    } finally {
      // empty
    }
  }

  onDragEnter(e) {
    // console.log('onDragEnter', this.props.eventKey)
    e.preventDefault();
    e.stopPropagation();
    this.props.root.onDragEnter(e, this);
  }

  onDragOver(e) {
    // console.log('onDragOver', this.props.eventKey)
    // todo disabled
    e.preventDefault();
    e.stopPropagation();
    this.props.root.onDragOver(e, this);
    return false;
  }

  onDragLeave(e) {
    // console.log('onDragLeave', this.props.eventKey)
    e.stopPropagation();
    this.props.root.onDragLeave(e, this);
  }

  onDrop(e) {
    // console.log('onDrop', this.props.eventKey)
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      dragNodeHighlight: false,
    });
    this.props.root.onDrop(e, this);
  }

  onDragEnd(e) {
    // console.log('onDragEnd', this.props.eventKey)
    e.stopPropagation();
    this.setState({
      dragNodeHighlight: false,
    });
    this.props.root.onDragEnd(e, this);
  }

  onExpand() {
    const callbackPromise = this.props.root.onExpand(this);
    if (callbackPromise && typeof callbackPromise === 'object') {
      const setLoading = dataLoading => {
        this.setState({ dataLoading });
      };
      setLoading(true);
      callbackPromise.then(
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    }
  }

  // keyboard event support
  onKeyDown(e) {
    e.preventDefault();
  }

  renderSwitcher(props, expandedState) {
    const { prefixCls } = props;
    const switcherCls = {
      [`${prefixCls}-switcher`]: true,
    };
    if (!props.showLine) {
      switcherCls[`${prefixCls}-noline_${expandedState}`] = true;
    } else if (props.pos === '0-0') {
      switcherCls[`${prefixCls}-roots_${expandedState}`] = true;
    } else {
      switcherCls[`${prefixCls}-center_${expandedState}`] = !props.last;
      switcherCls[`${prefixCls}-bottom_${expandedState}`] = props.last;
    }
    const thisOnExpand = props.disabled && props.disableExpand ? noop : this.onExpand;
    if (props.disabled) {
      switcherCls[`${prefixCls}-switcher-disabled`] = true;
      return <span className={classNames(switcherCls)} onClick={thisOnExpand} />;
    }
    return <span className={classNames(switcherCls)} onClick={thisOnExpand} />;
  }

  renderCheckbox(props) {
    const { prefixCls } = props;
    const checkboxCls = {
      [`${prefixCls}-checkbox`]: true,
    };
    // 三态父节点
    if (props.pnsChecked) {
      checkboxCls[`${prefixCls}-checkbox-checked-o`] = true;
    } else if (props.checked) {
      checkboxCls[`${prefixCls}-checkbox-checked`] = true;
    } else if (props.halfChecked) {
      checkboxCls[`${prefixCls}-checkbox-indeterminate`] = true;
    }
    let customEle = null;
    if (typeof props.checkable !== 'boolean') {
      customEle = props.checkable;
    }
    const thisOnCheck = props.disabled || props.disableCheckbox ? noop : this.onCheck;
    if (props.disabled || props.disableCheckbox) {
      checkboxCls[`${prefixCls}-checkbox-disabled`] = true;
      return (
        <span
          ref={span => {
            this.checkbox = span;
          }}
          className={classNames(checkboxCls)}
          onClick={thisOnCheck}
        >
          {customEle}
        </span>
      );
    }
    return (
      <span
        ref={span => {
          this.checkbox = span;
        }}
        className={classNames(checkboxCls)}
        onClick={thisOnCheck}
      >
        {customEle}
      </span>
    );
  }

  renderChildren(props) {
    const { renderFirst } = this;
    this.renderFirst = 1;
    let transitionAppear = true;
    if (!renderFirst && props.expanded) {
      transitionAppear = false;
    }
    const { children } = props;
    let newChildren = children;
    if (
      children &&
      ((typeof children.type === 'function' && children.type.displayName === 'TreeNode') ||
        (Array.isArray(children) &&
          children.every(item => typeof item.type === 'function' && item.type.displayName === 'TreeNode')))
    ) {
      const cls = {
        [`${props.prefixCls}-child-tree`]: true,
        [`${props.prefixCls}-child-tree-open`]: props.expanded,
      };
      if (props.showLine) {
        cls[`${props.prefixCls}-line`] = !props.last;
      }
      const animProps = {};
      if (props.openTransitionName) {
        animProps.transitionName = props.openTransitionName;
      } else if (typeof props.openAnimation === 'object') {
        animProps.animation = Object.assign({}, props.openAnimation);
        if (!transitionAppear) {
          delete animProps.animation.appear;
        }
      }
      newChildren = (
        <Animate {...animProps} showProp="data-expanded" transitionAppear={transitionAppear} component="">
          {!props.expanded ? null : (
            <ul className={classNames(cls)} data-expanded={props.expanded}>
              {React.Children.map(
                children,
                (item, index) => props.root.renderTreeNode(item, index, props.pos),
                props.root,
              )}
            </ul>
          )}
        </Animate>
      );
    }
    return newChildren;
  }

  render() {
    const { props } = this;
    const { prefixCls } = props;
    const expandedState = props.expanded ? 'open' : 'close';
    const iconEleCls = {
      [`${prefixCls}-iconEle`]: true,
      [`${prefixCls}-icon_loading`]: this.state.dataLoading,
      [`${prefixCls}-icon__${expandedState}`]: true,
    };
    let canRenderSwitcher = true;
    const content = props.title;
    let newChildren = this.renderChildren(props);
    if (!newChildren || newChildren === props.children) {
      // content = newChildren
      newChildren = null;
      if (!props.loadData || props.isLeaf) {
        canRenderSwitcher = false;
      }
    }
    // For performance, does't render children into dom when `!props.expanded` (move to Animate)
    // if (!props.expanded) {
    //   newChildren = null
    // }
    const selectHandle = () => {
      const icon =
        props.showIcon || (props.loadData && this.state.dataLoading) ? (
          <span className={classNames(iconEleCls)} />
        ) : null;
      const title = <span className={`${prefixCls}-title`}>{content}</span>;
      const domProps = {
        className: `${prefixCls}-node-content-wrapper`,
      };
      if (!props.disabled) {
        if (props.selected || (!props._dropTrigger && this.state.dragNodeHighlight)) {
          domProps.className += ` ${prefixCls}-node-selected`;
        }
        domProps.onClick = e => {
          e.preventDefault();
          if (props.selectable) {
            this.onSelect();
          } else {
            this.onClick();
          }
          // not fire check event
          // if (props.checkable) {
          //   this.onCheck()
          // }
        };
        if (props.onRightClick) {
          domProps.onContextMenu = this.onContextMenu;
        }
        if (props.onMouseEnter) {
          domProps.onMouseEnter = this.onMouseEnter;
        }
        if (props.onMouseLeave) {
          domProps.onMouseLeave = this.onMouseLeave;
        }
        if (props.draggable) {
          domProps.className += ' draggable';
          if (ieOrEdge) {
            // ie bug!
            domProps.href = '#';
          }
          domProps.draggable = true;
          domProps['aria-grabbed'] = true;
          domProps.onDragStart = this.onDragStart;
        }
      }
      return (
        <a
          {...domProps}
          ref={a => {
            this.selectHandle = a;
          }}
          title={typeof content === 'string' ? content : ''}
        >
          {icon}
          {title}
        </a>
      );
    };
    const liProps = {};
    if (props.draggable) {
      liProps.onDragEnter = this.onDragEnter;
      liProps.onDragOver = this.onDragOver;
      liProps.onDragLeave = this.onDragLeave;
      liProps.onDrop = this.onDrop;
      liProps.onDragEnd = this.onDragEnd;
    }
    let disabledCls = '';
    let dragOverCls = '';
    if (props.disabled) {
      disabledCls = `${prefixCls}-treenode-disabled`;
    } else if (props.dragOver) {
      dragOverCls = 'drag-over';
    } else if (props.dragOverGapTop) {
      dragOverCls = 'drag-over-gap-top';
    } else if (props.dragOverGapBottom) {
      dragOverCls = 'drag-over-gap-bottom';
    }
    const filterCls = props.filterTreeNode(this) ? 'filter-node' : '';
    const noopSwitcher = () => {
      const cls = {
        [`${prefixCls}-switcher`]: true,
        [`${prefixCls}-switcher-noop`]: true,
      };
      if (props.showLine) {
        cls[`${prefixCls}-center_docu`] = !props.last;
        cls[`${prefixCls}-bottom_docu`] = props.last;
      } else {
        cls[`${prefixCls}-noline_docu`] = true;
      }
      return <span className={classNames(cls)} />;
    };
    return (
      <li
        {...liProps}
        id={props.eventKey}
        ref={li => {
          this.li = li;
        }}
        className={classNames(props.className, disabledCls, dragOverCls, filterCls)}
      >
        {canRenderSwitcher ? this.renderSwitcher(props, expandedState) : noopSwitcher()}
        {props.checkable ? this.renderCheckbox(props) : null}
        {selectHandle()}
        {newChildren}
      </li>
    );
  }
}
TreeNode.isTreeNode = 1;
TreeNode.propTypes = {
  prefixCls: PropTypes.string,
  disabled: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
  expanded: PropTypes.bool,
  isLeaf: PropTypes.bool,
  root: PropTypes.object,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
  // last: PropTypes.bool,
};
TreeNode.defaultProps = {
  title: defaultTitle,
  isChecked: false,
};
export default TreeNode;
