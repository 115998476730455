import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Radio, Dropdown, Icon, Input, PureCheckbox as Checkbox } from 'components';
import { Map } from 'immutable';
import { fromJS, highlightStr } from 'utils';
import { prefixCls } from './index.scss';

class CheckDrop extends PureComponent {
  static propTypes = {
    inputStyle: PropTypes.object,
    inputClass: PropTypes.string,
    // inputDefVal: PropTypes.string,
    inputIconType: PropTypes.string,
    sectionClass: PropTypes.string,
    header: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isDropdown: PropTypes.bool,
    radioKey: PropTypes.string,
    placeholder: PropTypes.string,
    inputValueEffect: PropTypes.array, // effect数组值从item取值，有一个checked为true就展示输入框value
    // checkClick: PropTypes.func,
  };
  static defaultProps = {
    // isDisable: true,
    isDropdown: false,
    disabled: false,
    placeholder: '',
  };

  constructor(prop) {
    super(prop);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      value: '',
      data: Map(), // == content
      inputValues: [],
      isHide: true,
      // radioVal: prop.content.radioDef,
    };
    this.isDropClick = false;
  }

  UNSAFE_componentWillMount() {
    const { content = {} } = this.props;
    this.setState({
      // radioVal: content.radioDef,
      // data: fromJS(content.mainData)
      data: fromJS(content),
    });
    // console.log('here is UNSAFE_componentWillMount -----> state')
    // console.log(this.state.data)
  }

  componentDidMount() {
    this.initInputVal();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { content = {} } = nextProps;
    this.setState(
      {
        // radioVal: content.radioDef,
        // data: fromJS(content.mainData),
        data: fromJS(content),
      },
      () => this.initInputVal(),
    );
  }

  /* UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      this.replaceState(nextState)
    }
  } */
  //  test checkClick begin
  checkClick = (row, column, value) => {
    // value;
  };
  // test checkClick end
  inputClick = () => {
    const { isDropdown } = this.props;

    this.setState({
      keyword: '',
      isHide: false,
    });
    if (isDropdown) {
      this.drop.open();
    }
  };
  inputChange = e => {
    this.setState({
      keyword: e.target.value,
    });
  };
  inputBlur = () => {
    setTimeout(() => {
      if (this.isDropClick) {
        this.isDropClick = false;
        return;
      }
      this.setState({
        isHide: true,
      });
    }, 200);
  };
  containerClick = () => {
    console.log('here is containerClick');
    this.isDropClick = true;
    this.input.focus();
    this.setState({
      isHide: false,
    });
  };
  radioClick = (val, event) => {
    event.stopPropagation();
    console.log(`radioClick ${val}`);
    const _data = this.state.data.setIn(['radioDef'], val);
    this.setState(
      {
        // radioVal: val
        data: _data,
      },
      () => this.props.onClick && this.props.onClick(this.state.data.toJS()),
    );
  };
  renderRadio = props => {
    const { data, disabled = false } = props;
    return (
      <Radio
        value={this.state.data.toJS().radioDef}
        classname="fn-radio"
        data={data}
        disabled={disabled}
        onClick={this.radioClick}
      />
    );
  };
  initInputVal = () => {
    const { data = Map() } = this.state;
    const { inputValueEffect } = this.props;
    let dataAry = data.toJS().mainData;
    if (Object.keys(dataAry).length === 0) dataAry = {};
    const _inputValues = [];
    Object.values(dataAry).map((item, index) => {
      if (item?.show?.Props?.checked) {
        _inputValues.push(item.field_name.Props.children);
      } else if (inputValueEffect && Array.isArray(inputValueEffect)) {
        inputValueEffect.some(key => item[key]?.Props?.checked) && _inputValues.push(item.field_name.Props.children);
      }
      return index;
    });
    // console.log(data, inputValues, _inputValues)
    this.setState({
      inputValues: _inputValues,
    });
  };
  checkBoxClick = (rowIndex, rowKey, column, event) => {
    event.stopPropagation();
    const { radioKey } = this.props;
    const isChecked = event.target.checked;
    const { data = Map(), inputValues = [] } = this.state;

    // const rowIndexRadioVal = data.getIn(['mainData', rowIndex, radioKey, 'Props', 'data', '0', 'value'])
    // const radioDef = data.get('radioDef')
    // if (rowIndexRadioVal === radioDef) {
    //   return false
    // }

    let _data = data
      .setIn(['mainData', rowIndex, column, 'Props', 'checked'], isChecked)
      .set('changeKey', rowIndex)
      .set('changeCol', column);
    radioKey && (_data = _data.setIn(['mainData', rowIndex, radioKey, 'Props', 'disabled'], !isChecked));
    const _text = data.getIn(['mainData', rowIndex, rowKey, 'Props', 'children']);
    let _inputValues = null;
    if (inputValues.includes(_text)) {
      _inputValues = inputValues.filter(item => item !== _text);
    } else {
      _inputValues = [...inputValues, _text];
    }
    this.isDropClick = true;
    this.input.focus();
    this.setState(
      {
        data: _data,
        isHide: false,
        inputValues: _inputValues,
      },
      this.props.onClick && this.props.onClick(_data.toJS(), isChecked),
    );
  };
  renderHeader = header =>
    Object.entries(header).map(([key, value]) => {
      let classHere = 'w100';
      if (value.type !== 'text') {
        classHere = 'w40';
      }
      return (
        <span className={classHere} key={key}>
          {value.text}
        </span>
      );
    });
  isDisabled = (key, row, radioDef) => {
    const { radioKey } = this.props;
    const oriDis = (row.otherProps || {}).disable && row.otherProps.disable.includes(key);
    const currKeyRadio = radioKey && row[radioKey].Props.data[0].value;
    const stateDis = radioKey ? radioDef === currKeyRadio : false;
    return oriDis || stateDis;
  };
  renderLiItem = () => {
    const keyField = this.props.content.key;
    const { data = Map(), keyword } = this.state;
    const dataToJS = data.toJS();
    const { radioDef } = dataToJS;
    const _data = Object.entries(dataToJS.mainData);
    const filtered = keyword
      ? _data.filter(([k, v]) => !v.field_name || v.field_name.Props.children.includes(keyword))
      : _data;
    if (!filtered.length) {
      return null;
    }
    return filtered.map(([key, value]) => (
      <li className="check_drop_item" key={key}>
        {Object.entries(value).map(([index, item]) => {
          if (index === 'otherProps') {
            return true;
          }
          let classHere = 'w100';
          if (item.checkType !== 'Text') {
            classHere = 'w40';
          }
          return (
            <span className={classHere} key={index}>
              {item.checkType === 'Text' && highlightStr(item.Props.children, keyword)}
              {item.checkType === 'Radio' && this.renderRadio(item.Props)}
              {item.checkType === 'CheckBox' && (
                <Checkbox
                  checked={item.Props.checked}
                  disabled={item.Props.disabled || this.isDisabled(index, value, radioDef)}
                  onClick={e => this.checkBoxClick(key, keyField, index, e)}
                />
              )}
            </span>
          );
        })}
      </li>
    ));
    // console.log(content)
  };

  renderDropdownMenu(options) {
    const { header, content, width } = options ?? {};

    return (
      <div className={`${prefixCls} ${prefixCls}__dropdown-cell`} style={{ width }} onClick={this.containerClick}>
        <div className={`${prefixCls}__header`}>{this.renderHeader(header)}</div>
        <ul className={`${prefixCls}__ul`}>{this.renderLiItem(content)}</ul>
      </div>
    );
  }

  renderDropdownCell(options) {
    const { inputStyle, inputClass, disabled } = this.props;
    const { isHide, keyword } = this.state;

    return (
      <Dropdown ref={drop => (this.drop = drop)} menu={this.renderDropdownMenu(options)}>
        <Input
          style={inputStyle}
          disabled={disabled}
          ref={r => (this.input = r)}
          defaultValue={isHide ? this.state.inputValues.join(',') : keyword}
          classname={inputClass}
          onClick={this.inputClick}
          onChange={this.inputChange}
          onBlur={this.inputBlur}
          placeholder={this.props.placeholder}
        />
      </Dropdown>
    );
  }

  render() {
    const { isHide, keyword } = this.state;
    const { isDropdown, inputClass, inputIconType, sectionClass, header, content } = this.props;
    const hideClass = isHide ? 'hide' : '';
    let wh = 10;
    for (const value of Object.values(header || {})) {
      if (value.type === 'text') {
        wh += 170;
      } else {
        wh += 60;
      }
    }
    return (
      <div className={sectionClass}>
        {isDropdown ? (
          this.renderDropdownCell({ header, content, width: wh })
        ) : (
          <>
            <Input
              disabled={this.props.disabled}
              ref={r => (this.input = r)}
              defaultValue={isHide ? this.state.inputValues.join(',') : keyword}
              classname={inputClass}
              onClick={this.inputClick}
              onChange={this.inputChange}
              onBlur={this.inputBlur}
              placeholder={this.props.placeholder}
            />
            {inputIconType && <Icon iconType={inputIconType} />}
            {!isHide && (
              <div className={`${prefixCls}  ${hideClass}`} style={{ width: wh }} onClick={this.containerClick}>
                <div className={`${prefixCls}__header`}>{this.renderHeader(header)}</div>
                <ul className={`${prefixCls}__ul`}>{this.renderLiItem(content)}</ul>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
export default CheckDrop;
